import clsx from 'clsx';
import { InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import Icon from '../../Icon/Icon';
import styles from './CurrencyInput.module.css';

type CustomInput = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'onBlur' | 'value'
>;
interface Props extends CustomInput {
  backspace?: () => void;
  showClearButton?: boolean;
  containerClassName?: string;
  onValueUpdate?: (val: string) => void;
  onChange?: (val: number) => void;
  onBlur?: (val: number) => void;
  value?: number;
}

export default function CurrencyInput({
  type = 'number',
  className = '',
  containerClassName = '',
  backspace,
  showClearButton = false,
  value,
  onBlur,
  onChange,
  ...rest
}: Props) {
  const [displayVal, setDisplayVal] = useState('');

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (value === 0) {
      setDisplayVal('');
    } else if (value !== undefined) {
      setDisplayVal(value.toFixed(2));
    }
  }, [value]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = e.target.value.replace('$', '');
      if (!isNaN(+newVal)) {
        setDisplayVal(newVal);
        if (onChange) {
          onChange(+newVal);
        }
      }
    },
    [onChange]
  );

  const onInputBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = e.target.value.replace('$', '');
      if (!isNaN(parseInt(newVal))) {
        setDisplayVal((+newVal).toFixed(2));
        if (onBlur) onBlur(+newVal);
      }
      setIsFocused(false);
    },
    [onBlur]
  );

  return (
    <div className={clsx(styles.inputContainer, containerClassName)}>
      <input
        type="text"
        className={clsx(styles.input, className)}
        {...rest}
        onFocus={() => {
          setIsFocused(true);
        }}
        onChange={onInputChange}
        onBlur={onInputBlur}
        value={displayVal ? `$${displayVal}` : ''}
        placeholder={!isFocused ? '$0.00' : ''}
        inputMode="numeric"
        pattern="\d*"
      />
      {showClearButton && (
        <div
          className={styles.iconContainer}
          onClick={backspace}
          role="button"
          tabIndex={-1}
        >
          <Icon className={styles.icon} name="clear" />
        </div>
      )}
    </div>
  );
}
