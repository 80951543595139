import dayjs from 'dayjs';
import { groupBy, keys } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
import {
  SportCategoriesWithEventsMinimalDocument,
  SportCategoriesWithEventsMinimalQuery,
  SportCategoriesWithEventsMinimalQueryVariables,
} from '../../generated/graphql';
import slugify from '../../utils/slugify';
import Icon from '../Icon/Icon';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import styles from './SportEventSelect.module.css';

type SportEventSelectProps = {
  categoryId?: string;
  sportEventId?: string;
};

const today = dayjs().startOf('day');
const endDate = today.add(2, 'days').endOf('day');

const SportEventSelect = ({
  categoryId,
  sportEventId,
}: SportEventSelectProps) => {
  const categoryIds = categoryId ? [categoryId] : [];

  const [{ data, fetching }] = useQuery<
    SportCategoriesWithEventsMinimalQuery,
    SportCategoriesWithEventsMinimalQueryVariables
  >({
    query: SportCategoriesWithEventsMinimalDocument,
    pause: categoryIds.length === 0,
    variables: {
      categoryIds,
      startDate: today.toDate(),
      endDate: endDate.toDate(),
    },
  });

  const compWithEvents =
    data?.sportCategories
      ?.flatMap((e) => e.competitions)
      .flatMap((c) => c?.events || [])
      .filter((c) => c) || [];

  const currentEventFiltered = compWithEvents.filter(
    (c) => c.id !== sportEventId
  );

  const grouped = groupBy(compWithEvents, 'competition.name');

  const navigate = useNavigate();

  const hasEvents = !fetching && currentEventFiltered.length > 0;

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  return (
    <div className={styles.container}>
      <select
        name="sportEvents"
        id="sportEvents"
        value={selectedValue || sportEventId}
        onChange={(e) => {
          const event = compWithEvents.find((ev) => ev.id === e.target.value);
          const sportType =
            event?.competition.category.sport_type.name.toLowerCase();
          const competition = slugify(event?.competition.category.name || '');
          navigate(
            `/sports/event/${sportType}/${competition}/${event?.id}/all`
          );
          setSelectedValue(e.target.value);
        }}
      >
        {hasEvents &&
          keys(grouped).map((group) => (
            <optgroup label={group} key={group}>
              {grouped[group].map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </optgroup>
          ))}
        {!hasEvents && <optgroup label="There are no other events" />}
      </select>
      <div className={styles.dropDown}>
        {fetching && (
          <div className={styles.loadingContainer}>
            <LoadingSpinner />
          </div>
        )}
        {!fetching && <span>@</span>}
        <Icon name="caretDown" />
      </div>
    </div>
  );
};
export default SportEventSelect;
