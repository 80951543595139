import betTypes from '../../constants/betTypes';

export const betTypeObjFromString = (betType: string) => {
  switch (betType) {
    case betTypes.WIN:
      return betTypes.WIN;
    case betTypes.PLACE:
      return betTypes.PLACE;
    case betTypes.SHOW:
      return betTypes.SHOW;
    case betTypes.EXACTA:
      return betTypes.EXACTA;
    case betTypes.TRIFECTA:
      return betTypes.TRIFECTA;
    case betTypes.QUINELLA:
      return betTypes.QUINELLA;
    case betTypes.SUPERFECTA:
      return betTypes.SUPERFECTA;
    case betTypes.DOUBLE:
      return betTypes.DOUBLE;
    case betTypes.PICK3:
      return betTypes.PICK3;
    case betTypes.PICK6:
      return betTypes.PICK6;
    case betTypes.DAILY_DOUBLE:
      return betTypes.DAILY_DOUBLE;
    case betTypes.EARLY_QUADRELLA:
      return betTypes.EARLY_QUADRELLA;
    case betTypes.EXTRA_DOUBLE:
      return betTypes.EXTRA_DOUBLE;
    case betTypes.FIRST_FOUR:
      return betTypes.FIRST_FOUR;
    case betTypes.OPENING_FLUC:
      return betTypes.OPENING_FLUC;
    case betTypes.PLACE_FIXED_ODDS2:
      return betTypes.PLACE_FIXED_ODDS2;
    case betTypes.QUADRELLA:
      return betTypes.QUADRELLA;
    case betTypes.QUINELLA_FIXED_ODDS:
      return betTypes.QUINELLA_FIXED_ODDS;
    case betTypes.QUINELLA_PLACE:
      return betTypes.QUINELLA_PLACE;
    case betTypes.RUNNING_DOUBLE:
      return betTypes.RUNNING_DOUBLE;
    case betTypes.SIX_UP:
      return betTypes.SIX_UP;
    case betTypes.STARTING_PRICE:
      return betTypes.STARTING_PRICE;
    case betTypes.TOP_FLUC:
      return betTypes.TOP_FLUC;
    case betTypes.TREBLE:
      return betTypes.TREBLE;
    case betTypes.TRIO:
      return betTypes.TRIO;
    case betTypes.WIN_FIXED_ODDS:
      return betTypes.WIN_FIXED_ODDS;

    default:
      return betTypes.EXACTA;
  }
};
