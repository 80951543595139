import { isEmpty } from 'lodash';
import { useQuery } from 'urql';
import {
  SportCategoriesDocument,
  SportCategoriesQuery,
  SportCategoriesQueryVariables,
} from '../../generated/graphql';

const useSportCategories = (params: SportCategoriesQueryVariables) => {
  const [{ data, ...rest }] = useQuery<
    SportCategoriesQuery,
    SportCategoriesQueryVariables
  >({
    query: SportCategoriesDocument,
    pause: isEmpty(params.name) && !params.sportTypeId,
    variables: {
      categoryIds: params.categoryIds,
      name: params.name,
      sportTypeId: params.sportTypeId,
    },
  });

  return {
    data,
    ...rest,
  };
};

export default useSportCategories;
