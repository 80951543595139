import React from 'react';
import SportTeamJersey from '../../../SportTeamJersey/SportTeamJersey';
import styles from './Basketball.module.css';

type BasketballProps = {};

const Basketball = (props: BasketballProps) => (
  <div className={styles.container}>
    <div className={styles.team}>
      <SportTeamJersey
        teamName="Chelsea"
        jersey="chicago"
        className={styles.teamName}
      />
      <span className={styles.score}>2</span>
    </div>
    <div className={styles.team}>
      <SportTeamJersey
        teamName="Arsenal"
        jersey="lakers"
        className={styles.teamName}
      />
      <span className={styles.score}>0</span>
    </div>
  </div>
);

export default Basketball;
