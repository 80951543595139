import dayjs from 'dayjs';
import { flatten } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import {
  SportCategoriesWithEventsDocument,
  SportCategoriesWithEventsQuery,
  SportCategoriesWithEventsQueryVariables,
} from '../../generated/graphql';
import { TopSportMarkets } from './../../utils/sport/transformers/SportEvents';

const today = dayjs().subtract(1, 'hours').startOf('day');
const tomorrow = today.add(1, 'days').endOf('day');

export default function useUpcomingSportTypeEvents(sportTypeId?: number) {
  const [{ data, ...rest }] = useQuery<
    SportCategoriesWithEventsQuery,
    SportCategoriesWithEventsQueryVariables
  >({
    query: SportCategoriesWithEventsDocument,
    pause: sportTypeId === undefined,
    variables: {
      startDate: today.toDate(),
      endDate: tomorrow.toDate(),
      sportTypeId,
    },
  });

  const events = useMemo(() => {
    if (sportTypeId === undefined) return [];
    const allEvents = flatten(
      flatten(
        data?.sportCategories?.map((a) =>
          a.competitions?.map((comp) => comp.events)
        )
      )
    ).filter((a) => a);

    // TODO change any type
    const allEventsWithMarket = TopSportMarkets(allEvents as any);

    return allEventsWithMarket;
  }, [data, sportTypeId]);

  return { events, ...rest };
}
