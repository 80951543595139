import { find } from 'lodash';
import { HEAD_TO_HEAD_MARKET_TYPE } from '../../../constants/marketTypes';
import {
  ExtendedSportEvents,
  SportEvent,
  SportEvents,
} from '../../../types/sport';

type Filter = number;

export const TopSportMarkets = (sportEvents: SportEvents, filters?: Filter[]) =>
  sportEvents
    .filter((event) => filterEventsBaseOnSportType(event, filters))
    .sort(sortByStartDate)
    .map(extratDetails)
    .filter(filterEventsWithMatchMarkets);

const filterEventsBaseOnSportType = (
  sportEvent: SportEvent,
  filters?: Filter[]
) => {
  if (!filters) return sportEvent;
  return filters[0] === -1 || filters.length === 0
    ? true
    : filters.includes(sportEvent.competition.category.sport_type.id);
};
const sortByStartDate = (a: SportEvent, b: SportEvent) =>
  new Date(a.start_date).getTime() - new Date(b.start_date).getTime();

const extratDetails = (event: SportEvent): ExtendedSportEvents => ({
  ...event,
  countOfMarkets: event?.countOfMarkets || 0,
  matchMarket: find(event.markets, {
    name: HEAD_TO_HEAD_MARKET_TYPE.match.name,
  }),
  lineMarket: find(event.markets, {
    name: HEAD_TO_HEAD_MARKET_TYPE.match.name,
  }), // TODO change to line
  totalMarket: find(event.markets, {
    name: HEAD_TO_HEAD_MARKET_TYPE.match.name,
  }),
});
const filterEventsWithMatchMarkets = (event: ExtendedSportEvents) =>
  event.matchMarket;
