import React from 'react';
import { useSelector } from 'react-redux';
import { SportsFilter, Tabs } from '../../../../components';
import { RootState } from '../../../../stores/store';
import styles from './FoxStatsSports.module.css';

const FoxStatsSports = () => {
  const iSportgeniusBaseUrl = useSelector(
    (state: RootState) => state.config.iSportgeniusBaseUrl
  );
  return (
    <div>
      <div className={styles.filters}>
        <SportsFilter onToggle={console.log} />
      </div>
      <div className={styles.tabs}>
        <Tabs />
      </div>
      <div className={styles.content}>
        <iframe title="Sports Hub" src={iSportgeniusBaseUrl} />
      </div>
    </div>
  );
};

export default FoxStatsSports;
