import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery, useSubscription } from 'urql';
import Button from '../../../../components/Button/Button';
import CountryFlag, {
  FlagName,
} from '../../../../components/CountryFlag/CountryFlag';
import HomeContainer from '../../../../components/HomeContainer/HomeContainer';
import Icon from '../../../../components/Icon/Icon';
import RaceStatus from '../../../../components/RaceStatus/RaceStatus';
import {
  RaceCardHeaderDocument,
  RaceCardHeaderQuery,
  RaceCardHeaderQueryVariables,
  RaceStatus as RaceStatusEnum,
  RaceUpdatedDocument,
  RaceUpdatedSubscription,
  RaceUpdatedSubscriptionVariables,
} from '../../../../generated/graphql';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs/useBreadcrumbs';
import useFormat from '../../../../hooks/useFormat';
import { RootState } from '../../../../stores/store';
import { LocaleType } from '../../../../types/localeType';
import LiveVision from '../../LiveVision/LiveVision';
import styles from './RaceCardHeader.module.css';
import SelectMeeting from './SelectMeeting';

type Props = {
  meetingId: string;
  raceId?: string;
  onChangeMeeting: (meetingId: string) => void;
  onChangeRace: (raceId: string) => void;
  resulted?: boolean;
  hideBreadcrumbs?: boolean;
  children?: React.ReactNode;
};

const selector = createSelector(
  [(state: RootState) => state.config.options.locale],
  (locale) => ({
    locale,
  })
);

export default function RaceCardHeader({
  meetingId,
  raceId,
  onChangeMeeting,
  onChangeRace,
  hideBreadcrumbs = false,
  children,
}: Props) {
  const params = useParams<{
    meetingId: string;
    raceId: string;
  }>();
  const [showLiveVision, setShowLiveVision] = useState(false);
  const [race, setRace] = useState<RaceCardHeaderQuery['race']>();
  const { locale } = useSelector(selector);
  const [results] = useQuery<RaceCardHeaderQuery, RaceCardHeaderQueryVariables>(
    {
      query: RaceCardHeaderDocument,
      variables: {
        meetingId,
        raceId,
        fetchRace: !!raceId,
        isUSA: locale === LocaleType.US,
      },
    }
  );
  useEffect(() => {
    setRace(results.data?.race);
  }, [results]);

  const [subscription] = useSubscription<
    RaceUpdatedSubscriptionVariables,
    RaceUpdatedSubscription
  >({
    query: RaceUpdatedDocument,
    variables: {
      raceId,
    },
  });
  useEffect(() => {
    if (subscription.data) {
      setRace((prev) => {
        if (prev) {
          return {
            ...prev,
            ...(subscription?.data?.raceUpdated || {}),
          };
        }

        return prev;
      });
    }
  }, [subscription]);

  const meeting = useMemo(() => results.data?.meeting, [results]);
  const route = useMemo(
    () =>
      hideBreadcrumbs
        ? []
        : [
            {
              label: 'Racing Home',
              url: '/',
            },
            {
              label: meeting?.track.name || 'Meeting',
              url: `/racing/meeting/${meetingId}`,
            },
            {
              label: `Race ${race?.number || ''}`,
              url: `/racing/meeting/${meetingId}/race/${raceId}`,
            },
          ],
    [race, meeting, meetingId, raceId, hideBreadcrumbs]
  );
  useBreadcrumbs(route);
  const coreTabs = useMemo(
    () =>
      locale === LocaleType.US
        ? [
            {
              label: `Win / Place / Show`,
              url: `/racing/meeting/${params.meetingId}/race/${params.raceId}`,
              desktopOnly: true,
            },
            {
              label: 'Win',
              url: `/racing/meeting/${params.meetingId}/race/${params.raceId}`,
              mobileOnly: true,
            },
            {
              label: 'Place',
              url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/place`,
              mobileOnly: true,
            },
            {
              label: 'Show',
              url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/show`,
              mobileOnly: true,
            },
          ]
        : [
            {
              label: `Win / Place`,
              url: `/racing/meeting/${params.meetingId}/race/${params.raceId}`,
              desktopOnly: true,
            },
            {
              label: 'Win',
              url: `/racing/meeting/${params.meetingId}/race/${params.raceId}`,
              mobileOnly: true,
            },
            {
              label: 'Place',
              url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/place`,
              mobileOnly: true,
            },
          ],
    [params, locale]
  );
  const platformTabs = useMemo(
    () => [
      {
        label: 'Same Race Multi',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/same-race-multi`,
      },
      {
        label: 'Quinella',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/quinella`,
      },
      {
        label: 'Exacta',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/exacta`,
      },
      {
        label: 'Trifecta',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/trifecta`,
      },
      {
        label: 'First 4',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/first-4`,
      },
      {
        label: 'Quaddie',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/quaddie`,
      },
      {
        label: 'Early Quaddie',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/early-quaddie`,
      },
      {
        label: 'Daily Double',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/daily-double`,
      },
      {
        label: 'Treble',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/treble`,
      },
      {
        label: 'Super 6',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/super-6`,
      },
      {
        label: 'Running Double',
        url: `/racing/meeting/${params.meetingId}/race/${params.raceId}/running-double`,
      },
    ],
    [params]
  );
  const [showDescription, setShowDescription] = useState(false);
  const format = useFormat();

  return (
    <>
      {showLiveVision ? (
        <LiveVision onDismiss={() => setShowLiveVision(false)} />
      ) : null}
      <HomeContainer
        hideBreadcrumbs={hideBreadcrumbs}
        HeaderComponent={
          <div className={styles.container}>
            <div className={styles.header}>
              <SelectMeeting
                meeting={meeting}
                onChangeMeeting={onChangeMeeting}
              />
              {!meeting?.hasVision && race?.status === RaceStatusEnum.Open ? (
                <Button
                  theme="primary"
                  onClick={() => setShowLiveVision((prev) => !prev)}
                  size="small"
                >
                  <Icon name="watch" />{' '}
                  <span className={styles.label}>Watch</span>
                </Button>
              ) : null}
            </div>
            <div className={styles.races}>
              {meeting?.races.map((r) => (
                <button
                  key={r.id}
                  type="button"
                  className={clsx(styles.raceNumber, {
                    [styles.closed]: r.status !== RaceStatusEnum.Open,
                    [styles.selected]: r.id === raceId,
                  })}
                  onClick={() => onChangeRace(r.id)}
                >
                  {r.number}
                </button>
              ))}
            </div>
            <div className={styles.information}>
              <div className={styles.primary}>
                <span className={styles.name}>{race?.name}</span>
                <span className={styles.time}>
                  {race ? (
                    <>
                      {dayjs(race?.startTime).format('h:mma')}{' '}
                      <RaceStatus
                        startTime={race.startTime}
                        status={race.status}
                      />
                    </>
                  ) : null}
                </span>
              </div>
              <div className={styles.content}>
                {locale === LocaleType.US && race?.prizeMoney ? (
                  <span>
                    <span className={styles.label}>Purse:</span>{' '}
                    {format.currency(race.prizeMoney)}
                  </span>
                ) : null}
                {meeting?.track.surface ? (
                  <span className={styles.surface}>
                    <span className={styles.label}>Surface:</span>{' '}
                    {meeting?.track.surface}
                  </span>
                ) : null}
                {race?.distance ? (
                  <span className={styles.distance}>
                    <span className={styles.label}>Dist:</span>{' '}
                    {format.distance(race.distance)}
                  </span>
                ) : null}
                {meeting ? (
                  <span className={styles.country}>
                    <CountryFlag
                      countryCode={meeting.track.country as FlagName}
                    />{' '}
                    {meeting.track.country}
                  </span>
                ) : null}
                {locale === LocaleType.US && race?.comments?.[0]?.comment ? (
                  <button
                    type="button"
                    onClick={() => setShowDescription((prev) => !prev)}
                    className={clsx(styles.descriptionButton, {
                      [styles.selected]: showDescription,
                    })}
                  >
                    Race Description{' '}
                    <Icon
                      name="chevron"
                      size="very-small"
                      className={styles.icon}
                    />
                  </button>
                ) : null}
              </div>
            </div>
            {showDescription ? <div>{race?.comments?.[0]?.comment}</div> : null}
          </div>
        }
        tabs={
          race?.status !== RaceStatusEnum.Open
            ? []
            : [...coreTabs, ...platformTabs]
        }
      >
        {children}
      </HomeContainer>
    </>
  );
}
