import ContentLoader from 'react-content-loader';

export default function TwoBoxShimmer() {
  return (
    <ContentLoader viewBox="0 0 192 48">
      <rect x="0" y="0" rx="2" ry="2" width="96" height="48" />
      <rect x="104" y="0" rx="2" ry="2" width="96" height="48" />
    </ContentLoader>
  );
}
