import clsx from 'clsx';
import { ForwardedRef, forwardRef } from 'react';
import Icon, { IconName } from '../Icon/Icon';
import styles from './ShadowButton.module.css';

type ShadowButtonType = 'button' | 'submit' | 'reset';
type ShadowButtonSize = 'default' | 'small' | 'medium' | 'large';

interface ShadowButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: ShadowButtonType;
  size?: ShadowButtonSize;
  selected?: boolean;
  rightIcon?: IconName;
  leftIcon?: IconName;
}

const ShadowButton = forwardRef(
  (
    {
      type = 'button',
      size = 'default',
      className = '',
      rightIcon,
      leftIcon,
      children,
      selected,
      ...rest
    }: ShadowButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <button
      /* eslint-disable react/button-has-type */
      type={type}
      /* eslint-enable react/button-has-type */
      className={clsx(styles.button, styles[`size--${size}`], className)}
      data-selected={selected}
      ref={ref}
      {...rest}
    >
      {leftIcon && (
        <div className={clsx(styles.iconContainer, styles.marginRight)}>
          <Icon name={leftIcon} />
        </div>
      )}
      <span className={styles.buttonContent}>{children}</span>
      {rightIcon && (
        <div className={clsx(styles.iconContainer, styles.marginLeft)}>
          <Icon name={rightIcon} />
        </div>
      )}
    </button>
  )
);

export default ShadowButton;
