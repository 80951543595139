import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Silk from '../../../../components/Silk/Silk';
import { MeetingType } from '../../../../generated/graphql';
import useFormat from '../../../../hooks/useFormat';
import { RootState } from '../../../../stores/store';
import { LocaleType } from '../../../../types/localeType';
import { RaceCompetitor } from '../../../../types/racing';
import { SilkType } from '../../../../types/silk';
import styles from './RunnerDetails.module.css';

type Props = {
  meeting: {
    type: MeetingType;
    track: {
      country: string;
    };
  };
  competitor: Pick<
    RaceCompetitor,
    | 'tabNo'
    | 'name'
    | 'jockey'
    | 'trainer'
    | 'scratched'
    | 'silksURL'
    | 'coupledRunnerId'
    | 'barrier'
  >;
  position?: number;
};

export default function RunnerDetailsCompact({
  meeting,
  competitor,
  position,
}: Props) {
  const format = useFormat();
  const locale = useSelector((state: RootState) => state.config.options.locale);

  function renderDetails() {
    switch (meeting.type) {
      case MeetingType.Thoroughbred:
        return (
          <div className={styles.runnerContainer}>
            <span className={clsx(styles.long, styles.jockey)}>
              <span className={styles.label}>J: </span>
              <span>{competitor.jockey}</span>
            </span>
          </div>
        );
      case MeetingType.Harness:
        return (
          <div className={styles.runnerContainer}>
            <span className={styles.long}>
              <span className={styles.label}>D: </span>
              <span>{competitor.jockey}</span>
            </span>
          </div>
        );
      case MeetingType.Greyhound:
        return (
          <div className={styles.runnerContainer}>
            <span className={styles.long}>
              <span className={styles.label}>T: </span>
              <span>{competitor.trainer}</span>
            </span>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div>
      <div
        className={clsx(styles.competitor, styles.compact, {
          [styles.scratched]: competitor.scratched,
        })}
      >
        {position ? (
          <div
            className={clsx(
              styles.positionBox,
              styles[`position--${position}`]
            )}
          >
            {format.ordinal(position)}
          </div>
        ) : null}
        <div className={styles.silkContainer}>
          <Silk
            competitor={competitor}
            meeting={meeting}
            outline
            overwriteSilkType={
              meeting.type === MeetingType.Greyhound ? SilkType.Silk : undefined
            }
          />
        </div>
        <div className={styles.competitorDetails}>
          <span className={styles.competitorName}>
            {competitor.tabNo}. {competitor.name}
            {locale !== LocaleType.US ? ` (${competitor.barrier})` : ''}
          </span>
          {competitor.scratched ? (
            <span className={styles.label}>SCRATCHED</span>
          ) : (
            renderDetails()
          )}
        </div>
      </div>
    </div>
  );
}
