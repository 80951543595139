import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import { useSubscription } from 'urql';
import Label from '../../../../../components/Label';
import {
  RaceCardQuery,
  ResultFieldDocument,
  ResultFieldSubscription,
  ResultFieldSubscriptionVariables,
} from '../../../../../generated/graphql';
import useFormat from '../../../../../hooks/useFormat';
import styles from '../Fields.module.css';
import resultStyles from './ExoticResultsField.module.css';

type Props = {
  race: RaceCardQuery['race'];
};

enum ExoticResultsType {
  QUINELLA = 'Quinella',
  EXACTA = 'Exacta',
  TRIFECTA = 'Trifecta',
  FIRST_FOUR = 'First 4',
  QUADRELLA = 'Quaddie',
}
const exoticsList = [
  'QUINELLA',
  'EXACTA',
  'TRIFECTA',
  'FIRST_FOUR',
  'QUADRELLA',
];

export default function ExoticResultField({ race }: Props) {
  const [results, setResults] = useState(race?.results);

  useEffect(() => {
    if (race) {
      setResults(race?.results);
    }
  }, [race]);

  const exotics = exoticsList.filter((exotic) =>
    results
      ?.find((dividend) => dividend.source === 'V')
      ?.dividends?.find((dividend) => dividend.type === exotic)
  );
  const format = useFormat();

  const [subscription] = useSubscription<
    ResultFieldSubscriptionVariables,
    ResultFieldSubscription
  >({
    query: ResultFieldDocument,
    variables: {
      raceId: race?.id,
    },
  });
  useEffect(() => {
    if (subscription.data) {
      const {
        results: { positions, status, dividends },
      } = subscription.data;

      setResults((prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          status,
          positions,
          dividends,
        };
      });
    }
  }, [subscription]);

  return (
    <>
      {results ? (
        <div className={clsx(styles.grid, resultStyles.grid)}>
          <Label className={styles.label}>EXOTICS</Label>
          <Label className={styles.label}>VIC</Label>
          <Label className={styles.label}>NSW</Label>
          <Label className={styles.label}>QLD</Label>

          {exotics.map((exotic, i) => {
            return (
              <Fragment key={`${race?.id}-${exotic}`}>
                <div className={resultStyles.exoticLabel}>
                  <span className={resultStyles.exoticTitle}>
                    {ExoticResultsType[exotic]}
                  </span>
                  <span className={resultStyles.dividendNumbers}>
                    {results
                      ?.find((dividend) => dividend.source === 'V')
                      ?.dividends?.find((dividend) => dividend.type === exotic)
                      ?.dividendNumbers?.toString()}
                  </span>
                </div>
                <span className={styles.center}>
                  {format.odds(
                    results
                      ?.find((dividend) => dividend.source === 'V')
                      ?.dividends?.find((dividend) => dividend.type === exotic)
                      ?.dividend
                  )}
                </span>
                <span className={styles.center}>
                  {format.odds(
                    results
                      ?.find((dividend) => dividend.source === 'N')
                      ?.dividends?.find((dividend) => dividend.type === exotic)
                      ?.dividend
                  )}
                </span>
                <span className={styles.center}>
                  {format.odds(
                    results
                      ?.find((dividend) => dividend.source === 'Q')
                      ?.dividends?.find((dividend) => dividend.type === exotic)
                      ?.dividend
                  )}
                </span>
                {i !== exotics.length - 1 && (
                  <div className={styles.separator} />
                )}
              </Fragment>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
