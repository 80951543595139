import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscription } from 'urql';
import Countdown from '../../components/Countdown/Countdown';
import MeetingType from '../../components/MeetingType/MeetingType';
import OddsButton from '../../components/OddsButton/OddsButton';
import { BetType } from '../../constants/betTypes';
import {
  FeaturedRaceFragment,
  PriceType,
  PriceUpdatesDocument,
  PriceUpdatesSubscription,
  PriceUpdatesSubscriptionVariables,
  RaceStatus,
  RaceUpdatedDocument,
  RaceUpdatedSubscription,
  RaceUpdatedSubscriptionVariables,
} from '../../generated/graphql';
import { addOrRemoveBet } from '../../stores/betslipSlice';
import { RootState } from '../../stores/store';
import { BetslipType } from '../../types/betslipType';
import { hasSingleBet } from '../../utils/betslip/common';
import RunnerDetailsCompact from '../Racebook/RaceCard/RunnerDetails/RunnerDetailCompact';
import styles from './FeaturedRace.module.css';

type Props = {
  defaultRace: FeaturedRaceFragment;
  baseUrl: string;
};

const selector = createSelector(
  [
    (state: RootState) => state.config.options.betslipType,
    (state: RootState) => state.betslip.betslip,
    (state: RootState) => state.config.coreApiSource,
  ],
  (betslipType, betslip, source) => ({
    betslipType,
    betslip,
    source,
  })
);

export default function FeaturedRace({ defaultRace, baseUrl }: Props) {
  const dispatch = useDispatch();
  const [race, setRace] = useState(defaultRace);
  const { betslipType, betslip, source } = useSelector(selector);

  const [subscription] = useSubscription<
    RaceUpdatedSubscriptionVariables,
    RaceUpdatedSubscription
  >({
    query: RaceUpdatedDocument,
    variables: {
      raceId: race.id,
    },
  });
  useEffect(() => {
    if (subscription.data) {
      setRace((prev) => {
        if (prev) {
          const competitors =
            subscription?.data?.raceUpdated?.competitors || [];
          return {
            ...prev,
            ...(subscription?.data?.raceUpdated || {}),
            competitors: prev.competitors.map((competitor) => ({
              ...competitor,
              ...(competitors.find((c) => c.tabNo === competitor.tabNo) || {}),
            })),
          };
        }

        return prev;
      });
    }
  }, [subscription]);

  const [priceSubscription] = useSubscription<
    PriceUpdatesSubscriptionVariables,
    PriceUpdatesSubscription
  >({
    query: PriceUpdatesDocument,
    variables: {
      raceId: race.id,
      source,
    },
  });
  useEffect(() => {
    if (priceSubscription.data) {
      const { priceUpdates } = priceSubscription.data;
      setRace((prev) => ({
        ...prev,
        competitors: prev?.competitors.map((competitor) => ({
          ...competitor,
          prices: competitor.prices.map((price) => {
            const newPrice = priceUpdates.prices.find(
              (updatedPrice) =>
                updatedPrice.tabNo === competitor.tabNo &&
                updatedPrice.type === price.type
            );
            return newPrice || price;
          }),
        })),
      }));
    }
  }, [priceSubscription]);

  const getWinPrice = useCallback(
    (competitor: NonNullable<FeaturedRaceFragment>['competitors'][0]) =>
      competitor.prices?.find((price) => price.type === PriceType.WinFixedOdds)
        ?.price,
    []
  );

  if (!race) {
    return null;
  }

  function renderCompetitors() {
    if (!race) {
      return null;
    }

    if (race.results.length > 0) {
      return race.results[race.results.length - 1]?.positions
        .slice(0, 4)
        .map((pos) => {
          const competitor = race?.competitors.find(
            (c) => c.tabNo === pos.tabNo
          );

          if (!competitor) return null;

          const price = competitor.prices.find(
            (p) =>
              p.tabNo === competitor.tabNo && p.type === PriceType.WinFixedOdds
          );

          return (
            <Fragment key={competitor.tabNo}>
              <RunnerDetailsCompact
                meeting={race.meeting}
                competitor={competitor}
                position={pos.position}
              />
              <OddsButton
                price={price?.price}
                disabled
                className={styles.button}
              />
              <div className={styles.separator} />
            </Fragment>
          );
        });
    }

    return race.competitors
      .filter((competitor) => !competitor.scratched)
      .slice(0, 4)
      .map((competitor) => (
        <Fragment key={competitor.tabNo}>
          <RunnerDetailsCompact
            meeting={race.meeting}
            competitor={competitor}
            position={
              race?.results[race?.results.length - 1]?.positions.find(
                (pos) => pos.tabNo === competitor.tabNo
              )?.position
            }
          />
          <OddsButton
            className={styles.button}
            price={getWinPrice(competitor)}
            onClick={() => {
              if (race) {
                dispatch(
                  addOrRemoveBet({
                    betType: BetType.WIN_FIXED_ODDS,
                    traditional: betslipType === BetslipType.American,
                    odds: getWinPrice(competitor),
                    race,
                    meeting: race?.meeting,
                    competitor,
                  })
                );
              }
            }}
            selected={
              !!race &&
              hasSingleBet(betslip, {
                betType: BetType.WIN_FIXED_ODDS,
                race,
                meeting: race?.meeting,
                competitor,
              })
            }
            disabled={competitor.scratched}
          />
          <div className={styles.separator} />
        </Fragment>
      ));
  }

  return (
    <div className={clsx(styles.container, styles[race.meeting.type])}>
      <div className={styles.header}>
        <div className={styles.track}>
          <MeetingType meetingType={race.meeting.type} />
          <span className={styles.trackName}>{race.meeting.track.name}</span>
          <span className={styles.raceNumber}>Race {race.number}</span>
        </div>
        <div className={styles.triangle} />
        <div className={styles.description}>
          {race.status !== RaceStatus.Final ? (
            <Countdown startTime={race.startTime} />
          ) : (
            <span>Resulted</span>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div className={styles.label}>RUNNER</div>
          <div className={clsx(styles.label, styles.center)}>FIXED</div>
          <div className={styles.separator} />
          {renderCompetitors()}
        </div>
      </div>
      <a
        href={`${baseUrl}#/racing/meeting/${race.meeting.id}/race/${race.id}/win`}
        className={clsx(styles.link, 'link')}
      >
        View Race Card
      </a>
    </div>
  );
}
