import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useSubscription } from 'urql';
import {
  RaceDocument,
  RaceQuery,
  RaceQueryVariables,
  RaceUpdatedDocument,
  RaceUpdatedSubscription,
  RaceUpdatedSubscriptionVariables,
} from '../../generated/graphql';
import { RootState } from '../../stores/store';
import useWindowVisible from '../useWindowVisible';

export default function useRace(id?: string) {
  const [race, setRace] = useState<NonNullable<RaceQuery['race']>>();
  const focus = useWindowVisible();
  const source = useSelector((state: RootState) => state.config.coreApiSource);
  const toteSource = useSelector(
    (state: RootState) => state.config.coreApiToteSource
  );
  const toteEnabled = useSelector(
    (state: RootState) => state.config.options.toteEnabled
  );

  const [{ data, fetching }, refetch] = useQuery<RaceQuery, RaceQueryVariables>(
    {
      query: RaceDocument,
      variables: {
        id,
        source,
        toteEnabled,
        toteSource,
      },
    }
  );
  useEffect(() => {
    if (data?.race) {
      setRace(data?.race);
    }
    return () => {
      setRace(undefined);
    };
  }, [data]);

  const [subscription] = useSubscription<
    RaceUpdatedSubscriptionVariables,
    RaceUpdatedSubscription
  >({
    query: RaceUpdatedDocument,
    variables: {
      raceId: race?.id,
    },
    pause: !race,
  });
  useEffect(() => {
    if (subscription.data) {
      setRace((prev) => {
        if (prev) {
          const competitors =
            subscription?.data?.raceUpdated?.competitors || [];
          return {
            ...prev,
            ...(subscription?.data?.raceUpdated || {}),
            competitors: prev.competitors.map((competitor) => ({
              ...competitor,
              ...(competitors.find((c) => c.tabNo === competitor.tabNo) || {}),
            })),
          };
        }

        return prev;
      });
    }
  }, [subscription]);

  useEffect(() => {
    if (focus && !fetching) {
      refetch();
    }
  }, [focus, fetching, refetch]);

  return { race, fetching };
}
