import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import { useState } from 'react';
import { useQuery } from 'urql';
import Icon, { IconName } from '../../../../components/Icon/Icon';
import {
  Maybe,
  Meeting,
  RaceCardHeaderMeetingsDocument,
  RaceCardHeaderMeetingsQuery,
  RaceCardHeaderMeetingsQueryVariables,
  Track,
} from '../../../../generated/graphql';
import styles from './SelectMeeting.module.css';

type Props = {
  meeting?: Maybe<
    Pick<Meeting, 'date' | 'type'> & {
      track: Pick<Track, 'name'>;
    }
  >;
  onChangeMeeting: (meetingId: string) => void;
};

export default function SelectMeeting({ meeting, onChangeMeeting }: Props) {
  const [open, setOpen] = useState(false);
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(4), flip(), shift()],
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  const [results] = useQuery<
    RaceCardHeaderMeetingsQuery,
    RaceCardHeaderMeetingsQueryVariables
  >({
    query: RaceCardHeaderMeetingsDocument,
    variables: {
      firstRaceFrom: dayjs(meeting?.date).startOf('day').toDate(),
      to: dayjs(meeting?.date).endOf('day').toDate(),
    },
    pause: !open || !meeting,
  });

  return (
    <>
      <button
        type="button"
        {...getReferenceProps({ ref: reference, className: styles.mainButton })}
      >
        {meeting ? (
          <>
            <Icon name={`${meeting.type.toLowerCase()}Filled` as IconName} />{' '}
            {meeting.track.name}
            <Icon name="chevron" size="semi-small" />
          </>
        ) : null}
      </button>
      <FloatingPortal>
        {open ? (
          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({
                ref: floating,
                className: styles.popup,
                style: {
                  position: strategy,
                  top: y ?? '',
                  left: x ?? '',
                },
              })}
            >
              {orderBy(
                results?.data?.meetingsDated,
                ['track.name'],
                ['asc']
              )?.map((m) => (
                <button
                  key={m.id}
                  className={styles.button}
                  onClick={() => onChangeMeeting(m.id)}
                >
                  <Icon name={`${m.type.toLowerCase()}Filled` as IconName} />{' '}
                  {m.track.name}
                </button>
              ))}
            </div>
          </FloatingFocusManager>
        ) : null}
      </FloatingPortal>
    </>
  );
}
