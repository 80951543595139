import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateStakeAllExotics,
  updateStakeAllMultiExotics,
  updateStakeAllSingles,
} from '../../../stores/betslipSlice';
import {
  selectExoticBets,
  selectMultiExoticBets,
  selectSingleBets,
} from '../../../stores/selectors/betslipSelectors';
import Accordion from '../Accordion';
import ExoticBetCard from '../BetCards/ExoticBetCard';
import MultiRaceBetCard from '../BetCards/MultiRaceBetCard';
import SingleBetCard from '../BetCards/SingleBetCard';
import { EmptyBetslip } from './EmptyBetslip';
import { TraditionalSingleBet } from './types';

export default function TraditionalBetslip() {
  const dispatch = useDispatch();
  const singleBetsArray = useSelector(selectSingleBets);
  const exoticBets = useSelector(selectExoticBets);
  const multiRaceBets = useSelector(selectMultiExoticBets);

  const isBetslipEmpty =
    singleBetsArray.length < 1 &&
    exoticBets.length < 1 &&
    multiRaceBets.length < 1;
  // Traditional betslip single bets are grouped by race and bet type.

  const singleBets = useMemo(
    () =>
      singleBetsArray.reduce<TraditionalSingleBet[]>((aggregated, value) => {
        const existingBet = aggregated.find(
          (bet) =>
            bet.betType === value.betType && bet.race.id === value.race.id
        );

        if (existingBet) {
          return aggregated.map((bet) => {
            if (
              bet.race.id === existingBet.race.id &&
              bet.betType === existingBet.betType
            ) {
              return {
                ...bet,
                selections: [...bet.selections, value.competitor].sort(
                  (a, b) => a.tabNo - b.tabNo
                ),
                odds: bet.odds && value.odds ? bet.odds + value.odds : 0,
              };
            }

            return bet;
          });
        }

        return [
          ...aggregated,
          {
            race: value.race,
            meeting: value.meeting,
            betType: value.betType,
            stake: value.stake,
            selections: [value.competitor],
            odds: value.odds,
          },
        ];
      }, []),
    [singleBetsArray]
  );

  return (
    <>
      {singleBets.length > 0 ? (
        <Accordion
          title="Singles"
          count={singleBets.length}
          onChangeStake={(stake) =>
            dispatch(
              updateStakeAllSingles({
                stake,
              })
            )
          }
        >
          {singleBets.map((bet) => (
            <SingleBetCard
              key={`${bet.betType}-${bet.race.id}`}
              bet={bet}
              traditional
            />
          ))}
        </Accordion>
      ) : null}
      {exoticBets.length > 0 && (
        <Accordion
          title="Exotics"
          count={exoticBets.length}
          onChangeStake={(stake) =>
            dispatch(
              updateStakeAllExotics({
                stake,
              })
            )
          }
        >
          {exoticBets.map((bet) => (
            <ExoticBetCard key={`${bet.betType}-${bet.race.id}`} bet={bet} />
          ))}
        </Accordion>
      )}
      {multiRaceBets.length > 0 && (
        <Accordion
          title="Multiples"
          count={multiRaceBets.length}
          onChangeStake={(stake) =>
            dispatch(
              updateStakeAllMultiExotics({
                stake,
              })
            )
          }
        >
          {multiRaceBets.map((bet) => (
            <MultiRaceBetCard
              key={`${bet.betType}-${bet.races?.[0]}`}
              bet={bet}
            />
          ))}
        </Accordion>
      )}
      {isBetslipEmpty && <EmptyBetslip />}
    </>
  );
}
