export enum BetType {
  WIN = 'Win',
  PLACE = 'Place',
  SHOW = 'Show',
  EXACTA = 'Exacta',
  QUINELLA = 'Quinella',
  TRIFECTA = 'Trifecta',
  SUPERFECTA = 'Superfecta',
  SUPERHI5 = 'Super Hi 5',
  PICK3 = 'Pick 3',
  PICK6 = 'Pick 6',
  DOUBLE = 'Double',
  DAILY_DOUBLE = 'DAILY_DOUBLE',
  EARLY_QUADRELLA = 'EARLY_QUADRELLA',
  EXTRA_DOUBLE = 'EXTRA_DOUBLE',
  FIRST_FOUR = 'FIRST_FOUR',
  OPENING_FLUC = 'OPENING_FLUC',
  PLACE_FIXED_ODDS2 = 'PLACE_FIXED_ODDS2',
  QUADRELLA = 'QUADRELLA',
  QUINELLA_FIXED_ODDS = 'QUINELLA_FIXED_ODDS',
  QUINELLA_PLACE = 'QUINELLA_PLACE',
  RUNNING_DOUBLE = 'RUNNING_DOUBLE',
  SIX_UP = 'SIX_UP',
  PLACE_FIXED_ODDS = 'PLACE_FIXED_ODDS',
  STARTING_PRICE = 'STARTING_PRICE',
  TOP_FLUC = 'TOP_FLUC',
  TREBLE = 'TREBLE',
  TRIO = 'TRIO',
  WIN_FIXED_ODDS = 'WIN_FIXED_ODDS',
  PARLAY = 'PARLAY',
  SPORT = 'SPORT',
  MULTI = 'MULTI',
}

export const sportSingleBetTypes = [BetType.SPORT];

const betTypes = { ...BetType };

export enum BetTypeLookup {
  WIN = 'Win - Tote',
  PLACE = 'Place - Tote',
  SHOW = 'Show - Tote',
  EXACTA = 'Exacta - Best Tote',
  QUINELLA = 'Quinella - Best Tote',
  TRIFECTA = 'Trifecta - Best Tote',
  SUPERFECTA = 'Superfecta - Best Tote',
  SUPERHI5 = 'Super Hi 5 - Best Tote',
  PICK3 = 'Pick 3 - Best Tote',
  PICK6 = 'Pick 6 - Best Tote',
  DOUBLE = 'Double - Best Tote',
  DAILY_DOUBLE = 'DAILY_DOUBLE - Best Tote',
  EARLY_QUADRELLA = 'EARLY_QUADRELLA - Best Tote',
  EXTRA_DOUBLE = 'EXTRA_DOUBLE - Best Tote',
  FIRST_FOUR = 'FIRST_FOUR - Best Tote',
  OPENING_FLUC = 'OPENING_FLUC',
  PLACE_FIXED_ODDS2 = 'Place',
  QUADRELLA = 'QUADRELLA - Best Tote',
  QUINELLA_FIXED_ODDS = 'QUINELLA_FIXED_ODDS',
  QUINELLA_PLACE = 'QUINELLA_PLACE',
  RUNNING_DOUBLE = 'RUNNING_DOUBLE',
  SIX_UP = 'SIX_UP',
  PLACE_FIXED_ODDS = 'Show',
  STARTING_PRICE = 'STARTING_PRICE',
  TOP_FLUC = 'TOP_FLUC',
  TREBLE = 'TREBLE',
  TRIO = 'TRIO',
  WIN_FIXED_ODDS = 'Win',
  PARLAY = 'Parlay',
}

export enum PlaceBetTypeLookup {
  WIN = 'Win',
  PLACE = 'Place',
  SHOW = 'Show',
  EXACTA = 'Exacta - Best Tote',
  QUINELLA = 'Quinella - Best Tote',
  TRIFECTA = 'Trifecta - Best Tote',
  SUPERFECTA = 'Superfecta - Best Tote',
  SUPERHI5 = 'Super Hi 5 - Best Tote',
  PICK3 = 'Pick 3 - Best Tote',
  PICK6 = 'Pick 6 - Best Tote',
  DOUBLE = 'Double - Best Tote',
  DAILY_DOUBLE = 'DAILY_DOUBLE - Best Tote',
  EARLY_QUADRELLA = 'EARLY_QUADRELLA - Best Tote',
  EXTRA_DOUBLE = 'EXTRA_DOUBLE - Best Tote',
  FIRST_FOUR = 'FIRST_FOUR - Best Tote',
  OPENING_FLUC = 'OPENING_FLUC',
  PLACE_FIXED_ODDS2 = 'us_place',
  QUADRELLA = 'QUADRELLA - Best Tote',
  QUINELLA_FIXED_ODDS = 'QUINELLA_FIXED_ODDS',
  QUINELLA_PLACE = 'QUINELLA_PLACE',
  RUNNING_DOUBLE = 'RUNNING_DOUBLE',
  SIX_UP = 'SIX_UP',
  PLACE_FIXED_ODDS = 'place',
  STARTING_PRICE = 'STARTING_PRICE',
  TOP_FLUC = 'TOP_FLUC',
  TREBLE = 'TREBLE',
  TRIO = 'TRIO',
  WIN_FIXED_ODDS = 'win',
  PARLAY = 'Parlay',
}

export enum MultiBetType {
  PICK3 = 'Pick 3',
  PICK6 = 'Pick 6',
  DOUBLE = 'Double',
}

export enum ExoticBetType {
  EXACTA = 'Exacta',
  QUINELLA = 'Quinella',
  TRIFECTA = 'Trifecta',
  SUPERFECTA = 'Superfecta',
}

export enum ExoticEntryMode {
  Normal = 'NORMAL',
  Boxed = 'BOXED',
  Key = 'KEY',
  KeyBoxed = 'KEY_BOXED',
}

export default betTypes;
