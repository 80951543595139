import { createSelector } from '@reduxjs/toolkit';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useQuery, useSubscription } from 'urql';
import {
  PriceUpdatesDocument,
  PriceUpdatesSubscription,
  PriceUpdatesSubscriptionVariables,
  RaceCardDocument,
  RaceCardQuery,
  RaceCardQueryVariables,
  RaceUpdatedDocument,
  RaceUpdatedSubscription,
  RaceUpdatedSubscriptionVariables,
} from '../../../generated/graphql';
import { RootState } from '../../../stores/store';
import { PlatformType } from '../../../types/platformType';

type RaceCardContext = {
  fetching: boolean;
  race: RaceCardQuery['race'];
  competitors: NonNullable<RaceCardQuery['race']>['competitors'];
  showShortFormForRunner: Record<string, boolean>;
  toggleShortFormForRunner: (runnerId: string) => void;
};

const Context = createContext({} as RaceCardContext);

export default function useRaceCard() {
  return useContext(Context);
}

type Props = {
  raceId: string;
  children: React.ReactNode;
};

const selector = createSelector(
  [
    (state: RootState) => state.config.options.toteEnabled,
    (state: RootState) => state.config.options.foxTipsEnabled,
    (state: RootState) =>
      state.setting.selectedPriceType === PlatformType.Fixed
        ? state.config.coreApiSource
        : state.config.coreApiToteSource,
    (state: RootState) => state.setting,
  ],
  (toteEnabled, foxTipsEnabled, source, settings) => ({
    toteEnabled,
    foxTipsEnabled,
    source,
    settings,
  })
);

export function RaceCardProvider({ raceId, children }: Props) {
  const [race, setRace] = useState<RaceCardQuery['race']>();
  const [showShortFormForRunner, setShowShortFormForRunner] = useState<
    Record<string, boolean>
  >({});
  const { toteEnabled, source, settings } = useSelector(selector);
  const [{ data, fetching }] = useQuery<RaceCardQuery, RaceCardQueryVariables>({
    query: RaceCardDocument,
    variables: {
      id: raceId,
      source,
      toteEnabled,
      fetchRunnerInfo: settings.showRunnerInfo,
    },
  });
  useEffect(() => {
    if (data?.race) {
      setRace(data.race);
    }
  }, [data?.race]);

  const [subscription] = useSubscription<
    RaceUpdatedSubscriptionVariables,
    RaceUpdatedSubscription
  >({
    query: RaceUpdatedDocument,
    variables: {
      raceId,
    },
  });
  useEffect(() => {
    if (subscription.data) {
      setRace((prev) => {
        if (prev) {
          const competitors =
            subscription?.data?.raceUpdated?.competitors || [];
          return {
            ...prev,
            ...(subscription?.data?.raceUpdated || {}),
            competitors: prev.competitors.map((competitor) => ({
              ...competitor,
              ...(competitors.find((c) => c.tabNo === competitor.tabNo) || {}),
            })),
          };
        }

        return prev;
      });
    }
  }, [subscription]);

  const [priceSubscription] = useSubscription<
    PriceUpdatesSubscriptionVariables,
    PriceUpdatesSubscription
  >({
    query: PriceUpdatesDocument,
    variables: {
      raceId,
      source,
    },
  });
  useEffect(() => {
    if (priceSubscription.data) {
      const { priceUpdates } = priceSubscription.data;
      setRace((prev) => {
        if (!prev) return undefined;

        return {
          ...prev,
          competitors: prev?.competitors.map((competitor) => ({
            ...competitor,
            prices: competitor.prices.map((price) => {
              const newPrice = priceUpdates.prices.find(
                (updatedPrice) =>
                  updatedPrice.tabNo === competitor.tabNo &&
                  updatedPrice.type === price.type
              );
              return newPrice || price;
            }),
          })),
        };
      });
    }
  }, [priceSubscription]);

  const toggleShortFormForRunner = useCallback(
    (runnerId: string) =>
      setShowShortFormForRunner((prev) => ({
        ...prev,
        [runnerId]: !prev?.[runnerId],
      })),
    []
  );

  const competitors = useMemo(() => race?.competitors || [], [race]);

  return (
    <Context.Provider
      value={{
        fetching,
        race,
        competitors,
        showShortFormForRunner,
        toggleShortFormForRunner,
      }}
    >
      <>
        <Helmet
          title={race ? `${race.meeting.track.name} R${race.number}` : ''}
        />
        {children}
      </>
    </Context.Provider>
  );
}
