import { BetType } from '../../constants/betTypes';
import { sportSingleBetTypes } from '../../utils/betslip';
import { isExoticBetType, isMultiBet } from '../../utils/betslip/common';
import {
  ExoticBet,
  MultiRaceBet,
  SingleBet,
  SportSingleBet,
} from '../../utils/betslip/types';
import { RootState } from '../store';

export const fixedSingleBetTypes = [
  BetType.WIN_FIXED_ODDS,
  BetType.PLACE_FIXED_ODDS2,
  BetType.PLACE_FIXED_ODDS,
];
export const toteSingleBetTypes = [BetType.WIN, BetType.PLACE, BetType.SHOW];
export const singleBetTypes = [...toteSingleBetTypes, ...fixedSingleBetTypes];
export const allSingleBetTypes = [...singleBetTypes, ...sportSingleBetTypes];

// Traditional betslip single bets are grouped by race and bet type.
export const selectSingleBets = (state: RootState) =>
  state.betslip.betslip.filter((bet) =>
    singleBetTypes.includes(bet.betType)
  ) as SingleBet[];

export const selectExoticBets = (state: RootState) =>
  state.betslip.betslip.filter((bet) =>
    isExoticBetType(bet.betType)
  ) as ExoticBet[];

export const selectMultiExoticBets = (state: RootState) =>
  state.betslip.betslip.filter((bet) => isMultiBet(bet)) as MultiRaceBet[];

export const selectSportSingleBets = (state: RootState) =>
  state.betslip.betslip.filter((bet) =>
    sportSingleBetTypes.includes(bet.betType)
  ) as SportSingleBet[];

export const selectAllSingleBets = (state: RootState) => state.betslip.betslip;

export const selectSportMultiBets = (state: RootState) =>
  state.betslip.multiFoldSelections;
