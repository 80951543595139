import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SportTypesQuery } from './../generated/graphql';

export interface SportsState {
  sportTypes: SportTypesQuery['sportTypes'];
  sportTypesLoading: boolean;
  sportTypesError: undefined | string;
}

const initialState: SportsState = {
  sportTypes: [],
  sportTypesLoading: false,
  sportTypesError: undefined,
};

export const sportsSlice = createSlice({
  name: 'sports',
  initialState,
  reducers: {
    setSportTypes: (state, action: PayloadAction<any>) => {
      state.sportTypes = action.payload;
    },
    setSportTypesLoading: (state, action: PayloadAction<any>) => {
      state.sportTypesLoading = action.payload;
    },
    setSportTypesError: (state, action: PayloadAction<any>) => {
      state.sportTypesError = action.payload;
    },
  },
});

export const { setSportTypes, setSportTypesLoading, setSportTypesError } =
  sportsSlice.actions;
export default sportsSlice.reducer;
