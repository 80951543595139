import { useCallback, useState } from 'react';
import useSportMarketsDetails from '../../hooks/useSportMarketsDetails/useSportMarketsDetails';
import { MarketSelection, SportMarket } from '../../types/sport';
import Accordion from '../Accordion/Accordion';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SportOddsButton from '../SportOddsButton/SportOddsButton';
import styles from './MarketAccordion.module.css';

type Market = Pick<SportMarket, 'id' | 'name' | 'status'>;
type MarketAccordionProps = {
  market: Market;
  addToBetSlip: (market: Market, selection: MarketSelection) => void;
};
const MarketAccordion = ({ market, addToBetSlip }: MarketAccordionProps) => {
  const [pause, setPause] = useState(true);

  const { data, fetching } = useSportMarketsDetails({
    marketId: market.id,
    pause,
  });

  const onExpand = useCallback((isExpanded: boolean) => {
    if (isExpanded) {
      setPause(false);
    }
  }, []);

  const renderMarket = () => {
    const sportMarket = data?.sportMarkets ? data?.sportMarkets[0] : undefined;
    if (!sportMarket) return null;
    return (
      <div>
        {sportMarket.selections?.map((s) => {
          return (
            <div key={s.name} className={styles.sportMarket}>
              <SportOddsButton
                label={s.name}
                odd={s.prices ? s.prices[0].decimal_price : 0}
                className={styles.oddsBtn}
                onOddButtonClick={() => {
                  addToBetSlip(market, s as SportMarket);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Accordion label={market.name} onExpand={onExpand}>
      {!fetching && renderMarket()}
      {fetching && <LoadingSpinner />}
    </Accordion>
  );
};
export default MarketAccordion;
