import clsx from 'clsx';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSubscription } from 'urql';
import Icon from '../../../../../components/Icon/Icon';
import Label from '../../../../../components/Label';
import {
  PriceType,
  RaceCardQuery,
  RaceStatus,
  ResultFieldDocument,
  ResultFieldSubscription,
  ResultFieldSubscriptionVariables,
} from '../../../../../generated/graphql';
import useFormat from '../../../../../hooks/useFormat';
import { useMeasure } from '../../../../../hooks/useMeasure';
import { RootState } from '../../../../../stores/store';
import { PlatformType } from '../../../../../types/platformType';
import RunnerDetails from '../../RunnerDetails/RunnerDetails';
import styles from '../Fields.module.css';
import resultStyles from './ResultField.module.css';

type Props = {
  race: RaceCardQuery['race'];
};

export default function ResultField({ race }: Props) {
  const timelineRef = useRef<HTMLDivElement>(null);
  const timelineDimensions = useMeasure(timelineRef);
  const selectedPriceType = useSelector(
    (state: RootState) => state.setting.selectedPriceType
  );
  const [results, setResults] = useState(
    race?.results.find((r) => r.source === 'BET')
  );
  useEffect(() => {
    if (race) {
      setResults(race?.results.find((r) => r.source === 'BET'));
    }
  }, [race]);
  const format = useFormat();

  const showBetTypeEnabled = useMemo(
    () => selectedPriceType === PlatformType.Tote,
    [selectedPriceType]
  );

  const resultStage = useMemo(() => {
    if (race?.status === RaceStatus.Final) {
      return 3;
    }

    if (race?.status === RaceStatus.Interim) {
      return 2;
    }

    if (race?.status === RaceStatus.Closed) {
      return 1;
    }

    return 0;
  }, [race]);

  const [subscription] = useSubscription<
    ResultFieldSubscriptionVariables,
    ResultFieldSubscription
  >({
    query: ResultFieldDocument,
    variables: {
      raceId: race?.id,
    },
  });
  useEffect(() => {
    if (subscription.data) {
      const {
        results: { positions, status },
      } = subscription.data;

      setResults((prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          status,
          positions,
        };
      });
    }
  }, [subscription]);

  return (
    <>
      <div className={resultStyles.timelineContainer}>
        <div className={resultStyles.timeline} ref={timelineRef}>
          <div className={resultStyles.timelineItem}>
            <div
              className={clsx(resultStyles.circle, {
                [resultStyles.enabled]: resultStage >= 1,
              })}
            >
              <Icon name="tick" size="extra-small" />
            </div>
            <span>Race Closed</span>
          </div>
          <div className={resultStyles.timelineItem}>
            <div
              className={clsx(resultStyles.circle, {
                [resultStyles.enabled]: resultStage >= 2,
              })}
            >
              <Icon name="tick" size="extra-small" />
            </div>
            <span>Interim</span>
          </div>
          <div className={resultStyles.timelineItem}>
            <div
              className={clsx(resultStyles.circle, {
                [resultStyles.enabled]: resultStage >= 3,
              })}
            >
              <Icon name="tick" size="extra-small" />
            </div>
            <span>Results</span>
          </div>
          <div
            className={resultStyles.progress}
            style={{
              width: `calc(${
                ((timelineDimensions?.width || 0) - 80) / 2 // 80 being the 4rem margin on both left and right of the timeline
              }px * ${resultStage - 1})`,
            }}
          />
        </div>
      </div>
      {results?.positions ? (
        <div
          className={clsx(styles.grid, resultStyles.grid, {
            [resultStyles.showBetTypeEnabled]: showBetTypeEnabled,
          })}
        >
          <Label className={styles.label}>RESULTS</Label>
          <Label className={styles.label}>WIN</Label>
          <Label className={styles.label}>PLACE</Label>
          {showBetTypeEnabled ? (
            <Label className={styles.label}>SHOW</Label>
          ) : null}
          {results?.positions.map((pos) => {
            const competitor = race?.competitors.find(
              (c) => c.tabNo === pos.tabNo
            );

            if (!competitor) return null;

            return (
              <Fragment key={`${race?.id}-${pos.tabNo}`}>
                <RunnerDetails
                  meeting={race!.meeting}
                  competitor={competitor}
                  position={pos.position}
                />
                <span className={styles.center}>
                  {format.odds(
                    competitor.prices.find(
                      (price) =>
                        price.type ===
                        (selectedPriceType === PlatformType.Fixed
                          ? PriceType.WinFixedOdds
                          : PriceType.Win)
                    )?.price
                  )}
                </span>
                <span className={styles.center}>
                  {format.odds(
                    competitor.prices.find(
                      (price) =>
                        price.type ===
                        (selectedPriceType === PlatformType.Fixed
                          ? PriceType.PlaceFixedOdds2
                          : PriceType.Place)
                    )?.price
                  )}
                </span>
                {showBetTypeEnabled ? (
                  <span className={styles.center}>
                    {format.odds(
                      competitor.prices.find(
                        (price) =>
                          price.type ===
                          (selectedPriceType === PlatformType.Fixed
                            ? PriceType.PlaceFixedOdds
                            : PriceType.Place)
                      )?.price
                    )}
                  </span>
                ) : null}
              </Fragment>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
