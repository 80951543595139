import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import styles from './BreadCrumbs.module.css';

export type BreadCrumbsProps = {
  breadcrumbs: { label: string; url: string }[];
};

const BreadCrumbs = ({ breadcrumbs }: BreadCrumbsProps) => {
  return (
    <ul className={styles.breadcrumbs}>
      {breadcrumbs.map((breadcrumb, index) => (
        <li className={styles.breadcrumb} key={breadcrumb.label}>
          <Link to={breadcrumb.url} className={styles.breadcrumbLink}>
            {breadcrumb.label}
          </Link>
          {index < breadcrumbs.length - 1 ? (
            <Icon
              name="chevron"
              size="very-small"
              className={styles.breadcrumbIcon}
            />
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default BreadCrumbs;
