import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import orderBy from 'lodash/orderBy';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PairedFlucs from '../../../../../components/Flucs/PairedFlucs';
import Toggle from '../../../../../components/Form/Toggle/Toggle';
import Icon from '../../../../../components/Icon/Icon';
import Label from '../../../../../components/Label';
import OddsButton from '../../../../../components/OddsButton/OddsButton';
import ShadowButton from '../../../../../components/ShadowButton/ShadowButton';
import { BetType, ExoticEntryMode } from '../../../../../constants/betTypes';
import { PriceType, RaceCardQuery } from '../../../../../generated/graphql';
import useFormat from '../../../../../hooks/useFormat';
import {
  addExotic,
  toggleExoticEntryMode,
  updateExoticSelection,
  updateExoticSelectionAll,
} from '../../../../../stores/betslipSlice';
import { RootState } from '../../../../../stores/store';
import { PlatformType } from '../../../../../types/platformType';
import { SortByKey, SortOrder } from '../../../../../utils/race/types';
import ExoticBetBuilder from '../../ExoticBetBuilder/ExoticBetBuilder';
import RunnerDetails from '../../RunnerDetails/RunnerDetails';
import useRaceCard from '../../useRaceCard';
import CompetitorFooter from '../CompetitorFooter';
import fieldStyles from '../Fields.module.css';
import ScratchedCompetitorDeductions from '../ScratchedCompetitorDeductions/ScratchedCompetitorDeductions';
import styles from './FirstFourField.module.css';

const selector = createSelector(
  [
    (state: RootState) => state.setting,
    (state: RootState) => state.setting.selectedPriceType,
    (state: RootState) => state.betslip.exoticEntryMode,
    (state: RootState) => state.betslip.exoticSelections,
  ],
  (settings, selectedPriceType, exoticEntryMode, exoticSelections) => ({
    settings,
    selectedPriceType,
    exoticEntryMode,
    exoticSelections,
  })
);

export default function FirstFourField() {
  const dispatch = useDispatch();
  const { race, competitors, toggleShortFormForRunner } = useRaceCard();
  const [sortBy, setSortBy] = useState(SortByKey.NAME);
  const [sortDesc, setSortDesc] = useState(false);
  const { settings, selectedPriceType, exoticEntryMode, exoticSelections } =
    useSelector(selector);
  const format = useFormat();

  const handleChangeSort = useCallback(
    (sortValue: SortByKey) => {
      if (sortValue === sortBy) {
        setSortDesc((prev) => !prev);
      } else {
        setSortBy(sortValue);
        setSortDesc(false);
      }
    },
    [sortBy]
  );

  const renderSortIcon = useCallback(
    (sortValue: string) => {
      if (sortValue !== sortBy) {
        return 'sort';
      }

      return sortDesc ? SortOrder.DESCENDING : SortOrder.ASCENDING;
    },
    [sortBy, sortDesc]
  );

  const getFlucsForCompetitor = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      settings.selectedPriceType === PlatformType.Fixed
        ? competitor.flucs?.filter((fluc) => fluc.price > 0)
        : competitor.toteFlucs?.filter((fluc) => fluc.price > 0),
    [settings.selectedPriceType]
  );

  const getOpenPrice = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      getFlucsForCompetitor(competitor)?.[0]?.price,
    [getFlucsForCompetitor]
  );

  const getWinPrice = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      competitor.prices?.find(
        (price) =>
          price.type ===
          (selectedPriceType === PlatformType.Fixed
            ? PriceType.WinFixedOdds
            : PriceType.Win)
      )?.price,
    [selectedPriceType]
  );

  const getPlacePrice = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      competitor.prices?.find(
        (price) =>
          price.type ===
          (selectedPriceType === PlatformType.Fixed
            ? PriceType.PlaceFixedOdds2
            : PriceType.Place)
      )?.price,
    [selectedPriceType]
  );

  const getShowPrice = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      competitor.prices?.find(
        (price) =>
          price.type ===
          (selectedPriceType === PlatformType.Fixed
            ? PriceType.PlaceFixedOdds
            : PriceType.Place) // TODO: Show bet type for tote doesn't exist yet in bet director, but it will be TBD regardless
      )?.price,
    [selectedPriceType]
  );

  const sortedCompetitors = useMemo(() => {
    switch (sortBy) {
      case SortByKey.OP:
        return orderBy(
          competitors,
          ['scratched', getOpenPrice],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
      case SortByKey.WIN:
        return orderBy(
          competitors,
          ['scratched', getWinPrice],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
      case SortByKey.PLACE:
        return orderBy(
          competitors,
          ['scratched', getPlacePrice],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
      case SortByKey.SHOW:
        return orderBy(
          competitors,
          ['scratched', getShowPrice],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
      case SortByKey.NAME:
      default:
        return orderBy(
          competitors,
          ['scratched', 'tabNo'],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
    }
  }, [
    competitors,
    sortBy,
    sortDesc,
    getOpenPrice,
    getWinPrice,
    getPlacePrice,
    getShowPrice,
  ]);

  const isBoxed = useMemo(
    () => exoticEntryMode === ExoticEntryMode.Boxed,
    [exoticEntryMode]
  );

  const addSelection = useCallback(
    (
      column: number,
      competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]
    ) => {
      dispatch(
        updateExoticSelection({
          betType: BetType.FIRST_FOUR,
          competitor,
          index: column,
        })
      );
    },
    [dispatch]
  );

  const selectField = useCallback(
    (column: number) => {
      const selections = sortedCompetitors.filter((c) => !c.scratched);

      dispatch(
        updateExoticSelectionAll({
          betType: BetType.FIRST_FOUR,
          index: column,
          selections,
        })
      );
    },
    [dispatch, sortedCompetitors]
  );

  const sendToBetslip = useCallback(() => {
    dispatch(
      addExotic({
        betType: BetType.FIRST_FOUR,
        race: race!,
        meeting: race!.meeting,
        selections: exoticSelections[BetType.FIRST_FOUR],
      })
    );
  }, [dispatch, exoticSelections, race]);

  return (
    <>
      <div
        className={clsx(fieldStyles.grid, styles.grid, {
          [styles.boxed]: isBoxed,
        })}
      >
        <div className={fieldStyles.hiddenOnMobile} />
        <div className={fieldStyles.hiddenOnMobile} />
        <div className={fieldStyles.hiddenOnMobile} />
        <div className={fieldStyles.row}>
          <Label
            className={clsx(
              fieldStyles.label,
              fieldStyles.center,
              fieldStyles.priority,
              fieldStyles.boxedButton
            )}
          >
            BOXED
            <Toggle
              id="boxed"
              checked={isBoxed}
              onChange={() => {
                dispatch(
                  toggleExoticEntryMode({
                    betType: BetType.FIRST_FOUR,
                    modeToToggle: ExoticEntryMode.Boxed,
                  })
                );
              }}
            />
          </Label>
          <Label
            className={clsx(
              fieldStyles.label,
              fieldStyles.center,
              fieldStyles.priority,
              fieldStyles.hiddenOnDesktop
            )}
          >
            FIELD
          </Label>
        </div>
        {isBoxed ? (
          <Label
            className={clsx(
              fieldStyles.label,
              fieldStyles.center,
              fieldStyles.priority
            )}
          >
            <ShadowButton
              className={fieldStyles.exoticsAllButton}
              onClick={() => selectField(0)}
            >
              All
            </ShadowButton>
          </Label>
        ) : (
          <>
            <Label
              className={clsx(
                fieldStyles.label,
                fieldStyles.center,
                fieldStyles.priority
              )}
            >
              <ShadowButton
                className={fieldStyles.exoticsAllButton}
                onClick={() => selectField(0)}
              >
                1
              </ShadowButton>
            </Label>
            <Label
              className={clsx(
                fieldStyles.label,
                fieldStyles.center,
                fieldStyles.priority
              )}
            >
              <ShadowButton
                className={fieldStyles.exoticsAllButton}
                onClick={() => selectField(1)}
              >
                2
              </ShadowButton>
            </Label>
            <Label
              className={clsx(
                fieldStyles.label,
                fieldStyles.center,
                fieldStyles.priority
              )}
            >
              <ShadowButton
                className={fieldStyles.exoticsAllButton}
                onClick={() => selectField(2)}
              >
                3
              </ShadowButton>
            </Label>
            <Label
              className={clsx(
                fieldStyles.label,
                fieldStyles.center,
                fieldStyles.priority
              )}
            >
              <ShadowButton
                className={fieldStyles.exoticsAllButton}
                onClick={() => selectField(3)}
              >
                4
              </ShadowButton>
            </Label>
          </>
        )}

        <Label
          className={clsx(fieldStyles.label, fieldStyles.sortable, {
            [fieldStyles.selected]: sortBy === SortByKey.NAME,
          })}
          onClick={() => handleChangeSort(SortByKey.NAME)}
        >
          RUNNER
          <Icon name={renderSortIcon(SortByKey.NAME)} size="small" />
        </Label>
        <Label
          className={clsx(
            fieldStyles.label,
            fieldStyles.center,
            fieldStyles.sortable,
            fieldStyles.hiddenOnMobile,
            {
              [fieldStyles.selected]: sortBy === SortByKey.OP,
            }
          )}
          onClick={() => handleChangeSort(SortByKey.OP)}
        >
          OPEN
          <Icon name={renderSortIcon(SortByKey.OP)} size="small" />
        </Label>
        <Label
          className={clsx(
            fieldStyles.label,
            fieldStyles.hiddenOnMobile,
            fieldStyles.leftAlign
          )}
        >
          TODAY
        </Label>
        <div className={fieldStyles.hiddenOnMobile} />
        {!isBoxed ? (
          <>
            <div />
            <div />
            <div />
          </>
        ) : null}
        <Label
          className={clsx(
            fieldStyles.label,
            fieldStyles.center,
            fieldStyles.sortable,
            {
              [fieldStyles.selected]: sortBy === SortByKey.WIN,
            }
          )}
          onClick={() => handleChangeSort(SortByKey.WIN)}
        >
          WIN
          <Icon name={renderSortIcon(SortByKey.WIN)} size="small" />
        </Label>

        {sortedCompetitors.map((competitor, index) => {
          const winPrice = getWinPrice(competitor);

          return (
            <Fragment key={`${competitor.tabNo}:${competitor.selectionID}`}>
              <div className={fieldStyles.priceContainer}>
                {!competitor.scratched ? (
                  <span className={fieldStyles.price}>
                    {format.odds(winPrice)}
                  </span>
                ) : null}
              </div>
              {race?.meeting ? (
                <RunnerDetails
                  meeting={race.meeting}
                  competitor={competitor}
                  onClick={() => toggleShortFormForRunner(competitor.runner.id)}
                  enforceMinHeight
                />
              ) : null}
              {!competitor.scratched ? (
                <>
                  <div
                    className={clsx(
                      fieldStyles.center,
                      fieldStyles.hiddenOnMobile
                    )}
                  >
                    {format.odds(getOpenPrice(competitor))}
                  </div>
                  <div
                    className={clsx(
                      fieldStyles.center,
                      fieldStyles.hiddenOnMobile
                    )}
                  >
                    <PairedFlucs
                      flucs={getFlucsForCompetitor(competitor)?.map(
                        (fluc) => fluc?.price
                      )}
                      scratched={competitor.scratched}
                    />
                  </div>
                  <div className={fieldStyles.hiddenOnMobile} />
                  {isBoxed ? (
                    <div className={fieldStyles.center}>
                      <OddsButton
                        label="Add"
                        type="button"
                        disabled={competitor.scratched}
                        className={fieldStyles.exoticsButton}
                        selected={
                          !!exoticSelections[BetType.FIRST_FOUR][0].find(
                            (c) => c.tabNo === competitor.tabNo
                          )
                        }
                        onClick={() => addSelection(0, competitor)}
                      />
                    </div>
                  ) : (
                    <>
                      <div className={fieldStyles.center}>
                        <OddsButton
                          label="1"
                          type="button"
                          disabled={competitor.scratched}
                          className={fieldStyles.exoticsButton}
                          selected={
                            !!exoticSelections[BetType.FIRST_FOUR][0].find(
                              (c) => c.tabNo === competitor.tabNo
                            )
                          }
                          onClick={() => addSelection(0, competitor)}
                        />
                      </div>
                      <div className={fieldStyles.center}>
                        <OddsButton
                          label="2"
                          type="button"
                          disabled={competitor.scratched}
                          className={fieldStyles.exoticsButton}
                          selected={
                            !!exoticSelections[BetType.FIRST_FOUR][1].find(
                              (c) => c.tabNo === competitor.tabNo
                            )
                          }
                          onClick={() => addSelection(1, competitor)}
                        />
                      </div>
                      <div className={fieldStyles.center}>
                        <OddsButton
                          label="3"
                          type="button"
                          disabled={competitor.scratched}
                          className={fieldStyles.exoticsButton}
                          selected={
                            !!exoticSelections[BetType.FIRST_FOUR][2].find(
                              (c) => c.tabNo === competitor.tabNo
                            )
                          }
                          onClick={() => addSelection(2, competitor)}
                        />
                      </div>
                      <div className={fieldStyles.center}>
                        <OddsButton
                          label="4"
                          type="button"
                          disabled={competitor.scratched}
                          className={fieldStyles.exoticsButton}
                          selected={
                            !!exoticSelections[BetType.FIRST_FOUR][3].find(
                              (c) => c.tabNo === competitor.tabNo
                            )
                          }
                          onClick={() => addSelection(3, competitor)}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <ScratchedCompetitorDeductions
                  competitor={competitor}
                  race={race}
                />
              )}
              <CompetitorFooter competitor={competitor} index={index} />
            </Fragment>
          );
        })}
      </div>
      <ExoticBetBuilder
        betType={BetType.FIRST_FOUR}
        onAddBetslip={sendToBetslip}
      />
    </>
  );
}
