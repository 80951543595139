import clsx from 'clsx';
import React from 'react';
import Basketball from './Basketball/Basketball';
import styles from './ScoreBoard.module.css';
import Soccer from './Soccer/Soccer';
import Tennis from './Tennis/Tennis';

type ScoreBoardProps = {
  sport: string;
  className?: string;
  hasPadding?: boolean;
};

const SCOREBOARD_MAPPING = {
  soccer: Soccer,
  basketball: Basketball,
  tennis: Tennis,
};

const ScoreBoard = ({ sport, className, hasPadding }: ScoreBoardProps) => {
  const ScoreBoardComponent = SCOREBOARD_MAPPING[sport];
  if (!ScoreBoardComponent) return null;
  return (
    <div
      className={clsx(styles.container, className, {
        [styles.padding]: hasPadding,
      })}
    >
      <ScoreBoardComponent />
    </div>
  );
};

export default ScoreBoard;
