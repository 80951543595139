export enum PriceType {
  DAILY_DOUBLE = 'DAILY_DOUBLE',
  EARLY_QUADRELLA = 'EARLY_QUADRELLA',
  EXACTA = 'EXACTA',
  EXTRA_DOUBLE = 'EXTRA_DOUBLE',
  FIRST_FOUR = 'FIRST_FOUR',
  OPENING_FLUC = 'OPENING_FLUC',
  PLACE = 'PLACE',
  PLACE_FIXED_ODDS2 = 'PLACE_FIXED_ODDS2',
  QUADRELLA = 'QUADRELLA',
  QUINELLA = 'QUINELLA',
  QUINELLA_FIXED_ODDS = 'QUINELLA_FIXED_ODDS',
  QUINELLA_PLACE = 'QUINELLA_PLACE',
  RUNNING_DOUBLE = 'RUNNING_DOUBLE',
  SIX_UP = 'SIX_UP',
  SHOW = 'SHOW',
  PLACE_FIXED_ODDS = 'PLACE_FIXED_ODDS',
  STARTING_PRICE = 'STARTING_PRICE',
  TOP_FLUC = 'TOP_FLUC',
  TREBLE = 'TREBLE',
  TRIFECTA = 'TRIFECTA',
  TRIO = 'TRIO',
  WIN = 'WIN',
  WIN_FIXED_ODDS = 'WIN_FIXED_ODDS',
}

const priceTypes = { ...PriceType };

export default priceTypes;
