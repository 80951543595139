import React from 'react';
import styles from './Phone.module.css';

type PhoneProps = {};

const Phone = (props: PhoneProps) => (
  <div className={styles.centerAlign}>
    <div className={styles.fastCode}>
      <span>Account Number:</span>
      <strong>235</strong>
    </div>
    <strong className={styles.phoneNumber}>1800 00 00</strong>
  </div>
);

export default Phone;
