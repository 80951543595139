import { BetType } from '../constants/betTypes';

export const fixedSingleBetTypes = [
  BetType.WIN_FIXED_ODDS,
  BetType.PLACE_FIXED_ODDS2,
  BetType.PLACE_FIXED_ODDS,
];
export const toteSingleBetTypes = [BetType.WIN, BetType.PLACE, BetType.SHOW];
export const singleBetTypes = [...toteSingleBetTypes, ...fixedSingleBetTypes];

export const sportSingleBetTypes = [BetType.SPORT];

export const multiBetTypes = [BetType.DOUBLE, BetType.PICK3, BetType.PICK6];

export const isMultiBetType = (betType: BetType) =>
  multiBetTypes.includes(betType);
