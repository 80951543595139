import { createRoot, Root } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import BreadcrumbsProvider from '../../hooks/useBreadcrumbs/BreadcrumbsProvider';
import AppProvider from '../../pages/AppProvider';
import { store } from '../../stores/store';
import Racebook from './Racebook';

const roots: Record<string, Root> = {};

export default function RacebookRenderer() {
  Array.from(document.querySelectorAll('.bm-racebook')).forEach((el) => {
    const id = el.getAttribute('data-id') || 'unknown';

    if (!roots[id]) {
      roots[id] = createRoot(el!);
    }

    roots[id].render(
      <Provider store={store}>
        <HelmetProvider>
          <BreadcrumbsProvider>
            <AppProvider>
              <Racebook />
            </AppProvider>
          </BreadcrumbsProvider>
        </HelmetProvider>
      </Provider>
    );
  });
}
