import React from 'react';
import { CombinedError } from 'urql';
import {
  MarketAccordion,
  TeamHistory,
  UnableToLoadErrorPage,
} from '../../../../components';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { SportEventWithMarkets } from '../../../../hooks/useSportEvent/useSportEvent';
import useSportMarkets from '../../../../hooks/useSportMarkets/useSportMarkets';
import useSportsBetslip from '../../../../hooks/useSportsBetslip/useSportsBetslip';
import { MarketSelection } from '../../../../types/sport';
import styles from './SportCardAll.module.css';

export type SportCardAllProps = {
  event?: SportEventWithMarkets;
  loading: boolean;
  eventFetchError?: CombinedError;
};

const SportCardAll = ({
  event,
  loading,
  eventFetchError,
}: SportCardAllProps) => {
  const { sportMarkets, fetching } = useSportMarkets({ eventId: event?.id });

  const { addToBetslip } = useSportsBetslip();

  const addItem = (market: any, selection: MarketSelection) => {
    if (market && event) {
      addToBetslip({
        eventDetails: event,
        selection,
        market,
      });
    }
  };

  return (
    <div className={styles.container}>
      {!fetching && eventFetchError?.message && <UnableToLoadErrorPage />}
      <div className={styles.wrapper}>
        <div className={styles.main}>
          {(fetching || loading) && (
            <div>
              <LoadingSpinner />
            </div>
          )}
          {!loading &&
            !fetching &&
            sportMarkets.map((market) => (
              <MarketAccordion
                key={market.id}
                market={market}
                addToBetSlip={addItem}
              />
            ))}
          {!loading && !fetching && !sportMarkets.length && (
            <div className={styles.noEvents}>No open markets</div>
          )}
        </div>
        {!loading && !fetching && !eventFetchError && (
          <div className={styles.widgets}>
            <TeamHistory className={styles.widget} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SportCardAll;
