import dayjs from 'dayjs';
import { HeadToHeadCardPlaceholder } from '../../../../components';
import H2HCard from '../../../../components/H2HCard/H2HCard';
import useSportCategoriesEvents from '../../../../hooks/useSportCategoriesEvents/useSportCategoriesEvents';
import styles from './Competition.module.css';

type CompetitionProps = {
  id?: string;
};

const startDate = dayjs().startOf('day').toDate();
const endDate = dayjs().add(3, 'days').startOf('day').toDate();

export default function Competition({ id }: CompetitionProps) {
  // Id need to be competion id at the moment is sportCategiry id
  const { events, fetching } = useSportCategoriesEvents({
    categoryIds: id ? [id] : undefined,
    startDate,
    endDate,
  });

  return (
    <div className={styles.container}>
      {fetching &&
        [...Array(5)].map((_, i) => <HeadToHeadCardPlaceholder key={i} />)}
      {!fetching && id && !events.length && (
        <div className={styles.noEvents}>No events</div>
      )}
      {events.map((e) => (
        <H2HCard
          key={e.id}
          sportEvent={e}
          containerClass={styles.card}
          showLeagueName
        />
      ))}
    </div>
  );
}
