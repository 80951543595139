import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button/Button';
import Icon from '../../../../components/Icon/Icon';
import OddsButton from '../../../../components/OddsButton/OddsButton';
import { BetType } from '../../../../constants/betTypes';
import {
  PriceType,
  RaceCardQuery,
  RaceStatus,
} from '../../../../generated/graphql';
import { useMeasure } from '../../../../hooks/useMeasure';
import { addExotic, addOrRemoveBet } from '../../../../stores/betslipSlice';
import { RootState } from '../../../../stores/store';
import { BetslipType } from '../../../../types/betslipType';
import { PlatformType } from '../../../../types/platformType';
import { hasSingleBet } from '../../../../utils/betslip/common';
import RunnerDetailsCompact from '../RunnerDetails/RunnerDetailCompact';
import styles from './FoxTip.module.css';
import ReadMoreFoxTipModal from './ReadMoreFoxTipModal';

type Props = {
  race: NonNullable<RaceCardQuery['race']>;
  comment: string;
  truncated?: boolean;
};

const selector = createSelector(
  [
    (state: RootState) => state.config.options.betslipType,
    (state: RootState) => state.betslip.betslip,
    (state: RootState) => state.setting.selectedPriceType,
  ],
  (betslipType, betslip, selectedPriceType) => ({
    betslipType,
    betslip,
    selectedPriceType,
  })
);

export default function FoxTip({ race, comment, truncated = false }: Props) {
  const { selectedPriceType, betslipType, betslip } = useSelector(selector);
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const commentRef = useRef<HTMLSpanElement>(null);
  const dimensions = useMeasure(commentRef);

  useEffect(() => {
    if (commentRef.current) {
      setShowMore((dimensions?.height || 0) < commentRef.current.scrollHeight);
    }
  }, [dimensions]);

  const competitors = useMemo(
    () =>
      (
        comment
          ?.match(/\(\d*?\)/g)
          ?.map((x) => x.replace(/[()]/g, ''))
          .slice(0, 4) || []
      ).reduce<{
        roughy?: NonNullable<RaceCardQuery['race']>['competitors'][0];
        best?: NonNullable<RaceCardQuery['race']>['competitors'][0];
        selections: NonNullable<RaceCardQuery['race']>['competitors'][0][];
      }>(
        (acc, tabNo) => {
          const competitor = race.competitors.find((c) => c.tabNo === +tabNo);

          if (
            competitor?.scratched &&
            dayjs(race.startTime).isAfter(dayjs().add(1, 'day'))
          ) {
            return acc;
          }

          const competitorPrice =
            competitor?.prices?.find((p) => p.type === PriceType.WinFixedOdds)
              ?.price || 0;

          if (competitorPrice === 0) {
            return {
              ...acc,
              selections: sortBy(
                [...acc.selections, competitor!],
                (c) => c.scratched
              ),
            };
          }

          return {
            roughy:
              competitorPrice >
              (acc.roughy?.prices?.find(
                (p) => p.type === PriceType.WinFixedOdds
              )?.price || 0)
                ? competitor
                : acc.roughy,
            best:
              competitorPrice <
              (acc.best?.prices?.find((p) => p.type === PriceType.WinFixedOdds)
                ?.price || Infinity)
                ? competitor
                : acc.best,
            selections: sortBy(
              [...acc.selections, competitor!],
              (c) => c.scratched
            ),
          };
        },
        {
          selections: [],
        }
      ),
    [comment, race.competitors, race.startTime]
  );

  const renderActions = useCallback(() => {
    const selections = [...competitors.selections.filter((c) => !c.scratched)];

    switch (selections.length) {
      case 4:
      case 3:
        return (
          <Button
            theme="secondary"
            onClick={() => {
              dispatch(
                addExotic({
                  betType: BetType.TRIFECTA,
                  race,
                  meeting: race.meeting,
                  selections: [selections, selections, selections],
                })
              );
            }}
          >
            Add Boxed Trifecta
          </Button>
        );
      case 2:
        return (
          <Button
            theme="secondary"
            onClick={() => {
              dispatch(
                addExotic({
                  betType: BetType.QUINELLA_FIXED_ODDS,
                  race,
                  meeting: race.meeting,
                  selections: [selections, selections],
                })
              );
            }}
          >
            Add Quinella
          </Button>
        );
      default:
        return null;
    }
  }, [competitors, dispatch, race]);

  const renderFlag = useCallback((tabNo: number) => {
    /* Note: This has been removed for now as we're waiting on FC to identify the best bet/roughy for the entire meeting.
      if (tabNo === competitors.best?.tabNo) {
        return (
          <div className={styles.center}>
            <span className={clsx(styles.lozenge, styles.bestBet)}>
              Best Bet
            </span>
          </div>
        );
      }

      if (tabNo === competitors.roughy?.tabNo) {
        return (
          <div className={styles.center}>
            <span className={clsx(styles.lozenge, styles.roughy)}>Roughy</span>
          </div>
        );
      }
      */

    return <div />;
  }, []);

  return (
    <div className={styles.commentContainer}>
      <div className={styles.header}>
        <div className={styles.author}>
          <Icon name="foxTips" className={styles.foxTips} />
        </div>
      </div>
      <div className={styles.grid}>
        <div>
          <span
            className={clsx(styles.comment, { [styles.truncated]: truncated })}
            ref={commentRef}
          >
            {comment}
          </span>
          {showMore ? (
            <ReadMoreFoxTipModal race={race} comment={comment} />
          ) : null}
        </div>
        <div className={styles.competitors}>
          {competitors.selections.map((competitor) => {
            if (!competitor) {
              return null;
            }

            const winPrice = competitor.prices?.find(
              (price) => price.type === PriceType.WinFixedOdds
            )?.price;

            return (
              <Fragment key={`${race.id}:${competitor.tabNo}`}>
                <RunnerDetailsCompact
                  meeting={race.meeting}
                  competitor={competitor}
                />
                {renderFlag(competitor.tabNo)}
                <OddsButton
                  className={clsx(styles.oddsButton, {
                    // Note: Removed temporarily until FC includes this detail into the meeting
                    // [styles.roughy]:
                    //   competitors.roughy?.tabNo === competitor.tabNo,
                    // [styles.bestBet]:
                    //   competitors.best?.tabNo === competitor.tabNo,
                  })}
                  price={winPrice}
                  onClick={() => {
                    if (race) {
                      dispatch(
                        addOrRemoveBet({
                          betType:
                            selectedPriceType === PlatformType.Fixed
                              ? BetType.WIN_FIXED_ODDS
                              : BetType.WIN,
                          traditional: betslipType === BetslipType.American,
                          odds: winPrice,
                          race,
                          meeting: race?.meeting,
                          competitor,
                        })
                      );
                    }
                  }}
                  selected={
                    !!race &&
                    hasSingleBet(betslip, {
                      betType:
                        selectedPriceType === PlatformType.Fixed
                          ? BetType.WIN_FIXED_ODDS
                          : BetType.WIN,
                      race,
                      meeting: race?.meeting,
                      competitor,
                    })
                  }
                  disabled={
                    competitor.scratched || race.status !== RaceStatus.Open
                  }
                />
                <div className={styles.separator} />
              </Fragment>
            );
          })}
          {competitors.selections.length > 2 ? (
            <div className={styles.actions}>{renderActions()}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
