import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useQuery, useSubscription } from 'urql';
import CarryOverBadge from '../../../../components/Badge/CarryoverBadge';
import FixedOddsBadge from '../../../../components/Badge/FixedOddsBadge';
import MandatoryPayoutBadge from '../../../../components/Badge/MandatoryPayoutBadge';
import CountryFilters from '../../../../components/CountryFilters/CountryFilters';
import Icon from '../../../../components/Icon/Icon';
import RacingFilters from '../../../../components/RacingFilters/RacingFilters';
import {
  MeetingsTodayDocument,
  MeetingsTodayQuery,
  MeetingsTodayQueryVariables,
  MeetingType,
  RaceStatus,
  ResultsDocument,
  ResultsSubscription,
  ResultsSubscriptionVariables,
} from '../../../../generated/graphql';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs/useBreadcrumbs';
import { CountryFilter } from '../../../../stores/settingSlice';
import { RootState } from '../../../../stores/store';
import RacingGrid from './RacingGrid';
import styles from './RacingToday.module.css';

const selector = createSelector(
  [
    (state: RootState) => state.config.options.localCountryFilter,
    (state: RootState) => state.setting.typeFilters,
    (state: RootState) => state.setting.countryFilter,
    (state: RootState) => state.config.options.toteEnabled,
    (state: RootState) => state.config.options.fixedEnabled,
  ],
  (
    localCountryFilter,
    typeFilters,
    countryFilter,
    toteEnabled,
    fixedEnabled
  ) => ({
    localCountryFilter,
    typeFilters,
    countryFilter,
    toteEnabled,
    fixedEnabled,
  })
);

export default function RacingTomorrow() {
  const route = useMemo(
    () => [
      {
        label: 'Racing Home',
        url: '/',
      },
    ],
    []
  );
  useBreadcrumbs(route);
  const [meetings, setMeetings] = useState<MeetingsTodayQuery['meetingsDated']>(
    []
  );
  const tomorrow = useMemo(() => dayjs().add(1, 'day'), []);

  const {
    typeFilters,
    countryFilter,
    localCountryFilter,
    toteEnabled,
    fixedEnabled,
  } = useSelector(selector);
  const [meetingsToday] = useQuery<
    MeetingsTodayQuery,
    MeetingsTodayQueryVariables
  >({
    query: MeetingsTodayDocument,
    variables: {
      firstRaceFrom: tomorrow.startOf('day').toDate(),
      to: tomorrow.endOf('day').toDate(),
      fixedEnabled,
      toteEnabled,
    },
  });
  useEffect(() => {
    if (meetingsToday.data) {
      setMeetings(meetingsToday.data?.meetingsDated);
    }
  }, [meetingsToday]);

  const [resultsSubscription] = useSubscription<
    ResultsSubscriptionVariables,
    ResultsSubscription
  >({
    query: ResultsDocument,
  });
  useEffect(() => {
    if (resultsSubscription.data) {
      const {
        results: { raceId, positions },
      } = resultsSubscription.data;

      setMeetings((prev) =>
        prev.map((meeting) => {
          if (!meeting.races.some((race) => race.id === raceId)) {
            return meeting;
          }

          return {
            ...meeting,
            races: meeting.races.map((race) => {
              if (race.id !== raceId) {
                return race;
              }

              return {
                ...race,
                results: [{ positions }],
              };
            }),
          };
        })
      );
    }
  }, [resultsSubscription]);

  const getDataForMeetingType = useCallback(
    (meetingType: MeetingType) => [
      ...(!countryFilter || countryFilter === CountryFilter.Local
        ? localCountryFilter?.countries.reduce<
            MeetingsTodayQuery['meetingsDated']
          >(
            (acc, country) => [
              ...acc,
              ..._(meetings)
                .filter(
                  (meeting) =>
                    meeting.type === meetingType &&
                    meeting.track.country === country
                )
                .orderBy(
                  [
                    (value) => (value.track.country === country ? 1 : 2),
                    (value) =>
                      value.races.find((r) => r.status === RaceStatus.Open)
                        ?.startTime,
                    (value) => value.races[value.races.length - 1]?.status,
                    (value) => value.track.country,
                    (value) => value.track.name,
                  ],
                  'asc'
                )
                .value(),
            ],
            []
          ) || []
        : []),
      ...(!countryFilter || countryFilter === CountryFilter.International
        ? _(meetings)
            .filter(
              (meeting) =>
                meeting.type === meetingType &&
                !localCountryFilter?.countries.includes(meeting.track.country)
            )
            .orderBy(
              [
                (value) =>
                  !localCountryFilter?.countries.includes(value.track.country)
                    ? 1
                    : 2,
                (value) =>
                  value.races.find((r) => r.status === RaceStatus.Open)
                    ?.startTime,
                (value) => value.races[value.races.length - 1]?.status,
                (value) => value.track.country,
                (value) => value.track.name,
              ],
              'asc'
            )
            .value()
        : []),
    ],
    [meetings, localCountryFilter, countryFilter]
  );

  const renderGrid = useCallback(
    (raceType: MeetingType) => {
      const raceTypeTitle = {
        [MeetingType.Thoroughbred]: 'THOROUGHBRED RACING',
        [MeetingType.Greyhound]: 'GREYHOUND RACING',
        [MeetingType.Harness]: 'HARNESS RACING',
      };

      return (
        <section className={styles.section}>
          <div className={styles.sectionHeader}>
            <span className={styles.title}>
              <Icon name={raceType} /> {raceTypeTitle[raceType]}
            </span>
            <div
              className={clsx(styles.legend, {
                [styles.hideMobile]: toteEnabled,
              })}
            >
              {fixedEnabled ? (
                <span className={styles.tooltip}>
                  <FixedOddsBadge helper />
                </span>
              ) : null}
              {toteEnabled && (
                <>
                  <span className={styles.tooltip}>
                    <CarryOverBadge helper />
                  </span>
                  <span className={styles.tooltip}>
                    <MandatoryPayoutBadge helper />
                  </span>
                </>
              )}
            </div>
          </div>
          <RacingGrid meetings={getDataForMeetingType(raceType)} />
        </section>
      );
    },
    [toteEnabled, fixedEnabled, getDataForMeetingType]
  );

  return (
    <>
      <Helmet title="Tomorrow">
        <meta name="description" content="Tomorrows Racing" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.filters}>
            <RacingFilters />
            <CountryFilters />
          </div>
        </div>
        {meetingsToday.fetching ? (
          <Icon name="loading" />
        ) : (
          <>
            {typeFilters.length === 0 ||
            typeFilters.includes(MeetingType.Thoroughbred)
              ? renderGrid(MeetingType.Thoroughbred)
              : null}
            {typeFilters.length === 0 ||
            typeFilters.includes(MeetingType.Greyhound)
              ? renderGrid(MeetingType.Greyhound)
              : null}
            {typeFilters.length === 0 ||
            typeFilters.includes(MeetingType.Harness)
              ? renderGrid(MeetingType.Harness)
              : null}
          </>
        )}
      </div>
    </>
  );
}
