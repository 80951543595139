import HorizontalOverflow from '../../../../components/HorizontalOverflow/HorizontalOverflow';
import useRaceCard from '../useRaceCard';
import FoxTip from './FoxTip';
import styles from './FoxTips.module.css';

export default function FoxTips() {
  const { race } = useRaceCard();

  return (
    <div className={styles.container}>
      <HorizontalOverflow>
        {race?.comments!.map(({ comment }) => (
          <div className={styles.commentContainer} key={comment}>
            <FoxTip race={race} comment={comment} truncated />
          </div>
        ))}
      </HorizontalOverflow>
    </div>
  );
}
