import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKey } from '../constants/localStorage';
import { MeetingType } from '../generated/graphql';
import { OddsDisplay } from '../types/oddsDisplay';
import { PlatformType } from '../types/platformType';

export enum CountryFilter {
  Local = 'local',
  International = 'international',
}

export const MEETING_TYPE_LENGTH = Object.keys(MeetingType).length;

export type SettingState = {
  oddsType: OddsDisplay;
  showRunnerInfo: boolean;
  showShortForm: boolean;
  selectedPriceType: PlatformType;
  showBalance: boolean;
  showFoxTips: boolean;
  countryFilter: CountryFilter | undefined;
  typeFilters: MeetingType[];
};

const localAppSettings = localStorage.getItem(LocalStorageKey.AppSettings);
const initialState: SettingState = localAppSettings
  ? (JSON.parse(localAppSettings) as SettingState)
  : {
      oddsType: OddsDisplay.Decimal,
      showRunnerInfo: false,
      showShortForm: false,
      selectedPriceType: PlatformType.Fixed,
      showBalance: true,
      showFoxTips: false,
      countryFilter: undefined,
      typeFilters: [],
    };

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<Partial<SettingState>>) => {
      const newAppSettings = {
        ...state,
        ...action.payload,
      };

      localStorage.setItem(
        LocalStorageKey.AppSettings,
        JSON.stringify(newAppSettings)
      );
      return newAppSettings;
    },
    setCountryFilter: (state, action: PayloadAction<CountryFilter>) => {
      if (!state.countryFilter) {
        state.countryFilter = action.payload;
      } else {
        state.countryFilter =
          state.countryFilter === action.payload ? undefined : action.payload;
      }

      localStorage.setItem(LocalStorageKey.AppSettings, JSON.stringify(state));
    },
    updateTypeFilters: (state, action: PayloadAction<MeetingType>) => {
      if (state.typeFilters.includes(action.payload)) {
        state.typeFilters = state.typeFilters.filter(
          (type) => type !== action.payload
        );
      } else {
        const newFilters = [...state.typeFilters, action.payload];
        state.typeFilters =
          newFilters.length === MEETING_TYPE_LENGTH ? [] : newFilters;
      }

      localStorage.setItem(LocalStorageKey.AppSettings, JSON.stringify(state));
    },
  },
});

export const { updateSettings, setCountryFilter, updateTypeFilters } =
  settingSlice.actions;
export default settingSlice.reducer;
