import { createSlice } from '@reduxjs/toolkit';
import { BetResponse, CheckBetResponse } from '../utils/betslip/types';

export interface BetState {
  pendingBets: BetResponse[];
  reviewedBets: CheckBetResponse[];
}

const initialState: BetState = {
  pendingBets: [],
  reviewedBets: [],
};

export const betSlice = createSlice({
  name: 'bet',
  initialState,
  reducers: {
    setPendingBets: (state, action) => {
      state.pendingBets = action.payload;
    },
    removePendingBet: (state, action) => {
      state.pendingBets.filter((b) => b.ticket_id !== action.payload.ticket_id);
    },
    addReviewedBet: (state, action) => {
      state.reviewedBets.push(action.payload);
    },
    removeReviewedBet: (state, action) => {
      state.reviewedBets.filter(
        (bet) => bet.ticket_id !== action.payload.ticket_id
      );
    },
  },
});

export const {
  setPendingBets,
  removePendingBet,
  addReviewedBet,
  removeReviewedBet,
} = betSlice.actions;
export default betSlice.reducer;
