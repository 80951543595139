import { createSelector } from '@reduxjs/toolkit';
import { lowerCase, sortBy } from 'lodash';
import { cmsApi } from '../../services/cmsApi/cmsApi';
import { RootState } from '../store';

export const selectGetSportCategoriesResult =
  cmsApi.endpoints.getSportCategories.select();

export const selectSportType = (sportType?: string) =>
  createSelector(
    [(state: RootState) => state.sports.sportTypes],
    (sportTypes) => {
      return sportTypes?.find(
        (item) =>
          lowerCase(item?.name).replace(' ', '') ===
          lowerCase(sportType).replace(' ', '')
      );
    }
  );
export const selectSportTypes = createSelector(
  [(state: RootState) => state.sports.sportTypes],
  (sportTypes) => sportTypes || []
);

export const selectSportTypesLoading = createSelector(
  [(state: RootState) => state.sports.sportTypesLoading],
  (sportTypesLoading) => sportTypesLoading
);
export const selectSportTypesError = createSelector(
  [(state: RootState) => state.sports.sportTypesError],
  (sportTypesError) => sportTypesError
);

export const selectPopularSports = createSelector(
  [
    selectGetSportCategoriesResult,
    (state: RootState) => state.sports.sportTypes,
  ],
  (cmsPopular, sportTypes) => {
    if (!sportTypes?.length || !cmsPopular.data) return [];
    const cmsWithDescription = cmsPopular.data.map((c) => ({
      ...c,
      description:
        sportTypes.find((s) => s?.name === c.name)?.description || '',
    }));
    return sortBy(cmsWithDescription, 'name');
  }
);

export const selectSportPopularCategories = (sportName: string) =>
  createSelector([selectGetSportCategoriesResult], (cmsPopular) => {
    if (!cmsPopular.data) return [];
    return cmsPopular.data.find((d) => d.name === sportName)?.categories || [];
  });

export const selectPopularSportCategoryIds = createSelector(
  [selectGetSportCategoriesResult],
  (cmsPopular) => {
    const categoryIds =
      cmsPopular.data
        ?.flatMap((sportWithCategories) => sportWithCategories.categories)
        .filter((i) => i)
        .map((i) => i?.id || '') || [];
    return categoryIds || [];
  }
);
export const selectSportPopularCategoryIds = (name: string) =>
  createSelector([selectGetSportCategoriesResult], (cmsPopular) => {
    const data = cmsPopular.data?.find(
      (d) => lowerCase(d.name) === lowerCase(name)
    );
    const ids = data?.categories?.map(({ id }) => id) || [];
    return ids;
  });
