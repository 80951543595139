import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import AppProvider from '../../pages/AppProvider';
import { store } from '../../stores/store';
import NextToJumpTicker from './NextToJumpTicker';

const roots: Record<string, Root> = {};

export default function RacebookRenderer() {
  Array.from(document.querySelectorAll('.bm-next_to_jump_ticker')).forEach(
    (el) => {
      const id = el.getAttribute('data-id') || 'unknown';

      if (!roots[id]) {
        roots[id] = createRoot(el!);
      }

      const unpreparedProps = el.getAttribute('data-props');
      const props = unpreparedProps ? JSON.parse(unpreparedProps) : {};

      roots[id].render(
        <Provider store={store}>
          <AppProvider>
            <NextToJumpTicker {...props} />
          </AppProvider>
        </Provider>
      );
    }
  );
}
