import { Navigate, Route, Routes } from 'react-router-dom';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import RacingResults from '../RacingResults/RacingResults';
import RacingSaturday from './RacingToday/RacingSaturday';
import RacingToday from './RacingToday/RacingToday';
import RacingTomorrow from './RacingToday/RacingTomorrow';
import RacingUpcoming from './RacingUpcoming/RacingUpcoming';

export default function RacingHome() {
  return (
    <HomeContainer
      HeaderComponent={<h1>Racing</h1>}
      tabs={[
        {
          label: 'Results',
          url: '/racing/home/results',
        },
        {
          label: 'Upcoming Races',
          url: '/racing/home/upcoming',
        },
        {
          label: 'Today',
          url: '/racing/home/today',
        },
        {
          label: 'Tomorrow',
          url: '/racing/home/tomorrow',
        },
        {
          label: 'Saturday',
          url: '/racing/home/saturday',
        },
      ]}
    >
      <Routes>
        <Route path="/racing/home/results" element={<RacingResults />} />
        <Route path="/racing/home/upcoming" element={<RacingUpcoming />} />
        <Route path="/racing/home/today" element={<RacingToday />} />
        <Route path="/racing/home/tomorrow" element={<RacingTomorrow />} />
        <Route path="/racing/home/saturday" element={<RacingSaturday />} />
        <Route path="*" element={<Navigate to="/racing/home/today" />} />
      </Routes>
    </HomeContainer>
  );
}
