import { useContext, useEffect } from 'react';
import BreadcrumbsContext, { Breadcrumb } from './BreadcrumbsContext';

export default function useBreadcrumbs(items: Breadcrumb[] = []) {
  const { breadcrumbs, push, pop, reset } = useContext(BreadcrumbsContext);

  useEffect(() => {
    if (items.length > 0) {
      push(items);
    }

    return () => {
      if (items.length > 0) {
        pop(items.length);
      }
    };
  }, [items, push, pop, reset]);

  return breadcrumbs;
}
