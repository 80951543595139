import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button/Button';
import { BetslipStage, BetStatus } from '../../constants/betStatus';
import { LocalStorageKey } from '../../constants/localStorage';
import useBettingClient from '../../hooks/useBettingClient';
import useFormat from '../../hooks/useFormat';
import { setPendingBets } from '../../stores/betSlice';
import {
  clearBetslip,
  generateSportMulties,
  setBetslipStage,
  toggleBetslip,
  updateBetStatus,
} from '../../stores/betslipSlice';
import { selectAllSingleBets } from '../../stores/selectors/betslipSelectors';
import { RootState } from '../../stores/store';
import { BetslipType } from '../../types/betslipType';
import { convertBetStatus, isValidBet } from '../../utils/betslip/common';
import styles from './Betslip.module.css';
import BetReviewed from './BettingModals/BetReviewed';
import Parlays from './Parlays/Parlays';
import StandardBetslip from './StandardBetslip/StandardBetslip';
import TraditionalBetslip from './TraditionalBetslip/TraditionalBetslip';

const selector = createSelector(
  [
    (state: RootState) => state.betslip.betslip,
    (state: RootState) => state.betslip.betslipStage,
  ],
  (betslip, betslipStage) => ({
    betslip,
    betslipStage,
  })
);

const Betslip = forwardRef<HTMLDivElement, unknown>((_, ref) => {
  const dispatch = useDispatch();
  const [placeBetsDisabled, setPlaceBetsDisabled] = useState<boolean>(false);
  const { betslip, betslipStage } = useSelector(selector);

  const onBetFailure = useSelector(
    (state: RootState) => state.config.onBetFailure
  );

  useEffect(() => {
    localStorage.setItem(LocalStorageKey.Betslip, JSON.stringify(betslip));
    dispatch(generateSportMulties());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betslip]);

  // const { placeBets } = useBettingClient();
  const format = useFormat();
  const singleBets = useSelector(selectAllSingleBets);
  const { placeBets } = useBettingClient();
  const betslipType = useSelector(
    (state: RootState) => state.config.options?.betslipType
  );

  async function handlePlaceBets() {
    if (betslipStage === BetslipStage.NORMAL) {
      dispatch(setBetslipStage(BetslipStage.CONFIRM));

      return;
    }

    setPlaceBetsDisabled(true);
    const filteredBets = stakedBets.filter((bet) => isValidBet(bet));
    dispatch(setBetslipStage(BetslipStage.SENDING));

    const response = await placeBets(filteredBets);

    dispatch(setBetslipStage(BetslipStage.SENT));
    response.forEach((responseObject) => {
      dispatch(
        updateBetStatus({
          bet: responseObject.bet,
          status: convertBetStatus(responseObject.status),
        })
      );

      if (
        onBetFailure &&
        (responseObject.error || responseObject.status !== BetStatus.UNRESULTED)
      ) {
        const error = responseObject.error?.message
          ? ` - ${responseObject.error?.message}`
          : '';
        onBetFailure(`${responseObject.status} ${error}`);
      }
    });

    const pending = response.filter((bet) => bet.status === BetStatus.PENDING);

    dispatch(setPendingBets(pending));

    setPlaceBetsDisabled(false);
  }

  async function handleReuseSelections() {
    betslip
      .filter((bet) => bet.betStatus)
      .filter((bet) => isValidBet(bet))
      .forEach((bet) => {
        dispatch(
          updateBetStatus({
            bet,
            status: undefined,
          })
        );
      });
    dispatch(setBetslipStage(BetslipStage.NORMAL));
  }

  function handleClearBetslip() {
    dispatch(clearBetslip());
    dispatch(setBetslipStage(BetslipStage.NORMAL));
  }

  const stakedBets = betslip.filter((bet) => bet.stake);
  const betslipTotalStake = stakedBets.reduce<number>(
    (acc, val) => acc + (val?.stake || 0),
    0
  );
  const betslipTotalReturn = stakedBets.reduce<number>(
    (acc, val) => acc + (val?.stake || 0) * (val?.odds || 0),
    0
  );

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.header}>
        <Button
          theme="none"
          onClick={() => dispatch(toggleBetslip())}
          className={styles.chevron}
          leftIcon="caretLeft"
          iconClassName={styles.caret}
        />
        <div className={styles.betslipTitle}>BETSLIP</div>
      </div>
      <div className={styles.betslip}>
        {betslipType === BetslipType.American ? (
          <TraditionalBetslip />
        ) : (
          <StandardBetslip />
        )}
        {singleBets.length > 0 && <Parlays singleBets={singleBets} />}
      </div>
      <div className={styles.footer}>
        <div className={styles.row}>
          <span className={styles.label}>Bet Slip Total</span>
          <span className={styles.value}>
            {format.currency(betslipTotalStake, 2)}
          </span>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>Potential Return</span>
          <span className={styles.value}>
            {format.currency(betslipTotalReturn, 2)}
          </span>
        </div>
        <div className={styles.actions}>
          {betslipStage === BetslipStage.CONFIRM ? (
            <Button
              theme="secondary"
              size="medium"
              onClick={() => dispatch(setBetslipStage(BetslipStage.NORMAL))}
            >
              Edit Bets
            </Button>
          ) : (
            <Button
              theme="secondary"
              className={styles.clearBets}
              size="medium"
              onClick={handleClearBetslip}
            >
              Clear Slip
            </Button>
          )}
          {betslipStage === BetslipStage.SENT ? (
            <Button
              size="medium"
              theme="none"
              onClick={handleReuseSelections}
              className={clsx(styles.buttonBetPlacement)}
            >
              Reuse Selections
            </Button>
          ) : (
            <Button
              size="medium"
              theme="none"
              disabled={placeBetsDisabled || stakedBets.length === 0}
              onClick={handlePlaceBets}
              className={clsx(
                styles.buttonBetPlacement,
                placeBetsDisabled ||
                  (stakedBets.length === 0 && styles.disabled)
              )}
            >
              {getBetButtonText(betslipStage, betslip.length)}
            </Button>
          )}
        </div>
      </div>
      <BetReviewed />
    </div>
  );
});

function getBetButtonText(betslipState: BetslipStage, betslipLength: number) {
  if (betslipState === BetslipStage.CONFIRM) {
    return `Confirm Bet${betslipLength > 1 ? 's' : ''}`;
  }
  return `Place Bet${betslipLength > 1 ? 's' : ''}`;
}

export default Betslip;
