import React from 'react';
import styles from './SportTeamSeperator.module.css';

type SportTeamSeperatorProps = {};

const SportTeamSeperator = (props: SportTeamSeperatorProps) => (
  <div className={styles.container}>
    <span>@</span>
    <div className={styles.border} />
  </div>
);

export default SportTeamSeperator;
