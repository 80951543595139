import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export interface TimeState {
  currentTime: string;
}

const initialState: TimeState = {
  currentTime: dayjs().toISOString(),
};

export const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    updateCurrentTime: (state) => {
      state.currentTime = dayjs().toISOString();
    },
  },
});

export const { updateCurrentTime } = timeSlice.actions;
export default timeSlice.reducer;
