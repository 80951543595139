import {
  FloatingFocusManager,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import styles from './BetLiveModal.module.css';
import Heading from './Heading/Heading';
import Phone from './Phone/Phone';
import Selection from './Selection/Selection';

const BetLiveModal = () => {
  const [open, setOpen] = useState(false);
  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: setOpen,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <button type="button" {...getReferenceProps({ ref: reference })}>
        Open
      </button>
      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context}>
            <Modal
              className={styles.modalOverlay}
              {...getFloatingProps({ ref: floating })}
            >
              <div className={styles.modal}>
                <Heading />
                <Selection code="ZHF" />
                <Phone />
              </div>
            </Modal>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};

export default BetLiveModal;
