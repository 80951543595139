import { lowerCase } from 'lodash';
import { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import useSportCategories from '../../../hooks/useSportCategories/useSportCategories';
import { selectSportType } from '../../../stores/selectors/sportsSelector';
import slugify, { inverse_slugify } from '../../../utils/slugify';
import Competition from './Competition/Competition';
import Stats from './Stats/Stats';

const routes = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Sports',
    url: '/sports/home/all',
  },
];

export default function CompetitionHome() {
  const { sportName = '', competitionName = '' } = useParams<{
    sportName: string;
    competitionName: string;
  }>();

  const sportTypeObj = useSelector(selectSportType(sportName));

  const competition = inverse_slugify(competitionName);

  const { data, fetching } = useSportCategories({
    sportTypeId: sportTypeObj?.id,
    name: competition,
  });

  const competionObj = useMemo(() => {
    return data?.sportCategories ? data.sportCategories[0] : undefined;
  }, [data?.sportCategories]);

  const allRoutes = useMemo(() => {
    if (!sportTypeObj || !competionObj) return [];
    return [
      ...routes,
      {
        label: sportTypeObj.description,
        url: `/sports/sport/${lowerCase(sportTypeObj.name)}/all`,
      },
      {
        label: competionObj.description,
        url: `/sports/competition/${lowerCase(sportTypeObj.name)}/${slugify(
          lowerCase(competionObj.name)
        )}/matches`,
      },
    ];
  }, [competionObj, sportTypeObj]);

  useBreadcrumbs(allRoutes);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Matches',
        url: `/sports/competition/${sportName}/${competitionName}/matches`,
      },
      {
        label: 'Fox Stats',
        url: `/sports/competition/${sportName}/${competitionName}/stats`,
      },
      {
        label: 'Futures',
        url: `/sports/competition/${sportName}/${competitionName}/futures`,
      },
    ];
  }, [competitionName, sportName]);

  return (
    <HomeContainer
      HeaderComponent={
        <h1>{fetching ? <TextPlaceholder /> : competionObj?.description}</h1>
      }
      tabs={tabs}
      tabsLoading={false}
    >
      <Routes>
        <Route
          path="/matches"
          element={<Competition id={competionObj?.id} />}
        />
        <Route path="/stats" element={<Stats />} />
        <Route path="/futures" element={<Stats />} />
        <Route path="" element={<Navigate to="/matches" />} />
      </Routes>
    </HomeContainer>
  );
}

const TextPlaceholder = () => {
  return (
    <div style={{ height: '20px' }}>
      <ContentLoader viewBox="0 0 400 20">
        <rect x="0" y="4" style={{ width: '26%' }} height="8" />
      </ContentLoader>
    </div>
  );
};
