import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import {
  toggleFixedEnabled,
  toggleLocale,
  toggleToteEnabled,
} from '../../stores/configSlice';
import Racebook from '../../widgets/Racebook/Racebook';
import SportsBook from '../SportsBook/SportsBook';

export default function LocalApp() {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const [selectedWidget, setSelectedWidget] = useState<'racing' | 'sports'>(
    'racing'
  );
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.shiftKey) {
        switch (e.code) {
          case 'KeyR':
            e.preventDefault();
            setSelectedWidget('racing');
            break;
          case 'KeyS':
            e.preventDefault();
            setSelectedWidget('sports');
            break;
          case 'KeyT':
            e.preventDefault();
            setTheme((prev) => (prev === 'light' ? 'dark' : 'light'));
            break;
          case 'KeyL':
            e.preventDefault();
            dispatch(toggleLocale());
            break;
          case 'Digit1':
            e.preventDefault();
            dispatch(toggleFixedEnabled());
            break;
          case 'Digit2':
            e.preventDefault();
            dispatch(toggleToteEnabled());
            break;
          default:
            break;
        }
      }
    });
  }, [dispatch]);

  const renderTheme = useCallback(() => {
    switch (theme) {
      case 'dark':
        return (
          <Helmet>
            <style>
              {`:root {
        --black: var(--color-grey--800);
        --white: #fff;

        --color-grey--50: #eef0f2;
        --color-grey--100: #dbdee3;
        --color-grey--200: #b3b8c2;
        --color-grey--300: #979dab;
        --color-grey--400: #7b8294;
        --color-grey--500: #60697e;
        --color-grey--600: #465169;
        --color-grey--700: #2c3a54;
        --color-grey--800: #232d3f;

        --color-primary--50: #a8dc9c;
        --color-primary--100: #3eb738;
        --color-primary--200: #349a2f;
        --color-primary--300: #2a7c26;
        --color-primary--400: #2c6a26;

        --color-secondary--50: #c0d4d7;
        --color-secondary--100: #3e828c;
        --color-secondary--200: #346c75;
        --color-secondary--300: #29575d;
        --color-secondary--400: #154349;

        --color-medal--gold: #f6ddad;

        --color-black: #191919;

        --font-family: 'Source Sans Pro', sans-serif;

        --font-size--display-1: 2.2rem;
        --font-size--display-2: 2rem;

        --font-size--header-1: 1.6rem;
        --font-size--header-2: 1.4rem;
        --font-size--header-3: 1.2rem;

        --font-size--body: 1.4rem;

        --font-size--label: 1.2rem;

        --font-size--caption: 1.1rem;

        --line-height--small: 1.1;
        --line-height--medium: 1.25;
        --line-height--large: 1.4;
        --line-height--xlarge: 1.6;
        --line-height--title: 1.5;

        --font-weight--bold: 700;
        --font-weight--semi-bold: 600;
        --font-weight--regular: 400;
        --font-weight--light: 300;

        --z-index--badge: 301;
        --z-index--layout: 10;
        --z-index--priority: 100;
        --z-index--modal: 300;


        /* new variables */
        --color-alert: #e2215a;
        --color-attention: #dd6b20;
        --color-success: #049b5e;
        --color-white: #ffffff;

        --core-backgroundPrimary: #121212;
        --core-backgroudSecondary: #191919;
        --core-backgroundTertiary: #1E1E1E;
        --core-backgroundModerate: #2F2F2F;
        --core-backgroundContrast: #191919;

        --background-body: #000;
        --background-top-nav: #121212;
        --background-left-nav: #FFF;
        --background-footer: #dbdee3;
        --background-up-next: #EEF0F2;
        --background-betslip: #191919;
        --background-bet-text-field: #252525;
        --background-vision-bar: #465169;
        --background-vision-buttons: #53D337;

        --background-bottom-selector: #296677;
        --background-button-selector-header:#53D337;
        --background-tab-primary-active: #53D337;
        --background-toggles-active: #53D337;
        --background-table: #121212;

        --color-text-primary: #ffffff;
        --color-text-secondary: #c5c5c5;
        --color-text-tertiary: #ffffff;
        --color-text-inverse: #232d3f;
        --color-text-dark: #232d3f;
        --color-text-link: #308aa2;

        --color-text-button-primary: #ffffff;
        --color-text-button-secondary: #c5c5c5;
        --color-text-button-alt: #ffffff;
        --color-text-button-place-bet: #232d3f;
        --color-text-button-confirm-bet: #232d3f;
        --color-text-button-odds: #ffffff;
        --color-text-button-odds-active: #FFF;
        --color-text-button-filters: #ffffff;
        --color-text-button-filters-active: #232D3F;

        --surface-feather: #000000;
        --surface-light: #2F2F2F;
        --surface-moderate: #242424;
        --surface-bold: #979DAB;
        --surface-strong: #7B8294;
        --surface-contrast: #ddd;

        --border-primary: #242424;
        --border-secondary: #2F2F2F;
        --border-tertiary: #ffffff;

        --button-primary: #242424;
        --button-primary-hover: #465169;
        --button-primary-disabled: #465169;
        --button-secondary: #2F2F2F;
        --button-secondary-hover: #FFF;
        --button-secondary-disabled: #FFF;
        --button-top-nav-light: #69707D;
        --button-top-nav-dark: #69707D;
        --button-odds: #242424;
        --button-odds-hover: #465169;
        --button-odds-disabled: #69707D;
        --button-odds-active: #53D337;
        --button-place-bet: #53D337;
        --button-place-bet-hover: #2A7C26;
        --button-place-bet-disabled: #979DAB;
        --button-confirm-bet: #53D337;
        --button-confirm-bet-hover: #2A7C26;
        --button-alt: #2D788C;
        --button-race-nav-previous: #1E1E1E;
        --button-race-nav-next: #242424;
        --button-numpad: #252525;
        --button-filters: #1E1E1E;
        --button-filters-active: #53D337;

        --brand-background-login: #232D3F;
        --brand-background-bottomSelector: #121212;
        --brand-background-bottomSelectorHeader: #242424;
        --brand-background-tabPrimaryActive: #53D337;
        --brand-background-togglesActive: #53D337;
        --brand-background-table: #2D788C;

        --shadow-c1-center: 0 2px 4px rgba(0, 0, 0, 0.08);
      }`}
            </style>
          </Helmet>
        );
      case 'light':
      default:
        return (
          <Helmet>
            <style>
              {`:root {
        --black: var(--color-grey--800);
        --white: #fff;

        --color-grey--50: #eef0f2;
        --color-grey--100: #dbdee3;
        --color-grey--200: #b3b8c2;
        --color-grey--300: #979dab;
        --color-grey--400: #7b8294;
        --color-grey--500: #60697e;
        --color-grey--600: #465169;
        --color-grey--700: #2c3a54;
        --color-grey--800: #232d3f;

        --color-primary--50: #a8dc9c;
        --color-primary--100: #3eb738;
        --color-primary--200: #349a2f;
        --color-primary--300: #2a7c26;
        --color-primary--400: #2c6a26;

        --color-secondary--50: #c0d4d7;
        --color-secondary--100: #3e828c;
        --color-secondary--200: #346c75;
        --color-secondary--300: #29575d;
        --color-secondary--400: #154349;

        --color-medal--gold: #f6ddad;

        --color-black: #191919;

        --font-family: 'Source Sans Pro', sans-serif;

        --font-size--display-1: 2.2rem;
        --font-size--display-2: 2rem;

        --font-size--header-1: 1.6rem;
        --font-size--header-2: 1.4rem;
        --font-size--header-3: 1.2rem;

        --font-size--body: 1.4rem;
        --font-size--label: 1.2rem;
        --font-size--caption: 1.1rem;

        --line-height--small: 1.1;
        --line-height--medium: 1.25;
        --line-height--large: 1.4;
        --line-height--xlarge: 1.6;
        --line-height--title: 1.5;

        --font-weight--bold: 700;
        --font-weight--semi-bold: 600;
        --font-weight--regular: 400;
        --font-weight--light: 300;


        --z-index--badge: 301;
        --z-index--layout: 10;
        --z-index--priority: 100;
        --z-index--modal: 300;

        /* new variables -- light */

        --color-alert: #e2215a;
        --color-attention: #dd6b20;
        --color-success: #049b5e;
        --color-white: #ffffff;

        --core-backgroundPrimary: #FFF;
        --core-backgroudSecondary: #FFF;
        --core-backgroundTertiary: #FFF;
        --core-backgroundModerate: #FFF;
        --core-backgroundContrast: #FFF;

        --background-body: #EEF0F2;
        --background-top-nav: #232D3F;
        --background-left-nav: #FFF;
        --background-footer: #dbdee3;
        --background-up-next: #EEF0F2;
        --background-betslip: #EEF0F2;
        --background-bet-text-field: #EEF0F2;
        --background-vision-bar: #232D3F;
        --background-vision-buttons: #465169;

        --background-bottom-selector: #296677;
        --background-button-selector-header:#308AA2;
        --background-tab-primary-active: #34B0CF;
        --background-toggles-active: #34B0CF;
        --background-table: #2D788C;

        --color-text-primary: #232D3F;
        --color-text-secondary: #465169;
        --color-text-tertiary: #7B8294;
        --color-text-inverse: #FFF;
        --color-text-dark: #232D3F;
        --color-text-link: #308AA2;

        --color-text-button-primary: #FFFFFF;
        --color-text-button-secondary: #232D3F;
        --color-text-button-alt: #FFFFFF;
        --color-text-button-place-bet: #FFFFFF;
        --color-text-button-confirm-bet: #FFFFFF;
        --color-text-button-odds: #232D3F;
        --color-text-button-odds-active: #FFFFFF;
        --color-text-button-filters: #232D3F;
        --color-text-button-filters-active: #FFFFFF;

        --surface-feather: #F5F6F8;
        --surface-light: #EEF0F2;
        --surface-moderate: #DBDEE3;
        --surface-bold: #979DAB;
        --surface-strong: #7B8294;
        --surface-contrast: #232D3F;

        --border-primary: #DBDEE3;
        --border-secondary: #B3B8C2;
        --border-tertiary: #979DAB;

        --button-primary: #308AA2;
        --button-primary-hover: #346C75;
        --button-primary-disabled: #465169;
        --button-secondary: #FFF;
        --button-secondary-hover: #346C75;
        --button-secondary-disabled: #FFF;
        --button-top-nav-light: #69707D;
        --button-top-nav-dark: #69707D;
        --button-odds: #EEF0F2;
        --button-odds-hover: #DBDEE3;
        --button-odds-disabled: #EEF0F2;
        --button-odds-active: #349A2F;
        --button-place-bet: #049B5E;
        --button-place-bet-hover: #2A7C26;
        --button-place-bet-disabled: #979DAB;
        --button-confirm-bet: #3E828C;
        --button-confirm-bet-hover: #346C75;
        --button-alt: #2D788C;
        --button-race-nav-previous: #EEF0F2;
        --button-race-nav-next: #DBDEE3;
        --button-numpad: #FFFFFF;
        --button-filters: #fff;
        --button-filters-active:#308aa2;

        --brand-background-login: #232D3F;
        --brand-background-bottomSelector: #296677;
        --brand-background-bottomSelectorHeader: #308AA2;
        --brand-background-tabPrimaryActive: #34B0CF;
        --brand-background-togglesActive: #308AA2;
        --brand-background-table: #2D788C;

        --shadow-c1-center: 0 2px 4px rgba(0, 0, 0, 0.08);
        --shadow-b2-below-center: 0px 6px 12px 1px rgba(0, 0, 0, 0.12);
      }`}
            </style>
          </Helmet>
        );
    }
  }, [theme]);

  const render = useCallback(() => {
    switch (selectedWidget) {
      case 'sports':
        return <SportsBook />;
      case 'racing':
      default:
        return <Racebook />;
    }
  }, [selectedWidget]);

  return (
    <>
      {renderTheme()}
      {render()}
    </>
  );
}
