export const HEAD_TO_HEAD_MARKET_TYPE = {
  line: {
    id: 'c3BvcnRtYXJrZXR0eXBlOjYzOA==',
    name: 'Money Line',
  },
  match: {
    id: 'c3BvcnRtYXJrZXR0eXBlOjMxOQ==',
    name: 'Match Result',
  },
  total: {
    id: 'c3BvcnRtYXJrZXR0eXBlOjM1NQ==',
    name: 'Odd or Even Total',
  },
};
