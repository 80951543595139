import utils from '@bm/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import { OddsDisplay } from '../types/oddsDisplay';

function useFormat() {
  const options = useSelector((state: RootState) => state.config.options);

  const config = {
    oddsType: options?.oddsType || OddsDisplay.Decimal,
    locale: options?.locale || 'en-AU',
    currency: options?.currency || 'AUD',
    measurement: options?.measurement || 'METRIC',
  };

  function formatCurrency(value = 0, minimumFractionDigits = 0) {
    const sanitised = parseFloat(String(value || 0).replace(/[^-\d.]/g, ''));

    return new Intl.NumberFormat(config.locale, {
      style: 'currency',
      currency: config.currency,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 2,
      minimumFractionDigits,
    }).format(sanitised);
  }

  return {
    odds: utils.format(config).odds,
    currency: formatCurrency,
    ordinal: utils.format(config).ordinal,
    weight: utils.format(config).weight,
    distance: utils.format(config).distance,
  };
}

export default useFormat;
