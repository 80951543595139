import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import orderBy from 'lodash/orderBy';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../../../components/Icon/Icon';
import Label from '../../../../../components/Label';
import OddsButton from '../../../../../components/OddsButton/OddsButton';
import { BetType } from '../../../../../constants/betTypes';
import { PriceType, RaceCardQuery } from '../../../../../generated/graphql';
import { addOrRemoveBet } from '../../../../../stores/betslipSlice';
import { RootState } from '../../../../../stores/store';
import { BetslipType } from '../../../../../types/betslipType';
import { PlatformType } from '../../../../../types/platformType';
import { hasSingleBet } from '../../../../../utils/betslip/common';
import { SortByKey, SortOrder } from '../../../../../utils/race/types';
import RunnerDetails from '../../RunnerDetails/RunnerDetails';
import useRaceCard from '../../useRaceCard';
import CompetitorFooter from '../CompetitorFooter';
import styles from '../Fields.module.css';

const selector = createSelector(
  [
    (state: RootState) => state.config.options.toteEnabled,
    (state: RootState) => state.setting,
    (state: RootState) => state.config.options.betslipType,
    (state: RootState) => state.betslip.betslip,
    (state: RootState) => state.setting.selectedPriceType,
  ],
  (toteEnabled, settings, betslipType, betslip, selectedPriceType) => ({
    toteEnabled,
    settings,
    betslipType,
    betslip,
    selectedPriceType,
  })
);

export default function PlaceField() {
  const { race, competitors, toggleShortFormForRunner } = useRaceCard();
  const [sortBy, setSortBy] = useState(SortByKey.NAME);
  const [sortDesc, setSortDesc] = useState(false);
  const { selectedPriceType, betslipType, betslip } = useSelector(selector);
  const dispatch = useDispatch();

  const handleChangeSort = useCallback(
    (sortValue: SortByKey) => {
      if (sortValue === sortBy) {
        setSortDesc((prev) => !prev);
      } else {
        setSortBy(sortValue);
        setSortDesc(false);
      }
    },
    [sortBy]
  );

  const renderSortIcon = useCallback(
    (sortValue: string) => {
      if (sortValue !== sortBy) {
        return 'sort';
      }

      return sortDesc ? SortOrder.DESCENDING : SortOrder.ASCENDING;
    },
    [sortBy, sortDesc]
  );

  const getPlacePrice = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      competitor.prices?.find(
        (price) =>
          price.type ===
          (selectedPriceType === PlatformType.Fixed
            ? PriceType.PlaceFixedOdds2
            : PriceType.Place)
      )?.price,
    [selectedPriceType]
  );

  const sortedCompetitors = useMemo(() => {
    switch (sortBy) {
      case SortByKey.PLACE:
        return orderBy(
          competitors,
          ['scratched', getPlacePrice],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
      case SortByKey.NAME:
      default:
        return orderBy(
          competitors,
          ['scratched', 'tabNo'],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
    }
  }, [competitors, sortBy, sortDesc, getPlacePrice]);

  return (
    <>
      <div className={styles.grid}>
        <Label
          className={clsx(styles.label, styles.sortable, {
            [styles.selected]: sortBy === SortByKey.NAME,
          })}
          onClick={() => handleChangeSort(SortByKey.NAME)}
        >
          RUNNER
          <Icon name={renderSortIcon(SortByKey.NAME)} size="small" />
        </Label>
        <Label
          className={clsx(styles.label, styles.center, styles.sortable, {
            [styles.selected]: sortBy === SortByKey.PLACE,
          })}
          onClick={() => handleChangeSort(SortByKey.PLACE)}
        >
          PLACE
          <Icon name={renderSortIcon(SortByKey.PLACE)} size="small" />
        </Label>

        {sortedCompetitors.map((competitor, index) => {
          const placePrice = getPlacePrice(competitor);

          return (
            <Fragment key={`${competitor.tabNo}:${competitor.selectionID}`}>
              {race?.meeting ? (
                <RunnerDetails
                  meeting={race.meeting}
                  competitor={competitor}
                  onClick={() => toggleShortFormForRunner(competitor.runner.id)}
                />
              ) : null}
              <div className={styles.center}>
                <OddsButton
                  price={placePrice}
                  onClick={() => {
                    if (race) {
                      dispatch(
                        addOrRemoveBet({
                          betType:
                            selectedPriceType === PlatformType.Fixed
                              ? BetType.PLACE_FIXED_ODDS2
                              : BetType.PLACE,
                          traditional: betslipType === BetslipType.American,
                          odds: placePrice,
                          race,
                          meeting: race?.meeting,
                          competitor,
                        })
                      );
                    }
                  }}
                  selected={
                    !!race &&
                    hasSingleBet(betslip, {
                      betType:
                        selectedPriceType === PlatformType.Fixed
                          ? BetType.PLACE_FIXED_ODDS2
                          : BetType.PLACE,
                      race,
                      meeting: race?.meeting,
                      competitor,
                    })
                  }
                  disabled={competitor.scratched}
                />
              </div>
              <CompetitorFooter competitor={competitor} index={index} />
            </Fragment>
          );
        })}
      </div>
    </>
  );
}
