import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../../components/Icon/Icon';
import NewButton from '../../../../components/NewButton/NewButton';
import { BetType } from '../../../../constants/betTypes';
import useFormat from '../../../../hooks/useFormat';
import { useMeasure } from '../../../../hooks/useMeasure';
import useOnScreen from '../../../../hooks/useOnScreen';
import { resetExoticSelections } from '../../../../stores/betslipSlice';
import { RootState } from '../../../../stores/store';
import {
  getExoticCombinations,
  getStandoutExoticCombinations,
} from '../../../../utils/betslip/common';
import { ExoticBetType } from '../../../../utils/betslip/types';
import styles from './ExoticBetBuilder.module.css';

type Props = {
  betType: BetType;
  onAddBetslip: () => void;
};

const selector = createSelector(
  [(state: RootState) => state.betslip.exoticSelections],
  (exoticSelections) => ({
    exoticSelections,
  })
);

export default function ExoticBetBuilder({ betType, onAddBetslip }: Props) {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(containerRef);
  const dimensions = useMeasure(containerRef);
  const format = useFormat();
  const [toast, setToast] = useState('');
  const [showSelections, setShowSelections] = useState(false);
  const { exoticSelections } = useSelector(selector);
  const selectedBet = exoticSelections[betType as ExoticBetType];

  // Reset exotic selections on unmount
  useEffect(
    () => () => {
      dispatch(resetExoticSelections());
    },
    [dispatch]
  );

  const combinations = useMemo(() => {
    if (betType === BetType.QUINELLA) {
      return getStandoutExoticCombinations(
        selectedBet.map((sel) => sel.map((c) => c.tabNo))
      );
    }

    return getExoticCombinations(
      exoticSelections[betType]?.length,
      selectedBet.map((sel) => sel.map((c) => c.tabNo))
    );
  }, [selectedBet, exoticSelections, betType]);

  useEffect(() => {
    if (combinations > 0) {
      setToast('');
    }
  }, [combinations]);

  const addToBetslip = useCallback(() => {
    if (combinations === 0) {
      setToast(`Must select ${selectedBet.length} runners`);
    } else {
      setToast('Added to Betslip');
      onAddBetslip();
    }
  }, [combinations, onAddBetslip, selectedBet]);

  return (
    <div
      className={clsx(styles.container, {
        [styles.noSelections]: selectedBet.flat().length === 0,
        [styles.showSelections]: showSelections,
        [styles.hasError]: toast,
      })}
      ref={containerRef}
    >
      <div
        className={clsx(styles.builder, {
          [styles.fixed]: !onScreen,
        })}
        style={{
          width: dimensions?.width,
        }}
      >
        {toast ? (
          <div className={styles.toast} onClick={() => setToast('')}>
            {toast}
          </div>
        ) : null}
        <button
          type="button"
          className={styles.header}
          onClick={() => setShowSelections((prev) => !prev)}
        >
          <span>
            Selections{' '}
            <Icon
              name="chevron"
              size="extra-small"
              className={styles.selectionsIcon}
            />
          </span>
          <span>
            <span className={styles.combos}>Combos:</span> {combinations}
          </span>
        </button>
        <div className={styles.content}>
          <div className={styles.selections}>
            {selectedBet.map((selection, index) => (
              <div key={format.ordinal(index + 1)} className={styles.selection}>
                <Icon
                  name={
                    selection.length === 0
                      ? 'circleTickDefault'
                      : 'circleTickActive'
                  }
                  size="small"
                />
                <span className={styles.ordinal}>
                  {format.ordinal(index + 1)}:{' '}
                </span>
                {selection.map((c) => c.tabNo).join(', ')}
              </div>
            ))}
          </div>
          <div className={styles.actions}>
            <NewButton
              size="medium"
              theme="stroke"
              onClick={() => dispatch(resetExoticSelections())}
            >
              Clear All Selections
            </NewButton>
            <NewButton
              size="small"
              onClick={addToBetslip}
              leftIcon="plus"
              theme="secondary"
            >
              Add To Betslip
            </NewButton>
          </div>
        </div>
      </div>
    </div>
  );
}
