import React from 'react';
import styles from './CategorySeperator.module.css';

interface CategorySeperatorProps {
  label: string;
}

const CategorySeperator = ({ label }: CategorySeperatorProps) => (
  <div className={styles.container}>{label}</div>
);

export default CategorySeperator;
