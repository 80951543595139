import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useSubscription } from 'urql';
import Countdown from '../../components/Countdown/Countdown';
import Icon from '../../components/Icon/Icon';
import {
  FilterKey,
  MeetingType,
  NextToJumpTickerDocument,
  NextToJumpTickerQuery,
  NextToJumpTickerQueryVariables,
  RaceStatus,
  RaceUpdatedDocument,
  RaceUpdatedSubscription,
  RaceUpdatedSubscriptionVariables,
} from '../../generated/graphql';
import { useMeasure } from '../../hooks/useMeasure';
import {
  MEETING_TYPE_LENGTH,
  updateSettings,
  updateTypeFilters,
} from '../../stores/settingSlice';
import { RootState } from '../../stores/store';
import styles from './NextToJumpTicker.module.css';

type Props = {
  baseUrl: string;
};

const selector = createSelector(
  [
    (state: RootState) => state.setting.typeFilters,
    (state: RootState) => state.time.currentTime, // We reselect this to ensure that races < -1 min are not displayed. This is an intentional additional rerender every second.
  ],
  (typeFilters, currentTime) => ({
    typeFilters,
    currentTime,
  })
);

export default function NextToJumpTicker({ baseUrl = '' }: Props) {
  const dispatch = useDispatch();
  const { typeFilters, currentTime } = useSelector(selector);
  const [leftArrowEnabled, setLeftArrowEnabled] = useState(false);
  const [rightArrowEnabled, setRightArrowEnabled] = useState(true);
  const [time, setTime] = useState(dayjs());
  const [races, setRaces] = useState<NextToJumpTickerQuery['races']>([]);
  const [results] = useQuery<
    NextToJumpTickerQuery,
    NextToJumpTickerQueryVariables
  >({
    query: NextToJumpTickerDocument,
    variables: {
      from: time.toDate(),
      to: time.add(24, 'hours').toDate(),
      limit: 10,
      include: [
        ...typeFilters.map((type) => ({
          key: FilterKey.MeetingType,
          value: type,
        })),
      ],
    },
  });
  useEffect(() => {
    if (results.data) {
      setRaces(results.data.races);
    }
  }, [results]);

  const [subscription] = useSubscription<
    RaceUpdatedSubscriptionVariables,
    RaceUpdatedSubscription
  >({
    query: RaceUpdatedDocument,
  });
  useEffect(() => {
    if (subscription.data) {
      setRaces((prev) => {
        if (
          prev.some((race) => race.id === subscription.data?.raceUpdated.id)
        ) {
          return prev.map((race) => {
            if (race.id === subscription.data?.raceUpdated.id) {
              return {
                ...race,
                ...(subscription?.data?.raceUpdated || {}),
              };
            }

            return race;
          });
        }

        return prev;
      });
    }
  }, [subscription]);

  // When race status changes, we'll check the first race to see if its after the current time before refetching
  useEffect(() => {
    if (
      !results.fetching &&
      races?.[0] &&
      dayjs(currentTime)
        .subtract(1, 'minute') // Ensures that races < -1 min are not displayed
        .isAfter(dayjs(races[0].startTime))
    ) {
      setTime(dayjs());
    }
  }, [results.fetching, races, currentTime]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const dimensions = useMeasure(scrollRef);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    setLeftArrowEnabled(event.currentTarget.scrollLeft > 0);
    setRightArrowEnabled(
      event.currentTarget.scrollLeft <
        event.currentTarget.scrollWidth - event.currentTarget.clientWidth
    );
  }, []);

  useEffect(() => {
    setLeftArrowEnabled(Math.round(scrollRef.current?.scrollLeft || 0) > 0);
    setRightArrowEnabled(
      Math.round(scrollRef.current?.clientWidth || 0) <=
        (scrollRef.current?.scrollWidth || 0)
    );
  }, [dimensions, races]);

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.label}>Next to Jump</div>
      </div>
      <div className={styles.section}>
        <div className={styles.filters}>
          <button
            type="button"
            className={clsx(styles.filter, {
              [styles.selected]:
                typeFilters.length === 0 ||
                typeFilters.length === MEETING_TYPE_LENGTH,
            })}
            onClick={() =>
              dispatch(
                updateSettings({
                  typeFilters: [],
                })
              )
            }
          >
            All
          </button>
          <button
            type="button"
            className={clsx(styles.filter, {
              [styles.selected]: typeFilters.includes(MeetingType.Thoroughbred),
            })}
            onClick={() =>
              dispatch(updateTypeFilters(MeetingType.Thoroughbred))
            }
          >
            <Icon name="THOROUGHBRED" />
          </button>
          <button
            type="button"
            className={clsx(styles.filter, {
              [styles.selected]: typeFilters.includes(MeetingType.Greyhound),
            })}
            onClick={() => dispatch(updateTypeFilters(MeetingType.Greyhound))}
          >
            <Icon name="GREYHOUND" />
          </button>
          <button
            type="button"
            className={clsx(styles.filter, {
              [styles.selected]: typeFilters.includes(MeetingType.Harness),
            })}
            onClick={() => dispatch(updateTypeFilters(MeetingType.Harness))}
          >
            <Icon name="HARNESS" />
          </button>
        </div>
      </div>
      <div className={styles.races} ref={scrollRef} onScroll={handleScroll}>
        {results.data?.races
          .filter((race) => race.status === RaceStatus.Open)
          .map((race) => (
            <button
              onClick={() => {
                const pathname = `/racing/meeting/${race.meeting.id}/race/${race.id}`;
                if (window.location.pathname === baseUrl) {
                  window.location.hash = pathname;
                } else {
                  window.location.href = `${baseUrl}/#${pathname}`;
                }
              }}
              key={race.id}
              className={styles.link}
            >
              <div className={styles.race}>
                <Icon name={race.meeting.type} />
                <span className={styles.track}>{race.meeting.track.name}</span>
                <span className={styles.raceNumber}>R{race.number}</span>
                <Countdown
                  startTime={race.startTime}
                  className={styles.countdown}
                  hideLozenge
                />
              </div>
            </button>
          ))}
      </div>
      <div className={styles.arrows}>
        <button
          type="button"
          className={styles.arrow}
          onClick={() => {
            if (scrollRef.current) {
              scrollRef.current?.scrollTo({
                left: scrollRef.current.scrollLeft - 200,
                behavior: 'smooth',
              });
            }
          }}
          disabled={!leftArrowEnabled}
        >
          <Icon name="caretLeft" size="small" />
        </button>
        <aside className={styles.separator} />
        <button
          type="button"
          className={styles.arrow}
          onClick={() => {
            if (scrollRef.current) {
              scrollRef.current?.scrollTo({
                left: scrollRef.current.scrollLeft + 200,
                behavior: 'smooth',
              });
            }
          }}
          disabled={!rightArrowEnabled}
        >
          <Icon name="caretRight" size="small" />
        </button>
      </div>
    </div>
  );
}
