import Icon from '../../components/Icon/Icon';
import styles from './UpcomingTip.module.css';

export default function TipAuthor() {
  return (
    <div className={styles.header}>
      <Icon name="foxTips" className={styles.foxTips} />
    </div>
  );
}
