import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
import Input from '../../../components/Form/Input/Input';
import Select from '../../../components/Form/Select/Select';
import Label from '../../../components/Label';
import RacingFilters from '../../../components/RacingFilters/RacingFilters';
import {
  RaceStatus,
  ResultedMeetingsDocument,
  ResultedMeetingsQuery,
  ResultedMeetingsQueryVariables,
} from '../../../generated/graphql';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import { RootState } from '../../../stores/store';
import RaceCardHeader from '../RaceCard/RaceCardHeader/RaceCardHeader';
import RacingResult from './RacingResult';
import styles from './RacingResults.module.css';

export default function RacingResults() {
  const navigate = useNavigate();
  const route = useMemo(
    () => [
      {
        label: 'Racing Home',
        url: '/',
      },
      {
        label: 'Results',
        url: '/racing/results',
      },
    ],
    []
  );
  useBreadcrumbs(route);

  const yesterday = useMemo(() => dayjs().subtract(1, 'day'), []);
  const [selectedDate, setSelectedDate] = useState(
    yesterday.format('YYYY-MM-DD')
  );
  const [selectedMeetingId, setSelectedMeetingId] = useState<
    string | undefined
  >();
  const [selectedRaceId, setSelectedRaceId] = useState<string | undefined>();
  const typeFilters = useSelector(
    (state: RootState) => state.setting.typeFilters
  );
  const [results] = useQuery<
    ResultedMeetingsQuery,
    ResultedMeetingsQueryVariables
  >({
    query: ResultedMeetingsDocument,
    variables: {
      date: new Date(selectedDate),
    },
  });

  const filteredMeetings = useMemo(
    () =>
      typeFilters.length > 0
        ? results.data?.meetingsDated?.filter((meeting) =>
            typeFilters.includes(meeting.type)
          )
        : results.data?.meetingsDated,
    [results, typeFilters]
  );

  function onChangeDateResults(event: ChangeEvent<HTMLInputElement>) {
    setSelectedDate(event.target.value);
    setSelectedMeetingId(undefined);
  }

  function onChangeMeeting(meetingId: string) {
    setSelectedMeetingId(meetingId);
  }

  useEffect(() => {
    if (selectedMeetingId) {
      const selectedMeeting = results?.data?.meetingsDated.find(
        (m) => m.id === selectedMeetingId
      );
      const nextRace = selectedMeeting?.races.filter(
        (r) =>
          (r.status === RaceStatus.Closed ||
            r.status !== RaceStatus.Abandoned) &&
          r.results.length > 0
      )[0];

      if (nextRace) {
        setSelectedRaceId(nextRace?.id);
      } else {
        navigate(
          `/racing/meeting/${selectedMeetingId}/race/${selectedMeeting?.races[0].id}`
        );
      }
    }
  }, [selectedMeetingId, results, navigate]);

  return (
    <>
      <Helmet title="Results">
        <meta name="description" content="Racing Results" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.filters}>
            <Label>DATE</Label>
            <div className={styles.inputContainer}>
              <Input
                id="date"
                type="date"
                value={selectedDate}
                onChange={onChangeDateResults}
                max={dayjs().format('YYYY-MM-DD')}
              />
            </div>
            <div>
              <RacingFilters />
            </div>
            <div className={styles.trackSelect}>
              <Select
                value={selectedMeetingId}
                onChange={(event) => onChangeMeeting(event.target.value)}
                options={[
                  {
                    value: '',
                    label: 'Track',
                  },
                  ...(filteredMeetings?.map((m) => ({
                    value: m.id,
                    label: m.track.name,
                  })) || []),
                ]}
              />
            </div>
          </div>
        </div>
        {selectedRaceId && selectedMeetingId && filteredMeetings ? (
          <RaceCardHeader
            meetingId={selectedMeetingId}
            raceId={selectedRaceId}
            onChangeMeeting={onChangeMeeting}
            onChangeRace={setSelectedRaceId}
            resulted
            hideBreadcrumbs
          >
            <RacingResult raceId={selectedRaceId} />
          </RaceCardHeader>
        ) : null}
      </div>
    </>
  );
}
