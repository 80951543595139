import clsx from 'clsx';
import { RaceStatus as RaceStatusEnum } from '../../generated/graphql';
import Countdown from '../Countdown/Countdown';
import styles from './RaceStatus.module.css';

type Props = {
  startTime: Date;
  status?: RaceStatusEnum;
  className?: string;
};

export default function RaceStatus({
  startTime,
  status = RaceStatusEnum.Open,
  className,
}: Props) {
  return (
    <span className={clsx(className, styles.container)}>
      {status === RaceStatusEnum.Open && startTime ? (
        <Countdown startTime={startTime} className={className} />
      ) : (
        <span
          className={clsx({
            [styles.closed]: status === RaceStatusEnum.Closed,
          })}
        >
          {status === RaceStatusEnum.Abandoned ? 'ABAND' : status}
        </span>
      )}
    </span>
  );
}
