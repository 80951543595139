import { RaceCardQuery } from '../../../../../generated/graphql';
import styles from './ScratchedCompetitorDeductions.module.css';

export default function ScratchedCompetitorDeductions({
  competitor,
  race,
}: {
  competitor: NonNullable<RaceCardQuery['race']>['competitors'][0];
  race: RaceCardQuery['race'];
}) {
  function noDeduction() {
    return (
      <div className={styles.noDeductions}>
        <span className={styles.label}>No Deductions</span>
      </div>
    );
  }

  if (race && race.deductionSet?.length > 0) {
    const deductionsObject = race.deductionSet.find(
      (d) => d.scratchedTabNo === competitor.tabNo
    );
    const deductionsApplied = deductionsObject?.deductions.find(
      (d) => d.deduction > 0
    );
    if (deductionsApplied) {
      return (
        <div className={styles.deductions}>
          <span className={styles.label}>Deduction Applied</span>
          <span className={styles.label}>{`W ${
            deductionsObject?.deductions.find((d) => d.type === 'WIN')
              ?.deduction
          }`}</span>
          <span className={styles.label}>{`P ${
            deductionsObject?.deductions.find((d) => d.type === 'PLACE')
              ?.deduction
          }`}</span>
        </div>
      );
    }
    return noDeduction();
  }
  return noDeduction();
}
