import dayjs from 'dayjs';
import React from 'react';
import { ExtendedSportEvents } from '../../types/sport';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import Countdown from '../Countdown/Countdown';
import Icon, { IconName } from '../Icon/Icon';
import SportEventSelect from '../SportEventSelect/SportEventSelect';
import styles from './SportCardInfo.module.css';

type SportCardInfoProps = {
  sportEvent: ExtendedSportEvents | undefined;
  loading: boolean;
  routes: { label: string; url: string }[];
};

const DISPLAY_DATE_FORMAT = 'D MMM YYYY, h:mma';

const showCountdown = (date: Date) =>
  dayjs().isBefore(date) && dayjs().isAfter(dayjs(date).subtract(15, 'm'));

const SportCardInfo = ({ sportEvent, loading, routes }: SportCardInfoProps) => {
  const teams = sportEvent?.teams;
  const teamA = teams ? teams[0] : undefined;
  const teamB = teams ? teams[1] : undefined;

  const categoryId = sportEvent?.competition.category.id;

  const renderTeam = (temaName?: string, teamIcon?: string) => {
    return (
      <>
        <div className={styles.teamBadgeContainer}>
          <div className={styles.teamBadge}>
            <Icon name={teamIcon as IconName} />
          </div>
        </div>
        <div className={styles.teamName}>{temaName}</div>
      </>
    );
  };

  const renderTime = (isMobile = false) => {
    if (sportEvent?.start_date) {
      const shouldShowCountdown = showCountdown(sportEvent?.start_date);
      return (
        <>
          <div className={styles.timeText}>
            {dayjs(sportEvent?.start_date).format(DISPLAY_DATE_FORMAT)}
          </div>
          {shouldShowCountdown && (
            <Countdown startTime={sportEvent?.start_date} />
          )}
        </>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.breadCrumbs}>
        <BreadCrumbs breadcrumbs={routes} />
      </div>
      <div className={styles.mobileTimeContainer}>{renderTime()}</div>
      <div className={styles.teamsContainer}>
        <div className={styles.team}>{renderTeam(teamA?.name, 'lakers')}</div>
        <div className={styles.eventSelect}>
          <SportEventSelect
            categoryId={categoryId}
            sportEventId={sportEvent?.id}
          />
          <div className={styles.desktopTimeContainer}>{renderTime()}</div>
        </div>
        <div className={styles.team}>{renderTeam(teamB?.name, 'chicago')}</div>
      </div>
    </div>
  );
};

export default SportCardInfo;
