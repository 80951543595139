import clsx from 'clsx';
import Icon from '../../../components/Icon/Icon';
import { BetStatus } from '../../../constants/betStatus';
import { CheckBetResponse } from '../../../utils/betslip/types';
import styles from './BetPlacedStatus.module.css';

export default function BetPlacedStatus({
  checkBetResponse,
}: {
  checkBetResponse: CheckBetResponse;
}) {
  function getStatusComponent() {
    switch (checkBetResponse.bet_details.status) {
      case BetStatus.UNRESULTED:
      case BetStatus.PAID:
        return (
          <div className={clsx(styles.container, styles.placed)}>
            <Icon name="circleTickActive" />
            <span>Bet Placed</span>
            <span>{`#${checkBetResponse.ticket_id}`}</span>
          </div>
        );
      case BetStatus.REJECTED:
      case BetStatus.REFUNDED:
      case BetStatus.CANCELLED:
      case BetStatus.BAD_REQUEST:
      case BetStatus.INTERNAL_SERVER_ERROR:
      case BetStatus.FAILED:
        return (
          <div className={clsx(styles.container, styles.rejected)}>
            <Icon name="alert" />
            <span>Your Bet has been declined</span>
            <span>{`#${checkBetResponse.ticket_id}`}</span>
          </div>
        );
      case BetStatus.PARTIAL:
        return (
          <>
            <div className={clsx(styles.container, styles.partial)}>
              <Icon name="alert" />
              <span>Bet amount adjusted from </span>
            </div>
            <div className={clsx(styles.container, styles.placed)}>
              <Icon name="circleTickActive" />
              <span>Bet Placed</span>
              <span>{`#${checkBetResponse.ticket_id}`}</span>
            </div>
          </>
        );
      default:
        return 'rejected';
    }
  }
  return <>{getStatusComponent()}</>;
}
