import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Icon from '../../../components/Icon/Icon';
import { BetslipStage, BetStatus } from '../../../constants/betStatus';
import useFormat from '../../../hooks/useFormat';
import styles from './BetStatusBar.module.css';

export default function BetStatusBar({
  price,
  stake,
  betslipStage,
  betStatus,
}: {
  price?: number;
  stake?: number;
  betslipStage?: BetslipStage;
  betStatus?: BetStatus;
}) {
  const [currentPrice, setCurrentPrice] = useState<number>();
  const [currentStake, setCurrentStake] = useState<number>();
  const [currentBetStage, setCurrentBetStage] = useState<BetslipStage>();
  const format = useFormat();
  useEffect(() => {
    if (price && !currentPrice) setCurrentPrice(price);
    if (!currentPrice || !price) return;
    if (currentPrice < price) {
      setCurrentBetStage(BetslipStage.NORMAL);
      setCurrentPrice(price);
    }
    if (currentPrice > price && currentBetStage !== BetslipStage.PRICECHANGE) {
      setCurrentBetStage(BetslipStage.PRICECHANGE);
    }
    if (
      currentBetStage === BetslipStage.PRICECHANGE &&
      currentPrice === price
    ) {
      setCurrentBetStage(BetslipStage.NORMAL);
    }
  }, [price, currentPrice, currentBetStage]);

  useEffect(() => {
    if (stake && !currentStake) setCurrentStake(stake);
    if (!currentStake || !stake) return;
    setCurrentStake(stake);
  }, [stake, currentStake]);

  useEffect(() => {
    if (betslipStage && !currentBetStage) setCurrentBetStage(betslipStage);
  }, [betslipStage, currentBetStage]);

  function getStatusComponent() {
    if (betStatus) {
      switch (betStatus) {
        case BetStatus.UNRESULTED:
        case BetStatus.PAID:
          return (
            <div className={clsx(styles.container, styles.placed)}>
              <Icon name="circleTickActive" />
              <span>Bet Placed</span>
            </div>
          );
        case BetStatus.REJECTED:
        case BetStatus.REFUNDED:
        case BetStatus.CANCELLED:
        case BetStatus.BAD_REQUEST:
        case BetStatus.INTERNAL_SERVER_ERROR:
        case BetStatus.FAILED:
          return (
            <div className={clsx(styles.container, styles.rejected)}>
              <Icon name="alert" />
              <span>Your Bet has been declined</span>
            </div>
          );
        default:
          return null;
      }
    }
    switch (currentBetStage) {
      case BetslipStage.PRICECHANGE:
        return (
          <div className={clsx(styles.container, styles.alert)}>
            <Icon name="alert" />
            <span>{`Bet amount adjusted from ${format.odds(
              currentPrice
            )} to ${format.odds(price)}`}</span>
          </div>
        );
      default:
        return null;
    }
  }
  return <>{getStatusComponent()}</>;
}
