import {
  FloatingFocusManager,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { RaceCardQuery } from '../../../../generated/graphql';
import FoxTip from './FoxTip';
import styles from './FoxTip.module.css';

type Props = {
  comment: string;
  race: NonNullable<RaceCardQuery['race']>;
};

export default function ReadMoreFoxTipModal({ comment, race }: Props) {
  const [open, setOpen] = useState(false);
  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: setOpen,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <Button
        theme="none"
        size="small"
        className={styles.readmore}
        {...getReferenceProps({
          ref: reference,
        })}
      >
        Read more
      </Button>
      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context}>
            <Modal
              {...getFloatingProps({
                ref: floating,
              })}
            >
              <FoxTip race={race} comment={comment} />
            </Modal>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}
