import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useSubscription } from 'urql';
import {
  GetFeaturedRaceDocument,
  GetFeaturedRaceQuery,
  GetFeaturedRaceQueryVariables,
  PriceUpdatesDocument,
  PriceUpdatesSubscription,
  PriceUpdatesSubscriptionVariables,
  RaceUpdatedDocument,
  RaceUpdatedSubscription,
  RaceUpdatedSubscriptionVariables,
} from '../../generated/graphql';
import { RootState } from '../../stores/store';
import ReadMoreTipModal from './ReadMoreTipModal';
import TipAuthor from './TipAuthor';
import TipRunner from './TipRunner';
import TipTrack from './TipTrack';
import styles from './UpcomingTip.module.css';

type Props = {
  raceId: string;
  tabNo: string;
  comment: string;
};

export default function UpcomingTip({ raceId, tabNo, comment }: Props) {
  const source = useSelector((state: RootState) => state.config.coreApiSource);
  const [race, setRace] = useState<GetFeaturedRaceQuery['race']>();
  const [resulted, setResulted] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const [{ data }] = useQuery<
    GetFeaturedRaceQuery,
    GetFeaturedRaceQueryVariables
  >({
    query: GetFeaturedRaceDocument,
    variables: {
      id: raceId,
      source,
    },
  });
  useEffect(() => {
    if (data?.race) {
      setRace(data.race);
    }
  }, [data]);

  const [subscription] = useSubscription<
    RaceUpdatedSubscriptionVariables,
    RaceUpdatedSubscription
  >({
    query: RaceUpdatedDocument,
    variables: {
      raceId,
    },
  });
  useEffect(() => {
    if (subscription.data) {
      setRace((prev) => {
        if (prev) {
          const competitors =
            subscription?.data?.raceUpdated?.competitors || [];
          return {
            ...prev,
            ...(subscription?.data?.raceUpdated || {}),
            competitors: prev.competitors.map((competitor) => ({
              ...competitor,
              ...(competitors.find((c) => c.tabNo === competitor.tabNo) || {}),
            })),
          };
        }

        return prev;
      });
    }
  }, [subscription]);

  const [priceSubscription] = useSubscription<
    PriceUpdatesSubscriptionVariables,
    PriceUpdatesSubscription
  >({
    query: PriceUpdatesDocument,
    variables: {
      raceId: race?.id,
      source,
    },
    pause: !race,
  });
  useEffect(() => {
    if (priceSubscription.data) {
      const { priceUpdates } = priceSubscription.data;
      setRace((prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          competitors: prev?.competitors.map((c) => ({
            ...c,
            prices: c.prices.map((price) => {
              const newPrice = priceUpdates.prices.find(
                (updatedPrice) =>
                  updatedPrice.tabNo === c.tabNo &&
                  updatedPrice.type === price.type
              );
              return newPrice || price;
            }),
          })),
        };
      });
    }
  }, [priceSubscription]);

  useEffect(() => {
    if (race) {
      setResulted(race.results.length > 0);

      if (descriptionRef.current) {
        setShowMore(
          descriptionRef.current.offsetHeight <
            descriptionRef.current.scrollHeight
        );
      }
    }
  }, [race]);

  return (
    <div className={clsx(styles.container)}>
      <TipAuthor />
      <div className={styles.content}>
        <div
          className={styles.comment}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: comment }}
          ref={descriptionRef}
        />
        {showMore ? (
          <ReadMoreTipModal
            tabNo={tabNo}
            race={race}
            resulted={resulted}
            comment={comment}
          />
        ) : (
          <div className={styles.readMoreEmpty} />
        )}
      </div>
      <TipTrack race={race} />
      <TipRunner race={race} tabNo={tabNo} resulted={resulted} />
    </div>
  );
}
