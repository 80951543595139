import React, { createContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BetType } from '../../constants/betTypes';
import { addOrRemoveBet } from '../../stores/actions/combinedActions';
import {
  MarketSelections,
  SportEventDetails,
  SportMarket,
} from '../../types/sport';
import { SportSingleBet } from '../../utils/betslip/types';

type SportBetslipContextType = {
  addToBetslip: (params: AddToBetSlipType) => void;
};

export type MarketSelection = NonNullable<MarketSelections>[0];

type AddToBetSlipType = {
  eventDetails: SportEventDetails;
  market: SportMarket;
  selection: MarketSelection;
};

export const SportBetslipContext = createContext<SportBetslipContextType>({
  addToBetslip: () => {},
});

export const useSportsBetslip = () => {
  const dispatch = useDispatch();

  const addToBetslip = useCallback(
    ({ eventDetails, market, selection }: AddToBetSlipType) => {
      const sportSingleBet: SportSingleBet = {
        betType: BetType.SPORT,
        marketDetails: market,
        selection,
        eventDetails,
        odds: selection.prices ? selection.prices[0].decimal_price : 0,
      };

      addOrRemoveBet(sportSingleBet, dispatch);
    },
    [dispatch]
  );

  return {
    addToBetslip,
  };
};

export const SportBetSlipProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { addToBetslip } = useSportsBetslip();
  return (
    <SportBetslipContext.Provider value={{ addToBetslip }}>
      {children}
    </SportBetslipContext.Provider>
  );
};

export default useSportsBetslip;
