import clsx from 'clsx';
import React, { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyInput from '../../../components/Form/CurrencyInput/CurrencyInput';
import Icon from '../../../components/Icon/Icon';
import { BetType } from '../../../constants/betTypes';
import useFormat from '../../../hooks/useFormat';
import {
  addOrRemoveParlays,
  updateParlayStake,
} from '../../../stores/betslipSlice';
import { RootState } from '../../../stores/store';
import {
  findSingleBetIndex,
  getBetDetails,
  isSportSingleBet,
} from '../../../utils/betslip/common';
import { AllSingleBet, Bet } from '../../../utils/betslip/types';
import ParlayBet from './ParlayBet';
import styles from './Parlays.module.css';

function getKey(bet: Bet) {
  if (isSportSingleBet(bet)) {
    return `${bet?.eventDetails?.id}:${bet.betType}:${bet.selection.id}`;
  }
  return `${bet?.race?.id}:${bet.betType}:${bet?.competitor?.tabNo}`;
}

function getParlayGroupKey(bets: Bet[]) {
  if (isSportSingleBet(bets[0])) {
    return bets[0].eventDetails?.id;
  }
  return bets[0]?.race?.id;
}

export default function Parlays({ singleBets }: { singleBets: Bet[] }) {
  const { betslip, parlaySelections } = useSelector(
    (state: RootState) => state.betslip
  );
  const dispatch = useDispatch();

  const format = useFormat();

  const parlayBet = betslip.find((bet) => bet.betType === BetType.PARLAY);
  const parlayStake = parlayBet?.stake || 0;

  const parlayGroups = Array.from(
    new Set(singleBets.map((bet) => getBetDetails(bet)?.id))
  ).map((id) => ({
    bets: singleBets.filter((b) => getBetDetails(b)?.id === id),
  }));

  function handleSetSelected(bet: AllSingleBet) {
    dispatch(addOrRemoveParlays(bet));
    return null;
  }

  const totalOdds = parlaySelections
    .filter((bet) => bet?.race?.status === 'OPEN' && bet.odds && bet.odds > 0)
    .map((bet) => bet.odds)
    .reduce<number>((acc, val) => (val ? acc * val : acc), 1);

  return (
    <ParlayAccordion
      title="Multis"
      count={
        parlaySelections.filter((bet) => bet?.race?.status === 'OPEN').length
      }
    >
      {parlayGroups.length > 1 ? (
        <div className={styles.parlays}>
          <div className={styles.heading}>
            <Icon name="combined" />{' '}
            <span className={styles.bold}>
              {parlayGroups.length} Leg - Multi
            </span>
          </div>
          <div className={styles.inputContainer}>
            <CurrencyInput
              size={5}
              disabled={parlaySelections.length <= 1}
              containerClassName={styles.currencyInput}
              onChange={(val: number) =>
                dispatch(
                  updateParlayStake({
                    stake: val,
                    betType: BetType.PARLAY,
                  })
                )
              }
              value={parlayStake}
            />
          </div>
          <div
            className={styles.potentialReturn}
          >{`Potential Return: ${format.currency(
            parlayStake * totalOdds,
            2
          )} `}</div>
          {parlayGroups.map((group, index) => (
            <div
              className={styles.parlayGroup}
              key={getParlayGroupKey(group.bets)}
            >
              <div className={styles.groupHeading}>{`Leg ${index + 1}`}</div>
              <div className={styles.parlayBets}>
                {group.bets.map((bet, i) => (
                  <div key={getKey(bet)}>
                    <ParlayBet
                      bet={bet as AllSingleBet}
                      selected={findSingleBetIndex(parlaySelections, bet) >= 0}
                      setSelected={handleSetSelected}
                    />
                    {i !== group.bets.length - 1 && (
                      <div className={styles.seperator} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.parlays}>
          <div className={styles.heading}>
            <Icon name="combined" />{' '}
            <span className={styles.bold}>
              Add more selections to build a Multi
            </span>{' '}
          </div>
          <span className={styles.description}>
            You need selections from at least 2 events to create a multi
          </span>
        </div>
      )}
    </ParlayAccordion>
  );
}

function ParlayAccordion({
  title,
  count,
  children,
}: {
  title: string;
  count: number;
  children: ReactNode;
}) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div
      className={clsx(styles.acccordion, {
        [styles.expanded]: expanded,
      })}
    >
      <div className={styles.accordionHeader}>
        <button
          type="button"
          className={styles.accordionButton}
          onClick={() => setExpanded((prev) => !prev)}
          title={`${expanded ? 'Hide' : 'Show'} ${title}`}
        >
          <Icon className={styles.chevron} name="caretDown" />
          <span>{title.toUpperCase()}</span>
        </button>
      </div>
      <div className={styles.bets}>{children}</div>
    </div>
  );
}
