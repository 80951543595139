import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import AppProvider from '../../pages/AppProvider';
import { store } from '../../stores/store';
import UpcomingTip from './UpcomingTip';

const roots: Record<string, Root> = {};

export default function UpcomingTipRenderer() {
  Array.from(document.querySelectorAll('.bm-upcoming_tip')).forEach((el) => {
    const id = el.getAttribute('data-id') || 'unknown';

    if (!roots[id]) {
      roots[id] = createRoot(el!);
    }

    const unpreparedProps = el.getAttribute('data-props');
    const props = unpreparedProps ? JSON.parse(unpreparedProps) : {};

    roots[id].render(
      <Provider store={store}>
        <AppProvider>
          <UpcomingTip {...props} />
        </AppProvider>
      </Provider>
    );
  });
}
