import clsx from 'clsx';
import { ReactNode, useEffect, useRef, useState } from 'react';
import CurrencyInput from '../../components/Form/CurrencyInput/CurrencyInput';
import Icon from '../../components/Icon/Icon';
import styles from './Accordion.module.css';

export default function Accordion({
  title,
  count,
  children,
  onChangeStake,
}: {
  title: string;
  count: number;
  children: ReactNode;
  onChangeStake?: (stake: number) => void;
}) {
  const [expanded, setExpanded] = useState(true);
  const [isInFocus, setIsInFocus] = useState(false);

  const currencyInputRef = useRef<HTMLInputElement>(null);

  const toggleInFocus = () => {
    setIsInFocus(true);
  };

  useEffect(() => {
    if (currencyInputRef.current && isInFocus) {
      currencyInputRef.current.focus();
    }
  }, [isInFocus]);

  return (
    <div
      className={clsx(styles.accordion, {
        [styles.expanded]: expanded,
      })}
    >
      <div className={styles.accordionHeader}>
        <button
          type="button"
          className={styles.accordionButton}
          onClick={() => setExpanded((prev) => !prev)}
          title={`${expanded ? 'Hide' : 'Show'} ${title}`}
        >
          <Icon className={styles.chevron} name="caretDown" />
          <span>
            {title.toUpperCase()} &middot; {count}
          </span>
        </button>
      </div>
      <div className={styles.bets}>
        {onChangeStake && (
          <div className={styles.stakeInputContainer}>
            <div className={styles.label}>Add to all {title}</div>
            <CurrencyInput
              onClick={toggleInFocus}
              onChange={(val) => {
                onChangeStake(+val);
              }}
              className={styles.addToAllInput}
              containerClassName={styles.currencyInputContainer}
            />
          </div>
        )}
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
}
