import Countdown from '../../components/Countdown/Countdown';
import Icon, { IconName } from '../../components/Icon/Icon';
import LineShimmer from '../../components/Shimmers/LineShimmer';
import { GetFeaturedRaceQuery, RaceStatus } from '../../generated/graphql';
import styles from './UpcomingTip.module.css';

type Props = {
  race?: GetFeaturedRaceQuery['race'];
};

export default function TipTrack({ race }: Props) {
  return (
    <>
      <div className={styles.separator} />
      {race ? (
        <div className={styles.track}>
          <Icon name={race.meeting.type as IconName} />
          <span className={styles.trackName}>
            {race.meeting.track.name} - R {race.number}
          </span>
          <div className={styles.status}>
            {race.status !== RaceStatus.Final ? (
              <Countdown startTime={race.startTime} />
            ) : (
              <span>Resulted</span>
            )}
          </div>
        </div>
      ) : (
        <LineShimmer />
      )}
      <div className={styles.separator} />
    </>
  );
}
