import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '../../stores/store';
import AppProvider from '../AppProvider';
import BetslipWidget from './BetslipWidget';

const roots: Record<string, Root> = {};

export default function BetslipRenderer() {
  Array.from(document.querySelectorAll('.bm-betslip')).forEach((el) => {
    const id = el.getAttribute('data-id') || 'unknown';

    if (!roots[id]) {
      roots[id] = createRoot(el!);
    }

    roots[id].render(
      <Provider store={store}>
        <AppProvider>
          <BetslipWidget />
        </AppProvider>
      </Provider>
    );
  });
}
