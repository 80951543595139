import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { MeetingType } from '../../generated/graphql';
import {
  MEETING_TYPE_LENGTH,
  updateSettings,
  updateTypeFilters,
} from '../../stores/settingSlice';
import { RootState } from '../../stores/store';
import Icon, { IconSize } from '../Icon/Icon';
import styles from './RacingFilters.module.css';

export default function RacingFilters({
  hideAll = false,
  size,
  className = '',
}: {
  hideAll?: boolean;
  size?: IconSize;
  className?: string;
}) {
  const typeFilters = useSelector(
    (state: RootState) => state.setting.typeFilters
  );
  const dispatch = useDispatch();

  return (
    <div className={clsx(styles.container, className)}>
      {!hideAll ? (
        <button
          type="button"
          className={clsx(styles.button, {
            [styles.selected]:
              typeFilters.length === 0 ||
              typeFilters.length === MEETING_TYPE_LENGTH,
            [styles.smallButton]: size === 'small',
          })}
          onClick={() =>
            dispatch(
              updateSettings({
                typeFilters: [],
              })
            )
          }
        >
          All
        </button>
      ) : null}
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: typeFilters.includes(MeetingType.Thoroughbred),
          [styles.smallButton]: size === 'small',
        })}
        onClick={() => dispatch(updateTypeFilters(MeetingType.Thoroughbred))}
      >
        <Icon name={MeetingType.Thoroughbred} size={size} />
      </button>
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: typeFilters.includes(MeetingType.Greyhound),
          [styles.smallButton]: size === 'small',
        })}
        onClick={() => dispatch(updateTypeFilters(MeetingType.Greyhound))}
      >
        <Icon name={MeetingType.Greyhound} size={size} />
      </button>
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: typeFilters.includes(MeetingType.Harness),
          [styles.smallButton]: size === 'small',
        })}
        onClick={() => dispatch(updateTypeFilters(MeetingType.Harness))}
      >
        <Icon name={MeetingType.Harness} size={size} />
      </button>
    </div>
  );
}
