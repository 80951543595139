import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMeasure } from '../../hooks/useMeasure';
import { RootState } from '../../stores/store';
import Betslip from './Betslip';
import styles from './BetslipWidget.module.css';

export default function BetslipWidget() {
  const ref = useRef<HTMLDivElement>(null);
  const dimensions = useMeasure(ref);
  const [betslipTop, setBetslipTop] = useState(0);
  const showBetslip = useSelector(
    (state: RootState) => state.betslip.showBetslip
  );

  useEffect(() => {
    function handleResize() {
      setBetslipTop(
        document
          .getElementsByClassName('bm-betslip')?.[0]
          ?.getBoundingClientRect().top || 0
      );
    }

    setTimeout(handleResize, 0);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div
        className={clsx(styles.betslipContainer, {
          [styles.open]: showBetslip,
        })}
        style={{ top: betslipTop }}
      >
        <Betslip ref={ref} />
      </div>
      <aside style={{ width: dimensions?.width }} />
    </>
  );
}
