import { keys } from 'lodash';
import { useMemo, useState } from 'react';
import { Filters, SectionSeperator } from '../../../../components';
import H2HCard from '../../../../components/H2HCard/H2HCard';
import { IconName } from '../../../../components/Icon/Icon';
import { ExtendedSportEvents } from '../../../../types/sport';
import slugify from '../../../../utils/slugify';
import styles from './SportPopular.module.css';

type SportPopularProps = {
  events: ExtendedSportEvents[];
  sportName: string;
};
export default function SportPopular({ sportName, events }: SportPopularProps) {
  const [selectedFilters, setSelectedFilters] = useState(['all']);

  const categoriesEvents = events.reduce((prev, curr) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let events = prev[curr.competition.name];
    // eslint-disable-next-line no-param-reassign
    prev[curr.competition.name] = !events ? [curr] : [...events, curr];
    return prev;
  }, {});

  const filters = useMemo(
    () => keys(categoriesEvents).map((p) => ({ id: p, label: p })),
    [categoriesEvents]
  );

  const filteredCategories = useMemo(() => {
    if (selectedFilters.includes('all')) {
      return keys(categoriesEvents);
    }
    return keys(categoriesEvents).filter((s) => selectedFilters.includes(s));
  }, [categoriesEvents, selectedFilters]);

  return (
    <div className={styles.container}>
      {filters?.length ? (
        <div className={styles.filters}>
          <Filters onToggle={setSelectedFilters} filters={filters} />
        </div>
      ) : (
        <div className={styles.noEvents}>
          <h2>No events</h2>
        </div>
      )}
      {filteredCategories.map((k) => {
        return (
          <div key={k}>
            <SectionSeperator
              label={k}
              icon={sportName as IconName}
              className={styles.seperator}
              path={`/sports/competition/${sportName}/${slugify(k)}/matches`}
            />
            <div className={styles.card}>
              {categoriesEvents[k].map((sportEvent: ExtendedSportEvents) => {
                return (
                  <H2HCard
                    key={sportEvent.id}
                    sportEvent={sportEvent}
                    containerClass={styles.card}
                    showLeagueName
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
