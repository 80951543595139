import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Modal.module.css';
import ModalCard from './ModalCard';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Modal = forwardRef<HTMLDivElement, Props>(
  ({ children, className = '' }, ref) => (
    <div className={styles.container}>
      <div className={clsx(styles.modal, className)} ref={ref}>
        <ModalCard>{children}</ModalCard>
      </div>
    </div>
  )
);

Modal.displayName = 'Modal';

export default Modal;
