import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PlatformApiResponse, PlatformMeetings } from './types';

export const platformApi = createApi({
  reducerPath: 'platformApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    getPlatformMeetings: builder.query<
      PlatformApiResponse<PlatformMeetings>,
      string
    >({
      query: (baseUrl) => `${baseUrl}/v2/meetings`,
    }),
  }),
});

export const { useGetPlatformMeetingsQuery } = platformApi;
