import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { cmsApi } from './../services/cmsApi/cmsApi';
import { platformApi } from './apis/platformApi';
import betSliceReducer from './betSlice';
import { reducer as betslipSliceReducer } from './betslipSlice';
import configSliceReducer from './configSlice';
import settingSliceReducer from './settingSlice';
import sportsReducer from './sportsSlice';
import timeSliceReducer from './timeSlice';

export const store = configureStore({
  reducer: {
    config: configSliceReducer,
    time: timeSliceReducer,
    sports: sportsReducer,
    bet: betSliceReducer,
    setting: settingSliceReducer,
    betslip: betslipSliceReducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
    [platformApi.reducerPath]: platformApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Required for config.getHeaders() and config.getUserJwt()
    })
      .concat(cmsApi.middleware)
      .concat(platformApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
