import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BetslipStage } from '../../../constants/betStatus';
import { BetType } from '../../../constants/betTypes';
import {
  updateStakeAllExotics,
  updateStakeAllMultiExotics,
  updateStakeAllSingles,
} from '../../../stores/betslipSlice';
import {
  selectAllSingleBets,
  selectExoticBets,
  selectMultiExoticBets,
  selectSportMultiBets,
} from '../../../stores/selectors/betslipSelectors';
import { RootState } from '../../../stores/store';
import {
  isExoticBet,
  isMultiBet,
  isSingleBet,
  isSportSingleBet,
  isValidBet,
} from '../../../utils/betslip/common';
import { SingleBet, SportSingleBet } from '../../../utils/betslip/types';
import Accordion from '../Accordion';
import ExoticBetCard from '../BetCards/ExoticBetCard';
import MultiRaceBetCard from '../BetCards/MultiRaceBetCard';
import SingleBetCard from '../BetCards/SingleBetCard';
import { MultiFoldBetCards } from '../SportBetslip/MultiFoldBetCards';
import SingleSportBetCard from '../SportBetslip/SingleSportBetCard';
import { EmptyBetslip } from '../TraditionalBetslip/EmptyBetslip';
import { TraditionalSingleBet } from '../TraditionalBetslip/types';

export default function StandardBetslip() {
  const dispatch = useDispatch();
  const exoticBets = useSelector(selectExoticBets);
  const multiRaceBets = useSelector(selectMultiExoticBets);
  const sportMultiBets = useSelector(selectSportMultiBets);
  const betslipStage = useSelector(
    (state: RootState) => state.betslip.betslipStage
  );

  const allSingleBets = useSelector(selectAllSingleBets);

  const isBetslipEmpty =
    allSingleBets.length < 1 &&
    exoticBets.length < 1 &&
    multiRaceBets.length < 1;

  const renderSingleBet = (bet: any) => {
    if (isSingleBet(bet)) {
      return <SingleBetCard bet={bet as SingleBet | TraditionalSingleBet} />;
    }
    if (isSportSingleBet(bet)) {
      return <SingleSportBetCard bet={bet} />;
    }

    return null;
  };
  const filteredSingleBets =
    betslipStage !== BetslipStage.NORMAL
      ? allSingleBets.filter(
          (bet) => isValidBet(bet) && bet.betType !== BetType.PARLAY
        )
      : allSingleBets.filter((bet) => bet.betType !== BetType.PARLAY);

  const generateBetslipSingleBetKey = (bet: any) => {
    if (isSingleBet(bet)) {
      return `${bet.betType}-${bet.race?.id}-${
        (bet as SingleBet).competitor?.tabNo
      }`;
    }
    if (isSportSingleBet(bet)) {
      return (bet as SportSingleBet).selection.id;
    }
    if (isExoticBet(bet)) {
      return `${bet.betType}-${bet.race.id}`;
    }
    if (isMultiBet(bet)) {
      return `${bet.betType}-${bet.races?.[0]}`;
    }

    return 'UnknownBetType!';
  };

  return (
    <>
      {allSingleBets.length > 0 ? (
        <Accordion
          title="Singles"
          count={filteredSingleBets.length}
          onChangeStake={
            allSingleBets.length > 1
              ? (stake) =>
                  dispatch(
                    updateStakeAllSingles({
                      stake,
                    })
                  )
              : undefined
          }
        >
          {filteredSingleBets.map((bet) => (
            <Fragment key={generateBetslipSingleBetKey(bet)}>
              {renderSingleBet(bet)}
            </Fragment>
          ))}
        </Accordion>
      ) : null}
      {sportMultiBets.length > 0 && <MultiFoldBetCards bets={sportMultiBets} />}
      {exoticBets.length > 0 && (
        <Accordion
          title="Exotics"
          count={exoticBets.length}
          onChangeStake={(stake) =>
            dispatch(
              updateStakeAllExotics({
                stake,
              })
            )
          }
        >
          {exoticBets.map((bet) => (
            <ExoticBetCard key={`${bet.betType}-${bet.race.id}`} bet={bet} />
          ))}
        </Accordion>
      )}
      {multiRaceBets.length > 0 && (
        <Accordion
          title="Multiples"
          count={multiRaceBets.length}
          onChangeStake={(stake) =>
            dispatch(
              updateStakeAllMultiExotics({
                stake,
              })
            )
          }
        >
          {multiRaceBets.map((bet) => (
            <MultiRaceBetCard
              key={`${bet.betType}-${bet.races?.[0]}`}
              bet={bet}
            />
          ))}
        </Accordion>
      )}
      {isBetslipEmpty && <EmptyBetslip />}
    </>
  );
}
