import { Helmet } from 'react-helmet-async';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Meeting from './Meeting/Meeting';
import RaceCard from './RaceCard/RaceCard';
import RacingHome from './RacingHome/RacingHome';

export default function Racebook() {
  return (
    <>
      <Helmet titleTemplate="%s · Racing">
        <meta name="description" content="Racing" />
      </Helmet>
      <HashRouter>
        <Routes>
          <Route path="/racing/meeting/:meetingId" element={<Meeting />} />
          <Route
            path="/racing/meeting/:meetingId/race/:raceId"
            element={<RaceCard />}
          />
          <Route
            path="/racing/meeting/:meetingId/race/:raceId/:betType"
            element={<RaceCard />}
          />
          <Route path="*" element={<RacingHome />} />
        </Routes>
      </HashRouter>
    </>
  );
}
