import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BetslipStage } from '../constants/betStatus';
import { BetType, ExoticEntryMode, MultiBetType } from '../constants/betTypes';
import { LocalStorageKey } from '../constants/localStorage';
import { RaceCompetitor } from '../types/racing';
import {
  findExoticBetIndex,
  findSingleBetIndex,
  isExoticBet,
  isMultiBet,
  isSingleBet,
  isSportSingleBet,
} from '../utils/betslip/common';
import {
  AddBetslipExoticDetail,
  AddBetslipMultiExoticDetail,
  AddOrRemoveBetslipDetail,
  RemoveTraditionalBetslipDetail,
  SetExoticEntryMode,
  UpdateAllStakeBetslipDetail,
  UpdateBetPrice,
  UpdateBetRace,
  UpdateBetStake,
  UpdateBetStatus,
  UpdateExoticSelection,
  UpdateExoticSelectionAll,
  UpdateMultiRaces,
  UpdateMultiSelection,
  UpdateMultiSelectionAll,
  UpdateSportMultiBetStake,
  UpdateTraditionalSingleBetslipDetail,
} from '../utils/betslip/eventDispatcher';
import { generateAllPossibleCombos } from '../utils/betslip/multiBetGenerator';
import {
  Bet,
  BetslipState,
  ExoticBetType,
  SportSingleBet,
} from '../utils/betslip/types';
import {
  addOrRemoveParlaysReducer,
  generateParlaysReducer,
  updateParlayStakeReducer,
} from './reducers/betslip/parlay';

const defaultState: BetslipState = {
  showBetslip: false,
  betslipStage: BetslipStage.NORMAL,
  betType: BetType.WIN,
  exoticEntryMode: ExoticEntryMode.Boxed,
  betslip: [],
  parlaySelections: [],
  exoticSelections: {
    [BetType.EXACTA]: [[], []],
    [BetType.QUINELLA]: [[], []],
    [BetType.TRIFECTA]: [[], [], []],
    [BetType.FIRST_FOUR]: [[], [], [], []],
    [BetType.SUPERFECTA]: [[], [], [], []],
  },
  multiSelections: {
    [BetType.DOUBLE]: {
      races: [],
      selections: [[], []],
    },
    [BetType.PICK3]: {
      races: [],
      selections: [[], [], []],
    },
    [BetType.PICK6]: {
      races: [],
      selections: [[], [], [], [], [], []],
    },
  },
  multiFoldSelections: [],
};
const localBetslip = localStorage.getItem(LocalStorageKey.Betslip);
const initialState: BetslipState = {
  ...defaultState,
  betslip: localBetslip
    ? (JSON.parse(localBetslip) as BetslipState['betslip'])
    : defaultState.betslip,
};

const removeIndexFromBetslip = (
  updatingBetslip: Bet[],
  indexToRemove: number
) => updatingBetslip.filter((_, index) => index !== indexToRemove);

export const findSportsBetIndexInBetslip = (
  betslip: Bet[],
  bet: Omit<SportSingleBet, 'eventDetails' | 'marketDetails'>
) =>
  betslip.findIndex((betInBetslip) => {
    if ((betInBetslip as SportSingleBet)?.selection?.id === bet.selection.id) {
      return true;
    }
    return false;
  });

export const betSlice = createSlice({
  name: 'betslip',
  initialState,
  reducers: {
    setBetslipStage: (state, action: PayloadAction<BetslipStage>) => {
      state.betslipStage = action.payload;
    },
    toggleBetslip: (state) => {
      state.showBetslip = !state.showBetslip;
    },
    clearBetslip: (state) => ({
      ...state,
      ...defaultState,
    }),
    removeBetByBetTypeRace: (
      state,
      action: PayloadAction<Omit<RemoveTraditionalBetslipDetail, 'type'>>
    ) => {
      state.betslip = state.betslip.filter(
        (bet) =>
          !(
            bet.betType === action.payload.betType &&
            bet.race?.id === action.payload.raceId
          )
      );
    },
    updateBetStatus: (
      state,
      action: PayloadAction<Omit<UpdateBetStatus, 'type'>>
    ) => {
      const { bet, status } = action.payload;
      const existingBetIndex = isSingleBet(bet, status)
        ? findSingleBetIndex(state.betslip, bet)
        : -1;
      if (existingBetIndex !== -1) {
        return {
          ...state,
          betslip: state.betslip.map((b, index) =>
            existingBetIndex === index && isSingleBet(bet)
              ? { ...b, betStatus: status }
              : b
          ),
        };
      }
      return state;
    },
    updateBetPrice: (
      state,
      action: PayloadAction<Omit<UpdateBetPrice, 'type'>>
    ) => {
      const { bet, newPrice } = action.payload;
      const existingBetIndex = findSingleBetIndex(state.betslip, bet);
      if (existingBetIndex !== -1) {
        return {
          ...state,
          betslip: state.betslip.map((b, index) =>
            existingBetIndex === index && isSingleBet(bet)
              ? { ...b, odds: newPrice }
              : b
          ),
        };
      }
      return state;
    },
    updateBetRace: (
      state,
      action: PayloadAction<Omit<UpdateBetRace, 'type'>>
    ) => ({
      ...state,
      betslip: state.betslip.map((bet) =>
        bet.race?.id === action.payload.race?.id
          ? { ...bet, race: action.payload.race }
          : bet
      ),
    }),

    updateBetStake: (
      state,
      action: PayloadAction<Omit<UpdateBetStake, 'type'>>
    ) => {
      const { bet, newPrice } = action.payload;

      let existingBetIndex = -1;

      if (isSingleBet(bet)) {
        existingBetIndex = findSingleBetIndex(state.betslip, bet);
      }
      if (isSportSingleBet(bet)) {
        existingBetIndex = findSportsBetIndexInBetslip(state.betslip, bet);
      }
      if (isExoticBet(bet) || isMultiBet(bet)) {
        existingBetIndex = findExoticBetIndex(state.betslip, bet);
      }

      if (existingBetIndex !== -1) {
        return {
          ...state,
          betslip: state.betslip.map((b, index) =>
            existingBetIndex === index ? { ...b, stake: newPrice } : b
          ),
        };
      }
      return state;
    },
    updateStakeAllSingles: (
      state,
      action: PayloadAction<Omit<UpdateAllStakeBetslipDetail, 'type'>>
    ) => {
      state.betslip = state.betslip.map((bet) => {
        if (isSingleBet(bet) || isSportSingleBet(bet)) {
          return {
            ...bet,
            stake: action.payload.stake,
          };
        }

        return bet;
      });
    },
    updateStakeSportMulti: (
      state,
      action: PayloadAction<Omit<UpdateSportMultiBetStake, 'type'>>
    ) => ({
      ...state,
      multiFoldSelections: state.multiFoldSelections.map((multiBet) => {
        if (multiBet.title === action.payload.bet.title) {
          return {
            ...multiBet,
            stake: action.payload.newPrice,
          };
        }

        return multiBet;
      }),
    }),
    updateStakeAllExotics: (
      state,
      action: PayloadAction<Omit<UpdateAllStakeBetslipDetail, 'type'>>
    ) => {
      state.betslip = state.betslip.map((bet) => {
        if (isExoticBet(bet)) {
          return {
            ...bet,
            stake: action.payload.stake,
          };
        }

        return bet;
      });
    },
    updateStakeAllMultiExotics: (
      state,
      action: PayloadAction<Omit<UpdateAllStakeBetslipDetail, 'type'>>
    ) => {
      state.betslip = state.betslip.map((bet) => {
        if (isMultiBet(bet)) {
          return {
            ...bet,
            stake: action.payload.stake,
          };
        }

        return bet;
      });
    },
    updateStakeByBetTypeRace: (
      state,
      action: PayloadAction<Omit<UpdateTraditionalSingleBetslipDetail, 'type'>>
    ) => ({
      ...state,
      betslip: state.betslip.map((bet) => {
        if (
          bet.race?.id === action.payload.raceId &&
          bet.betType === action.payload.betType
        ) {
          return {
            ...bet,
            stake: action.payload.stake,
          };
        }

        return bet;
      }),
    }),

    addOrRemoveBet: (
      state,
      action: PayloadAction<Omit<AddOrRemoveBetslipDetail, 'type'>>
    ) => {
      const { traditional, ...bet } = action.payload;
      const existingBetIndex = findSingleBetIndex(state.betslip, bet);
      if (existingBetIndex !== -1) {
        return {
          ...state,
          betslip: removeIndexFromBetslip(state.betslip, existingBetIndex),
        };
      }

      return {
        ...state,
        betslip: [
          ...state.betslip,
          {
            ...bet,
            // Traditional betslip will always get the same stake from the same race
            stake: traditional
              ? state.betslip.find(
                  (b) => b.betType === bet.betType && b.race?.id === bet.race.id
                )?.stake
              : 0,
          },
        ],
      };
    },
    addOrRemoveSportsBet: (state, action: PayloadAction<SportSingleBet>) => {
      const bet = action.payload;
      const indexOfExistingBetInBetslip = findSportsBetIndexInBetslip(
        state.betslip,
        bet
      );

      const parlaySelections = state.parlaySelections.filter((b) => {
        if (isSportSingleBet(b)) {
          return b.selection.id !== bet.selection.id;
        }
        return true;
      });

      if (indexOfExistingBetInBetslip >= 0) {
        return {
          ...state,
          betslip: removeIndexFromBetslip(
            state.betslip,
            indexOfExistingBetInBetslip
          ),
          parlaySelections,
        };
      }
      return { ...state, betslip: [...state.betslip, bet] };
    },
    generateSportMulties: (state) => {
      const bets = state.betslip.filter((bet) => isSportSingleBet(bet));
      const multies = generateAllPossibleCombos(bets);
      const multiFoldSelections = multies.map((bet) => ({
        ...bet,
        betType: BetType.MULTI,
      })) as BetslipState['multiFoldSelections'];

      return {
        ...state,
        multiFoldSelections,
      };
    },
    toggleExoticEntryMode: (
      state,
      action: PayloadAction<Omit<SetExoticEntryMode, 'type'>>
    ) => {
      const { modeToToggle, betType } = action.payload;

      const newValue =
        state.exoticEntryMode === modeToToggle
          ? ExoticEntryMode.Normal
          : modeToToggle;

      state.exoticEntryMode = newValue;
      state.exoticSelections[betType] = initialState.exoticSelections[betType];
    },
    updateExoticSelection: (
      state,
      action: PayloadAction<Omit<UpdateExoticSelection, 'type'>>
    ) => {
      const { betType, index, competitor } = action.payload;
      const exoticBet = state.exoticSelections[betType as ExoticBetType];
      if (!exoticBet) return state;
      return {
        ...state,
        exoticSelections: {
          ...state.exoticSelections,
          ...(state.exoticEntryMode === 'NORMAL' && {
            [betType]: state.exoticSelections[betType as ExoticBetType].map(
              (selection, i) => {
                if (i === index) {
                  return selection.some(
                    (c: RaceCompetitor) => c.tabNo === competitor.tabNo
                  )
                    ? selection.filter((c) => c.tabNo !== competitor.tabNo)
                    : [...selection, competitor];
                }
                return selection;
              }
            ),
          }),
          ...(state.exoticEntryMode === 'BOXED' && {
            [betType]: state.exoticSelections[betType as ExoticBetType].map(
              (selection) =>
                selection.some(
                  (c: RaceCompetitor) => c.tabNo === competitor.tabNo
                )
                  ? selection.filter((c) => c.tabNo !== competitor.tabNo)
                  : [...selection, competitor]
            ),
          }),
          ...(state.exoticEntryMode === 'KEY' && {
            [betType]: exoticBet.map(
              (selections, i, [keySelections, ...otherSelections]) => {
                const withinKey = keySelections.some(
                  (sel) => sel.tabNo === competitor.tabNo
                );

                if (index === 0) {
                  if (withinKey || i !== 0)
                    return selections.filter(
                      (sel) => sel.tabNo !== competitor.tabNo
                    );

                  return [...selections, competitor];
                }

                const withinOthers = otherSelections.some((selAtPos) =>
                  selAtPos.some((sel) => sel.tabNo === competitor.tabNo)
                );

                if (withinOthers || i === 0)
                  return selections.filter(
                    (sel) => sel.tabNo !== competitor.tabNo
                  );

                return [...selections, competitor];
              }
            ),
          }),
        },
      };
    },
    updateExoticSelectionAll: (
      state,
      action: PayloadAction<Omit<UpdateExoticSelectionAll, 'type'>>
    ) => {
      const { betType, index, selections } = action.payload;
      const exoticBet = state.exoticSelections[betType as ExoticBetType];
      if (!exoticBet) return state;

      return {
        ...state,
        exoticSelections: {
          ...state.exoticSelections,
          [betType]: exoticBet.map((selection, i) => {
            if (state.exoticEntryMode === 'KEY') {
              if (index === 0) return i === 0 ? selections : [];

              return i === 0 ? [] : selections;
            }

            if (state.exoticEntryMode === 'BOXED' || i === index) {
              if (selection.length !== selections.length) {
                return selections;
              }

              return [];
            }

            return selection;
          }),
        },
      };
    },
    addExotic: (
      state,
      action: PayloadAction<Omit<AddBetslipExoticDetail, 'type'>>
    ) => {
      const bet = action.payload;

      const existingBetIndex = findExoticBetIndex(state.betslip, bet);
      if (existingBetIndex !== -1) {
        return {
          ...state,
          betslip: removeIndexFromBetslip(state.betslip, existingBetIndex),
        };
      }

      return {
        ...state,
        showBetslip: state.betslip.length === 0, // Show betslip on first bet
        betslip: [
          {
            ...bet,
            stake: 0,
          },
          ...state.betslip,
        ],
      };
    },
    resetExoticSelections: (state) => ({
      ...state,
      exoticSelections: initialState.exoticSelections,
    }),

    updateMultiRaces: (
      state,
      action: PayloadAction<Omit<UpdateMultiRaces, 'type'>>
    ) => {
      const { betType, races } = action.payload;
      const multiBet = state.multiSelections[betType as string as MultiBetType];

      if (multiBet) {
        state.multiSelections[betType] = {
          ...multiBet,
          selections:
            initialState.multiSelections[betType as string as MultiBetType]
              .selections,
          races: races.slice(
            0,
            initialState.multiSelections[betType as string as MultiBetType]
              .selections.length
          ),
        };
      }
    },
    updateMultiSelection: (
      state,
      action: PayloadAction<Omit<UpdateMultiSelection, 'type'>>
    ) => {
      const { betType, competitor, raceId } = action.payload;
      const multiBet = state.multiSelections[betType as string as MultiBetType];
      if (!multiBet) return state;

      const raceIndex = multiBet.races.indexOf(raceId);

      if (raceIndex === -1) return state;

      return {
        ...state,
        multiSelections: {
          ...state.multiSelections,
          [betType]: {
            ...multiBet,
            selections: multiBet.selections.map((selection, index) => {
              if (raceIndex === index) {
                if (
                  selection.some(
                    (c: RaceCompetitor) => c.tabNo === competitor.tabNo
                  )
                ) {
                  return selection.filter((c) => c.tabNo !== competitor.tabNo);
                }

                return [...selection, competitor];
              }

              return selection;
            }),
          },
        },
      };
    },
    updateMultiSelectionAll: (
      state,
      action: PayloadAction<Omit<UpdateMultiSelectionAll, 'type'>>
    ) => {
      const { betType, index, selections } = action.payload;
      const multiBet = state.multiSelections[betType as string as MultiBetType];
      if (!multiBet) return state;

      return {
        ...state,
        multiSelections: {
          ...state.multiSelections,
          [betType]: {
            ...multiBet,
            selections: multiBet.selections.map((selection, i) => {
              if (index === i) {
                if (selection.length !== selections.length) {
                  return selections;
                }

                return [];
              }

              return selection;
            }),
          },
        },
      };
    },
    resetMultiSelections: (state) => ({
      ...state,
      multiSelections: initialState.multiSelections,
    }),
    addMulti: (
      state,
      action: PayloadAction<Omit<AddBetslipMultiExoticDetail, 'type'>>
    ) => {
      const bet = action.payload;

      const existingBetIndex = findExoticBetIndex(state.betslip, bet);
      if (existingBetIndex !== -1) {
        return {
          ...state,
          betslip: removeIndexFromBetslip(state.betslip, existingBetIndex),
        };
      }

      return {
        ...state,
        showBetslip: state.betslip.length === 0, // Show betslip on first bet
        betslip: [
          {
            ...bet,
            stake: 0,
          },
          ...state.betslip,
        ],
      };
    },
    addOrRemoveParlays: addOrRemoveParlaysReducer,
    updateParlayStake: updateParlayStakeReducer,
    generateParlays: generateParlaysReducer,
    clearParlays: (state) => {
      state.parlaySelections = [];
    },
  },
});

export const {
  setBetslipStage,
  toggleBetslip,
  clearBetslip,
  removeBetByBetTypeRace,
  updateBetStatus,
  updateBetPrice,
  updateBetRace,

  updateBetStake,
  updateStakeAllSingles,
  updateStakeSportMulti,
  updateStakeAllExotics,
  updateStakeAllMultiExotics,
  updateStakeByBetTypeRace,

  addOrRemoveBet,
  addOrRemoveSportsBet,
  generateSportMulties,

  toggleExoticEntryMode,
  updateExoticSelection,
  updateExoticSelectionAll,
  addExotic,
  resetExoticSelections,

  updateMultiRaces,
  updateMultiSelection,
  updateMultiSelectionAll,
  resetMultiSelections,
  addMulti,

  addOrRemoveParlays,
  updateParlayStake,
  clearParlays,
  generateParlays,
} = betSlice.actions;

export const { reducer } = betSlice;
