/* eslint-disable react/no-array-index-key */
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  BetLiveModal,
  CategorySeperator,
  HeadToHeadCard,
  HeadToHeadCardPlaceholder,
  SportsFilter,
  UnableToLoadErrorPage,
} from '../../../../components';
import usePopularSportEvents from '../../../../hooks/useSportsEvents/usePopularSportsEvents';
import { selectPopularSportCategoryIds } from '../../../../stores/selectors/sportsSelector';
import styles from './LiveBettingSports.module.css';

export default function LiveBettingSports() {
  const onToggle = useCallback((val: any) => val, []); // TODO:

  const categoryIds = useSelector(selectPopularSportCategoryIds);

  const { events, fetching, error } = usePopularSportEvents({
    categoryIds,
  });

  const renderEvents = () =>
    events.map((event) => {
      const teamA = event.teams ? event.teams[0] : undefined;
      const teamB = event.teams ? event.teams[1] : undefined;
      return (
        <HeadToHeadCard
          key={event.id}
          outcomes={[
            {
              name: teamA?.name || '',
              outcome: 'something',
              price: 3,
            },
            {
              name: teamB?.name || '',
              outcome: 'something',
              price: 2,
            },
          ]}
          startDate={new Date()}
          sportType="basketball"
          countOfMarkets={11}
          containerClass={styles.card}
        />
      );
    });

  return (
    <div className={styles.container}>
      {events.length > 0 && (
        <div className={styles.filters}>
          <SportsFilter onToggle={onToggle} />
        </div>
      )}
      {!fetching && error?.message && <UnableToLoadErrorPage />}
      <div className={styles.head2headContainer}>
        {fetching && (
          <>
            {[1, 2, 3, 4].map((a) => (
              <HeadToHeadCardPlaceholder key={a} />
            ))}
          </>
        )}
        <BetLiveModal />
        <CategorySeperator label="IN-PLAY" />
        {renderEvents()}
        <CategorySeperator label="UPCOMING" />
        {renderEvents()}
      </div>
    </div>
  );
}
