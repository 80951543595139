import clsx from 'clsx';
import styles from './Icon.module.css';
import * as IconList from './icons';

export type IconName = keyof typeof IconList;

export type IconSize =
  | 'very-small'
  | 'extra-small'
  | 'semi-small'
  | 'small'
  | 'medium'
  | 'large';

interface Props {
  name: IconName;
  className?: string;
  size?: IconSize;
}

export const ListOfIcons = Object.keys(IconList);

export default function Icon({
  name,
  className = '',
  size = 'medium',
  ...rest
}: Props) {
  const IconComponent =
    IconList[name] ||
    IconList[name.toLowerCase()] ||
    IconList.thoroughbredFilled;

  return <IconComponent className={clsx(styles[size], className)} {...rest} />;
}
