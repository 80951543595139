import { useRef } from 'react';
import Icon from '../../../components/Icon/Icon';
import { useMeasure } from '../../../hooks/useMeasure';
import styles from './LiveVision.module.css';

type Props = {
  onDismiss: () => void;
};

export default function LiveVision({ onDismiss }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const dimensions = useMeasure(ref);

  const desiredHeight = dimensions?.height ? dimensions?.height / 3 : 240;
  const desiredWidth = (desiredHeight / 3) * 4;

  const scaleFactor =
    dimensions?.width && dimensions?.width < desiredWidth ? 0.75 : 1;

  const scaledWidth = desiredWidth * scaleFactor;

  const streamWidth = scaledWidth > 300 ? scaledWidth : 300;
  const streamHeight = (streamWidth / 4) * 3;

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.topBar}>
        <button
          type="button"
          className={styles.closeIconContainer}
          onClick={onDismiss}
          title="Close"
        >
          <Icon name="cross" className={styles.icon} />
        </button>
      </div>
      <iframe
        title="LiveVision"
        loading="lazy"
        name="videoFrame"
        id="videoFrame"
        frameBorder="0"
        height={streamHeight}
        width={streamWidth}
        src="https://vision.prod.thebetmakers.com/vision/stream/1?bm_id=43&theme_id=48" // TODO: Move over to client configuration
        scrolling="no"
        referrerPolicy="origin"
        allowFullScreen
      />
    </div>
  );
}
