import { lowerCase } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import useSportName from '../../../hooks/useSportName/useSportName';
import usePopularSportEvents from '../../../hooks/useSportsEvents/usePopularSportsEvents';
import { selectSportPopularCategoryIds } from '../../../stores/selectors/sportsSelector';
import SportAll from './SportAll/SportAll';
import SportPopular from './SportPopular/SportPopular';
import SportUpcoming from './SportUpcoming/SportUpcoming';

const routes = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Sports',
    url: '/sports/home/all',
  },
];
export default function SportHome() {
  const { sportName = '' } = useParams<{ sportName: string }>();
  const { description, id, name } = useSportName(sportName);

  const categoryIds = useSelector(selectSportPopularCategoryIds(name));

  const { events } = usePopularSportEvents({ categoryIds });

  const allRoutes = useMemo(
    () => [
      ...routes,
      {
        label: description,
        url: `/sports/sport/${lowerCase(sportName)}/all`,
      },
    ],
    [description, sportName]
  );

  useBreadcrumbs(allRoutes);

  const tabs = useMemo(() => {
    const items = [
      {
        label: `All ${description}`,
        url: `/sports/sport/${sportName}/all`,
      },
      {
        label: 'Upcoming',
        url: `/sports/sport/${sportName}/upcoming`,
      },
    ];
    if (!events.length) return items;
    return [
      ...items,
      {
        label: 'Popular',
        url: `/sports/sport/${sportName}/popular`,
      },
    ];
  }, [description, events, sportName]);

  return (
    <HomeContainer HeaderComponent={<h1>{description} </h1>} tabs={tabs}>
      <Routes>
        <Route
          path="/all"
          element={
            <SportAll sportTypeId={id} sportName={name} events={events} />
          }
        />
        <Route path="/upcoming" element={<SportUpcoming sportTypeId={id} />} />
        {events.length && (
          <Route
            path="/popular"
            element={<SportPopular sportName={name} events={events} />}
          />
        )}
        <Route
          path="*"
          element={<Navigate to={`/sports/sport/${sportName}/all`} replace />}
        />
      </Routes>
    </HomeContainer>
  );
}
