import dayjs from 'dayjs';
import React from 'react';
import Countdown from '../../Countdown/Countdown';
import Icon from '../../Icon/Icon';
import styles from './SportsRowHeader.module.css';

type HeaderProps = {
  isLive?: boolean;
  startDate: Date;
  countOfMarkets: number;
  category: string;
};

const SportsRowHeader = ({
  isLive,
  startDate,
  countOfMarkets,
  category,
}: HeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.competition}>{category}</div>
        <div className={styles.features}>
          <div className={styles.icon}>
            <Icon name="depositBonus" />
          </div>
          <div className={styles.icon}>
            <Icon name="cashOut" />
          </div>
          <div className={styles.icon}>
            <Icon name="livebetting" />
          </div>
          <div className={styles.icon}>
            <Icon name="samegamemulti" />
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.timeMarket}>
          {!isLive && (
            <div className={styles.time}>
              <Countdown startTime={startDate} className={styles.countdown} />
              <span>{dayjs(startDate).format('h:mma')}</span>
            </div>
          )}
          {isLive && (
            <div className={styles.live}>
              <Icon name="live" />
            </div>
          )}
          <div className={styles.verticalSeperator} />
          <div className={styles.markets}>
            {countOfMarkets}
            <span> Markets</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SportsRowHeader;
