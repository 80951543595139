import styles from './TimeAndDistanceDisplayItem.module.css';

export default function TimeAndDistanceDisplayItem({
  fraction,
  position,
  time,
  margin,
}: {
  fraction: string;
  position: string;
  time: string;
  margin: string;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.fraction}>{fraction}</div>
      <div className={styles.position}>{position}</div>
      <div className={styles.time}>{time}</div>
      <div className={styles.margin}>{margin}</div>
    </div>
  );
}
