import clsx from 'clsx';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { MeetingType } from '../../../../generated/graphql';
import useFormat from '../../../../hooks/useFormat';
import { useMeasure } from '../../../../hooks/useMeasure';
import { RunnerFormHistory } from '../../../../types/racing';
import styles from './StartInfo.module.css';

type Props = {
  start: NonNullable<RunnerFormHistory>;
  startBefore: RunnerFormHistory;
  meetingType: MeetingType;
};

function formatTime(millisecond: number) {
  const time = (millisecond / 60000).toFixed(2).split('.');
  return `${time[0]}:${time[1]}`;
}

function daysBetweenDates(dateFrom: Date, dateTo?: Date) {
  const date1 = dayjs(dateFrom);
  const date2 = dayjs(dateTo);
  const days = date1.diff(date2, 'day');
  return days;
}

export default function StartInfo({ start, startBefore, meetingType }: Props) {
  const format = useFormat();
  const startInfoRef = useRef<HTMLDivElement>(null);
  const measure = useMeasure(startInfoRef);
  const isWide = measure ? measure.width > 320 : null;
  const isMobile = measure ? measure.width < 321 : null;
  const isWrapping = measure ? measure.width < 768 : null;

  const startInfoSectionStyles = clsx(styles.startInfoSection, {
    [styles.twoColumn]: isWide,
    [styles.singleColumn]: isMobile,
  });

  return (
    <>
      <div className={styles.shortFormRunnerInfo}>
        <div className={styles.recentStartInfo}>
          <div
            className={clsx(styles.startInfo, { [styles.wrap]: isWrapping })}
            ref={startInfoRef}
          >
            <div className={startInfoSectionStyles}>
              {!meetingType?.includes(MeetingType.Greyhound) && (
                <span>
                  <span className={styles.label}>M/L:</span>
                </span>
              )}
              <span>
                <span className={styles.label}>Margin:</span>
                <span className={styles.ellipsis}>{`${start.margin} L`}</span>
              </span>
              <span>
                <span className={styles.label}>Time:</span>
                <span className={styles.ellipsis}>
                  {formatTime(start.elapsedTime)}
                </span>
              </span>
              <span>
                <span className={styles.label}>Class:</span>
                <span className={styles.ellipsis}>{start.class}</span>
              </span>
            </div>
            <div className={startInfoSectionStyles}>
              <span>
                <span className={styles.label}>Prize:</span>
                <span className={styles.ellipsis}>
                  {format.currency(start.prizeTotal)}
                </span>
              </span>
              {!meetingType?.includes(MeetingType.Greyhound) && (
                <>
                  <span>
                    <span className={styles.label}>Jockey:</span>
                    <span className={styles.ellipsis}>{start.jockey}</span>
                  </span>

                  <span>
                    <span className={styles.label}>Weight:</span>
                    <span className={styles.ellipsis}>{start.weight} kg</span>
                  </span>
                </>
              )}
            </div>
            <div className={startInfoSectionStyles}>
              {!meetingType?.includes(MeetingType.Greyhound) && (
                <span>
                  <span className={styles.label}>Gate:</span>
                  <span className={styles.ellipsis}>{start.barrier}</span>
                </span>
              )}
              <span>
                <span className={styles.label}>Days:</span>
                <span className={styles.ellipsis}>
                  {daysBetweenDates(start?.date, startBefore?.date)}
                </span>
              </span>
              {!meetingType?.includes(MeetingType.Greyhound) && (
                <>
                  <span>
                    <span className={styles.label}>Meds:</span>
                  </span>
                  <span>
                    <span className={styles.label}>Equip:</span>
                  </span>
                </>
              )}
            </div>
            <div className={startInfoSectionStyles}>
              <span>
                <span className={styles.label}>1st:</span>
                <span className={styles.ellipsis}>
                  {start?.placeGetters?.[0]?.name}
                </span>
              </span>
              <span>
                <span className={styles.label}>2nd:</span>
                <span className={styles.ellipsis}>
                  {start?.placeGetters?.[1]?.name}
                </span>
              </span>
              <span>
                <span className={styles.label}>3rd:</span>
                <span className={styles.ellipsis}>
                  {start?.placeGetters?.[2]?.name}
                </span>
              </span>
              <span>
                <span className={styles.label}>Note:</span>
                <span className={styles.ellipsis} title={start.gearChanges}>
                  {start.gearChanges}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
