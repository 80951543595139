import { MultiFoldBet } from '../../../utils/betslip/types';
import Accordion from '../Accordion';
import { MultiFoldBetCard } from './MultiFoldBetCard';

type MultiFoldBetCardsProps = { bets: MultiFoldBet[] };
export function MultiFoldBetCards({ bets }: MultiFoldBetCardsProps) {
  // 1. get the multiFoldBets
  // 2. itterat trough them and render their card

  return (
    <Accordion title="Combo Multis" count={bets.length}>
      {bets.map((bet) => (
        <MultiFoldBetCard bet={bet} key={bet.title} />
      ))}
    </Accordion>
  );
}
