import React from 'react';
import Icon from '../Icon/Icon';
import styles from './UnableToLoadErrorPage.module.css';

const UnableToLoadErrorPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Icon name="unableToLoad" />
        </div>
        <h2>Unable to load the page</h2>
        <div>
          This should be fixed soon - but if you keep seeing this error, please
          contact customer service on <span>1800 000 000</span> or via our{' '}
          <span>live chat</span>
        </div>
      </div>
    </div>
  );
};

export default UnableToLoadErrorPage;
