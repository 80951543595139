import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BetType } from '../../../constants/betTypes';
import { addOrRemoveBet } from '../../../stores/actions/combinedActions';
import { RootState } from '../../../stores/store';
import {
  MarketSelections,
  SportEventDetails,
  SportMarket,
} from '../../../types/sport';
import { findSportsBetIndexInBetslip } from '../../../utils/betslip/common';
import { SportSingleBet } from '../../../utils/betslip/types';
import SportOddsButton from '../../SportOddsButton/SportOddsButton';
import styles from './Selections.module.css';

type SelectionsProps = {
  market: SportMarket;
  eventDetails?: SportEventDetails;
  onCardClick: () => void;
};

const Selections = ({ market, eventDetails, onCardClick }: SelectionsProps) => {
  const betslip = useSelector((state: RootState) => state.betslip.betslip);
  const dispatch = useDispatch();
  const onOddButtonClicked = (selection: NonNullable<MarketSelections>[0]) => {
    const sportSingleBet: SportSingleBet = {
      betType: BetType.SPORT,
      marketDetails: market,
      selection,
      eventDetails,
      odds: selection.prices ? selection.prices[0].decimal_price : 0,
    };
    addOrRemoveBet(sportSingleBet, dispatch);
  };

  const isCurrentlySelected = (selection: NonNullable<MarketSelections>[0]) =>
    findSportsBetIndexInBetslip(betslip, selection) !== -1;

  return (
    <div
      className={styles.container}
      onClick={onCardClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onCardClick();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className={styles.odds}>
        {market?.selections?.map((selection, i) => (
          <SportOddsButton
            isSelected={isCurrentlySelected(selection)}
            key={selection.id}
            jerseyIcon={selection.name !== 'Draw' ? 'chicago' : undefined}
            label={selection.name}
            odd={
              selection.prices ? selection.prices[0]?.decimal_price : undefined
            }
            className={styles.oddsbutton}
            onOddButtonClick={() => onOddButtonClicked(selection)}
            reverse={i === (market.selections?.length || 0) - 1}
          />
        ))}
      </div>
    </div>
  );
};

export default Selections;
