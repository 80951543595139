import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'urql';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
import {
  MeetingType,
  RaceCardQuery,
  RunnerDocument,
  RunnerQuery,
  RunnerQueryVariables,
} from '../../../../generated/graphql';
import { RootState } from '../../../../stores/store';
import JockeyTrainerForm from './JockeyTrainerForm';
import styles from './ShortForm.module.css';
import ShortFormInfo from './ShortFormInfo';
import Start from './Start';

type Props = {
  competitor: NonNullable<RaceCardQuery['race']>['competitors'][0];
  race: NonNullable<RaceCardQuery['race']>;
};

export default function ShortForm({ competitor, race }: Props) {
  const [{ data }] = useQuery<RunnerQuery, RunnerQueryVariables>({
    query: RunnerDocument,
    variables: {
      id: competitor.runner.id,
    },
  });
  const showShortForm = useSelector(
    (state: RootState) => state.setting.showShortForm
  );
  const [highlightRelevantRace, setHighlightRelevantRace] = useState(false);
  const handleHighlightRelevantRace = () =>
    setHighlightRelevantRace(!highlightRelevantRace);

  const recentStarts = useMemo(
    () =>
      data?.runner?.competitorsConnection?.competitors?.[0].formHistory?.slice?.(
        0,
        4
      ),
    [data?.runner?.competitorsConnection]
  );

  const compConnection = useMemo(
    () => data?.runner?.competitorsConnection.competitors?.[0],
    [data?.runner]
  );

  return (
    <>
      {recentStarts && recentStarts?.length > 0 ? (
        <div className={styles.container}>
          <div className={styles.content}>
            {compConnection ? (
              <ShortFormInfo competitor={compConnection} race={race} />
            ) : null}
            <div className={styles.divider} />
            <div className={styles.shortFormRunnerInfo}>
              <span className={clsx(styles.label, styles.title)}>
                Recent Starts
              </span>
              <div className={styles.highlightSection}>
                <div
                  className={clsx(styles.label, styles.highlightRelevantRace)}
                >
                  Highlight Relevance to this race
                </div>
                <div>
                  <ToggleSwitch
                    value={highlightRelevantRace}
                    changeValue={handleHighlightRelevantRace}
                  />
                </div>
              </div>
            </div>
            <div
              className={clsx(styles.separator, styles.marginBottom, {
                [styles.shortForm]: showShortForm,
              })}
            />
            <div className={styles.recentStartsContainer}>
              {recentStarts.map((start, index) =>
                start ? (
                  <Start
                    key={`${start?.date}:${start?.finishingPosition}`}
                    start={start}
                    startBefore={recentStarts[index + 1]}
                    meetingType={race?.meeting?.type}
                  />
                ) : null
              )}
            </div>
            <div className={styles.divider} />
            <div className={styles.jockeyTrainerWorkoutsContainer}>
              {compConnection &&
              !race.meeting.type.includes(MeetingType.Greyhound) ? (
                <JockeyTrainerForm
                  competitor={compConnection}
                  meetingType={race?.meeting?.type}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
