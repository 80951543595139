import { useEffect, useState } from 'react';

const isVisible = () => typeof document !== 'undefined' && !document.hidden;

const useWindowVisible = () => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    setVisible(isVisible());

    window.addEventListener('visibilitychange', (event) => {
      if (document.hidden) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    });

    return () => {
      // window.removeEventListener('visibilitychange');
    };
  }, []);

  return visible;
};

export default useWindowVisible;
