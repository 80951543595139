import clsx from 'clsx';
import useFormat from '../../hooks/useFormat';
import styles from './PairedFlucs.module.css';

export default function PairedFlucs({
  flucs = [],
  scratched,
}: {
  flucs: number[];
  scratched: boolean;
}) {
  const format = useFormat();
  const open = flucs?.[0];
  const fluc1 = flucs[flucs.length - 4];
  const fluc2 = flucs[flucs.length - 3];
  const fluc3 = flucs[flucs.length - 2];

  return fluc1 && fluc2 && !scratched ? (
    <div className={styles.container}>
      <div className={styles.flucContainer}>
        <div className={clsx(fluc2 > open ? styles.drift : styles.firm)}>
          {format.odds(fluc2) || 'N/A'}
        </div>
      </div>
      <div className={styles.flucContainer}>
        <div className={clsx(fluc3 > fluc2 ? styles.drift : styles.firm)}>
          {format.odds(fluc3) || 'N/A'}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.flucContainer}>
        <div>N/A</div>
      </div>
      <div className={styles.flucContainer}>
        <div>N/A</div>
      </div>
    </div>
  );
}
