import { Navigate, Route, Routes } from 'react-router-dom';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import { SportsFilterProvider } from '../../../hooks/useSportFilters/useSportFilters';
import AllSports from './AllSports/AllSports';
import FoxStatsSports from './FoxStatsSports/FoxStatsSports';
import LiveBettingSports from './LiveBettingSports/LiveBettingSports';
import PopularSports from './PopularSports/PopularSports';
import UpcomingSports from './UpcomingSports/UpcomingSports';

const tabs = [
  {
    label: 'All Sports',
    url: '/sports/home/all',
  },
  {
    label: 'Fox Stats & Insights',
    url: '/sports/home/stats',
  },
  {
    label: 'Upcoming',
    url: '/sports/home/upcoming',
  },
  {
    label: 'Popular',
    url: '/sports/home/popular',
  },
  {
    label: 'Live Betting',
    url: '/sports/home/livebetting',
  },
];
const routes = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Sports',
    url: '/sports/home/all',
  },
];

export default function SportsHome() {
  useBreadcrumbs(routes);
  return (
    <SportsFilterProvider>
      <HomeContainer HeaderComponent={<h1>Sports</h1>} tabs={tabs}>
        <Routes>
          <Route path="/sports/home/all" element={<AllSports />} />
          <Route path="/sports/home/upcoming" element={<UpcomingSports />} />
          <Route path="/sports/home/popular" element={<PopularSports />} />
          <Route path="/sports/home/stats" element={<FoxStatsSports />} />
          <Route
            path="/sports/home/livebetting"
            element={<LiveBettingSports />}
          />
          <Route path="*" element={<Navigate to="/sports/home/all" />} />
        </Routes>
      </HomeContainer>
    </SportsFilterProvider>
  );
}
