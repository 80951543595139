export enum SortByKey {
  NAME = 'name',
  WIN = 'win',
  PLACE = 'place',
  SHOW = 'show',
  ML = 'ml',
  OP = 'O/P',
  MTP = '10 MTP',
  TRACK = 'tr',
  POOL_AMOUNT = 'pool-amount',
}

export enum SortOrder {
  ASCENDING = 'sortAscending',
  DESCENDING = 'sortDescending',
}
