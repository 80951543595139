import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Countdown from '../../components/Countdown/Countdown';
import Icon, { IconName } from '../../components/Icon/Icon';
import OddsButton from '../../components/OddsButton/OddsButton';
import LineShimmer from '../../components/Shimmers/LineShimmer';
import RunnerDetailShimmer from '../../components/Shimmers/RunnerDetailShimmer';
import TwoBoxShimmer from '../../components/Shimmers/TwoBoxShimmer';
import { BetType } from '../../constants/betTypes';
import {
  GetFeaturedRaceQuery,
  PriceType,
  RaceStatus,
} from '../../generated/graphql';
import { addOrRemoveBet } from '../../stores/betslipSlice';
import { RootState } from '../../stores/store';
import { BetslipType } from '../../types/betslipType';
import { hasSingleBet } from '../../utils/betslip/common';
import RunnerDetailsCompact from '../Racebook/RaceCard/RunnerDetails/RunnerDetailCompact';
import styles from './UpcomingFavourite.module.css';

type Props = {
  race?: GetFeaturedRaceQuery['race'];
  meeting?: NonNullable<GetFeaturedRaceQuery['race']>['meeting'];
  competitor?: NonNullable<GetFeaturedRaceQuery['race']>['competitors'][0];
  resulted: boolean;
  className?: string;
};

const selector = createSelector(
  [
    (state: RootState) => state.config.options.betslipType,
    (state: RootState) => state.betslip.betslip,
  ],
  (betslipType, betslip) => ({
    betslipType,
    betslip,
  })
);

export default function UpcomingFavourite({
  race,
  meeting,
  competitor,
  resulted,
  className = '',
}: Props) {
  const dispatch = useDispatch();
  const { betslip, betslipType } = useSelector(selector);

  const getWinPrice = useCallback(
    () =>
      competitor?.prices?.find((price) => price.type === PriceType.WinFixedOdds)
        ?.price,
    [competitor]
  );

  const getPlacePrice = useCallback(
    () =>
      competitor?.prices?.find(
        (price) => price.type === PriceType.PlaceFixedOdds2
      )?.price,
    [competitor]
  );

  const render = useCallback(() => {
    if (!meeting || !race || !competitor) {
      return (
        <>
          <LineShimmer />
          <div>
            <TwoBoxShimmer />
          </div>
        </>
      );
    }

    return (
      <>
        <div className={styles.track}>
          <Icon name={meeting.type as IconName} />
          <span className={styles.trackName}>
            {meeting.track.name} - R {race.number}
          </span>
          <div className={styles.status}>
            {race.status !== RaceStatus.Final ? (
              <Countdown startTime={race.startTime} />
            ) : (
              <span>Resulted</span>
            )}
          </div>
        </div>
        <div className={styles.prices}>
          <OddsButton
            price={getWinPrice()}
            priceTypeText="Win"
            disabled={competitor.scratched || resulted}
            onClick={() => {
              if (race) {
                dispatch(
                  addOrRemoveBet({
                    betType: BetType.WIN_FIXED_ODDS,
                    traditional: betslipType === BetslipType.American,
                    odds: getWinPrice(),
                    race,
                    meeting: race?.meeting,
                    competitor,
                  })
                );
              }
            }}
            selected={
              !!race &&
              hasSingleBet(betslip, {
                betType: BetType.WIN_FIXED_ODDS,
                race,
                meeting: race?.meeting,
                competitor,
              })
            }
          />
          <OddsButton
            price={getPlacePrice()}
            priceTypeText="Place"
            disabled={competitor.scratched || resulted}
            className={styles.placeButton}
            onClick={() => {
              if (race) {
                dispatch(
                  addOrRemoveBet({
                    betType: BetType.PLACE_FIXED_ODDS2,
                    traditional: betslipType === BetslipType.American,
                    odds: getPlacePrice(),
                    race,
                    meeting: race?.meeting,
                    competitor,
                  })
                );
              }
            }}
            selected={
              !!race &&
              hasSingleBet(betslip, {
                betType: BetType.PLACE_FIXED_ODDS2,
                race,
                meeting: race?.meeting,
                competitor,
              })
            }
          />
        </div>
      </>
    );
  }, [
    betslip,
    betslipType,
    dispatch,
    competitor,
    meeting,
    race,
    resulted,
    getPlacePrice,
    getWinPrice,
  ]);

  return (
    <div className={clsx(styles.container, className)}>
      {!meeting || !competitor || !race ? (
        <RunnerDetailShimmer />
      ) : (
        <RunnerDetailsCompact
          meeting={meeting}
          competitor={competitor}
          position={
            race.results[race.results.length - 1]?.positions.find(
              (pos) => pos.tabNo === competitor.tabNo
            )?.position
          }
        />
      )}
      <div className={styles.separator} />
      {render()}
    </div>
  );
}
