import clsx from 'clsx';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Silk from '../../../../components/Silk/Silk';
import { Competitor, MeetingType } from '../../../../generated/graphql';
import useFormat from '../../../../hooks/useFormat';
import { RootState } from '../../../../stores/store';
import { LocaleType } from '../../../../types/localeType';
import { SilkType } from '../../../../types/silk';
import styles from './RunnerDetails.module.css';

type Props = {
  meeting: {
    type: MeetingType;
    track: {
      country: string;
    };
  };
  competitor: Pick<
    Competitor,
    | 'tabNo'
    | 'jockey'
    | 'weightTotal'
    | 'trainer'
    | 'scratched'
    | 'scratchingType'
    | 'lateScratchingTime'
    | 'name'
    | 'silksURL'
    | 'barrier'
  > & {
    formHistory: ReadonlyArray<{
      finishingPosition: number;
    } | null>;
  };
  position?: number;
  onClick?: () => void;
  enforceMinHeight?: boolean;
};

export default function RunnerDetails({
  meeting,
  competitor,
  position,
  onClick,
  enforceMinHeight = false,
}: Props) {
  const format = useFormat();
  const locale = useSelector((state: RootState) => state.config.options.locale);

  const renderDetails = useCallback(() => {
    switch (meeting.type) {
      case MeetingType.Thoroughbred:
        return (
          <div className={styles.runnerContainer}>
            <span className={clsx(styles.long, styles.jockey)}>
              <span className={styles.label}>J: </span>
              <span>{competitor.jockey}</span>
            </span>
            <span className={clsx(styles.long, styles.weightJ)}>
              <span className={styles.label}>W: </span>
              {format.weight(competitor.weightTotal)}
            </span>
            <span className={clsx(styles.long, styles.trainer)}>
              <span className={styles.label}>T: </span>
              {competitor.trainer}
            </span>
            <span className={clsx(styles.long, styles.form)}>
              <span className={styles.label}>F: </span>
              {competitor.formHistory
                ?.slice(0, 6)
                .map((form) => form?.finishingPosition)}
            </span>
          </div>
        );
      case MeetingType.Harness:
        return (
          <div className={styles.runnerContainer}>
            <span className={styles.long}>
              <span className={styles.label}>D: </span>
              <span>{competitor.jockey}</span>
            </span>
            <span className={styles.long}>
              <span className={styles.label}>F: </span>
              {competitor.formHistory
                ?.slice(0, 6)
                .map((form) => form?.finishingPosition)}
            </span>
            <span className={styles.long}>
              <span className={styles.label}>T: </span>
              {competitor.trainer}
            </span>
          </div>
        );
      case MeetingType.Greyhound:
        return (
          <div className={styles.runnerContainer}>
            <span className={styles.long}>
              <span className={styles.label}>T: </span>
              {competitor.trainer}
            </span>
            <span className={styles.long}>
              <span className={styles.label}>F: </span>
              {competitor.formHistory
                ?.slice(0, 6)
                .map((form) => form?.finishingPosition)}
            </span>
          </div>
        );
      default:
        return null;
    }
  }, [meeting, competitor, format]);

  return (
    <div className={clsx({ [styles.clickable]: !!onClick })} onClick={onClick}>
      <div
        className={clsx(styles.competitor, {
          [styles.scratched]: competitor.scratched,
          [styles.enforceMinHeight]: enforceMinHeight,
        })}
      >
        <div className={styles.silkContainer}>
          <Silk
            competitor={competitor}
            meeting={meeting}
            outline
            overwriteSilkType={
              meeting.type === MeetingType.Greyhound ? SilkType.Silk : undefined
            }
          />
          {position ? (
            <div
              className={clsx(
                styles.positionBox,
                styles[`position--${position}`]
              )}
            >
              {format.ordinal(position)}
            </div>
          ) : null}
        </div>
        <div className={styles.competitorDetails}>
          <span className={styles.competitorName}>
            {competitor.tabNo}. {competitor.name}
            {locale !== LocaleType.US ? ` (${competitor.barrier})` : ''}
          </span>
          {competitor.scratched ? (
            <div className={styles.label}>
              <span>SCRATCHED </span>
              <span className={styles.scratchedTime}>
                {competitor.lateScratchingTime
                  ? dayjs(competitor.lateScratchingTime).format(
                      'M:ss YYYY-MM-DD'
                    )
                  : null}
              </span>
            </div>
          ) : (
            renderDetails()
          )}
        </div>
      </div>
    </div>
  );
}
