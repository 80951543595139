import React from 'react';
import SportOddsButton from '../../SportOddsButton/SportOddsButton';
import ScoreBoard from '../ScoreBoard/ScoreBoard';
import styles from './TeamsLive.module.css';

type TeamsLiveProps = {
  sport: string;
  onClick: () => void;
};

const TeamsLive = ({ sport, onClick }: TeamsLiveProps) => (
  <div
    className={styles.container}
    onKeyPress={(e) => {
      if (e.key === 'Enter') {
        onClick();
      }
    }}
    role="button"
    tabIndex={0}
  >
    <ScoreBoard sport={sport} />
    <div className={styles.odds}>
      <SportOddsButton odd={1.2} jerseyIcon="lakers" label="Chelsea" />
      <SportOddsButton odd={1.2} jerseyIcon="chicago" label="Arsenal" reverse />
    </div>
  </div>
);

export default TeamsLive;
