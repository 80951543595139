import { HeadToHeadCardPlaceholder } from '../../../../components';
import H2HCard from '../../../../components/H2HCard/H2HCard';
import useUpcomingSportTypeEvents from '../../../../hooks/useSportTypeEvents/useUpcomingSportTypeEvents';
import styles from './SportUpcoming.module.css';

export default function SportUpcoming({
  sportTypeId,
}: {
  sportTypeId?: number;
}) {
  const { events, fetching } = useUpcomingSportTypeEvents(sportTypeId);

  return (
    <div className={styles.head2headContainer}>
      {fetching &&
        [...Array(5)].map((_, i) => <HeadToHeadCardPlaceholder key={i} />)}
      {!fetching && !events.length && (
        <div className={styles.noEvents}>No upcoming events</div>
      )}
      {events?.map((sportEvent) => (
        <H2HCard
          key={sportEvent.id}
          sportEvent={sportEvent}
          containerClass={styles.headToHeadCard}
          type="h2h"
          showLeagueName
        />
      ))}
    </div>
  );
}
