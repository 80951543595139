import { lowerCase } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import { SportCardHeader } from '../../../components';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import useSportEvent from '../../../hooks/useSportEvent/useSportEvent';
import { selectSportType } from '../../../stores/selectors/sportsSelector';
import slugify from '../../../utils/slugify';
import SportCardAll from './SportCardAll/SportCardAll';
import SportCardStats from './SportCardStats/SportCardStats';

const baseRoutes = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Sports',
    url: '/sports/home/all',
  },
];

export default function SportCardHome() {
  const { eventId = '' } = useParams<{ eventId: string }>();

  const { event, fetching, error } = useSportEvent(eventId);

  const sportType = event?.competition.category.sport_type.name;

  const sportTypeDescription = useSelector(
    selectSportType(sportType)
  )?.description;

  const competitionName = event?.competition.category.name;

  const routes = useMemo(() => {
    if (!competitionName || !sportTypeDescription) {
      return [];
    }
    const sportTypeFormatted = lowerCase(sportType).replace(' ', '');
    return [
      ...baseRoutes,
      {
        label: sportTypeDescription,
        url: `/sports/sport/${sportTypeFormatted}/all`,
      },
      {
        label: competitionName,
        url: `/sports/competition/${sportTypeFormatted}/${slugify(
          lowerCase(competitionName)
        )}/matches`,
      },
    ];
  }, [competitionName, sportType, sportTypeDescription]);

  const tabs = useMemo(() => {
    const baseEventUrl = `/sports/event/${lowerCase(sportType).replace(
      ' ',
      ''
    )}/${slugify(lowerCase(competitionName))}/${eventId}`;

    return [
      {
        label: `All Markets`,
        url: `${baseEventUrl}/all`,
      },
      {
        label: 'Same Game Multi',
        url: `${baseEventUrl}/sgm`,
      },
      {
        label: 'Fox Stats & Insights',
        url: `${baseEventUrl}/stats`,
      },
    ];
  }, [sportType, competitionName, eventId]);

  return (
    <div>
      <SportCardHeader sportEvent={event} loading={fetching} routes={routes} />
      <HomeContainer tabs={tabs} hideBreadcrumbs>
        <Routes>
          <Route
            path="/all"
            element={
              <SportCardAll
                event={event}
                loading={fetching}
                eventFetchError={error}
              />
            }
          />
          <Route path="/sgm" element={<SportCardStats />} />
          <Route path="/stats" element={<SportCardStats event={event} />} />
        </Routes>
      </HomeContainer>
    </div>
  );
}
