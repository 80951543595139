import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import {
  createClient,
  defaultExchanges,
  Provider,
  subscriptionExchange,
} from 'urql';
import { RootState } from '../stores/store';

type Props = {
  children: React.ReactNode;
};

export default function AppProvider({ children }: Props) {
  const config = useSelector((state: RootState) => state.config);
  const [headers, setHeaders] = useState<Record<string, any>>({});

  useEffect(() => {
    (async () => {
      const fetchedHeaders = (await config?.getHeaders()) || {};
      setHeaders(fetchedHeaders);
    })();
  }, [config]);

  const client = useMemo(() => {
    if (headers.Authorization) {
      const subscriptionClient = new SubscriptionClient(config.socketApiUrl, {
        reconnect: true,
        connectionParams: () => headers,
      });

      return createClient({
        url: config.apiUrl,
        exchanges: [
          ...defaultExchanges,
          subscriptionExchange({
            forwardSubscription: (operation) =>
              subscriptionClient.request(operation),
          }),
        ],
        fetchOptions: () => ({
          headers,
        }),
      });
    }

    return null;
  }, [config, headers]);

  if (!client) return null;

  return <Provider value={client}>{children}</Provider>;
}
