import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { BetStatus } from '../../constants/betStatus';
import betTypes, {
  BetType,
  sportSingleBetTypes,
} from '../../constants/betTypes';
import { PriceType } from '../../constants/priceTypes';
import { RaceQuery, RaceStatus } from '../../generated/graphql';
import { Prices } from '../../hooks/usePrices/types';
import { PlatformType } from '../../types/platformType';
import { MarketSelection } from '../../types/sport';
import { singleBetTypes } from '../betslip';
import {
  Bet,
  ExoticBet,
  MultiRaceBet,
  SingleBet,
  SportSingleBet,
} from './types';

export function findSingleBetIndex(betslip: Bet[], singleBet: Bet) {
  if (isSportSingleBet(singleBet)) {
    return betslip.findIndex(
      (bet) =>
        isSportSingleBet(bet) &&
        bet.betType === singleBet.betType &&
        bet.eventDetails?.id === singleBet?.eventDetails?.id &&
        bet.selection.id === singleBet.selection.id
    );
  }
  return betslip.findIndex(
    (bet) =>
      bet.betType === singleBet.betType &&
      bet?.race?.id === singleBet?.race?.id &&
      bet?.competitor?.tabNo === singleBet?.competitor?.tabNo
  );
}

export function findExoticBetIndex(
  betslip: Bet[],
  exoticBet: ExoticBet | MultiRaceBet
) {
  return betslip.findIndex(
    (bet) =>
      bet.betType === exoticBet.betType && bet.race?.id === exoticBet.race.id
  );
}

export function findParlayBetIndex(betslip: Bet[]) {
  return betslip.findIndex((bet) => bet.betType === BetType.PARLAY);
}

export function removeInvalidParlays(betslip: Bet[]) {
  return betslip.filter((bet) => {
    if (bet.betType !== BetType.PARLAY) return true;
    if (bet.selections.length <= 1) return false;
    return true;
  });
}

export function hasSingleBet(betslip: Bet[], singleBet: SingleBet) {
  return findSingleBetIndex(betslip, singleBet) !== -1;
}

export function isSingleBet(bet: any, status?: any): bet is SingleBet {
  return singleBetTypes.includes(bet.betType);
}

export function isSportSingleBet(bet: any): bet is SportSingleBet {
  return sportSingleBetTypes.includes(bet.betType);
}

export function getBetDetails(bet: Bet) {
  if (isSportSingleBet(bet)) return bet.eventDetails;
  return bet.race;
}

export function getEventStatus(bet: Bet) {
  if (isSportSingleBet(bet)) {
    // TODO
    return 'OPEN';
  }
  return bet?.race?.status === 'OPEN' ? 'OPEN' : 'CLOSED';
}
export function getEventStartTime(bet: Bet) {
  if (isSportSingleBet(bet)) {
    // TODO
    return bet.eventDetails?.start_date;
  }
  return bet.race?.startTime;
}

export function isExoticBet(bet: any): bet is ExoticBet {
  return [
    BetType.EXACTA,
    BetType.QUINELLA,
    BetType.TRIFECTA,
    BetType.SUPERFECTA,
    BetType.SUPERHI5,
  ].includes(bet.betType);
}

export function isMultiExoticBetType(type: string) {
  return [BetType.DOUBLE, BetType.PICK3, BetType.PICK6]
    .map((t) => t?.toLowerCase().split(' ').join(''))
    .includes(type?.toLowerCase().split(' ').join('') as BetType);
}

export function isExoticBetType(type: string) {
  return [
    BetType.EXACTA,
    BetType.QUINELLA,
    BetType.TRIFECTA,
    BetType.SUPERFECTA,
    BetType.SUPERHI5,
  ]
    .map((t) => t.toLowerCase().split(' ').join(''))
    .includes(type.toLowerCase() as BetType);
}

export function isExoticOrMultiBetType(type: string) {
  return [
    BetType.EXACTA,
    BetType.QUINELLA,
    BetType.TRIFECTA,
    BetType.SUPERFECTA,
    BetType.SUPERHI5,
    betTypes.DOUBLE,
    betTypes.PICK3,
    betTypes.PICK6,
  ]
    .map((t) => t.toLowerCase().split(' ').join(''))
    .includes(type.toLowerCase() as BetType);
}

export function isMultiBet(bet: any): bet is MultiRaceBet {
  return [BetType.DOUBLE, BetType.PICK3, BetType.PICK6].includes(bet.betType);
}

export function getSingleBets(betslip: Bet[]): SingleBet[] {
  return betslip.filter((bet) => isSingleBet(bet)) as SingleBet[];
}

export function getExoticBets(betslip: Bet[]): ExoticBet[] {
  return betslip.filter((bet) => isExoticBet(bet)) as ExoticBet[];
}

export function getMultiBets(betslip: Bet[]): MultiRaceBet[] {
  return betslip.filter((bet) => isMultiBet(bet)) as MultiRaceBet[];
}

export function betTypeLookup(type: string): BetType {
  const lookup: Record<string, BetType[]> = {
    ...Object.entries(BetType).reduce(
      (acc, [key, value]) => ({ ...acc, [key.toLowerCase()]: [value] }),
      {}
    ),
    win: singleBetTypes,
  };
  const betType = lookup[type?.toLowerCase?.()] || lookup.win;

  return betType?.[0];
}

// Note: This shouldn't be required if we used an 2D array of selections
export function getSelectionsRequired(betType: BetType[]) {
  if (
    betType.some((type) =>
      [betTypes.EXACTA, betTypes.QUINELLA, betTypes.DOUBLE].includes(type)
    )
  ) {
    return 2;
  }

  if (
    betType.some((type) => [betTypes.TRIFECTA, betTypes.PICK3].includes(type))
  ) {
    return 3;
  }

  if (betType.some((type) => [betTypes.SUPERFECTA].includes(type))) {
    return 4;
  }

  if (betType.some((type) => [betTypes.SUPERHI5].includes(type))) {
    return 5;
  }

  if (betType.some((type) => [betTypes.PICK6].includes(type))) {
    return 6;
  }

  return 0;
}

export function getStandoutExoticCombinations(selections: Array<number[]>) {
  const generateCombos = (
    n = 0,
    result: number[][] = [],
    current: number[] = []
  ) => {
    if (n === selections.length) {
      // Ensure no duplicate tabNos are sent in different order
      if (uniq(current).length === selections.length) {
        result.push(current);
      }
    } else {
      selections[n].forEach((item) =>
        generateCombos(n + 1, result, [...current, item])
      );
    }

    return result;
  };

  const combinations = generateCombos();
  const removedDuplicates = uniqBy(
    combinations,
    (array) => JSON.stringify(array.sort().join(',')) // Sort and stringify the combination to remove any duplicates. Join with comma to ensure 11,2 is not considered 1,12
  );

  return removedDuplicates.length;
}

export function getExoticCombinations(
  numberOfSelections: number,
  selections: Array<number[]>
) {
  const cartesian = selections.reduce<number[][]>(
    (acc, selection) => {
      const prev = acc.flatMap((tabNo) =>
        selection.map((prevTabNo) => [tabNo, prevTabNo].flat())
      );
      return prev;
    },
    [[]]
  );
  const removedDuplicates = cartesian.filter(
    (combo) =>
      combo.filter((tabNo, index, array) => array.indexOf(tabNo) === index)
        .length === numberOfSelections
  );
  return removedDuplicates.length;
}

export function getMultiExoticCombinations(
  selections: Array<NonNullable<RaceQuery['race']>['competitors']> = []
) {
  return selections.reduce(
    (accumulator, selection) => selection.length * accumulator,
    1
  );
}

export function getBetCombinations(bet: Bet) {
  if (isSportSingleBet(bet)) return 0;
  if (isSingleBet(bet) || !bet?.selections) return 0;
  if (isMultiBet(bet)) return getMultiExoticCombinations(bet.selections);
  if (isExoticBet(bet))
    return getExoticCombinations(
      bet.selections?.length,
      bet.selections?.map((sel) => sel.map((c) => c.tabNo))
    );
  return 0;
}

export function getPrice(
  selectedPriceType: PlatformType,
  priceType: PriceType,
  competitorPrices?: Prices
) {
  if (!competitorPrices) return null;
  if (selectedPriceType === PlatformType.Fixed) {
    switch (priceType) {
      case PriceType.WIN:
        return competitorPrices?.[PriceType.WIN_FIXED_ODDS] || null;
      case PriceType.PLACE:
        return competitorPrices?.[PriceType.PLACE_FIXED_ODDS2] || null;
      case PriceType.SHOW:
        return competitorPrices?.[PriceType.PLACE_FIXED_ODDS] || null;
      default:
        return null;
    }
  }
  if (selectedPriceType === PlatformType.Tote) {
    return competitorPrices?.[priceType] || null;
  }
  return null;
}

export function getBetType(selectedPriceType: string, betType: BetType) {
  if (selectedPriceType === PlatformType.Fixed) {
    switch (betType) {
      case BetType.WIN:
        return BetType.WIN_FIXED_ODDS;
      case BetType.PLACE:
        return BetType.PLACE_FIXED_ODDS2;
      case BetType.SHOW:
        return BetType.PLACE_FIXED_ODDS;
      default:
        return BetType.WIN_FIXED_ODDS;
    }
  }
  switch (betType) {
    case BetType.WIN:
      return BetType.WIN;
    case BetType.PLACE:
      return BetType.PLACE;
    case BetType.SHOW:
      return BetType.SHOW;
  }
  return BetType.WIN;
}

export function indexOfBetTypeSelectedOnRace(selections: Bet[], bet: Bet) {
  if (isSportSingleBet(bet)) {
    return selections.findIndex(
      (selection) =>
        isSportSingleBet(selection) &&
        selection.eventDetails?.id === bet.eventDetails?.id
    );
  }
  return selections.findIndex(
    (selection) => selection.race?.id === bet.race?.id
  );
}

export function isValidBet(bet: Bet) {
  // minimum stake needs to come from the backend
  const MININUMSTAKE = 0.1;
  if (!bet.stake) return false;
  if (bet.stake < MININUMSTAKE) return false;
  if (isSingleBet(bet)) {
    if (bet.competitor.scratched) return false;
  }
  if (isSingleBet(bet) || isExoticBet(bet)) {
    if (bet.race.status !== RaceStatus.Open) return false;
  }
  return true;
}

export function convertBetStatus(betStatus: BetStatus) {
  switch (betStatus) {
    case BetStatus.INTERNAL_SERVER_ERROR:
    case BetStatus.BAD_REQUEST:
      return BetStatus.CANCELLED;
    default:
      return betStatus;
  }
}

export const removeIndexFromBetslip = (
  updatingBetslip: Bet[],
  indexToRemove: number
) => updatingBetslip.filter((_, index) => index !== indexToRemove);

export const findSportsBetIndexInBetslip = (
  betslip: Bet[],
  selection: MarketSelection
) =>
  betslip.findIndex((betInBetslip) => {
    if ((betInBetslip as SportSingleBet)?.selection?.id === selection.id) {
      return true;
    }
    return false;
  });
