import React from 'react';
import Icon, { IconName } from '../Icon/Icon';
import styles from './IconButton.module.css';

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconName: IconName;
}

const IconButton = ({ iconName, ...rest }: IconButtonProps) => (
  <button className={styles.container} type="button" {...rest}>
    <Icon name={iconName} />
  </button>
);

export default IconButton;
