import clsx from 'clsx';
import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './HeadToHeadCardPlaceholder.module.css';

export type HeadToHeadCardPlaceholderProps = {
  className?: string;
};

export const HeadToHeadCardPlaceholder = ({
  className,
}: HeadToHeadCardPlaceholderProps) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.wrapper}>
      <ContentLoader viewBox="0 0 400 150">
        <rect x="0" y="0" style={{ width: '96%' }} height="6" />
        <rect x="0" y="12" style={{ width: '16%' }} height="4" />
        <rect x="0" y="20" rx="2" ry="2" width="120" height="20" />
        <rect x="138" y="20" rx="2" ry="2" width="120" height="20" />
        <rect x="268" y="20" rx="2" ry="2" width="120" height="20" />
      </ContentLoader>
    </div>
  </div>
);

export const HeadToHeadCardPlaceholderPopular = ({
  className,
}: HeadToHeadCardPlaceholderProps) => (
  <div className={clsx(styles.popular, className)}>
    <ContentLoader viewBox="0 0 400 150">
      <rect x="12" y="12" width="380" height="20" />
      <rect x="12" y="42" width="380" height="20" />
      <rect x="12" y="100" rx="5" ry="5" width="180" height="40" />
      <rect x="210" y="100" rx="5" ry="5" width="180" height="40" />
    </ContentLoader>
  </div>
);

export default HeadToHeadCardPlaceholder;
