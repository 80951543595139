import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useQuery } from 'urql';
import {
  RaceCardDocument,
  RaceCardQuery,
  RaceCardQueryVariables,
  RaceStatus,
} from '../../../generated/graphql';
import { RootState } from '../../../stores/store';
import { PlatformType } from '../../../types/platformType';
import ExoticResultField from '../RaceCard/Fields/ExoticResultsField/ExoticResultsField';
import ResultField from '../RaceCard/Fields/ResultField/ResultField';
import WPSField from '../RaceCard/Fields/WPSField/WPSField';
import styles from './RacingResult.module.css';

type Props = {
  raceId: string;
};

const selector = createSelector(
  [
    (state: RootState) =>
      state.setting.selectedPriceType === PlatformType.Fixed
        ? state.config.coreApiSource
        : state.config.coreApiToteSource,
    (state: RootState) => state.config.options.toteEnabled,
    (state: RootState) => state.setting,
  ],
  (source, toteEnabled, settings) => ({
    source,
    toteEnabled,
    settings,
  })
);

export default function RacingResult({ raceId }: Props) {
  const { source, toteEnabled, settings } = useSelector(selector);
  const [{ data }] = useQuery<RaceCardQuery, RaceCardQueryVariables>({
    query: RaceCardDocument,
    variables: {
      id: raceId,
      source,
      toteEnabled,
      fetchRunnerInfo: settings.showRunnerInfo,
    },
  });

  return (
    <div className={styles.container}>
      {![RaceStatus.Abandoned, RaceStatus.Open, RaceStatus.Unknown].includes(
        data?.race?.status as RaceStatus
      ) ? (
        <>
          <ResultField race={data?.race} />
          <ExoticResultField race={data?.race} />
        </>
      ) : null}
      <WPSField race={data?.race} competitors={data?.race?.competitors} />
    </div>
  );
}
