import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useSubscription } from 'urql';
import {
  GetFeaturedRaceDocument,
  GetFeaturedRaceQuery,
  GetFeaturedRaceQueryVariables,
  PriceUpdatesDocument,
  PriceUpdatesSubscription,
  PriceUpdatesSubscriptionVariables,
  RaceUpdatedDocument,
  RaceUpdatedSubscription,
  RaceUpdatedSubscriptionVariables,
} from '../../generated/graphql';
import { RootState } from '../../stores/store';
import UpcomingFavourite from './UpcomingFavourite';

type Props = {
  raceId: string;
  tabNo: string;
};

export default function UpcomingFavouriteWrapper({ raceId, tabNo }: Props) {
  const source = useSelector((state: RootState) => state.config.coreApiSource);
  const [race, setRace] = useState<GetFeaturedRaceQuery['race']>();
  const [resulted, setResulted] = useState(false);

  const [{ data }] = useQuery<
    GetFeaturedRaceQuery,
    GetFeaturedRaceQueryVariables
  >({
    query: GetFeaturedRaceDocument,
    variables: {
      id: raceId,
      source,
    },
  });
  useEffect(() => {
    if (data?.race) {
      setRace(data.race);
    }
  }, [data]);

  const [subscription] = useSubscription<
    RaceUpdatedSubscriptionVariables,
    RaceUpdatedSubscription
  >({
    query: RaceUpdatedDocument,
    variables: {
      raceId: race?.id,
    },
    pause: !race,
  });
  useEffect(() => {
    if (subscription.data) {
      setRace((prev) => {
        if (prev) {
          const competitors =
            subscription?.data?.raceUpdated?.competitors || [];
          return {
            ...prev,
            ...(subscription?.data?.raceUpdated || {}),
            competitors: prev.competitors.map((competitor) => ({
              ...competitor,
              ...(competitors.find((c) => c.tabNo === competitor.tabNo) || {}),
            })),
          };
        }

        return prev;
      });
    }
  }, [subscription]);

  const [priceSubscription] = useSubscription<
    PriceUpdatesSubscriptionVariables,
    PriceUpdatesSubscription
  >({
    query: PriceUpdatesDocument,
    variables: {
      raceId: race?.id,
      source,
    },
    pause: !race,
  });
  useEffect(() => {
    if (priceSubscription.data) {
      const { priceUpdates } = priceSubscription.data;
      setRace((prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          competitors: prev?.competitors.map((c) => ({
            ...c,
            prices: c.prices.map((price) => {
              const newPrice = priceUpdates.prices.find(
                (updatedPrice) =>
                  updatedPrice.tabNo === c.tabNo &&
                  updatedPrice.type === price.type
              );
              return newPrice || price;
            }),
          })),
        };
      });
    }
  }, [priceSubscription]);

  useEffect(() => {
    if (race) {
      setResulted(race.results.length > 0);
    }
  }, [race]);

  const competitor = race?.competitors.find((c) => c.tabNo === Number(tabNo));

  return (
    <UpcomingFavourite
      race={race}
      meeting={race?.meeting}
      competitor={competitor}
      resulted={resulted}
    />
  );
}
