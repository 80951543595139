import axios, { AxiosInstance } from 'axios';

let client: AxiosInstance;

export const initClient = (url: string) => {
  client = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 10 * 1000,
  });
};

export const getInstance = () => {
  if (client) {
    return client;
  }

  throw new Error('The cms Api should be initiated first');
};
