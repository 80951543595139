import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import ShadowButton from '../../../components/ShadowButton/ShadowButton';
import { RaceStatus } from '../../../generated/graphql';
import { updateSettings } from '../../../stores/settingSlice';
import { RootState } from '../../../stores/store';
import DailyDoubleField from './Fields/DailyDoubleField/DailyDoubleField';
import EarlyQuaddieField from './Fields/EarlyQuaddieField/EarlyQuaddieField';
import ExactaField from './Fields/ExactaField/ExactaField';
import ExoticResultField from './Fields/ExoticResultsField/ExoticResultsField';
import FirstFourField from './Fields/FirstFourField/FirstFourField';
import PlaceField from './Fields/PlaceField/PlaceField';
import QuaddieField from './Fields/QuaddieField/QuaddieField';
import QuinellaField from './Fields/QuinellaField/QuinellaField';
import ResultField from './Fields/ResultField/ResultField';
import RunningDoubleField from './Fields/RunningDoubleField/RunningDoubleField';
import SameRaceMultiField from './Fields/SameRaceMultiField/SameRaceMultiField';
import ShowField from './Fields/ShowField/ShowField';
import SuperSixField from './Fields/SuperSixField/SuperSixField';
import TrebleField from './Fields/TrebleField/TrebleField';
import TrifectaField from './Fields/TrifectaField/TrifectaField';
import WPSField from './Fields/WPSField/WPSField';
import FoxTips from './FoxTips/FoxTips';
import styles from './RaceCard.module.css';
import RaceCardHeader from './RaceCardHeader/RaceCardHeader';
import SelectPlatform from './SelectPlatform';
import useRaceCard, { RaceCardProvider } from './useRaceCard';

const selector = createSelector(
  [
    (state: RootState) => state.config.options.foxTipsEnabled,
    (state: RootState) => state.setting,
  ],
  (foxTipsEnabled, settings) => ({
    foxTipsEnabled,
    settings,
  })
);

export function RaceCardConsumer() {
  const navigate = useNavigate();
  const params = useParams<{
    meetingId: string;
    raceId: string;
    betType: string;
  }>();
  const { foxTipsEnabled, settings } = useSelector(selector);
  const dispatch = useDispatch();
  const { race, fetching, toggleShortFormForRunner } = useRaceCard();

  const renderBetType = useCallback(() => {
    if (race?.status !== RaceStatus.Open) {
      return (
        <WPSField
          race={race}
          competitors={race?.competitors}
          onClickRunner={toggleShortFormForRunner}
        />
      );
    }

    switch (params.betType) {
      case 'place':
        return <PlaceField />;
      case 'show':
        return <ShowField />;
      case 'same-race-multi':
        return <SameRaceMultiField />;
      case 'quinella':
        return <QuinellaField />;
      case 'exacta':
        return <ExactaField />;
      case 'trifecta':
        return <TrifectaField />;
      case 'first-4':
        return <FirstFourField />;
      case 'quaddie':
        return <QuaddieField />;
      case 'early-quaddie':
        return <EarlyQuaddieField />;
      case 'daily-double':
        return <DailyDoubleField />;
      case 'treble':
        return <TrebleField />;
      case 'super-6':
        return <SuperSixField />;
      case 'running-double':
        return <RunningDoubleField />;
      default:
        return (
          <WPSField
            race={race}
            competitors={race?.competitors}
            onClickRunner={toggleShortFormForRunner}
          />
        );
    }
  }, [params.betType, race, toggleShortFormForRunner]);

  const foxTipsAvailable = useMemo(
    () => foxTipsEnabled && race?.comments?.[0]?.comment,
    [foxTipsEnabled, race]
  );
  const canTogglePriceType = useMemo(
    () => !params.betType || ['place', 'show'].includes(params.betType!),
    [params]
  );

  return (
    <RaceCardHeader
      meetingId={params.meetingId!}
      raceId={params.raceId!}
      onChangeMeeting={(meetingId) => navigate(`/racing/meeting/${meetingId}`)}
      onChangeRace={(raceId) =>
        navigate(
          `/racing/meeting/${params.meetingId}/race/${raceId}${
            params.betType ? `/${params.betType}` : ''
          }`
        )
      }
    >
      <div className={styles.content}>
        {!fetching &&
        ![RaceStatus.Abandoned, RaceStatus.Open, RaceStatus.Unknown].includes(
          race?.status as RaceStatus
        ) ? (
          <>
            <ResultField race={race} />
            <ExoticResultField race={race} />
          </>
        ) : null}
        <div className={styles.row}>
          <div className={styles.buttons}>
            <ShadowButton
              selected={settings.showRunnerInfo}
              onClick={() =>
                dispatch(
                  updateSettings({
                    showRunnerInfo: !settings.showRunnerInfo,
                  })
                )
              }
              className={styles.button}
            >
              Runner Info
            </ShadowButton>

            <ShadowButton
              selected={settings.showShortForm}
              onClick={() =>
                dispatch(
                  updateSettings({
                    showShortForm: !settings.showShortForm,
                  })
                )
              }
              size="medium"
              className={styles.button}
            >
              Short Form
            </ShadowButton>

            {foxTipsAvailable ? (
              <ShadowButton
                selected={settings.showFoxTips}
                onClick={() =>
                  dispatch(
                    updateSettings({
                      showFoxTips: !settings.showFoxTips,
                    })
                  )
                }
                size="medium"
                className={styles.button}
              >
                <span className={styles.foxTips}>
                  <Icon name="fox" /> TIPS
                </span>
              </ShadowButton>
            ) : null}
          </div>
          {canTogglePriceType ? (
            <div className={styles.flexEnd}>
              <SelectPlatform />
            </div>
          ) : null}
        </div>
        {settings.showFoxTips && foxTipsAvailable ? <FoxTips /> : null}
        {renderBetType()}
      </div>
    </RaceCardHeader>
  );
}

export default function RaceCard() {
  const params = useParams<{
    raceId: string;
  }>();

  return (
    <RaceCardProvider raceId={params.raceId!}>
      <RaceCardConsumer />
    </RaceCardProvider>
  );
}
