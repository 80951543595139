/* eslint-disable react/no-array-index-key */
import { lowerCase } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Carousel,
  CategoryListItem,
  CategorySeperator,
  HeadToHeadCardPlaceholderPopular,
  UnableToLoadErrorPage,
} from '../../../../components';
import H2HCard from '../../../../components/H2HCard/H2HCard';
import { IconName } from '../../../../components/Icon/Icon';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { SportTypesQuery } from '../../../../generated/graphql';
import usePopularSportEvents from '../../../../hooks/useSportsEvents/usePopularSportsEvents';
import {
  selectPopularSportCategoryIds,
  selectPopularSports,
  selectSportTypes,
  selectSportTypesError,
  selectSportTypesLoading,
} from '../../../../stores/selectors/sportsSelector';
import styles from './AllSports.module.css';

type SportTypeCategory = SportTypesQuery['sportTypes'][0];

export default function AllSports() {
  const sportTypes = useSelector(selectSportTypes);
  const sportTypesLoading = useSelector(selectSportTypesLoading);
  const sportTypesError = useSelector(selectSportTypesError);

  const popularSports = useSelector(selectPopularSports);
  const categoryIds = useSelector(selectPopularSportCategoryIds);

  const { events, fetching } = usePopularSportEvents({
    categoryIds,
  });

  const renderLoading = () => (
    <>
      {[...Array(6)].map((_, i) => (
        <Carousel.CarouselItem key={i}>
          <HeadToHeadCardPlaceholderPopular />
        </Carousel.CarouselItem>
      ))}
    </>
  );

  const renderCategoryItem =
    ({ isFavourite = false, showFavourite = false }) =>
    (sport?: SportTypeCategory) => {
      if (!sport) return null;
      return (
        <CategoryListItem
          key={sport.id}
          label={sport.description}
          icon={sport.name as IconName}
          sportType={sport.name}
          path={`/sports/sport/${lowerCase(sport.name)}/all`}
          isFavourite={isFavourite}
          showFavourite={showFavourite}
        />
      );
    };

  if (!sportTypesLoading && sportTypesError) {
    return <UnableToLoadErrorPage />;
  }

  return (
    <div className={styles.container}>
      {fetching && (
        <>
          <CategorySeperator label="Popular" />
          <Carousel>{renderLoading()}</Carousel>
        </>
      )}
      {events.length > 0 && (
        <>
          <CategorySeperator label="Popular" />
          <Carousel>
            {events.map((sportEvent) => {
              return (
                <Carousel.CarouselItem key={sportEvent.id}>
                  <H2HCard sportEvent={sportEvent} type="h2h" />
                </Carousel.CarouselItem>
              );
            })}
          </Carousel>
        </>
      )}
      {sportTypesLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <CategorySeperator label="My Favourites" />
          {sportTypes
            .slice(0, 2)
            .map(renderCategoryItem({ isFavourite: true }))}
          <CategorySeperator label="Popular" />
          {popularSports &&
            popularSports.map(renderCategoryItem({ isFavourite: false }))}
          <CategorySeperator label="All Sports" />
          {sportTypes.map(renderCategoryItem({ showFavourite: true }))}
        </>
      )}
    </div>
  );
}
