import clsx from 'clsx';
import React from 'react';
import alphabetMapping from '../../../utils/phonetic_alphabet';
import Icon from '../../Icon/Icon';
import styles from './Selection.module.css';

type SelectionProps = {
  code: string;
};

const Selection = ({ code }: SelectionProps) => (
  <div className={styles.selectionContainer}>
    <div className={styles.selection}>
      <div className={styles.icon}>
        <Icon name="basketball" />
      </div>
      <div className={styles.teamInfo}>
        <div className={styles.oddsContainer}>
          <span>Miami Heat</span>
          <span>
            Odds <strong>2.60</strong>
          </span>
        </div>
        <span>Head To Head</span>
        <span>Golden State Warriors @ Miami Heat</span>
      </div>
    </div>
    <div className={clsx(styles.centerAlign, styles.codesContainer)}>
      <div>Fast Code</div>
      <div className={styles.code}>{code}</div>
      <div>
        {code
          .split('')
          .map((c) => alphabetMapping[c])
          .join(', ')}
      </div>
    </div>
  </div>
);
export default Selection;
