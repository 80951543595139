import { createContext } from 'react';

export type Breadcrumb = {
  label: string;
  url: string;
};

export const INITIAL_BREADCRUMBS = [];

const BreadcrumbsContext = createContext<{
  breadcrumbs: Breadcrumb[];
  push: (breadcrumbs: Breadcrumb[]) => void;
  pop: (count: number) => void;
  reset: () => void;
}>({
  breadcrumbs: [],
  push: () => {},
  pop: () => {},
  reset: () => {},
});

export default BreadcrumbsContext;
