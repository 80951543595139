import { CompetitorSex, MeetingType } from '../../../../generated/graphql';
import styles from './RunnerInfo.module.css';

type CompetitorInfo = {
  sex?: CompetitorSex;
  dam?: string;
  colour?: string;
  sire?: string;
  age?: number;
  owners?: string;
};

export default function RunnerInfo({
  meetingType,
  competitor,
}: {
  meetingType?: MeetingType;
  competitor: CompetitorInfo;
}) {
  switch (meetingType) {
    case MeetingType.Thoroughbred:
      return <Horse competitor={competitor} />;
    case MeetingType.Harness:
      return <Harness competitor={competitor} />;
    case MeetingType.Greyhound:
      return <Greyhound competitor={competitor} />;
    default:
      return null;
  }
}

function Horse({ competitor }: { competitor: CompetitorInfo }) {
  return (
    <div className={styles.container}>
      <div className={styles.runnerContainer}>
        <span className={styles.long}>
          <span className={styles.label}>Sex: </span>
          {competitor.sex}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Dam: </span>
          {competitor.dam}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Color: </span>
          {competitor.colour}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Sire: </span>
          {competitor.sire}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Age: </span>
          {competitor.age}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Owners: </span>
          {competitor.owners}
        </span>
      </div>
    </div>
  );
}

function Harness({ competitor }: { competitor: CompetitorInfo }) {
  return (
    <div className={styles.container}>
      <div className={styles.runnerContainer}>
        <span className={styles.long}>
          <span className={styles.label}>Sex: </span>
          {competitor.sex}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Dam: </span>
          {competitor.dam}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Color: </span>
          {competitor.colour}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Sire: </span>
          {competitor.sire}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>A: </span>
          {competitor.age}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Equip: </span>-
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Owners: </span>
          {competitor.owners}
        </span>
      </div>
    </div>
  );
}

function Greyhound({ competitor }: { competitor: CompetitorInfo }) {
  return (
    <div className={styles.container}>
      <div className={styles.runnerContainer}>
        <span className={styles.long}>
          <span className={styles.label}>Sex: </span>
          {competitor.sex}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Dam: </span>
          {competitor.dam}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Color: </span>
          {competitor.colour}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Sire: </span>
          {competitor.sire}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>A: </span>
          {competitor.age}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Owners: </span>
          {competitor.owners}
        </span>
      </div>
    </div>
  );
}
