import { find, lowerCase } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import {
  SportTypesDocument,
  SportTypesQuery,
  SportTypesQueryVariables,
} from '../../generated/graphql';

export default function useSportName(sportType: string) {
  const [{ data }] = useQuery<SportTypesQuery, SportTypesQueryVariables>({
    query: SportTypesDocument,
  });

  const sport = useMemo(
    () =>
      find(
        data?.sportTypes,
        (l) => lowerCase(l?.name) === lowerCase(sportType)
      ),
    [data?.sportTypes, sportType]
  );

  return {
    name: sport?.name || '',
    id: sport?.id,
    description: sport?.description || '',
  };
}
