import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CurrencyInput from '../../../components/Form/CurrencyInput/CurrencyInput';
import Icon, { IconName } from '../../../components/Icon/Icon';
import { BetslipStage } from '../../../constants/betStatus';
import useFormat from '../../../hooks/useFormat';
import {
  addOrRemoveSportsBet,
  updateBetStake,
} from '../../../stores/betslipSlice';
import { SportSingleBet } from '../../../utils/betslip/types';
import BetStatusBar from '../BetCards/BetStatusBar';
import styles from './SingleSportBetCard.module.css';

const BETTING_OPEN_STATUSES = ['Trading', 'Open'];
const isBettingOpen = (selection: SportSingleBet['selection']) =>
  BETTING_OPEN_STATUSES.includes(selection.status);

type SingleSportBetCardProp = { bet: SportSingleBet };

export default function SingleSportBetCard({ bet }: SingleSportBetCardProp) {
  const [betslipStage] = useState<BetslipStage>(BetslipStage.NORMAL);
  const { selection, stake, eventDetails, marketDetails } = bet;
  const format = useFormat();
  const dispatch = useDispatch();
  const bettingOpen = isBettingOpen(selection);

  function getBettingClosedReason() {
    return selection.status;
  }

  function handleRemoveBet() {
    dispatch(addOrRemoveSportsBet(bet));
  }

  function handleStakeChange(newPrice: number) {
    updateBetStake({ bet, newPrice });
  }

  const potentialReturn = (bet?.odds || 0) * (stake || 0);
  return (
    <>
      <div className={styles.betItem}>
        <div className={styles.icons}>
          <Icon
            name={
              bet.eventDetails?.competition.category.sport_type.name as IconName
            }
            className={styles.icon}
            size="medium"
          />
          <div className={styles.bottomIcons}>
            <Icon name="samegamemulti" className={styles.icon} size="medium" />
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.header}>
            <span className={styles.title}>{bet.selection.name}</span>
            {betslipStage !== BetslipStage.CONFIRM && (
              <>
                {bettingOpen && (
                  <span className={styles.stake}>{format.odds(bet.odds)}</span>
                )}
                {!bettingOpen && (
                  <span className={styles.headerMessage}>
                    {getBettingClosedReason()}
                  </span>
                )}
                <div>
                  <button
                    type="button"
                    className={styles.removeButton}
                    onClick={handleRemoveBet}
                  >
                    <Icon name="cross" size="small" />
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={styles.selectionsContainer}>
            <div className={styles.selections}>{eventDetails?.name}</div>
            <div className={styles.marketName}>{marketDetails.name}</div>
            <div className={styles.inputContainer}>
              <CurrencyInput
                containerClassName={styles.input}
                onChange={handleStakeChange}
                value={bet.stake}
                onBlur={(newPrice) => {
                  dispatch(updateBetStake({ bet, newPrice }));
                }}
              />
            </div>
          </div>
          <div className={styles.return}>
            {betslipStage === BetslipStage.CONFIRM && (
              <>
                <div>
                  Fixed <span>{format.odds(bet.odds)}</span>
                </div>
                <div>
                  Cost: <span>{format.currency(bet.stake, 2)}</span>
                </div>
              </>
            )}
            {bettingOpen && (
              <div>
                Potential Return:{' '}
                <span>{format.currency(potentialReturn, 2)}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <BetStatusBar
        price={bet.odds}
        stake={bet.stake}
        betslipStage={betslipStage}
      />
    </>
  );
}
