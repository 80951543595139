import ContentLoader from 'react-content-loader';

export default function RunnerDetailShimmer() {
  return (
    <ContentLoader viewBox="0 0 150 24">
      <rect x="24" y="6" rx="3" ry="3" width="52" height="3" />
      <rect x="24" y="12" rx="3" ry="3" width="64" height="3" />
      <circle cx="10" cy="10" r="10" />
    </ContentLoader>
  );
}
