import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { BetslipType } from '../types/betslipType';
import { LocaleType } from '../types/localeType';
import { OddsDisplay } from '../types/oddsDisplay';
import { SilkType } from '../types/silk';

export interface ConfigOptions {
  locale: LocaleType;
  currency: string;
  measurement: 'IMPERIAL' | 'METRIC';
  localCountryFilter?: {
    label: string;
    countries: string[];
  };
  oddsType: OddsDisplay;
  silkType: SilkType;
  betslipType: BetslipType;
  scratchedRunnersToBottomEnabled: boolean;
  sortUpcomingByCodeEnabled: boolean;
  toteEnabled: boolean;
  fixedEnabled: boolean;
  foxTipsEnabled: boolean;
}

export interface ConfigState {
  sessionStartTime: string;
  contentUrl: string;
  platformApiUrl: string;
  apiUrl: string;
  cmsUrl: string;
  client: string;
  iSportgeniusBaseUrl: string;
  socketApiUrl: string;
  coreApiSource: string;
  coreApiToteSource: string;
  getHeaders: () => Promise<any>;
  getUserJwt?: () => Promise<string>;
  onBetFailure?: (response: string) => void;
  options: ConfigOptions;
}

const initialState: ConfigState = {
  sessionStartTime: dayjs().toISOString(),
  contentUrl: process.env.REACT_APP_CONTENT_URL, // Silks / public images
  platformApiUrl: 'https://api.benchmark.ext.thebetmakers.com/api',
  apiUrl: 'https://graphql.coreapi.gcpuatau.tbm.sh/query',
  socketApiUrl: 'wss://graphql.coreapi.gcpuatau.tbm.sh/query',
  cmsUrl: '',
  coreApiSource: 'INT',
  coreApiToteSource: 'ST4',
  client: '',
  iSportgeniusBaseUrl: 'https://ft-demo.isportgenius.com.au',
  getHeaders: async () => {}, // Core API headers
  getUserJwt: async () => '', // Bet director JWT
  onBetFailure: async () => {},
  options: {
    locale: LocaleType.AU,
    currency: 'AUD',
    measurement: 'METRIC',
    localCountryFilter: {
      label: 'Aus/NZ',
      countries: ['AUS', 'NZL'],
    },
    oddsType: OddsDisplay.Decimal,
    silkType: SilkType.Silk,
    betslipType: BetslipType.Standard,
    scratchedRunnersToBottomEnabled: true,
    sortUpcomingByCodeEnabled: true,
    toteEnabled: false,
    fixedEnabled: true,
    foxTipsEnabled: false,
  },
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<ConfigState>) => ({
      ...state,
      ...action.payload,
      options: {
        ...state.options,
        ...(action.payload?.options || {}),
      },
      sessionStartTime: dayjs().toISOString(),
    }),
    toggleLocale: (state) => {
      const isAuLocale = state.options.locale === LocaleType.AU;

      if (isAuLocale) {
        state.options = {
          ...state.options,
          locale: LocaleType.US,
          currency: 'USD',
          localCountryFilter: {
            label: 'USA',
            countries: ['USA'],
          },
          oddsType: OddsDisplay.Fractional,
          betslipType: BetslipType.American,
          silkType: SilkType.Both,
          foxTipsEnabled: true,
        };
      } else {
        state.options = {
          ...state.options,
          locale: LocaleType.AU,
          currency: 'AUD',
          localCountryFilter: {
            label: 'Aus/NZ',
            countries: ['AUS', 'NZ'],
          },
          oddsType: OddsDisplay.Decimal,
          betslipType: BetslipType.Standard,
          silkType: SilkType.Silk,
          foxTipsEnabled: false,
        };
      }
    },
    toggleToteEnabled: (state) => {
      state.options.toteEnabled = !state.options.toteEnabled;
    },
    toggleFixedEnabled: (state) => {
      state.options.fixedEnabled = !state.options.fixedEnabled;
    },
  },
});

export const {
  setConfig,
  toggleLocale,
  toggleToteEnabled,
  toggleFixedEnabled,
} = configSlice.actions;
export default configSlice.reducer;
