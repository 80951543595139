import React from 'react';
import styles from './Tennis.module.css';

type TennisProps = {};

const Tennis = (props: TennisProps) => (
  <div className={styles.container}>
    <div className={styles.scoring}>
      <div className={styles.scoreCell}>Set</div>
      <div className={styles.scoreCell}>Games</div>
      <div className={styles.scoreCell}>Points</div>
    </div>
    <div className={styles.player}>
      <div className={styles.playerName}>Rafael Nadal</div>
      <div className={styles.score}>
        <div className={styles.scoreCell}>2</div>
        <div className={styles.scoreCell}>3</div>
        <div className={styles.scoreCell}>4</div>
      </div>
    </div>
    <div className={styles.player}>
      <div className={styles.playerName}>Roger Federer</div>
      <div className={styles.score}>
        <div className={styles.scoreCell}>4</div>
        <div className={styles.scoreCell}>9</div>
        <div className={styles.scoreCell}>11</div>
      </div>
    </div>
  </div>
);
export default Tennis;
