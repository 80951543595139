import React from 'react';
import Icon from '../Icon/Icon';
import styles from './FavouriteButton.module.css';

interface FavouriteButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isFavourite?: boolean;
}

const FavouriteButton = ({ isFavourite, ...rest }: FavouriteButtonProps) => (
  <button className={styles.container} type="button" {...rest}>
    <Icon name={isFavourite ? 'favouriteFilled' : 'favourite'} />
  </button>
);

export default FavouriteButton;
