import { useQuery } from 'urql';
import {
  SportMarketsDetailsDocument,
  SportMarketsDetailsQuery,
  SportMarketsDetailsQueryVariables,
} from '../../generated/graphql';

const useSportMarketsDetails = (
  params: SportMarketsDetailsQueryVariables & { pause: boolean }
) => {
  const [{ data, ...rest }] = useQuery<
    SportMarketsDetailsQuery,
    SportMarketsDetailsQueryVariables
  >({
    query: SportMarketsDetailsDocument,
    pause: params.pause,
    variables: {
      marketId: params.marketId,
    },
  });

  return {
    data,
    ...rest,
  };
};

export default useSportMarketsDetails;
