import { useCallback, useState } from 'react';
import {
  HeadToHeadCardPlaceholder,
  SportsFilter,
  UnableToLoadErrorPage,
} from '../../../../components';
import H2HCard from '../../../../components/H2HCard/H2HCard';
import useUpcomingSportsEvents from '../../../../hooks/useSportsEvents/useUpcomingSportsEvents';
import styles from './UpcomingSports.module.css';

const NUMBER_OF_VISIBLE_HEAD2HEAD = 15;
const POPULAR_MARKET_TYPES: string[] = ['c3BvcnRtYXJrZXR0eXBlOjMxOQ=='];
export default function UpcomingSports() {
  const [selectedFilter, setFilter] = useState<number[]>([]);

  const { events, fetching, error } = useUpcomingSportsEvents({
    selectedSportTypes: selectedFilter,
    marketTypeIDs: POPULAR_MARKET_TYPES,
  });

  const onToggle = useCallback((val: any) => setFilter(val), []);

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <SportsFilter onToggle={onToggle} />
      </div>
      <div className={styles.head2headContainer}>
        {fetching && (
          <>
            {[1, 2, 3].map((i) => (
              <HeadToHeadCardPlaceholder key={i} />
            ))}
          </>
        )}
        {!fetching && error?.message && <UnableToLoadErrorPage />}
        {!fetching &&
          events
            .slice(0, NUMBER_OF_VISIBLE_HEAD2HEAD)
            .map((event) => (
              <H2HCard
                key={event.id}
                containerClass={styles.card}
                sportEvent={event}
                type="h2h"
              />
            ))}
        {!fetching && !events.length && (
          <div className={styles.noEvents}>No upcoming events</div>
        )}
      </div>
    </div>
  );
}
