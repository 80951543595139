import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import { HEAD_TO_HEAD_MARKET_TYPE } from '../../constants/marketTypes';
import {
  SportCategoriesWithEventsQuery,
  SportCategoriesWithEventsQueryVariables,
} from '../../generated/graphql';
import { TopSportMarkets } from '../../utils/sport/transformers/SportEvents';
import { SportCategoriesWithEventsDocument } from './../../generated/graphql';
import { ExtendedSportEvents } from './../../types/sport';

const today = dayjs().startOf('day');

/* TODO change to 1 day */
const endDate = today.add(2, 'days').endOf('day');

const POPULAR_MARKET_TYPES_IDS: string[] = Object.entries(
  HEAD_TO_HEAD_MARKET_TYPE
).map((h2h) => h2h[1].id);

export default function usePopularSportEvents({
  marketTypeIDs = POPULAR_MARKET_TYPES_IDS,
  categoryIds,
}: SportCategoriesWithEventsQueryVariables) {
  const [{ data, ...rest }] = useQuery<
    SportCategoriesWithEventsQuery,
    SportCategoriesWithEventsQueryVariables
  >({
    query: SportCategoriesWithEventsDocument,
    pause: isEmpty(categoryIds),
    variables: {
      startDate: today.toDate(),
      endDate: endDate.toDate(),
      // marketTypeIDs, // TODO This needs to be updated to use market names
      categoryIds,
    },
  });

  const allEvents = useMemo(() => {
    const compWithEvents =
      data?.sportCategories
        ?.flatMap((e) => e.competitions)
        .flatMap((c) => c?.events || [])
        .filter((c) => c) || [];

    if (compWithEvents) {
      return TopSportMarkets(compWithEvents as ExtendedSportEvents[]);
    }
    return [];
  }, [data]);

  return {
    events: allEvents,
    ...rest,
  };
}
