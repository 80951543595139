import clsx from 'clsx';
import Icon from '../../../../components/Icon/Icon';
import TimeAndDistanceDisplayItem from './TimeAndDistanceDisplayItem';
import styles from './TimesAndDistance.module.css';

export default function TimesAndDistance() {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Race times and distance from leader</div>
        <div className={styles.fands}>
          <Icon name="ellipse" className={clsx(styles.ellipse, styles.fast)} />{' '}
          Fast{' '}
          <Icon name="ellipse" className={clsx(styles.ellipse, styles.slow)} />
          Slow
        </div>
      </div>
      <div className={styles.displayContainer}>
        <TimeAndDistanceDisplayItem
          fraction="1/4"
          position="2nd"
          time="22.1s"
          margin="3L off lead"
        />
        <TimeAndDistanceDisplayItem
          fraction="1/2"
          position="7th"
          time="22.1s"
          margin="3.5 off lead"
        />
        <TimeAndDistanceDisplayItem
          fraction="1/2"
          position="3rd"
          time="22.1s"
          margin="3L off lead"
        />
        <TimeAndDistanceDisplayItem
          fraction="Finish"
          position="1st"
          time="22.1s"
          margin="Leader"
        />
      </div>
      <div className={styles.gradientBar} />
    </div>
  );
}
