import clsx from 'clsx';
import { find, lowerCase } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SportBetSlipProvider } from '../../hooks/useSportsBetslip/useSportsBetslip';
import { selectSportTypes } from '../../stores/selectors/sportsSelector';
import { ExtendedSportEvents } from '../../types/sport';
import slugify from '../../utils/slugify';
import DividerHorizental from '../Dividers/DividerHorizonal';
import styles from './H2HCard.module.css';
import HeadToHeadMarket from './HeadToHeadMarket/HeadToHeadMarket';
import MultipleMarket from './MultipleMarket/MultipleMarket';
import Header from './SportsRowHeader/SportsRowHeader';

export type H2HCardProps = {
  sportEvent: ExtendedSportEvents;
  containerClass?: string;
  type?: 'h2h';
  showLeagueName?: boolean;
};

const H2HCard = ({
  sportEvent,
  containerClass,
  type,
  showLeagueName,
}: H2HCardProps) => {
  const countOfMarkets = sportEvent.markets?.length || 0;
  const navigate = useNavigate();

  const navigateToEvent = () => {
    const sportName = lowerCase(
      sportEvent.competition.category.sport_type.name
    ).replace(' ', '');
    const competition = slugify(sportEvent.competition.category.name);
    navigate(`/sports/event/${sportName}/${competition}/${sportEvent.id}/all`);
  };

  const renderMarkets = () => {
    if (type === 'h2h') {
      return (
        <HeadToHeadMarket
          sportEvent={sportEvent}
          navigateToEvent={navigateToEvent}
        />
      );
    }
    // TODO revisit logic
    if (sportEvent.matchMarket) {
      return (
        <MultipleMarket
          sportEvent={sportEvent}
          navigateToEvent={navigateToEvent}
        />
      );
    }
  };

  const sportTypes = useSelector(selectSportTypes);

  const category = useMemo(() => {
    if (showLeagueName) return sportEvent.competition.name;
    const sportType = lowerCase(
      sportEvent?.competition?.category.sport_type.name
    ).replace(' ', '');
    return find(sportTypes, { name: sportType })?.description || 'n/a';
  }, [showLeagueName, sportEvent, sportTypes]);

  return (
    <SportBetSlipProvider>
      <div className={clsx(styles.container, containerClass)}>
        <Header
          countOfMarkets={countOfMarkets}
          startDate={sportEvent.start_date}
          category={category}
        />
        <DividerHorizental />
        {renderMarkets()}
      </div>
    </SportBetSlipProvider>
  );
};

export default H2HCard;
