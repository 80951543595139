import { useSelector } from 'react-redux';
import { RaceCardQuery } from '../../../../generated/graphql';
import { RootState } from '../../../../stores/store';
import RunnerInfo from '../RunnerInfo/RunnerInfo';
import ShortForm from '../ShortForm/ShortForm';
import useRaceCard from '../useRaceCard';
import styles from './Fields.module.css';

type Props = {
  competitor: NonNullable<RaceCardQuery['race']>['competitors'][0];
  index: number;
};

export default function CompetitorFooter({ competitor, index }: Props) {
  const { race, competitors, showShortFormForRunner } = useRaceCard();
  const settings = useSelector((state: RootState) => state.setting);

  return (
    <>
      {settings.showRunnerInfo ? (
        <div className={styles.sectionRow}>
          <RunnerInfo
            competitor={competitor}
            meetingType={race?.meeting?.type}
          />
        </div>
      ) : null}
      {settings.showShortForm ||
      showShortFormForRunner?.[competitor?.runner?.id] ? (
        <div className={styles.sectionRow}>
          <ShortForm competitor={competitor} race={race!} />
        </div>
      ) : null}
      {index < competitors?.length - 1 ? (
        <div className={styles.separator} />
      ) : null}
    </>
  );
}
