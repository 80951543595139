import Button from '../../../components/Button/Button';
import styles from './QuickStakeButtons.module.css';

export default function QuickStakeButtons({
  onAddStake,
}: {
  onAddStake: (value: number) => void;
}) {
  const quickStakeButtons = [0.5, 1, 5, 10, 25, 50, 100, 500];

  return (
    <div className={styles.container}>
      {quickStakeButtons.map((button) => (
        <Button
          key={button}
          type="button"
          theme="secondary"
          className={styles.button}
          onClick={() => onAddStake(button)}
        >
          +{button}
        </Button>
      ))}
    </div>
  );
}
