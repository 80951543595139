import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OddsButton from '../../components/OddsButton/OddsButton';
import LineShimmer from '../../components/Shimmers/LineShimmer';
import TwoBoxShimmer from '../../components/Shimmers/TwoBoxShimmer';
import { BetType } from '../../constants/betTypes';
import { GetFeaturedRaceQuery, PriceType } from '../../generated/graphql';
import { addOrRemoveBet } from '../../stores/betslipSlice';
import { RootState } from '../../stores/store';
import { BetslipType } from '../../types/betslipType';
import { hasSingleBet } from '../../utils/betslip/common';
import RunnerDetailsCompact from '../Racebook/RaceCard/RunnerDetails/RunnerDetailCompact';
import styles from './UpcomingTip.module.css';

type Props = {
  race?: GetFeaturedRaceQuery['race'];
  tabNo: string;
  resulted: boolean;
};

const selector = createSelector(
  [
    (state: RootState) => state.config.options.betslipType,
    (state: RootState) => state.betslip.betslip,
  ],
  (betslipType, betslip) => ({
    betslipType,
    betslip,
  })
);

export default function TipRunner({ race, tabNo, resulted }: Props) {
  const dispatch = useDispatch();
  const { betslip, betslipType } = useSelector(selector);

  const competitor = useMemo(
    () => race?.competitors.find((c) => c.tabNo === +tabNo),
    [race, tabNo]
  );

  const getWinPrice = useCallback(
    () =>
      competitor?.prices?.find((price) => price.type === PriceType.WinFixedOdds)
        ?.price,
    [competitor]
  );
  const getPlacePrice = useCallback(
    () =>
      competitor?.prices?.find(
        (price) => price.type === PriceType.PlaceFixedOdds2
      )?.price,
    [competitor]
  );

  if (!race || !competitor) {
    return (
      <>
        <LineShimmer />
        <div>
          <TwoBoxShimmer />
        </div>
      </>
    );
  }

  return (
    <div className={styles.priceContainer}>
      <div className={styles.runnerDetails}>
        <RunnerDetailsCompact
          meeting={race.meeting}
          competitor={competitor}
          position={
            race.results[race.results.length - 1]?.positions.find(
              (pos) => pos.tabNo === competitor.tabNo
            )?.position
          }
        />
      </div>

      <div className={styles.prices}>
        <OddsButton
          price={getWinPrice()}
          onClick={() => {
            if (race) {
              dispatch(
                addOrRemoveBet({
                  betType: BetType.WIN_FIXED_ODDS,
                  traditional: betslipType === BetslipType.American,
                  odds: getWinPrice(),
                  race,
                  meeting: race?.meeting,
                  competitor,
                })
              );
            }
          }}
          selected={
            !!race &&
            hasSingleBet(betslip, {
              betType: BetType.WIN_FIXED_ODDS,
              race,
              meeting: race?.meeting,
              competitor,
            })
          }
          disabled={competitor.scratched || resulted}
          priceTypeText="Win"
        />
        <OddsButton
          price={getPlacePrice()}
          priceTypeText="Place"
          disabled={competitor.scratched || resulted}
          className={styles.placeButton}
          onClick={() => {
            if (race) {
              dispatch(
                addOrRemoveBet({
                  betType: BetType.PLACE_FIXED_ODDS2,
                  traditional: betslipType === BetslipType.American,
                  odds: getPlacePrice(),
                  race,
                  meeting: race?.meeting,
                  competitor,
                })
              );
            }
          }}
          selected={
            !!race &&
            hasSingleBet(betslip, {
              betType: BetType.PLACE_FIXED_ODDS2,
              race,
              meeting: race?.meeting,
              competitor,
            })
          }
        />
      </div>
    </div>
  );
}
