import clsx from 'clsx';
import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './HomeContainer.module.css';

export type TabsPlaceholderProps = {
  className?: string;
};

const TabsPlaceholder = ({ className }: TabsPlaceholderProps) => (
  <div className={clsx(styles.tabsPlaceholder, className)}>
    <ContentLoader viewBox="0 0 100 100">
      <rect x="0" y="2" width="20" height="2" />
      <rect x="21" y="2" width="20" height="2" />
      <rect x="42" y="2" width="20" height="2" />
      <rect x="63" y="2" width="20" height="2" />
      <rect x="84" y="2" width="20" height="2" />
    </ContentLoader>
  </div>
);

export default TabsPlaceholder;
