import { useContext, useEffect, useState } from 'react';
import { SportsFilterContext } from '../../hooks/useSportFilters/useSportFilters';
import HorizontalOverflow from '../HorizontalOverflow/HorizontalOverflow';
import ToggleButton from '../ToggleButton/ToggleButton';
import styles from './SportsFilter.module.css';

export type Filter = { label: string; value: number };
type PropsType = {
  defaultFilter?: number;
  onToggle: (filter?: number[]) => void;
};

export default function SportsFilter({
  defaultFilter = -1,
  onToggle,
}: PropsType) {
  const { filters } = useContext(SportsFilterContext);

  const [selectedFilters, setSelectedFilters] = useState([defaultFilter]);

  useEffect(() => {
    onToggle(selectedFilters.includes(-1) ? [-1] : selectedFilters);
  }, [selectedFilters, onToggle]);

  const fliterPressed = (filter: number) => {
    if (filter === -1) {
      setSelectedFilters([-1]);
    } else if (selectedFilters.includes(-1)) {
      setSelectedFilters([filter]);
    } else if (selectedFilters.includes(filter)) {
      setSelectedFilters((prev) =>
        prev.length === 1 ? [-1] : prev.filter((f) => f !== filter)
      );
    } else {
      setSelectedFilters((prev) => [...prev, filter]);
    }
  };

  return (
    <HorizontalOverflow showArrows roundedCorner scrollWidth={82}>
      <section className={styles.container}>
        <div className={styles.filterContainer}>
          {[{ value: -1, label: 'All' }, ...filters].map((option) => (
            <ToggleButton
              key={option.value}
              isChecked={selectedFilters.includes(option.value)}
              onChange={() => fliterPressed(option.value)}
              label={option.label}
            />
          ))}
        </div>
      </section>
    </HorizontalOverflow>
  );
}
