import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useQuery, useSubscription } from 'urql';
import CountryFilters from '../../../../components/CountryFilters/CountryFilters';
import Icon from '../../../../components/Icon/Icon';
import RacingFilters from '../../../../components/RacingFilters/RacingFilters';
import {
  FilterKey,
  RacesUpcomingDocument,
  RacesUpcomingQuery,
  RacesUpcomingQueryVariables,
  RaceUpdatedDocument,
  RaceUpdatedSubscription,
  RaceUpdatedSubscriptionVariables,
} from '../../../../generated/graphql';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs/useBreadcrumbs';
import { CountryFilter } from '../../../../stores/settingSlice';
import { RootState } from '../../../../stores/store';
import RaceUpcoming from './RaceUpcoming';
import styles from './RacingUpcoming.module.css';

const selector = createSelector(
  [
    (state: RootState) => state.config.options.localCountryFilter,
    (state: RootState) => state.setting.typeFilters,
    (state: RootState) => state.setting.countryFilter,
    (state: RootState) => state.config.options.toteEnabled,
    (state: RootState) => state.config.options.fixedEnabled,
  ],
  (
    localCountryFilter,
    typeFilters,
    countryFilter,
    toteEnabled,
    fixedEnabled
  ) => ({
    localCountryFilter,
    typeFilters,
    countryFilter,
    toteEnabled,
    fixedEnabled,
  })
);

export default function RacingUpcoming() {
  const route = useMemo(
    () => [
      {
        label: 'Racing Home',
        url: '/',
      },
      {
        label: 'Upcoming Racing',
        url: '/racing/upcoming',
      },
    ],
    []
  );
  useBreadcrumbs(route);

  const {
    localCountryFilter,
    countryFilter,
    typeFilters,
    fixedEnabled,
    toteEnabled,
  } = useSelector(selector);
  const [time, setTime] = useState(dayjs());
  const [races, setRaces] = useState<RacesUpcomingQuery['races']>([]);
  const [results] = useQuery<RacesUpcomingQuery, RacesUpcomingQueryVariables>({
    query: RacesUpcomingDocument,
    variables: {
      fixedEnabled,
      toteEnabled,
      from: time.toDate(),
      to: time.add(24, 'hours').toDate(),
      limit: 20,
      include: [
        ...typeFilters.map((type) => ({
          key: FilterKey.MeetingType,
          value: type,
        })),
        ...(localCountryFilter && countryFilter === CountryFilter.Local
          ? localCountryFilter.countries.map((country) => ({
              key: FilterKey.Country,
              value: country,
            }))
          : []),
      ],
      exclude:
        localCountryFilter && countryFilter === CountryFilter.International
          ? localCountryFilter.countries.map((country) => ({
              key: FilterKey.Country,
              value: country,
            }))
          : [],
    },
  });
  useEffect(() => {
    if (results.data) {
      setRaces(results.data.races);
    }
  }, [results]);

  const [subscription] = useSubscription<
    RaceUpdatedSubscriptionVariables,
    RaceUpdatedSubscription
  >({
    query: RaceUpdatedDocument,
  });
  useEffect(() => {
    if (subscription.data) {
      setRaces((prev) => {
        if (
          prev.some((race) => race.id === subscription.data?.raceUpdated.id)
        ) {
          return prev.map((race) => {
            if (race.id === subscription.data?.raceUpdated.id) {
              return {
                ...race,
                ...(subscription?.data?.raceUpdated || {}),
              };
            }

            return race;
          });
        }

        return prev;
      });
    }
  }, [subscription]);

  // When race status changes, we'll check the first race to see if its after the current time before refetching
  useEffect(() => {
    if (dayjs().isAfter(dayjs(races?.[0]?.startTime))) {
      setTime(dayjs());
    }
  }, [races]);

  return (
    <>
      <Helmet title="Upcoming">
        <meta name="description" content="Upcoming Racing" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.filters}>
            <RacingFilters />
            <CountryFilters />
          </div>
        </div>

        {results.fetching ? (
          <Icon name="loading" />
        ) : (
          <RaceUpcoming races={races} />
        )}
      </div>
    </>
  );
}
