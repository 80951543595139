/* eslint-disable react/no-array-index-key */
import { groupBy, keys } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Filters,
  HeadToHeadCardPlaceholder,
  SectionSeperator,
  UnableToLoadErrorPage,
} from '../../../../components';
import H2HCard from '../../../../components/H2HCard/H2HCard';
import { IconName } from '../../../../components/Icon/Icon';
import usePopularSportEvents from '../../../../hooks/useSportsEvents/usePopularSportsEvents';
import { selectPopularSportCategoryIds } from '../../../../stores/selectors/sportsSelector';
import styles from './PopularSports.module.css';

export default function PopularSports() {
  const [selectedSportTypes, setSelectedSportTypes] = useState<string[]>([]);

  const onToggle = useCallback((filter?: string[]) => {
    if (filter) {
      setSelectedSportTypes(filter.map((f) => f));
    }
  }, []);

  const categoryIds = useSelector(selectPopularSportCategoryIds);

  const { events, fetching, error } = usePopularSportEvents({
    categoryIds,
  });

  const filters = useMemo(() => {
    const sportTypes = events.reduce((prev, curr) => {
      // TODO = curr.competition.category.sport_type.name should be changed to .description
      // eslint-disable-next-line no-param-reassign
      prev[curr.competition.category.sport_type.name] =
        curr.competition.category.sport_type.name;
      return prev;
    }, {});

    return keys(sportTypes).map((k) => ({
      label: sportTypes[k],
      id: k,
    }));
  }, [events]);

  const filteredEvents = useMemo(
    () =>
      events.filter((e) =>
        selectedSportTypes.includes('all')
          ? true
          : selectedSportTypes.includes(e.competition.category.sport_type.name)
      ),
    [events, selectedSportTypes]
  );

  /**
   *  TODO Requires API changes atm competition.category.sport_type.description returns empty string
   */
  const grouped = groupBy(
    filteredEvents,
    'competition.category.sport_type.name'
  );

  return (
    <div className={styles.container}>
      {filters.length > 0 && (
        <div className={styles.filters}>
          <Filters onToggle={onToggle} filters={filters} />
        </div>
      )}
      {!fetching && error?.message && <UnableToLoadErrorPage />}
      <div className={styles.head2headContainer}>
        {!fetching && categoryIds.length > 0 && !filteredEvents.length && (
          <div className={styles.noEvents}>No popular events</div>
        )}
        {fetching && (
          <>
            {[1, 2, 3, 4].map((a) => (
              <HeadToHeadCardPlaceholder key={a} />
            ))}
          </>
        )}
        {keys(grouped).map((competition) => {
          const sport =
            grouped[competition][0].competition.category.sport_type.name;
          const sportEvents = grouped[competition];
          return (
            <React.Fragment key={competition}>
              <SectionSeperator
                label={competition}
                icon={sport as IconName}
                path={`/sports/sport/${sport.toLowerCase()}/all`}
              />
              {sportEvents.map((event) => {
                return (
                  <H2HCard
                    key={event.id}
                    containerClass={styles.card}
                    sportEvent={event}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
