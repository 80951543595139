import clsx from 'clsx';
import React from 'react';
import useSportsBetslip from '../../../hooks/useSportsBetslip/useSportsBetslip';
import {
  ExtendedSportEvents,
  MarketSelection,
  SportMarket,
} from '../../../types/sport';
import SportOddsButton from '../../SportOddsButton/SportOddsButton';
import SportTeamJersey from '../../SportTeamJersey/SportTeamJersey';
import SportTeamSeperator from '../../SportTeamSeperator/SportTeamSeperator';
import styles from './MultipleMarket.module.css';

type MultipleMarketProps = {
  sportEvent: ExtendedSportEvents;
  navigateToEvent: () => void;
};

const MultipleMarket = ({
  sportEvent,
  navigateToEvent,
}: MultipleMarketProps) => {
  const { addToBetslip } = useSportsBetslip();

  const { teams, matchMarket, lineMarket, totalMarket } = sportEvent;

  const homeTeam = teams && teams[0] ? teams[0] : { name: 'n/a' };
  const awayTeam = teams && teams[1] ? teams[1] : { name: 'n/a' };

  const addItem = (market: SportMarket, selection?: MarketSelection) => {
    if (selection) {
      addToBetslip({
        eventDetails: sportEvent,
        selection,
        market,
      });
    }
  };

  const getSelection = (sportMarket: SportMarket, selectionIndex: number) => {
    if (
      sportMarket.selections &&
      sportMarket.selections.length > 0 &&
      sportMarket.selections[selectionIndex]
    ) {
      return sportMarket.selections[selectionIndex];
    }
    return undefined;
  };

  const getOdds = (sportMarket: SportMarket, selectionIndex: number) => {
    const selection = getSelection(sportMarket, selectionIndex);
    if (selection) {
      const prices = selection.prices;
      if (prices && prices.length) {
        return prices[0].decimal_price;
      }
      return undefined;
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.container}
      onClick={navigateToEvent}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          navigateToEvent();
        }
      }}
    >
      <div className={clsx(styles.team)}>
        <div className={styles.homeTeamWrapper}>
          <SportTeamJersey
            teamName={homeTeam.name}
            jersey={'lakers'}
            className={styles.teamName}
          />
          <div className={styles.seperator}>
            <SportTeamSeperator />
          </div>
        </div>
        <div className={styles.homeTeamOdds}>
          <div className={styles.oddsWithBetType}>
            <div className={styles.betTypeWrapper}>Match</div>
            {matchMarket && matchMarket.selections?.length && (
              <SportOddsButton
                odd={getOdds(matchMarket, 0)}
                onOddButtonClick={() => {
                  addItem(matchMarket, getSelection(matchMarket, 0));
                }}
              />
            )}
          </div>
          <div className={styles.oddsWithBetType}>
            <div className={styles.betTypeWrapper}>Line</div>
            {lineMarket && (
              <SportOddsButton
                odd={getOdds(lineMarket, 0)}
                label="+235"
                onOddButtonClick={() => {
                  addItem(lineMarket, getSelection(lineMarket, 0));
                }}
              />
            )}
          </div>
          <div className={styles.oddsWithBetType}>
            <div className={styles.betTypeWrapper}>Total</div>
            {totalMarket && (
              <SportOddsButton
                odd={getOdds(totalMarket, 0)}
                onOddButtonClick={() => {
                  addItem(totalMarket, getSelection(totalMarket, 0));
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.team}>
        <SportTeamJersey
          teamName={awayTeam.name}
          jersey="chicago"
          className={styles.teamName}
        />
        <div className={styles.odds}>
          {matchMarket && (
            <SportOddsButton
              odd={getOdds(matchMarket, 1)}
              onOddButtonClick={() => {
                addItem(matchMarket, getSelection(matchMarket, 1));
              }}
            />
          )}
          {lineMarket && (
            <SportOddsButton
              odd={getOdds(lineMarket, 1)}
              label="+123"
              onOddButtonClick={() => {
                addItem(lineMarket, getSelection(lineMarket, 1));
              }}
            />
          )}
          {totalMarket && (
            <SportOddsButton
              odd={getOdds(totalMarket, 1)}
              onOddButtonClick={() => {
                addItem(totalMarket, getSelection(totalMarket, 1));
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MultipleMarket;
