import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import CurrencyInput from '../../../components/Form/CurrencyInput/CurrencyInput';
import {
  updateBetStake,
  updateStakeByBetTypeRace,
} from '../../../stores/betslipSlice';
import { ExoticBet, SingleBet } from '../../../utils/betslip/types';
import { betTypeObjFromString } from '../../../utils/race/common';
import QuickStakeButtons from '../QuickStakeButtons/QuickStakeButtons';
import {
  TraditionalExoticBet,
  TraditionalMultiRaceBet,
  TraditionalSingleBet,
} from '../TraditionalBetslip/types';
import styles from './SetStakeInput.module.css';

export default function SetStakeInput({
  bet,
  showClearButton = false,
  traditional = false,
}: {
  bet:
    | TraditionalSingleBet
    | TraditionalExoticBet
    | TraditionalMultiRaceBet
    | SingleBet
    | ExoticBet;
  showClearButton?: boolean;
  traditional?: boolean;
}) {
  const { race, stake = 0 } = bet;
  const dispatch = useDispatch();
  const betType = betTypeObjFromString(bet.betType);
  const currencyInputRef = useRef<HTMLInputElement>(null);
  const [isInFocus, setIsInFocus] = useState(false);

  function backspace() {
    const newValue = 0;
    if (traditional) {
      dispatch(
        updateStakeByBetTypeRace({
          raceId: race.id,
          betType,
          stake: newValue,
        })
      );
    } else {
      dispatch(
        updateBetStake({
          bet,
          newPrice: newValue,
        })
      );
    }
  }

  const toggleInFocus = () => {
    setIsInFocus(true);
  };

  function addStake(amt: number) {
    const newValue = amt === 0 ? 0 : stake + amt;
    if (traditional) {
      dispatch(
        updateStakeByBetTypeRace({
          raceId: race.id,
          betType,
          stake: newValue,
        })
      );
    } else {
      dispatch(
        updateBetStake({
          bet,
          newPrice: newValue,
        })
      );
    }
  }

  function addInputStake(value: number) {
    if (!isNaN(value)) {
      if (traditional) {
        dispatch(
          updateStakeByBetTypeRace({
            raceId: race.id,
            betType,
            stake: value,
          })
        );
      } else {
        dispatch(
          updateBetStake({
            bet,
            newPrice: value,
          })
        );
      }
    }
  }

  useEffect(() => {
    if (currencyInputRef.current && isInFocus) {
      currencyInputRef.current.focus();
    }
  }, [isInFocus]);

  return (
    <div>
      <div ref={currencyInputRef}>
        <CurrencyInput
          className={styles.stakeInput}
          value={stake}
          onClick={toggleInFocus}
          onChange={(val: number) => addInputStake(val)}
          showClearButton={showClearButton}
          backspace={backspace}
        />
      </div>
      <QuickStakeButtons onAddStake={addStake} />
    </div>
  );
}
