import { useEffect, useState } from 'react';
import HorizontalOverflow from '../HorizontalOverflow/HorizontalOverflow';
import ToggleButton from '../ToggleButton/ToggleButton';
import styles from './Filters.module.css';

export type Filter = { label: string; id: string };
type PropsType = {
  onToggle: (filter: string[]) => void;
  filters: Filter[];
};

const defaultFilter = 'all';

export default function Filters({ onToggle, filters }: PropsType) {
  const [selectedFilters, setSelectedFilters] = useState([defaultFilter]);

  useEffect(() => {
    onToggle(
      selectedFilters.includes(defaultFilter)
        ? [defaultFilter]
        : selectedFilters
    );
  }, [selectedFilters, onToggle]);

  const fliterPressed = (filter: string) => {
    if (filter === defaultFilter) {
      setSelectedFilters([defaultFilter]);
    } else if (selectedFilters.includes(filter)) {
      setSelectedFilters((prev) =>
        prev.length === 1 ? [defaultFilter] : prev.filter((f) => f !== filter)
      );
    } else {
      setSelectedFilters((prev) =>
        [...prev, filter].filter((d) => d !== defaultFilter)
      );
    }
  };

  if (!filters.length) return null;

  return (
    <HorizontalOverflow showArrows roundedCorner scrollWidth={82}>
      <section className={styles.container}>
        <div className={styles.filterContainer}>
          {[{ id: defaultFilter, label: 'All' }, ...filters].map((option) => (
            <ToggleButton
              key={option.id}
              isChecked={selectedFilters.includes(option.id)}
              onChange={() => fliterPressed(option.id)}
              label={option.label}
            />
          ))}
        </div>
      </section>
    </HorizontalOverflow>
  );
}
