import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscription } from 'urql';
import MeetingType from '../../components/MeetingType/MeetingType';
import OddsButton from '../../components/OddsButton/OddsButton';
import RaceStatus from '../../components/RaceStatus/RaceStatus';
import { BetType } from '../../constants/betTypes';
import {
  NextToJumpNewQuery,
  PriceType,
  PriceUpdatesDocument,
  PriceUpdatesSubscription,
  PriceUpdatesSubscriptionVariables,
  RaceUpdatedDocument,
  RaceUpdatedSubscription,
  RaceUpdatedSubscriptionVariables,
} from '../../generated/graphql';
import { addOrRemoveBet } from '../../stores/betslipSlice';
import { RootState } from '../../stores/store';
import { BetslipType } from '../../types/betslipType';
import { hasSingleBet } from '../../utils/betslip/common';
import RunnerDetailsCompact from '../Racebook/RaceCard/RunnerDetails/RunnerDetailCompact';
import styles from './NextToJumpRace.module.css';

const selector = createSelector(
  [
    (state: RootState) => state.config.options.betslipType,
    (state: RootState) => state.betslip.betslip,
    (state: RootState) => state.config.coreApiSource,
  ],
  (betslipType, betslip, source) => ({
    betslipType,
    betslip,
    source,
  })
);

export default function NextToJumpRace({
  race: defaultRace,
  baseUrl,
  onChangeStatus,
}: {
  race: NextToJumpNewQuery['races'][0];
  baseUrl: string;
  onChangeStatus: () => void;
}) {
  const [race, setRace] = useState(defaultRace);
  const dispatch = useDispatch();
  const { betslipType, betslip, source } = useSelector(selector);

  const [subscription] = useSubscription<
    RaceUpdatedSubscriptionVariables,
    RaceUpdatedSubscription
  >({
    query: RaceUpdatedDocument,
    variables: {
      raceId: race.id,
    },
  });
  useEffect(() => {
    if (subscription.data) {
      if (race.status !== subscription.data?.raceUpdated.status) {
        onChangeStatus();
      } else {
        setRace((prev) => {
          if (prev) {
            const competitors =
              subscription?.data?.raceUpdated?.competitors || [];
            return {
              ...prev,
              ...(subscription?.data?.raceUpdated || {}),
              competitors: prev.competitors.map((competitor) => ({
                ...competitor,
                ...(competitors.find((c) => c.tabNo === competitor.tabNo) ||
                  {}),
              })),
            };
          }

          return prev;
        });
      }
    }
  }, [subscription, onChangeStatus, race.status]);

  const [priceSubscription] = useSubscription<
    PriceUpdatesSubscriptionVariables,
    PriceUpdatesSubscription
  >({
    query: PriceUpdatesDocument,
    variables: {
      raceId: race.id,
      source,
    },
  });
  useEffect(() => {
    if (priceSubscription.data) {
      const { priceUpdates } = priceSubscription.data;
      setRace((prev) => ({
        ...prev,
        competitors: prev?.competitors.map((competitor) => ({
          ...competitor,
          prices: competitor.prices.map((price) => {
            const newPrice = priceUpdates.prices.find(
              (updatedPrice) =>
                updatedPrice.tabNo === competitor.tabNo &&
                updatedPrice.type === price.type
            );
            return newPrice || price;
          }),
        })),
      }));
    }
  }, [priceSubscription]);

  const raceName = useMemo(
    () => `R${race.number} ${race.meeting.track.name}`,
    [race]
  );

  const getWinPrice = useCallback(
    (
      competitor: NonNullable<NextToJumpNewQuery['races'][0]>['competitors'][0]
    ) =>
      competitor.prices?.find((price) => price.type === PriceType.WinFixedOdds)
        ?.price,
    []
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <MeetingType meetingType={race.meeting.type} className={styles.icon} />
        <div className={styles.raceDetails}>
          <a
            href={`${baseUrl}#/racing/meeting/${race.meeting.id}/race/${race.id}/win`}
            className={styles.raceName}
            title={raceName}
          >
            {raceName}
          </a>
        </div>
        <div className={styles.countdown}>
          <RaceStatus startTime={race.startTime} status={race?.status} />
        </div>
      </div>
      <div className={styles.runners}>
        <div className={styles.grid}>
          <div className={styles.separator} />
          <span className={styles.header}>RUNNERS</span>
          <span className={clsx(styles.header, styles.center)}>FIXED</span>
          <div className={styles.separator} />
          {sortBy(
            race?.competitors
              .filter((competitor) => !competitor.scratched)
              .slice(0, 4),
            (c) =>
              c.prices.find((p) => p.type === PriceType.WinFixedOdds)?.price ||
              Infinity
          ).map((competitor, index) => {
            const winPrice = getWinPrice(competitor) || 0;

            return (
              <Fragment key={`${race.id}:${competitor.tabNo}`}>
                <RunnerDetailsCompact
                  meeting={race.meeting}
                  competitor={competitor}
                />
                <div className={styles.oddsContainer}>
                  <OddsButton
                    price={winPrice}
                    favourite={index === 0 && winPrice > 0}
                    onClick={() => {
                      if (race) {
                        dispatch(
                          addOrRemoveBet({
                            betType: BetType.WIN_FIXED_ODDS,
                            traditional: betslipType === BetslipType.American,
                            odds: winPrice,
                            race,
                            meeting: race?.meeting,
                            competitor,
                          })
                        );
                      }
                    }}
                    selected={
                      !!race &&
                      hasSingleBet(betslip, {
                        betType: BetType.WIN_FIXED_ODDS,
                        race,
                        meeting: race?.meeting,
                        competitor,
                      })
                    }
                    disabled={competitor.scratched}
                  />
                </div>
                <div className={styles.separator} />
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className={styles.footer}>
        <a
          href={`${baseUrl}#/racing/meeting/${race.meeting.id}/race/${race.id}/win`}
          className={styles.viewRaceCard}
        >
          View Race Card
        </a>
      </div>
    </div>
  );
}
