import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/store';
import styles from './TeamHistory.module.css';

export type TeamHistoryProps = {
  className?: string;
};

const TeamHistory = ({ className }: TeamHistoryProps) => {
  const iSportgeniusBaseUrl = useSelector(
    (state: RootState) => state.config.iSportgeniusBaseUrl
  );
  return (
    <div className={clsx(styles.container, className)}>
      <iframe
        title="Stats"
        src={iSportgeniusBaseUrl}
        className={styles.frame}
      />
    </div>
  );
};

export default TeamHistory;
