import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Countdown from '../../../components/Countdown/Countdown';
import Icon from '../../../components/Icon/Icon';
import { BetTypeLookup } from '../../../constants/betTypes';
import useFormat from '../../../hooks/useFormat';
import {
  getEventStartTime,
  getEventStatus,
  isSingleBet,
  isSportSingleBet,
} from '../../../utils/betslip/common';
import { AllSingleBet, Bet } from '../../../utils/betslip/types';
import styles from './ParlayBet.module.css';

function getCompetitors(bet: Bet) {
  if (isSingleBet(bet)) {
    const { competitor } = bet;
    return `${competitor?.tabNo}. ${competitor?.name} (${competitor?.barrier})`;
  }
  if (isSportSingleBet(bet)) {
    return bet.selection.name;
  }
  return null;
}

function getBetVenue(bet: Bet) {
  if ('meeting' in bet) {
    return `${bet?.meeting?.track.name} R${bet?.race?.number}`;
  }
  if (isSportSingleBet(bet)) {
    return bet.eventDetails?.competition.description;
  }
  return null;
}

export default function ParlayBet({
  bet,
  selected,
  setSelected,
}: {
  bet: AllSingleBet;
  selected: boolean;
  setSelected: (bet: AllSingleBet) => void;
}) {
  const [displayPrice, setDisplayPrice] = useState<number>(bet.odds || 0);
  const [animation, setAnimation] = useState<string>('');

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setAnimation('');
    }, 1500);

    return () => {
      clearTimeout(animationTimer);
    };
  }, [animation]);
  useEffect(() => {
    if (bet.odds && !displayPrice) setDisplayPrice(bet.odds);
    if (!displayPrice || !bet.odds) return;
    if (displayPrice > bet.odds) {
      setAnimation('firm');
    } else if (displayPrice < bet.odds) {
      setAnimation('drift');
    }

    setDisplayPrice(bet.odds);
  }, [bet.odds, displayPrice]);

  const format = useFormat();

  const status = getEventStatus(bet);

  const startTime = getEventStartTime(bet);
  return (
    <div className={styles.bet}>
      <Checkbox
        checked={selected}
        onChange={() => setSelected(bet)}
        size="small"
        disabled={status !== 'OPEN'}
      />
      <div className={styles.betContent}>
        <div className={styles.header}>
          <span className={styles[status]}>{`${getCompetitors(bet)}`}</span>
          {status === 'OPEN' && startTime ? (
            <Countdown startTime={startTime} className={styles[status]} />
          ) : (
            <div className={styles.eventClosed}>Event Closed</div>
          )}
        </div>
        <div className={clsx(styles.centerContent, styles[status])}>
          <span>{BetTypeLookup[bet.betType]}</span>
          <Icon name="dot" size="very-small" />
          <span>{`${getBetVenue(bet)}`}</span>
        </div>
        <div className={clsx(styles.bottomContent, styles[status])}>
          <span>Fixed</span>
          <span className={clsx(styles.odds, styles[animation])}>
            {format.odds(displayPrice)}
          </span>
          <span className={clsx(styles[`${animation}-arrow`])} />
        </div>
      </div>
    </div>
  );
}
