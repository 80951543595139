import clsx from 'clsx';
import useCountdown from '../../hooks/useCountdown';
import styles from './Countdown.module.css';

type Props = {
  startTime: Date;
  className?: string;
  onlyShowUrgent?: boolean;
  hideLozenge?: boolean;
};

export default function Countdown({
  startTime,
  className = '',
  onlyShowUrgent = false,
  hideLozenge = false,
}: Props) {
  const countdown = useCountdown(startTime);
  const showCountdown = onlyShowUrgent ? countdown.urgent : true;

  if (!showCountdown || !countdown.label) {
    return null;
  }

  return (
    <span
      className={clsx(
        styles.countdown,
        {
          [styles.urgent]: countdown.urgent,
          [styles.hideLozenge]: hideLozenge,
        },
        className
      )}
    >
      {countdown.label}
    </span>
  );
}
