import clsx from 'clsx';
import { first, last } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SportEventDetails, SportMarket } from '../../types/sport';
import DividerHorizental from '../Dividers/DividerHorizonal';
import SportTeamsName from '../SportTeamsName/SportTeamsName';
import styles from './HeadToHeadCard.module.css';
import Selections from './Selections/Selections';
import Header from './SportsRowHeader/SportsRowHeader';
import Teams from './Teams/Teams';
import TeamsLive from './TeamsLive/TeamsLive';
import TeamsMultipleBetType from './TeamsMultipleBetType/TeamsMultipleBetType';

type Bet = {
  name: string;
  outcome: string;
  price: number;
};

type HeadToHeadCardProps = {
  type?: 'live' | 'match-result' | 'multi-markets';
  outcomes?: Bet[];
  startDate: Date;
  sportType: string;
  countOfMarkets: number;
  containerClass?: string;
  eventDetails?: SportEventDetails;
  market?: SportMarket;
};

const HeadToHeadCard = ({
  type = 'match-result',
  outcomes,
  startDate,
  sportType,
  countOfMarkets,
  containerClass,
  eventDetails,
  market,
}: HeadToHeadCardProps) => {
  const navigate = useNavigate();

  const navigateToEvent = (eventId: string) => {
    navigate(`/sports/event/soccer/nba/${eventId}/all`);
  };

  return (
    <div className={clsx(styles.container, containerClass)}>
      <Header
        isLive={type === 'live'}
        countOfMarkets={countOfMarkets}
        startDate={startDate}
        category={sportType}
      />
      <DividerHorizental />
      {type === 'match-result' && outcomes && market && (
        <>
          <SportTeamsName
            homeTeam={{ name: first(outcomes)?.name || 'unknown' }}
            awayTeam={{ name: last(outcomes)?.name || 'unknown' }}
            sportType={sportType}
          />
          <Selections
            market={market}
            eventDetails={eventDetails}
            onCardClick={() => navigateToEvent('c3BvcnRldmVudDo0MQ==')}
          />
        </>
      )}
      {type === 'match-result' && outcomes && !market && (
        <Teams
          onClick={() => navigateToEvent('c3BvcnRldmVudDo0MQ==')}
          teams={outcomes}
          sportType={sportType}
        />
      )}

      {type === 'multi-markets' && (
        <TeamsMultipleBetType
          homeTeam={{ name: 'Los Angeles' }}
          awayTeam={{ name: 'Chicago' }}
          onClick={() => navigateToEvent('c3BvcnRldmVudDo0MQ==')}
        />
      )}
      {type === 'live' && (
        <TeamsLive
          sport={'soccer' || 'basketball'}
          onClick={() => navigateToEvent('c3BvcnRldmVudDo0MQ==')}
        />
      )}
    </div>
  );
};

export default HeadToHeadCard;
