import clsx from 'clsx';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import useFormat from '../../hooks/useFormat';
import styles from './OddsButton.module.css';

type Props = InputHTMLAttributes<HTMLButtonElement> & {
  price?: number | null;
  label?: string;
  selected?: boolean;
  fixedWidth?: boolean;
  priceTypeText?: string;
  favourite?: boolean;
};

export default function OddsButton({
  price,
  label,
  selected,
  fixedWidth = false,
  className = '',
  priceTypeText,
  favourite = false,
  ...rest
}: Props) {
  const [displayPrice, setDisplayPrice] = useState(price);
  const [animation, setAnimation] = useState('');

  useEffect(() => {
    if (price && !displayPrice) setDisplayPrice(price);
    if (!displayPrice || !price) return;
    if (displayPrice > price) {
      setAnimation('firm');
    } else if (displayPrice < price) {
      setAnimation('drift');
    }

    setDisplayPrice(price);
  }, [price, displayPrice]);

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setAnimation('');
    }, 1500);

    return () => {
      clearTimeout(animationTimer);
    };
  }, [animation]);

  const format = useFormat();
  const displayLabel = label || 'TBD';

  return (
    <>
      <button
        className={clsx(
          styles.button,
          {
            [styles.fixedWidth]: fixedWidth,
            [styles.selected]: selected,
            [styles.flex]: priceTypeText,
          },
          styles[animation],
          className
        )}
        {...rest}
        type="button"
      >
        <span>{priceTypeText}</span>
        <span>{price ? format.odds(price) : displayLabel}</span>
        {favourite ? <span className={styles.favourite}>FAV</span> : null}
      </button>
    </>
  );
}
