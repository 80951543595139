import Axios from 'axios';
import md5 from 'crypto-js/md5';
import { flattenDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PlaceBetTypeLookup } from '../constants/betTypes';
import { ConfigState } from '../stores/configSlice';
import { RootState } from '../stores/store';
import {
  getBetCombinations,
  isExoticBet,
  isMultiBet,
  isSingleBet,
} from '../utils/betslip/common';
import { Bet } from '../utils/betslip/types';

export default function useBettingClient() {
  const [jwt, setJwt] = useState<string>();
  const config = useSelector((state: RootState) => state.config);
  const axios = useMemo(
    () =>
      Axios.create({
        baseURL: process.env.REACT_APP_BET_DIRECTOR_URL,
      }),
    []
  );

  useEffect(() => {
    (async () => {
      const token = await config?.getUserJwt?.();
      setJwt(token);
    })();
  }, [config]);

  useEffect(() => {
    if (jwt) {
      axios.post('https://gwpublic.betdirector.gcpintau.tbm.sh/public/Login', {
        baTerminalid: jwt,
      });
    }
  }, [axios, jwt]);

  const selectedPriceType = useSelector(
    (state: RootState) => state.setting.selectedPriceType
  );

  async function placeBets(bets: Bet[]) {
    function getRaceMeta(bet: Bet) {
      return isMultiBet(bet)
        ? {}
        : {
            raceId: bet.race?.id,
            raceMeta: {
              trackName: bet.meeting?.track.name,
              raceStart: bet.race?.startTime,
              raceNumber: bet.race?.number,
              meetingType: bet.meeting?.type,
            },
          };
    }

    function getBetLegs(bet: Bet) {
      if (isSingleBet(bet)) {
        return [
          {
            leg: 1,
            selections: [
              {
                selectionId: bet.competitor?.selectionID,
                selectionMeta: {
                  name: bet.competitor?.name,
                  runner: bet.competitor?.tabNo,
                },
              },
            ],
          },
        ];
      }
      if (isMultiBet(bet)) {
        return bet.selections?.map((selection, index) => {
          const multiRace = bet?.meeting?.races?.find(
            (race) => bet?.races?.[index] === race.id
          );
          return {
            additionalData: {
              raceId: bet.races[index],
              raceMeta: {
                trackName: bet.meeting.track.name,
                raceStart: multiRace?.startTime,
                raceNumber: multiRace?.number,
                meetingType: bet.meeting.type,
              },
            },
            leg: index + 1,
            selections: selection.map((competitor) => ({
              selectionId: competitor.selectionID,
              selectionMeta: {
                name: competitor.name,
                runner: competitor.tabNo,
              },
            })),
          };
        });
      }
      if (isExoticBet(bet)) {
        return bet.selections?.map((selection, index) => ({
          leg: index + 1,
          selections: selection.map((competitor) => ({
            selectionId: competitor.selectionID,
            selectionMeta: {
              name: competitor.name,
              runner: competitor.tabNo,
            },
          })),
        }));
      }
      return [];
    }

    const mappedBets = bets.map((bet) => ({
      type: PlaceBetTypeLookup[bet.betType]?.toLowerCase(),
      platform: selectedPriceType,
      stake: Math.floor((bet.stake || 0) * 100),
      combinations: getBetCombinations(bet),
      ...getRaceMeta(bet),
      legs: getBetLegs(bet),
      odds: Math.floor((bet.odds || 0) * 100),
      raceId: bet.race?.id,
      originalBet: bet,
    }));

    const response = await Promise.all(
      mappedBets.map((bet) =>
        getBetHash(bet, config).then((hash) =>
          axios
            .post('/public/PlaceBetsV2', {
              bet,
              terminal_id: jwt,
              ticket_id: hash,
            })
            .then((responseObject) => ({
              ...responseObject,
              data: { ...responseObject.data, bet: bet.originalBet },
            }))
            .catch((error) => ({
              data: { error, bet: bet.originalBet, status: 'cancelled' },
            }))
        )
      )
    );
    return response.map((responseObject) => responseObject.data);
  }

  return {
    placeBets,
  };
}

async function getBetHash(bet: any, config: ConfigState | undefined) {
  function getSelectionId() {
    if (isSingleBet(bet)) {
      return bet.competitor.selectionID;
    }
    if (isExoticBet(bet)) {
      return flattenDeep(bet.selections)
        .map((c) => c.selectionID)
        .join(',');
    }
    if (isMultiBet(bet)) {
      return flattenDeep(bet.selections)
        .map((c) => c.selectionID)
        .join(',');
    }
    return '';
  }

  const customerId = await config?.getUserJwt?.();

  const hash = md5(
    `${Date.now()}:${customerId}:${getSelectionId()}:${bet.betType}:`
  ).toString();
  return hash;
}
