import React from 'react';
import Countdown from '../../Countdown/Countdown';
import Icon from '../../Icon/Icon';
import styles from './SportsRowHeader.module.css';

type HeaderProps = {
  isLive?: boolean;
  startDate: Date;
  countOfMarkets: number;
  category: string;
};

const SportsRowHeader = ({
  isLive,
  startDate,
  countOfMarkets,
  category,
}: HeaderProps) => (
  <div className={styles.container}>
    <div className={styles.competition}>{category}</div>
    <div className={styles.features}>
      <div className={styles.icon}>
        <Icon name="depositBonus" />
      </div>
      <div className={styles.icon}>
        <Icon name="cashOut" />
      </div>
      <div className={styles.icon}>
        <Icon name="livebetting" />
      </div>
      <div className={styles.icon}>
        <Icon name="samegamemulti" />
      </div>
    </div>
    <div className={styles.timeMarket}>
      {!isLive && (
        <div className={styles.time}>
          <strong>
            <Countdown startTime={startDate} />,{' '}
          </strong>
          <span>2:53pm</span>
        </div>
      )}
      {isLive && (
        <div className={styles.live}>
          <Icon name="live" />
        </div>
      )}
      <div className={styles.verticalSeperator} />
      <div className={styles.markets}>{countOfMarkets} Markets</div>
    </div>
  </div>
);

export default SportsRowHeader;
