import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'urql';
import {
  SportTypesDocument,
  SportTypesQuery,
  SportTypesQueryVariables,
} from '../../generated/graphql';
import {
  setSportTypes,
  setSportTypesError,
  setSportTypesLoading,
} from '../../stores/sportsSlice';

function useSportTypes() {
  const disaptch = useDispatch();
  const [{ data, ...rest }] = useQuery<
    SportTypesQuery,
    SportTypesQueryVariables
  >({
    query: SportTypesDocument,
  });

  useEffect(() => {
    disaptch(setSportTypes(data?.sportTypes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.sportTypes]);

  useEffect(() => {
    disaptch(setSportTypesLoading(rest.fetching));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.fetching]);
  useEffect(() => {
    disaptch(setSportTypesError(rest.error?.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.error]);

  return {
    data,
    ...rest,
  };
}

export default useSportTypes;
