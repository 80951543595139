import { Helmet } from 'react-helmet-async';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { UnableToLoadErrorPage } from '../../components';
import useSportTypes from '../../hooks/useSportTypes/useSportTypes';
import { useGetSportCategoriesQuery } from '../../services/cmsApi/cmsApi';
import CompetitionHome from './CompetitionHome/CompetitionHome';
import SportCardHome from './SportCardHome/SportCardHome';
import SportHome from './SportHome/SportHome';
import SportsHome from './SportsHome/SportsHome';

export default function SportsBook() {
  return (
    <>
      <Helmet titleTemplate="%s · Sports">
        <meta name="description" content="Sports" />
      </Helmet>
      <HashRouter>
        <SportBookWrappper />
      </HashRouter>
    </>
  );
}

const SportBookWrappper = () => {
  useSportTypes();
  useGetSportCategoriesQuery();
  return (
    <Routes>
      <Route path="/sports/error" element={<UnableToLoadErrorPage />} />
      <Route
        path="/sports/event/:sportName/:competitionName/:eventId/*"
        element={<SportCardHome />}
      />
      <Route
        path="/sports/competition/:sportName/:competitionName/*"
        element={<CompetitionHome />}
      />
      <Route path="/sports/sport/:sportName/*" element={<SportHome />} />
      <Route path="*" element={<SportsHome />} />
    </Routes>
  );
};
