import clsx from 'clsx';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Tabs.module.css';

const tabMocks = [
  {
    label: 'Featured',
    url: '/sports/home/stats',
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    label: 'Parlays',
    url: '/sports/home/stats/parlays',
    mobileOnly: false,
    desktopOnly: false,
  },
];
const Tabs = () => {
  const location = useLocation();
  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {tabMocks.map((tab) => (
          <Link
            key={tab.label}
            to={tab.url}
            className={clsx(styles.tab, {
              [styles.selected]: location.pathname === tab.url,
              [styles.mobileOnly]: tab.mobileOnly,
              [styles.desktopOnly]: tab.desktopOnly,
            })}
          >
            {tab.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
