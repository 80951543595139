import styles from './CountryFlag.module.css';
import * as flagList from './flags';

export type FlagName = keyof typeof flagList;

export default function CountryFlag({
  countryCode,
  size = 'medium',
}: {
  countryCode: FlagName;
  size?: 'small' | 'medium' | 'large';
}) {
  const FlagComponent = flagList[countryCode];

  if (!FlagComponent) return null;

  return <FlagComponent className={styles[size]} />;
}
