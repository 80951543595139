import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useBreadcrumbs from '../../hooks/useBreadcrumbs/useBreadcrumbs';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import HorizontalOverflow from '../HorizontalOverflow/HorizontalOverflow';
import styles from './HomeContainer.module.css';
import TabsPlaceholder from './TabsPlaceholder';

type Props = {
  HeaderComponent?: ReactNode;
  tabsLoading?: boolean;
  tabs?: {
    label: string;
    url: string;
    mobileOnly?: boolean;
    desktopOnly?: boolean;
  }[];
  children: ReactNode;
  hideBreadcrumbs?: boolean;
};

export default function HomeContainer({
  HeaderComponent,
  tabs = [],
  children,
  tabsLoading,
  hideBreadcrumbs = false,
}: Props) {
  const breadcrumbs = useBreadcrumbs();
  const location = useLocation();

  return (
    <>
      <div className={styles.container}>
        {!hideBreadcrumbs ? <BreadCrumbs breadcrumbs={breadcrumbs} /> : null}
        {HeaderComponent && (
          <div className={styles.headerComponent}>{HeaderComponent}</div>
        )}
        {tabsLoading && (
          <HorizontalOverflow>
            <TabsPlaceholder />
          </HorizontalOverflow>
        )}
        {tabs.length > 0 ? (
          <HorizontalOverflow>
            <div className={styles.tabs}>
              {tabs.map((tab) => (
                <Link
                  key={tab.label}
                  to={tab.url}
                  className={clsx(styles.tab, {
                    [styles.selected]: location.pathname === tab.url,
                    [styles.mobileOnly]: tab.mobileOnly,
                    [styles.desktopOnly]: tab.desktopOnly,
                  })}
                >
                  {tab.label}
                </Link>
              ))}
            </div>
          </HorizontalOverflow>
        ) : null}
      </div>
      {children}
    </>
  );
}
