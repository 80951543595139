import clsx from 'clsx';
import { ForwardedRef, forwardRef } from 'react';
import Icon, { IconName } from '../Icon/Icon';
import styles from './NewButton.module.css';

type ButtonTheme = 'primary' | 'secondary' | 'flat' | 'stroke';
type ButtonType = 'button' | 'submit' | 'reset';
type ButtonSize = 'small' | 'medium' | 'large';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: ButtonType;
  theme?: ButtonTheme;
  size?: ButtonSize;
  className?: string;
  leftIcon?: IconName;
  rightIcon?: IconName;
}

const NewButton = forwardRef(
  (
    {
      type = 'button',
      theme = 'primary',
      size = 'medium',
      className = '',
      leftIcon,
      rightIcon,
      children,
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <button
      /* eslint-disable react/button-has-type */
      type={type}
      /* eslint-enable react/button-has-type */
      className={clsx(
        styles.button,
        styles[`theme--${theme}`],
        styles[`size--${size}`],
        className
      )}
      ref={ref}
      {...rest}
    >
      {leftIcon && <Icon name={leftIcon} />}
      {children ? <span>{children}</span> : null}
      {rightIcon && <Icon name={rightIcon} />}
    </button>
  )
);

export default NewButton;
