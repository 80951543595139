import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import {
  SportEventsDocument,
  SportEventsQuery,
  SportEventsQueryVariables,
} from '../../generated/graphql';
import { TopSportMarkets } from '../../utils/sport/transformers/SportEvents';

const now = dayjs();
const tomorrow = now.add(1, 'day');

export default function useSportEvents({
  selectedSportTypes,
  marketTypeIDs,
}: {
  selectedSportTypes: number[];
  marketTypeIDs?: string[];
}) {
  const [{ data, ...rest }] = useQuery<
    SportEventsQuery,
    SportEventsQueryVariables
  >({
    query: SportEventsDocument,
    variables: {
      startDate: now.toDate(),
      endDate: tomorrow.toDate(),
      marketTypeIDs,
    },
  });

  const events = useMemo(() => {
    const sportEvents = data ? data.sportEvents : [];
    return TopSportMarkets(sportEvents, selectedSportTypes);
  }, [data, selectedSportTypes]);

  return { events, ...rest };
}
