import clsx from 'clsx';
import { useMemo, useState } from 'react';
import Icon from '../../../../components/Icon/Icon';
import {
  MeetingType,
  RunnerQuery,
  Statistics,
} from '../../../../generated/graphql';
import styles from './JockeyTrainerForm.module.css';
import PercentageBar from './PercentageBar';

export default function JockeyTrainerForm({
  competitor,
  meetingType,
}: {
  competitor: NonNullable<
    NonNullable<RunnerQuery['runner']>['competitorsConnection']['competitors']
  >[0];
  meetingType: MeetingType;
}) {
  const [navExpanded, setNavExpanded] = useState(true);
  const { jockeyStatistics: jockeyStats, trainerStatistics: trainerStats } =
    competitor;

  const getLifeStats = (
    stats?: Pick<
      NonNullable<Statistics['all']>,
      'numberOfRuns' | 'firsts' | 'seconds' | 'thirds'
    > | null
  ) =>
    `${stats ? stats?.numberOfRuns : 0} ${stats ? stats?.firsts : 0}-${
      stats ? stats?.seconds : 0
    }-${stats ? stats?.thirds : 0}`;

  const jockeyLife = useMemo(
    () => getLifeStats(jockeyStats?.career?.all),
    [jockeyStats]
  );
  const trainerLife = useMemo(
    () => getLifeStats(trainerStats?.career?.all),
    [trainerStats]
  );

  return (
    <div className={styles.container}>
      <button
        type="button"
        onClick={() => setNavExpanded((prev) => !prev)}
        className={clsx(styles.toggleButton, {
          [styles.openState]: navExpanded,
        })}
      >
        <span className={styles.title}>
          {!meetingType.includes(MeetingType.Greyhound)
            ? 'Jockey and Trainer Form'
            : 'Trainer Form'}
        </span>{' '}
        <span
          className={clsx(styles.chevronButton, {
            [styles.expanded]: navExpanded,
          })}
        >
          <Icon name="caretDown" />
        </span>
      </button>
      {navExpanded && (
        <>
          {!meetingType?.includes(MeetingType.Greyhound) && (
            <section className={styles.section}>
              <div className={styles.innerSection}>
                <div className={clsx(styles.title, styles.marginBottom)}>
                  Jockey Form
                </div>
                <div className={styles.jockeyForm}>
                  <div className={styles.tableContainer}>
                    <table className={clsx(styles.table, styles.limitWidth)}>
                      <tbody>
                        <tr>
                          <th className={styles.strongTableHeader}>Life</th>
                          <td>{jockeyLife}</td>
                        </tr>
                        <tr>
                          <th className={styles.strongTableHeader}>Margin</th>
                          <td>{trainerLife}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.percentageBarContainer}>
                    {/* Commenting out conditional rendering for now until stats data returning */}
                    {/* {jockeyStats?.all?.winPercentage && ( */}
                    <div className={styles.filteredStatsItem}>
                      <PercentageBar
                        // fallback number only provided here and below for mocking. Remove when data available
                        percentage={
                          jockeyStats?.career?.all?.winPercentage || 19
                        }
                      />
                      <span>Win</span>
                    </div>
                    {/* )} */}

                    {/* {jockeyStats?.all?.placePercentage && ( */}
                    <div className={styles.filteredStatsItem}>
                      <PercentageBar
                        percentage={
                          jockeyStats?.career?.all?.placePercentage || 41
                        }
                      />
                      <span>Place</span>
                    </div>
                    {/* )} */}

                    {/* {jockeyStats?.all?.placePercentage && ( */}
                    <div className={styles.filteredStatsItem}>
                      <PercentageBar percentage={90} />
                      <span>Show</span>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className={styles.section}>
            <div className={styles.innerSection}>
              {!meetingType?.includes(MeetingType.Greyhound) && (
                <div className={clsx(styles.title, styles.marginBottom)}>
                  Trainer Form
                </div>
              )}
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <th>Last 31-60 days:</th>
                    <td title="Starts">
                      <span className={styles.title}>
                        S<span className={styles.mobileHide}>tarts</span>:
                      </span>
                      <span className={styles.dataItem}>95</span>
                      {/* <span className={styles.dataItem}>
                        {trainerStats?.all?.numberOfRuns}
                      </span> */}
                    </td>
                    <td>
                      <span className={styles.title}>
                        W<span className={styles.mobileHide}>ins</span>:
                      </span>
                      <span className={styles.dataItem}>14</span>
                      {/* <span className={styles.dataItem}>
                        {trainerStats?.all?.firsts}
                      </span> */}
                    </td>
                    <td>
                      <span className={styles.title}>ROI:</span>
                      <span className={styles.dataItem}>$1.17</span>
                      {/* <span className={styles.dataItem}>
                        {trainerStats?.all?.returnOnInvestment}
                      </span> */}
                    </td>
                  </tr>
                  <tr>
                    <th>Dirt:</th>
                    <td>
                      <span className={styles.title}>
                        S<span className={styles.mobileHide}>tarts</span>:
                      </span>
                      <span className={styles.dataItem}>95</span>
                    </td>
                    <td>
                      <span className={styles.title}>
                        W<span className={styles.mobileHide}>ins</span>:
                      </span>
                      <span className={styles.dataItem}>14</span>
                    </td>
                    <td>
                      <span className={styles.title}>ROI:</span>
                      <span className={styles.dataItem}>$1.30</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Sprint:</th>
                    <td>
                      <span className={styles.title}>
                        S<span className={styles.mobileHide}>tarts</span>:
                      </span>
                      <span className={styles.dataItem}>5</span>
                    </td>
                    <td>
                      <span className={styles.title}>
                        W<span className={styles.mobileHide}>ins</span>:
                      </span>
                      <span className={styles.dataItem}>0</span>
                    </td>
                    <td>
                      <span className={styles.title}>ROI:</span>
                      <span className={styles.dataItem}>$1.88</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Stakes:</th>
                    <td>
                      <span className={styles.title}>
                        S<span className={styles.mobileHide}>tarts</span>:
                      </span>
                      <span className={styles.dataItem}>95</span>
                    </td>
                    <td>
                      <span className={styles.title}>
                        W<span className={styles.mobileHide}>ins</span>:
                      </span>
                      <span className={styles.dataItem}>9</span>
                    </td>
                    <td>
                      <span className={styles.title}>ROI:</span>
                      <span className={styles.dataItem}>$11.11</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          {!meetingType?.includes(MeetingType.Greyhound) && (
            <section className={styles.section}>
              <div className={styles.innerSection}>
                <div className={clsx(styles.title, styles.marginBottom)}>
                  Jockey / Trainer 2020-2021
                </div>
                <table className={styles.table}>
                  <tbody>
                    <tr>
                      <th>Last 31-60 days:</th>
                      <td>
                        <span className={styles.title}>
                          S<span className={styles.mobileHide}>tarts</span>:
                        </span>
                        <span className={styles.dataItem}>95</span>
                      </td>
                      <td>
                        <span className={styles.title}>
                          W<span className={styles.mobileHide}>ins</span>:
                        </span>
                        <span className={styles.dataItem}>95</span>
                      </td>
                      <td>
                        <span className={styles.title}>
                          W<span className={styles.mobileHide}>ins</span>:
                        </span>
                        <span className={styles.dataItem}>14</span>
                      </td>
                      <td>
                        <span className={styles.title}>ROI:</span>
                        <span className={styles.dataItem}>$1.17</span>
                      </td>
                    </tr>
                    <tr>
                      <th>All:</th>
                      <td>
                        <span className={styles.title}>
                          S<span className={styles.mobileHide}>tarts</span>:
                        </span>
                        <span className={styles.dataItem}>95</span>
                      </td>
                      <td>
                        <span className={styles.title}>
                          W<span className={styles.mobileHide}>ins</span>:
                        </span>
                        <span className={styles.dataItem}>95</span>
                      </td>
                      <td>
                        <span className={styles.title}>
                          W<span className={styles.mobileHide}>ins</span>:
                        </span>
                        <span className={styles.dataItem}>14</span>
                      </td>
                      <td>
                        <span className={styles.title}>ROI:</span>
                        <span className={styles.dataItem}>$1.30</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          )}
        </>
      )}
    </div>
  );
}
