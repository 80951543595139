import clsx from 'clsx';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import Icon from '../../../components/Icon/Icon';
import Silk from '../../../components/Silk/Silk';
import { BetStatus } from '../../../constants/betStatus';
import useRace from '../../../hooks/useRace/useRace';
import { CheckBetResponse } from '../../../utils/betslip/types';
import BetPlacedStatus from './BetPlacedStatus';
import styles from './BetReviewedModal.module.css';

export default function BetReviewedModal({
  bet,
  onDismiss,
}: {
  bet: CheckBetResponse;
  onDismiss: () => void;
}) {
  const status = BetStatus[bet.bet_details.status];
  const { race } = useRace(bet.raceId);
  const competitor = race?.competitors?.find(
    (c) => c.tabNo === bet.bet_details.runner_number
  );
  return (
    <div>
      <Card className={styles.receipt}>
        <div className={styles.heading}>
          <span>Bet Reviewed</span>
          <button
            type="button"
            className={styles.closeButton}
            onClick={onDismiss}
          >
            <Icon name="cross" />
          </button>
        </div>
        <div className={clsx(styles.container, styles[status])}>
          <div className={styles.bet}>
            <div className={styles.race}>
              <div className={styles.competitor}>
                <div className={styles.silkContainer}>
                  {competitor && race?.meeting && (
                    <Silk competitor={competitor} meeting={race?.meeting} />
                  )}
                </div>
                <div className={styles.competitorDetails}>
                  <span className={styles.competitorName}>
                    <div
                      className={styles.runner}
                    >{`${bet.bet_details.runner_number}. ${bet.bet_details.runner_name} (${competitor?.barrier})`}</div>
                  </span>
                  {`${bet.bet_details.bet_type} . ${bet.bet_details.meeting_name} - R${bet.bet_details.race_number}`}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.betDetails}>
            <span
              className={styles.bold}
            >{`Fixed ${bet.bet_details.odds}`}</span>
            <span>
              <span>Cost:</span>
              <span
                className={styles.bold}
              >{` $${bet.bet_details.stake}`}</span>
            </span>
            <span>
              <span>Potential Return:</span>
              <span className={styles.bold}>{` $${
                bet.bet_details.odds * bet.bet_details.stake
              }`}</span>
            </span>
          </div>
          <BetPlacedStatus checkBetResponse={bet} />
        </div>
        <div className={styles.actionButtons}>
          <Button size="large" onClick={onDismiss} theme="dark">
            Done
          </Button>
        </div>
      </Card>
    </div>
  );
}
