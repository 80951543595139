import clsx from 'clsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MeetingType } from '../../generated/graphql';
import { RootState } from '../../stores/store';
import { RaceCompetitor } from '../../types/racing';
import { SilkType } from '../../types/silk';
import styles from './Silk.module.css';

// Defaults to AUS silks below if no match here
const greyHoundSilksLookup = {
  AUS: { src: '/AUSGreysSprite_lrg.png', offset: 64, qty: 10 },
  NZL: { src: '/AUSGreysSprite_lrg.png', offset: 64, qty: 10 },
  GBR: { src: '/UKGreysSprite_lrg.png', offset: 64, qty: 8 },
  USA: { src: '/USGreysSprite_lrg.png', offset: 64, qty: 9 },
  MEX: { src: '/USGreysSprite_lrg.png', offset: 64, qty: 9 },
};

export default function Silk({
  meeting,
  competitor,
  small = false,
  outline = false,
  overwriteSilkType,
}: {
  meeting: {
    type: MeetingType;
    track: {
      country: string;
    };
  };
  competitor: Pick<RaceCompetitor, 'name' | 'tabNo' | 'silksURL'> & {
    coupledRunnerId?: RaceCompetitor['coupledRunnerId'];
  };
  small?: boolean;
  outline?: boolean;
  overwriteSilkType?: SilkType;
}) {
  const [isSilkError, setIsSilkError] = useState(false);
  let silkType = useSelector(
    (state: RootState) => state.config.options?.silkType
  );
  const contentUrl = useSelector((state: RootState) => state.config.contentUrl);
  const showOutline = outline && meeting.type !== MeetingType.Greyhound;

  if (overwriteSilkType) silkType = overwriteSilkType;

  function renderSilk() {
    const getGreyhoundSilkSettings = () => {
      const silkSrcSettings =
        greyHoundSilksLookup?.[meeting.track.country] ||
        greyHoundSilksLookup.AUS;

      const { src, offset, qty } = silkSrcSettings;
      const calculatedOffset = ((competitor.tabNo - 1) % qty) * offset;

      return { src, calculatedOffset };
    };

    const greyhoundSilkSettings = getGreyhoundSilkSettings();

    return meeting.type === MeetingType.Greyhound ? (
      <img
        className={clsx(styles.greyhoundSilkSpritesheet, {
          [styles.defaultSilk]: isSilkError,
        })}
        src={`${contentUrl}${greyhoundSilkSettings?.src}`}
        alt={competitor?.name}
        style={{
          top: `-${greyhoundSilkSettings?.calculatedOffset}px`,
        }}
        onError={(event) => {
          // eslint-disable-next-line no-param-reassign
          event.currentTarget.src = `${contentUrl}/default_silk.png`;
          setIsSilkError(true);
        }}
      />
    ) : (
      <img
        className={clsx(styles.silkSpritesheet, {
          [styles.defaultSilk]: isSilkError,
        })}
        src={competitor.silksURL}
        alt={competitor?.name}
        onError={(event) => {
          // Absolute link below only for SDK embedded demo.
          // To be restored to relative '/default_silk.png' link after demo.
          // eslint-disable-next-line no-param-reassign
          event.currentTarget.src = `${contentUrl}/default_silk.png`;
          setIsSilkError(true);
        }}
      />
    );
  }

  const runnerNumber =
    (competitor?.coupledRunnerId?.length || 0) > 1
      ? competitor.coupledRunnerId
      : competitor.tabNo;
  function renderSaddleCloth() {
    return (
      <div
        className={clsx(styles.saddlecloth, styles[`number${runnerNumber}`])}
      >
        {runnerNumber}
      </div>
    );
  }

  function renderBoth() {
    return (
      <div className={styles.bothContainer}>
        {renderSilk()}
        <div className={styles.bothSaddleCloth}>{renderSaddleCloth()}</div>
      </div>
    );
  }

  function renderSprites() {
    switch (silkType) {
      case 'SILK':
        return renderSilk();
      case 'SADDLECLOTH':
        return renderSaddleCloth();
      case 'BOTH':
      default:
        return renderBoth();
    }
  }

  return (
    <div className={styles.spriteContainer}>
      <div className={styles.container}>
        {showOutline && (
          <div
            className={clsx(styles.silkOutline, {
              [styles.smallOutline]: small,
            })}
          >
            <img
              // Relative link not working on embedded demo.
              // Restore the below src when demo no longer required
              // src="/silk-outline.svg"
              src={`${contentUrl}/silk-outline.svg`}
              alt=""
            />
          </div>
        )}
        <div
          className={clsx(styles.silkContainer, {
            [styles.small]: small,
            [styles.silkPosition]: small && showOutline,
          })}
        >
          {renderSprites()}
        </div>
      </div>
    </div>
  );
}
