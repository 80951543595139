import Icon, { IconName } from '../Icon/Icon';
import styles from './SportTeamsName.module.css';

type TeamsProps = {
  homeTeam: { name: string };
  awayTeam: { name: string };
  sportType: string;
};
export default function SportTeamsName({
  homeTeam = { name: 'a' },
  awayTeam = { name: 'b' },
  sportType,
}: TeamsProps) {
  return (
    <div className={styles.teamNamesContainer}>
      {/* <div className={styles.icon}>
        <Icon name="basketball" />
      </div> */}
      <div className={styles.icon}>
        <Icon name={sportType as IconName} />
      </div>
      <div className={styles.teamNames}>
        <div className={styles.teamName}>
          <div>{homeTeam.name}</div>
        </div>
        <span className={styles.seperatorText}>v</span>
        <div className={styles.teamName}>
          <div>{awayTeam.name}</div>
        </div>
      </div>
    </div>
  );
}
