import React from 'react';
import { ExtendedSportEvents } from '../../types/sport';
import SportCardInfo from '../SportCardInfo/SportCardInfo';
import styles from './SportCardHeader.module.css';

type SportCardHeaderProps = {
  sportEvent: ExtendedSportEvents | undefined;
  loading: boolean;
  routes: { label: string; url: string }[];
};

const SportCardHeader = ({
  sportEvent,
  loading,
  routes,
}: SportCardHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <SportCardInfo
          sportEvent={sportEvent}
          loading={loading}
          routes={routes}
        />
      </div>
    </div>
  );
};

export default SportCardHeader;
