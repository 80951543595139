import { startCase } from 'lodash';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FavouriteButton from '../FavouriteButton/FavouriteButton';
import Icon, { IconName } from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import styles from './CategoryListItem.module.css';

export type CategoryListItemProps = {
  label: string;
  icon?: IconName;
  sportType: string;
  path: string;
  isFavourite?: boolean;
  showFavourite?: boolean;
  toggleFavorite?: (lable: string) => {};
  showRightArrow?: boolean;
};

const CategoryListItem = ({
  label,
  icon,
  isFavourite,
  toggleFavorite,
  showFavourite,
  showRightArrow,
  path,
}: CategoryListItemProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <Link to={path} className={styles.link}>
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} />
          </div>
        )}
        <div className={styles.label}>{startCase(label)}</div>
      </Link>
      <div>
        {(showFavourite || isFavourite) && (
          <FavouriteButton
            isFavourite={isFavourite}
            onClick={() => toggleFavorite && toggleFavorite(label)}
          />
        )}
        {showRightArrow && (
          <IconButton
            iconName="caretRight"
            onClick={() => {
              navigate(path);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryListItem;
