import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Toggle.module.css';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  id: string;
};

const Toggle = forwardRef<HTMLInputElement, Props>(
  ({ className = '', id, ...rest }, ref) => (
    <>
      <input
        type="checkbox"
        id={id}
        className={clsx(styles.input, className)}
        ref={ref}
        {...rest}
      />
      <label htmlFor={id} className={styles.label}>
        Toggle
      </label>
    </>
  )
);

export default Toggle;
