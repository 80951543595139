import { isEmpty, sortBy } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import {
  SportMarketsDocument,
  SportMarketsQuery,
  SportMarketsQueryVariables,
} from '../../generated/graphql';

const useSportMarkets = (params: SportMarketsQueryVariables) => {
  const [{ data, ...rest }] = useQuery<
    SportMarketsQuery,
    SportMarketsQueryVariables
  >({
    query: SportMarketsDocument,
    pause: isEmpty(params.eventId),
    variables: {
      eventId: params.eventId,
    },
  });

  const sorted = useMemo(() => {
    if (!data?.sportMarkets) return [];
    return sortBy(data.sportMarkets, 'name');
  }, [data?.sportMarkets]);

  return {
    data,
    sportMarkets: sorted,
    ...rest,
  };
};

export default useSportMarkets;
