import clsx from 'clsx';
import dayjs from 'dayjs';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FixedOddsBadge from '../../../../components/Badge/FixedOddsBadge';
import CountryFlag, {
  FlagName,
} from '../../../../components/CountryFlag/CountryFlag';
import HorizontalOverflow from '../../../../components/HorizontalOverflow/HorizontalOverflow';
import RaceStatus from '../../../../components/RaceStatus/RaceStatus';
import {
  MeetingsTodayQuery,
  RaceStatus as RaceStatusEnum,
} from '../../../../generated/graphql';
import useFormat from '../../../../hooks/useFormat';
import { RootState } from '../../../../stores/store';
import styles from './RacingGrid.module.css';

type Props = {
  meetings?: MeetingsTodayQuery['meetingsDated'];
};

export default function RacingGrid({ meetings = [] }: Props) {
  const maxRaces = meetings.reduce(
    (max, meeting) => (meeting.races.length > max ? meeting.races.length : max),
    0
  );

  if (meetings.length === 0) {
    return (
      <span className={styles.noMeetingsAvailable}>No meetings available.</span>
    );
  }

  return (
    <div className={styles.container}>
      <HorizontalOverflow roundedCorner showArrows scrollWidth={82}>
        <div className={styles.desktop}>
          <div className={styles.meetingColumn}>
            <div className={clsx(styles.header, styles.blankHeader)} />
            <div className={styles.meetingWrapper}>
              {meetings.map((meeting) => (
                <Link
                  key={meeting.id}
                  className={clsx(
                    styles.race,
                    styles.trackContainer,
                    styles.meetingItem
                  )}
                  to={`/racing/meeting/${meeting.id}`}
                >
                  <div className={styles.track}>
                    <span className={styles.name}>{meeting.track.name}</span>
                    <span className={styles.country}>
                      {meeting.track.country === 'USA' ||
                      meeting.track.country === 'AUS'
                        ? meeting.track.state
                        : meeting.track.country}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div
            className={styles.grid}
            style={{ gridTemplateColumns: `repeat(${maxRaces}, 1fr)` }}
          >
            {Array.from(Array(maxRaces), (_, x) => x).map((x) => (
              <div key={x} className={styles.header}>
                {x + 1}
              </div>
            ))}
            {meetings.map((meeting) => (
              <Fragment key={meeting.id}>
                {meeting.races.map((race) => (
                  <Link
                    key={race.id}
                    className={clsx(styles.race, {
                      [styles.open]: race.status === RaceStatusEnum.Open,
                    })}
                    to={`/racing/meeting/${meeting.id}/race/${race.id}`}
                  >
                    {race.status === RaceStatusEnum.Final ? (
                      <span className={styles.results}>
                        {race.results?.[race.results.length - 1]?.positions
                          .slice(0, 4)
                          .map((pos) => pos.tabNo)
                          .join(', ')}
                      </span>
                    ) : (
                      <RaceStatus
                        startTime={race.startTime}
                        status={race.status}
                      />
                    )}
                    <div className={styles.badges}>
                      <RaceBadges race={race} />
                    </div>
                  </Link>
                ))}
                {Array.from(
                  Array(maxRaces - meeting.races.length),
                  (_, x) => x
                ).map((x) => (
                  <div key={x} className={styles.race} />
                ))}
              </Fragment>
            ))}
          </div>
        </div>
      </HorizontalOverflow>
      <div className={styles.mobile}>
        <div className={styles.list}>
          {meetings.map((meeting) => (
            <MobileMeeting key={meeting.id} meeting={meeting} />
          ))}
        </div>
      </div>
    </div>
  );
}

function MobileMeeting({
  meeting,
}: {
  meeting: MeetingsTodayQuery['meetingsDated'][0];
}) {
  const [expanded, setExpanded] = useState(false);
  const toteEnabled = useSelector(
    (state: RootState) => state.config.options.toteEnabled
  );

  const format = useFormat();

  const nextRace =
    meeting.races.filter((race) => race.status === RaceStatusEnum.Open)?.[0] ||
    meeting.races[meeting.races.length - 1];

  if (!nextRace) return null;

  return (
    <>
      <div
        className={styles.meeting}
        onClick={() => setExpanded((prev) => !prev)}
        role="button"
        tabIndex={-1}
      >
        <div className={styles.track}>
          <CountryFlag
            countryCode={meeting.track.country as FlagName}
            size="medium"
          />
          <span className={styles.raceNumber}>R{nextRace?.number}</span>
          <span className={styles.name}>{meeting.track.name}</span>
          &nbsp;
          <span className={styles.country}>
            {meeting.track.country === 'USA' || meeting.track.country === 'AUS'
              ? meeting.track.state
              : meeting.track.country}
          </span>
        </div>
        <div className={styles.secondary}>
          <div className={styles.badges}>
            <RaceBadges race={nextRace} />
          </div>
          <div className={styles.lozenge}>
            <RaceStatus
              startTime={nextRace.startTime}
              status={nextRace.status}
            />
          </div>
        </div>
      </div>
      {expanded
        ? meeting.races.map((race) => (
            <Link
              key={race.id}
              className={styles.race}
              to={`/racing/meeting/${meeting.id}/race/${race.id}`}
            >
              <div className={styles.circle}>
                <span>R{race.number}</span>
              </div>
              <div className={styles.column}>
                <div className={styles.row}>
                  <span className={styles.trackName}>{meeting.track.name}</span>
                  <div className={styles.secondary}>
                    {race.status === RaceStatusEnum.Final ? (
                      <span className={styles.results}>{race.status}</span>
                    ) : (
                      <>
                        <RaceBadges race={race} />
                        <div className={styles.lozenge}>
                          <RaceStatus
                            startTime={race.startTime}
                            status={race.status}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  {toteEnabled && (
                    <span className={styles.prizeMoney}>
                      Win Pool: {format.currency(race.prizeMoney)}
                    </span>
                  )}
                  <span className={styles.time}>
                    {dayjs(race.startTime).format('h:mma')}
                  </span>
                </div>
              </div>
            </Link>
          ))
        : null}
    </>
  );
}

function RaceBadges({
  race,
}: {
  race: MeetingsTodayQuery['meetingsDated'][0]['races'][0];
}) {
  return (
    <>
      {race.status === RaceStatusEnum.Open && race.fixedAvailable ? (
        <FixedOddsBadge />
      ) : null}
    </>
  );
}
