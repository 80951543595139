import { PayloadAction } from '@reduxjs/toolkit';
import { BetType } from '../../../constants/betTypes';
import {
  findParlayBetIndex,
  findSingleBetIndex,
  getBetDetails,
  indexOfBetTypeSelectedOnRace,
  removeInvalidParlays,
} from '../../../utils/betslip/common';
import {
  AddOrRemoveParlayDetail,
  GenerateParlays,
  UpdateParlayBetStake,
} from '../../../utils/betslip/eventDispatcher';
import {
  AllSingleBet,
  BetslipState,
  ParlayBet,
  SportSingleBet,
} from '../../../utils/betslip/types';

export const addOrRemoveParlaysReducer = (
  state: BetslipState,
  action: PayloadAction<Omit<AddOrRemoveParlayDetail, 'type'>>
) => {
  const bet = action.payload as AllSingleBet;

  const existingBetIndex = findSingleBetIndex(state.parlaySelections, bet);
  const existingParlayBetIndex = findParlayBetIndex(state.betslip);

  // Bet is already in selections
  if (existingBetIndex !== -1) {
    const selections = state.parlaySelections.filter(
      (_, index) => index !== existingBetIndex
    );

    state.parlaySelections.splice(existingBetIndex, 1);
    if (existingParlayBetIndex !== -1) {
      const parlayBet = state.betslip[existingParlayBetIndex] as ParlayBet;
      parlayBet.selections = selections;
    }
  } else {
    const incompatibleBetIndex = indexOfBetTypeSelectedOnRace(
      state.parlaySelections,
      bet
    );
    if (incompatibleBetIndex !== -1)
      state.parlaySelections.splice(incompatibleBetIndex, 1);

    if (existingParlayBetIndex !== -1) {
      const parlayBet = state.betslip[existingParlayBetIndex] as ParlayBet;
      parlayBet.selections.push(bet);
    }

    state.betslip = removeInvalidParlays(state.betslip);
    state.parlaySelections.push(bet);
  }
};

export const generateParlaysReducer = (
  state: BetslipState,
  action: PayloadAction<Omit<GenerateParlays, 'type'>>
) => {
  const { parlaySelections } = state;

  const bet = action.payload as SportSingleBet;

  /** To chec if Event already exist in betslip */
  const existingEventBet = parlaySelections.find(
    (b) => getBetDetails(b)?.id === bet.eventDetails?.id
  );

  /** To chec if bet is being added or removed */
  const existingBetIndex = findSingleBetIndex(state.betslip, bet);

  const addingToBetSlip = existingBetIndex >= 0;

  const sportsBets = state.betslip.filter((b) => b.betType === BetType.SPORT);

  const parlayGroups = Array.from(
    new Set(sportsBets.map((b) => getBetDetails(b)?.id))
  ).map((id) => ({
    bets: sportsBets.filter((b) => getBetDetails(b)?.id === id),
  }));

  if (addingToBetSlip) {
    /**
     * If the new bet belongs to an event
     * thats already in the betslip
     * Dont call addOrRemoveParlaysReducer(Don't change what user has already selected)
     * Otherwise add it to the parlay selections array
     */
    if (!existingEventBet) {
      addOrRemoveParlaysReducer(state, { payload: bet, type: '' });
    }
    /**
     * If adding a new bet and there is one bet already in the betslip
     * add the previous bet too
     * This happens when deslecting previous bets and leaving one selected and then adding
     * a new bet from a different event
     */
    if (parlayGroups.length === 2 && parlaySelections.length === 1) {
      const previousBets = state.betslip.filter(
        (b: any) => b.eventDetails.id !== bet.eventDetails?.id
      );

      if (previousBets.length) {
        addOrRemoveParlaysReducer(state, {
          payload: previousBets[0] as AllSingleBet,
          type: '',
        });
      }
    }
  } else if (parlaySelections.length === 1) {
    state.parlaySelections = [];
  } else {
    state.parlaySelections = state.parlaySelections.filter(
      (p: any) => p.selection.id !== bet.selection.id
    );
  }
};

export const updateParlayStakeReducer = (
  state: BetslipState,
  action: PayloadAction<Omit<UpdateParlayBetStake, 'type'>>
) => {
  const { stake, betType } = action.payload;

  if (betType !== BetType.PARLAY) {
    return state;
  }

  const existingParlayBetIndex = findParlayBetIndex(state.betslip);
  if (existingParlayBetIndex !== -1) {
    const updatedBetslip =
      stake > 0
        ? state.betslip.map((bet, i) =>
            existingParlayBetIndex !== i ? bet : { ...bet, stake }
          )
        : state.betslip.filter((bet) => bet.betType !== BetType.PARLAY);

    return {
      ...state,
      betslip: updatedBetslip,
    };
  }
  if (stake > 0) {
    const parlayBet = {
      betType: BetType.PARLAY,
      selections: state.parlaySelections,
      stake,
    };

    return {
      ...state,
      betslip: [...state.betslip, parlayBet],
    };
  }

  return state;
};
