import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Countdown from '../../../components/Countdown/Countdown';
import Icon from '../../../components/Icon/Icon';
import Silk from '../../../components/Silk/Silk';
import { BetslipStage } from '../../../constants/betStatus';
import { BetTypeLookup } from '../../../constants/betTypes';
import { RaceStatus } from '../../../generated/graphql';
import useFormat from '../../../hooks/useFormat';
import {
  addOrRemoveBet,
  removeBetByBetTypeRace,
} from '../../../stores/betslipSlice';
import { RootState } from '../../../stores/store';
import { SilkType } from '../../../types/silk';
import { isSingleBet } from '../../../utils/betslip/common';
import { SingleBet } from '../../../utils/betslip/types';
import AdditionalWagers from '../AdditionalWagers/AdditionalWagers';
import { TraditionalSingleBet } from '../TraditionalBetslip/types';
import BetOdds from './BetOdds';
import BetStatusBar from './BetStatusBar';
import SetStakeInput from './SetStakeInput';
import styles from './SingleBetCard.module.css';

export default function SingleBetCard({
  bet,
  traditional = false,
}: {
  bet: TraditionalSingleBet | SingleBet;
  traditional?: boolean;
}) {
  const { betType, race, meeting, stake } = bet;
  const format = useFormat();
  const dispatch = useDispatch();
  const betslipStage = useSelector(
    (state: RootState) => state.betslip.betslipStage
  );

  const showClearButton = Boolean(bet.stake && bet.stake > 0);
  const bettingOpen = Boolean(race.status === RaceStatus.Open);
  const alternateState = bet?.betStatus || betslipStage !== BetslipStage.NORMAL;

  function getBettingClosedReason() {
    if (race.status === RaceStatus.Abandoned) {
      return 'Abandoned';
    }
    if (race.status !== RaceStatus.Open) {
      return `Market Closed`;
    }
    return '';
  }

  function getSelections() {
    if ('selections' in bet) {
      return bet.selections;
    }
    if ('competitor' in bet) {
      return [bet.competitor];
    }
    return [];
  }

  function handleRemoveBet() {
    if (isSingleBet(bet)) {
      dispatch(
        addOrRemoveBet({
          traditional,
          ...bet,
        })
      );
    } else {
      dispatch(
        removeBetByBetTypeRace({
          raceId: race.id,
          betType,
        })
      );
    }
  }
  const selections = getSelections();
  const potentialReturn = (bet?.odds || 0) * (stake || 0);
  const betStatus = bet?.betStatus || '';
  return (
    <>
      <div className={clsx(styles.betItem, styles[betStatus])}>
        <div className={styles.header}>
          <Icon name={meeting.type} className={styles.icon} size="medium" />
          <span className={styles.betType}>{BetTypeLookup[betType]}</span>
          {!alternateState && (
            <>
              {bettingOpen && (
                <span className={styles.stake}>
                  {format.currency((stake || 0) * selections.length, 2)}
                </span>
              )}
              {!bettingOpen && (
                <span className={styles.headerMessage}>
                  {getBettingClosedReason()}
                </span>
              )}
              <div>
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={handleRemoveBet}
                >
                  <Icon name="cross" size="small" />
                </button>
              </div>
            </>
          )}
        </div>
        <span className={styles.race}>
          {meeting.track.name} R{race.number}
          {race.status === RaceStatus.Open ? (
            <Countdown
              startTime={race.startTime}
              className={styles.countdown}
              onlyShowUrgent
            />
          ) : null}
        </span>
        <div className={styles.selectionsContainer}>
          {selections.length === 1 && (
            <div className={styles.silkContainer}>
              <Silk
                competitor={selections[0]}
                meeting={meeting}
                small
                outline
                overwriteSilkType={SilkType.Silk}
              />
            </div>
          )}
          <div className={styles.selections}>
            {selections
              .reduce<{
                consecutive?: number;
                labels: string[];
              }>(
                (acc, competitor, index) => {
                  const nextTabNo = selections?.[index + 1]?.tabNo;

                  // Start consecutive
                  if (!acc.consecutive && nextTabNo === competitor.tabNo + 1) {
                    return {
                      ...acc,
                      consecutive: competitor.tabNo,
                    };
                  }

                  // Ignore consecutive
                  if (acc.consecutive && nextTabNo === competitor.tabNo + 1) {
                    return acc;
                  }

                  // Close consecutive
                  if (acc.consecutive && nextTabNo !== competitor.tabNo + 1) {
                    return {
                      ...acc,
                      consecutive: undefined,
                      labels: [
                        ...acc.labels,
                        `${acc.consecutive} - ${competitor.tabNo}`,
                      ],
                    };
                  }

                  return {
                    ...acc,
                    labels: [
                      ...acc.labels,
                      `${competitor.tabNo}. ${competitor.name}`,
                    ],
                  };
                },
                {
                  labels: [],
                }
              )
              .labels.join(', ')}
          </div>
          {!alternateState && (
            <div className={styles.price}>
              Fixed <BetOdds price={bet?.odds} label="Fixed" />
            </div>
          )}
        </div>
        {!alternateState && bettingOpen && (
          <SetStakeInput
            bet={bet}
            showClearButton={showClearButton}
            traditional={traditional}
          />
        )}
        <div className={styles.return}>
          {alternateState && (
            <>
              <div>
                Fixed <span>{format.odds(bet.odds)}</span>
              </div>
              <div>
                Cost: <span>{format.currency(bet.stake, 2)}</span>
              </div>
            </>
          )}
          {bettingOpen && (
            <div>
              Potential Return:{' '}
              <span>{format.currency(potentialReturn, 2)}</span>
            </div>
          )}
        </div>
      </div>
      <BetStatusBar
        price={bet.odds}
        stake={bet.stake}
        betslipStage={betslipStage}
        betStatus={bet.betStatus}
      />
      <AdditionalWagers bet={{ ...bet, selections }} />
    </>
  );
}
