/* eslint-disable react/no-array-index-key */
import { lowerCase, sortBy } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import {
  Carousel,
  CategoryListItem,
  CategorySeperator,
} from '../../../../components';
import H2HCard from '../../../../components/H2HCard/H2HCard';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import {
  SportCategoriesDocument,
  SportCategoriesQuery,
  SportCategoriesQueryVariables,
} from '../../../../generated/graphql';
import { ExtendedSportEvents } from '../../../../types/sport';
import slugify from '../../../../utils/slugify';
import styles from './SportAll.module.css';

export default function SportAll({
  sportTypeId,
  sportName,
  events,
}: {
  sportTypeId?: number;
  sportName: string;
  events: ExtendedSportEvents[];
}) {
  const [{ data, fetching }] = useQuery<
    SportCategoriesQuery,
    SportCategoriesQueryVariables
  >({
    query: SportCategoriesDocument,
    variables: {
      sportTypeId,
    },
  });

  const sorted = useMemo(
    () => sortBy(data?.sportCategories, 'name'),
    [data?.sportCategories]
  );

  return (
    <div className={styles.container}>
      {events.length > 0 && (
        <>
          <CategorySeperator label="Popular" />
          <Carousel>
            {events.map((sportEvent) => (
              <Carousel.CarouselItem key={sportEvent.id}>
                <H2HCard sportEvent={sportEvent} type="h2h" showLeagueName />
              </Carousel.CarouselItem>
            ))}
          </Carousel>
        </>
      )}
      {fetching && <LoadingSpinner />}
      <div className={styles.leagueContainer}>
        <CategorySeperator label="All" />
        {sorted.map((category) => (
          <CategoryListItem
            key={category.id}
            label={category.description}
            showRightArrow
            sportType={category.name}
            path={`/sports/competition/${lowerCase(sportName)}/${slugify(
              category.name
            )}/matches`}
          />
        ))}
      </div>
    </div>
  );
}
