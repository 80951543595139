import clsx from 'clsx';
import Icon, { IconSize } from '../Icon/Icon';
import styles from './Checkbox.module.css';

interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string;
  size?: IconSize;
}

export default function Checkbox({
  label,
  size,
  checked,
  ...rest
}: CheckboxProps) {
  return (
    <label className={styles.checkboxWrapper}>
      <input
        type="checkbox"
        className={styles.input}
        checked={checked}
        {...rest}
      />
      <div className={clsx(styles.checkbox, size ? styles[size] : null)}>
        {checked && (
          <Icon name="checkmark" className={styles.icon} size={size} />
        )}
      </div>
    </label>
  );
}
