import { useEffect, useRef } from 'react';
import styles from './PercentageBar.module.css';

export default function PercentageBar({ percentage }: { percentage: number }) {
  const barRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (barRef?.current) {
      barRef.current.style.transform = `rotate(${45 + percentage * 1.8}deg)`;
    }
  }, [percentage]);
  return (
    <div className={styles.progress}>
      <div className={styles.barOverflow}>
        <div className={styles.bar} ref={barRef} />
      </div>
      <span>{percentage}</span>%
    </div>
  );
}
