import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { gql, useQuery } from 'urql';
import HorizontalOverflow from '../../components/HorizontalOverflow/HorizontalOverflow';
import {
  FeaturedRaceFragment,
  FeaturedRaceFragmentDoc,
} from '../../generated/graphql';
import { RootState } from '../../stores/store';
import FeaturedRace from './FeaturedRace';
import styles from './FeaturedRaces.module.css';

type Props = {
  title: string;
  baseUrl: string;
  races: {
    raceId: string;
    date: string;
    race: {
      name: string;
      number: number;
    };
  }[];
};

export default function FeaturedRaces({ title, baseUrl, races }: Props) {
  const source = useSelector((state: RootState) => state.config.coreApiSource);

  const [results] = useQuery<
    {
      [key: string]: FeaturedRaceFragment;
    },
    {
      source: string;
    }
  >({
    query: gql`query featuredRaces($source: String!) {
      ${races
        .map(
          (race, index) => `race_${index}: race(id: "${race.raceId}") {
            ...featuredRace
          }`
        )
        .join('\n')}
    }

    ${FeaturedRaceFragmentDoc}
    
    `,
    variables: {
      source,
    },
  });

  return (
    <div>
      <div className={styles.header}>
        <h2>{title}</h2>
      </div>
      <HorizontalOverflow scrollWidth={240}>
        <div
          className={clsx(styles.contentContainer, {
            [styles.fullWidth]: races.length <= 3,
          })}
        >
          {Object.values(results.data || {}).map((race) => (
            <FeaturedRace key={race.id} defaultRace={race} baseUrl={baseUrl} />
          ))}
        </div>
      </HorizontalOverflow>
    </div>
  );
}
