// learn more about this function (method 1 in this link): https://www.geeksforgeeks.org/print-all-possible-combinations-of-r-elements-in-a-given-array-of-size-n/
export const createCombination = <T>(
  arr: T[],
  start: number,
  end: number,
  index: number,
  r: number,
  result: any[] = [],
  tempData: any[] = []
): T[][] => {
  if (index === r) {
    const combination: any[] = [];
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < r; j++) {
      combination.push(tempData[j]);
    }
    result.push(combination);
  }

  // eslint-disable-next-line no-plusplus
  for (let i = start; i <= end && end - i + 1 >= r - index; i++) {
    // eslint-disable-next-line no-param-reassign
    tempData[index] = arr[i];
    createCombination(arr, i + 1, end, index + 1, r, result, tempData);
  }

  return result;
};

export const generateNfoldMultiCombinations = <T>(
  selections: T[],
  foldLength: number
) => {
  const numberOfBets = selections.length;
  const result: T[][] = [];
  createCombination(selections, 0, numberOfBets - 1, 0, foldLength, result);
  return result;
};

export const generateAllPossibleCombos = <T>(
  selections: T[]
): { selections: T[][]; title: string }[] => {
  if (selections.length < 2) {
    return [];
  }
  // 1. find out the required folds
  const foldTitles = getFoldGroupTitle(selections.length);
  // 2. generate different folds
  const allCombinations = foldTitles.map((fold) => ({
    selections: generateNfoldMultiCombinations(selections, fold.foldLength),
    title: fold.title,
  }));
  return allCombinations;
};

type FoldGroupTitle = { title: string; foldLength: number };
export const getFoldGroupTitle = (numberOfBets: number): FoldGroupTitle[] => {
  const foldGroups = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 2; index < numberOfBets; index++) {
    if (index === 2) {
      foldGroups.push({ title: 'Double', foldLength: index });
    }
    if (index === 3) {
      foldGroups.push({ title: 'Treble', foldLength: index });
    }
    if (index > 3 && index <= 9) {
      foldGroups.push({ title: `${index}-fold`, foldLength: index });
    }
  }

  return foldGroups;
};
