import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'urql';
import CountryFilters from '../../components/CountryFilters/CountryFilters';
import HorizontalOverflow from '../../components/HorizontalOverflow/HorizontalOverflow';
import RacingFilters from '../../components/RacingFilters/RacingFilters';
import {
  FilterKey,
  NextToJumpNewDocument,
  NextToJumpNewQuery,
  NextToJumpNewQueryVariables,
} from '../../generated/graphql';
import { CountryFilter } from '../../stores/settingSlice';
import { RootState } from '../../stores/store';
import { PlatformType } from '../../types/platformType';
import styles from './NextToJump.module.css';
import NextToJumpRace from './NextToJumpRace';

type Props = {
  title: string;
  baseUrl: string;
};

const selector = createSelector(
  [
    (state: RootState) => state.setting.countryFilter,
    (state: RootState) => state.setting.typeFilters,
    (state: RootState) => state.config.options.localCountryFilter,
    (state: RootState) => state.config.options.toteEnabled,
    (state: RootState) =>
      state.setting.selectedPriceType === PlatformType.Fixed
        ? state.config.coreApiSource
        : state.config.coreApiToteSource,
  ],
  (countryFilter, typeFilters, localCountryFilter, toteEnabled, source) => ({
    countryFilter,
    typeFilters,
    localCountryFilter,
    toteEnabled,
    source,
  })
);

export default function NextToJump({
  title = 'Upcoming Races',
  baseUrl,
}: Props) {
  const [time, setTime] = useState(dayjs());
  const [races, setRaces] = useState<NextToJumpNewQuery['races']>([]);
  const {
    countryFilter,
    typeFilters,
    localCountryFilter,
    toteEnabled,
    source,
  } = useSelector(selector);
  const [results] = useQuery<NextToJumpNewQuery, NextToJumpNewQueryVariables>({
    query: NextToJumpNewDocument,
    variables: {
      from: time.toDate(),
      to: time.add(24, 'hours').toDate(),
      limit: 3,
      toteEnabled,
      source,
      include: [
        ...typeFilters.map((type) => ({
          key: FilterKey.MeetingType,
          value: type,
        })),
        ...(localCountryFilter && countryFilter === CountryFilter.Local
          ? localCountryFilter.countries.map((country) => ({
              key: FilterKey.Country,
              value: country,
            }))
          : []),
      ],
      exclude:
        localCountryFilter && countryFilter === CountryFilter.International
          ? localCountryFilter.countries.map((country) => ({
              key: FilterKey.Country,
              value: country,
            }))
          : [],
    },
  });
  useEffect(() => {
    if (results.data) {
      setRaces(results.data.races);
    }
  }, [results]);

  const onChangeStatus = useCallback(() => {
    setTime(dayjs());
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <h2>{title}</h2>
        <div className={styles.filters}>
          <RacingFilters />
          <CountryFilters />
        </div>
      </div>
      <HorizontalOverflow scrollWidth={150}>
        {races.map((race) => (
          <NextToJumpRace
            key={race.id}
            race={race}
            baseUrl={baseUrl}
            onChangeStatus={onChangeStatus}
          />
        ))}
      </HorizontalOverflow>
    </div>
  );
}
