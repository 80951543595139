import clsx from 'clsx';
import React from 'react';
import Icon, { IconName } from '../Icon/Icon';
import styles from './SportTeamJersey.module.css';

type SportTeamJerseyProps = {
  teamName: string;
  className?: string;
  jersey: IconName; // TODO CHANGE THIS.
};

const SportTeamJersey = ({
  teamName,
  jersey,
  className,
}: SportTeamJerseyProps) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.jerseyContainer}>
      <Icon name={jersey} />
    </div>
    <div className={styles.teamName}>
      <span>{teamName}</span>
    </div>
  </div>
);

export default SportTeamJersey;
