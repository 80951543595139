import {
  FloatingFocusManager,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { useState } from 'react';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import { GetFeaturedRaceQuery } from '../../generated/graphql';
import TipAuthor from './TipAuthor';
import TipRunner from './TipRunner';
import TipTrack from './TipTrack';
import styles from './UpcomingTip.module.css';

type Props = {
  comment: string;
  race: GetFeaturedRaceQuery['race'];
  resulted: boolean;
  tabNo: string;
};

export default function ReadMoreTipModal({
  comment,
  race,
  resulted,
  tabNo,
}: Props) {
  const [open, setOpen] = useState(false);
  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: setOpen,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <Button
        theme="none"
        size="small"
        className={styles.readmore}
        {...getReferenceProps({
          ref: reference,
        })}
      >
        Read more
      </Button>
      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context}>
            <Modal
              {...getFloatingProps({
                ref: floating,
              })}
            >
              <TipAuthor />
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: comment }}
              />
              <TipTrack race={race} />
              <TipRunner race={race} resulted={resulted} tabNo={tabNo} />
            </Modal>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}
