import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon, { IconName } from '../Icon/Icon';
import styles from './SectionSeperator.module.css';

type SectionSeperatorProps = {
  label: string;
  icon: IconName;
  className?: string;
  path: string;
};

const SectionSeperator = ({
  label,
  icon,
  className,
  path,
}: SectionSeperatorProps) => (
  <Link to={path} className={styles.link}>
    <div className={clsx(styles.container, className)}>
      <div className={styles.icon}>
        <Icon name={icon} />
      </div>
      <div className={styles.label}>{label}</div>
      <div>
        <div className={styles.icon}>
          <Icon name="caretRight" />
        </div>
      </div>
    </div>
  </Link>
);

export default SectionSeperator;
