/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import useSportsBetslip from '../../../hooks/useSportsBetslip/useSportsBetslip';
import { RootState } from '../../../stores/store';
import { ExtendedSportEvents, MarketSelection } from '../../../types/sport';
import { findSportsBetIndexInBetslip } from '../../../utils/betslip/common';
import SportOddsButton from '../../SportOddsButton/SportOddsButton';
import SportTeamsName from '../../SportTeamsName/SportTeamsName';
import styles from './HeadToHeadMarket.module.css';

type HeadToHeadMarketProps = {
  sportEvent: ExtendedSportEvents;
  navigateToEvent: () => void;
};

const HeadToHeadMarket = ({
  sportEvent,
  navigateToEvent,
}: HeadToHeadMarketProps) => {
  const { teams, matchMarket } = sportEvent;

  const { addToBetslip } = useSportsBetslip();

  const betslip = useSelector((state: RootState) => state.betslip.betslip);

  const sportType = sportEvent?.competition?.category?.sport_type.name;

  const homeTeam = teams && teams[0] ? teams[0] : { name: 'n/a' };
  const awayTeam = teams && teams[1] ? teams[1] : { name: 'n/a' };

  const addItem = (selection: MarketSelection) => {
    if (matchMarket) {
      addToBetslip({
        eventDetails: sportEvent,
        selection,
        market: matchMarket,
      });
    }
  };

  const marketsLength = matchMarket?.selections?.length || 0;

  return (
    <div
      className={styles.container}
      onClick={navigateToEvent}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          navigateToEvent();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <SportTeamsName
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        sportType={sportType}
      />
      <div className={styles.odds}>
        {matchMarket?.selections &&
          matchMarket.selections.map((selection, i) => {
            const odd = selection.prices
              ? selection.prices[0].decimal_price
              : 0;
            return (
              <SportOddsButton
                key={selection.id}
                jerseyIcon={selection.name === 'Draw' ? undefined : 'chicago'}
                label={selection.name}
                odd={odd}
                className={styles.oddsbutton}
                isSelected={
                  findSportsBetIndexInBetslip(betslip, selection) !== -1
                }
                reverse={i === marketsLength - 1}
                onOddButtonClick={() => {
                  addItem(selection);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default HeadToHeadMarket;
