import styles from './ToggleButton.module.css';

interface CheckboxButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isChecked?: boolean;
  onChange: () => void;
}

export default function ToggleButton({
  isChecked = false,
  label,
  onChange,
}: CheckboxButtonProps) {
  return (
    <label className={styles.toggleButtonLabelContainer}>
      <input
        type="checkbox"
        className={styles.toggleButtonInput}
        checked={isChecked}
        onChange={onChange}
      />
      <span className={styles.toggleButtonLabel}>{label}</span>
    </label>
  );
}
