import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../stores/store';

const WARN_SECONDS = 900;
const URGENT_SECONDS = 300;
const WAY_PAST_SECONDS = -300;

export default function useCountdown(compareTime: Date) {
  const time = useSelector((state: RootState) => state.time.currentTime);
  const countdown = dayjs.duration(dayjs(compareTime).diff(time));
  const warning = countdown.asSeconds() < WARN_SECONDS;
  const urgent = countdown.asSeconds() < URGENT_SECONDS;
  const NotWayPast = countdown.asSeconds() > WAY_PAST_SECONDS;
  const label = useMemo(() => {
    if (countdown.days() > 1) {
      return [
        {
          unit: 'd',
          value: countdown.days(),
        },
        {
          unit: 'h',
          value: countdown.hours(),
        },
      ]
        .reduce<string[]>((aggregated, t) => {
          if (t.value === 0) {
            return aggregated;
          }

          return [...aggregated, `${t.value}${t.unit}`];
        }, [])
        .join(' ');
    }

    return [
      {
        unit: 'h',
        value: countdown.hours(),
      },
      {
        unit: 'm',
        value: countdown.minutes(),
      },
      urgent && NotWayPast
        ? {
            unit: 's',
            value: countdown.seconds(),
          }
        : {
            value: 0,
          },
    ]
      .reduce<string[]>((aggregated, t) => {
        if (t.value === 0) {
          return aggregated;
        }

        return [...aggregated, `${t.value}${t.unit}`];
      }, [])
      .join(' ');
  }, [NotWayPast, countdown, urgent]);

  const posted = countdown.asSeconds() <= 0;

  return {
    warning: !urgent && warning,
    urgent,
    posted,
    label,
  };
}
