import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import {
  SportEventsDocument,
  SportEventsQuery,
  SportEventsQueryVariables,
} from '../../generated/graphql';

type SportEvent = SportEventsQuery['sportEvents'][0];
export type SportCardAllProps = {
  event?: SportEvent;
};

export type SportEventWithMarkets = SportEvent & {
  marketGroups: { [key: string]: SportEvent['markets'] };
};

export default function useSportEvent(eventId: string) {
  const [{ data, ...rest }] = useQuery<
    SportEventsQuery,
    SportEventsQueryVariables
  >({
    query: SportEventsDocument,
    variables: {
      eventId,
    },
  });

  const sportEvent = useMemo(() => {
    if (data?.sportEvents.length) {
      const event = data.sportEvents[0];

      const markets = event?.markets || [];

      const marketGroups = groupBy(markets, 'name');
      return {
        ...event,
        marketGroups,
      };
    }
    return undefined;
  }, [data?.sportEvents]);

  return {
    event: sportEvent,
    ...rest,
  };
}
