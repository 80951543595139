import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import {
  MeetingRacesOnlyDocument,
  MeetingRacesOnlyQuery,
  MeetingRacesOnlyQueryVariables,
  RaceStatus,
} from '../../../generated/graphql';

// Meetings will always redirect to the latest race card
export default function Meeting() {
  const { meetingId } = useParams<{
    meetingId: string;
  }>();
  const navigate = useNavigate();
  const [result] = useQuery<
    MeetingRacesOnlyQuery,
    MeetingRacesOnlyQueryVariables
  >({
    query: MeetingRacesOnlyDocument,
    variables: {
      id: meetingId,
    },
  });
  const { data } = result;

  useEffect(() => {
    if (data?.meeting) {
      const nextRace =
        data.meeting.races.filter(
          (race) => race.status === RaceStatus.Open
        )[0] || data.meeting.races[data.meeting.races.length - 1];

      if (!nextRace) {
        navigate('/');
      }

      navigate(`/racing/meeting/${meetingId}/race/${nextRace.id}`);
    }
  }, [data, navigate, meetingId]);

  return null;
}
