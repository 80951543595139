import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeReviewedBet } from '../../../stores/betSlice';
import { RootState } from '../../../stores/store';
import { CheckBetResponse } from '../../../utils/betslip/types';
import styles from './BetReviewed.module.css';
import BetReviewedModal from './BetReviewedModal';

export default function BetReviewed() {
  const [selectedBet, setSelectedBet] = useState<CheckBetResponse | null>(null);
  const reviewedBets = useSelector(
    (state: RootState) => state.bet.reviewedBets
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedBet && reviewedBets.length > 0) {
      setSelectedBet(reviewedBets[0]);
    }
  }, [reviewedBets, selectedBet]);

  function onDismissReviewed(reviewedBet: CheckBetResponse) {
    setSelectedBet(null);
    dispatch(removeReviewedBet(reviewedBet));
  }

  return (
    <>
      {selectedBet ? (
        <div className={styles.container}>
          <React.Fragment key={selectedBet.ticket_id}>
            <BetReviewedModal
              bet={selectedBet}
              onDismiss={() => onDismissReviewed(selectedBet)}
            />
          </React.Fragment>
        </div>
      ) : null}
    </>
  );
}
