import { batch } from 'react-redux';
import { SportSingleBet } from '../../utils/betslip/types';
import {
  addOrRemoveSportsBet,
  generateParlays,
  generateSportMulties,
} from '../betslipSlice';
import { AppDispatch } from '../store';

export const addOrRemoveBet = (
  detail: SportSingleBet,
  dispatch: AppDispatch
) => {
  batch(() => {
    dispatch(addOrRemoveSportsBet(detail));
    dispatch(generateParlays(detail));
    dispatch(generateSportMulties());
  });
};
