import {
  FloatingFocusManager,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import cx from 'clsx';
import React, { useState } from 'react';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/Modal/Modal';
import { SportSingleBet } from '../../../utils/betslip/types';
import styles from './CombinationsModal.module.css';

const getSelectionTitle = (bet: SportSingleBet) => {
  if (bet.selection.name.toLowerCase() === 'draw') {
    return `${bet.eventDetails?.name} (Draw)`;
  }

  return bet.selection.name;
};

export type CombinationsModalProps = {
  combos: SportSingleBet[][];
};

const CombinationsModal = ({ combos }: CombinationsModalProps) => {
  const [open, setOpen] = useState(false);
  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: setOpen,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <button
        className={styles.combosContainer}
        type="button"
        {...getReferenceProps({
          ref: reference,
        })}
      >
        <div>Combos: {combos.length}</div>
        <div className={styles.expandIcon}>
          <Icon name={open ? 'caretUp' : 'caretDown'} />
        </div>
      </button>
      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context}>
            <Modal
              className={styles.modalOverlay}
              {...getFloatingProps({ ref: floating })}
            >
              <div className={styles.modal}>
                <button
                  className={styles.closeBtn}
                  onClick={() => setOpen(false)}
                >
                  <Icon name="cross" />
                </button>
                <h2>Combinations</h2>
                <div className={styles.cards}>
                  {combos.map((selection, i) => (
                    <ComboCard
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      index={i + 1}
                      selection={selection}
                      isLast={i === combos.length - 1}
                    />
                  ))}
                </div>
              </div>
            </Modal>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};

const ComboCard = ({
  index,
  selection,
  isLast,
}: {
  index: number;
  isLast: boolean;
  selection: SportSingleBet[];
}) => (
  <div className={cx(styles.comboCard, { [styles.noBorder]: isLast })}>
    <div className={styles.title}>Combo {index}:</div>
    <div className={styles.details}>
      <div>
        {selection.map((s) => (
          <div key={s.selection.id}>{getSelectionTitle(s)}</div>
        ))}
      </div>
    </div>
  </div>
);

export default CombinationsModal;
