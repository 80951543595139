import { useDispatch } from 'react-redux';
import Badge from '../../../components/Badge/Badge';
import CurrencyInput from '../../../components/Form/CurrencyInput/CurrencyInput';
import Icon from '../../../components/Icon/Icon';
import { updateStakeSportMulti } from '../../../stores/betslipSlice';
import { MultiFoldBet } from '../../../utils/betslip/types';
import combosDescriptors from '../../../utils/combosDescriptions';
import CombinationsModal from './CombinationsModal';
import styles from './MultiFoldBetCard.module.css';

type MultiFoldBetCardProps = { bet: MultiFoldBet };
export function MultiFoldBetCard({ bet }: MultiFoldBetCardProps) {
  const dispatch = useDispatch();

  function handleStakeChange(newPrice: number) {
    dispatch(updateStakeSportMulti({ bet, newPrice }));
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.icon}>
          <Icon name="multiBet" />
        </div>
        <div className={styles.details}>
          <div className={styles.title}>
            {bet.title}
            <Badge
              containerClassName={styles.badge}
              Component={
                <Icon name="info" size="small" className={styles.infoIcon} />
              }
            >
              {combosDescriptors[bet.title]}
            </Badge>
          </div>
          <CombinationsModal combos={bet.selections} />
        </div>
        <div className={styles.stake}>
          <CurrencyInput onChange={handleStakeChange} />
        </div>
      </div>
    </>
  );
}
