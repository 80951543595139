import React from 'react';
import { useSelector } from 'react-redux';
import { SportEventWithMarkets } from '../../../../hooks/useSportEvent/useSportEvent';
import { RootState } from '../../../../stores/store';
import styles from './SportCardStats.module.css';

export type SportCardStatsProps = {
  event?: SportEventWithMarkets;
};

const SportCardStats = ({ event }: SportCardStatsProps) => {
  const iSportgeniusBaseUrl = useSelector(
    (state: RootState) => state.config.iSportgeniusBaseUrl
  );

  return (
    <div className={styles.container}>
      <iframe
        title="Event Preview"
        src={`${iSportgeniusBaseUrl}/preview1/${event?.id}`}
      />
    </div>
  );
};

export default SportCardStats;
