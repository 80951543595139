import clsx from 'clsx';
import React from 'react';
import SportOddsButton from '../../SportOddsButton/SportOddsButton';
import SportTeamJersey from '../../SportTeamJersey/SportTeamJersey';
import SportTeamSeperator from '../../SportTeamSeperator/SportTeamSeperator';
import styles from './TeamsMultipleBetType.module.css';

type TeamsMultipleBetTypeProps = {
  homeTeam: { name: string };
  awayTeam: { name: string };
  onClick: () => void;
};

const dummy =
  'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci nostrum ab commodi optio et earum similique totam repellat dolor quibusdam veritatis cumque blanditiis velit facilis molestiae a iste aperiam, illo accusantium rem nulla soluta voluptatem illum! Nisi ipsum enim mollitia in tempora dolorem, esse repellat sed pariatur quia, dolor molestias.';

const TeamsMultipleBetType = ({
  homeTeam,
  awayTeam,
  onClick,
}: TeamsMultipleBetTypeProps) => (
  <div
    className={styles.container}
    onKeyPress={(e) => {
      if (e.key === 'Enter') {
        onClick();
      }
    }}
    role="button"
    tabIndex={0}
  >
    <div className={clsx(styles.team)}>
      <div className={styles.homeTeamWrapper}>
        <SportTeamJersey
          teamName={dummy}
          jersey="lakers"
          className={styles.teamName}
        />
        <div className={styles.seperator}>
          <SportTeamSeperator />
        </div>
      </div>
      <div className={styles.homeTeamOdds}>
        <div className={styles.oddsWithBetType}>
          <div className={styles.betTypeWrapper}>Match</div>
          <SportOddsButton odd={1.2} />
        </div>
        <div className={styles.oddsWithBetType}>
          <div className={styles.betTypeWrapper}>Line</div>
          <SportOddsButton odd={1} label="+23.5" />
        </div>
        <div className={styles.oddsWithBetType}>
          <div className={styles.betTypeWrapper}>Total Points</div>
          <SportOddsButton odd={8.2} />
        </div>
      </div>
    </div>
    <div className={styles.team}>
      <SportTeamJersey
        teamName="Chicago Bulls"
        jersey="chicago"
        className={styles.teamName}
      />
      <div className={styles.odds}>
        <SportOddsButton odd={1.2} />
        <SportOddsButton odd={1} label="+23.5" />
        <SportOddsButton odd={8.2} />
      </div>
    </div>
  </div>
);

export default TeamsMultipleBetType;
