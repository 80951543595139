import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { CountryFilter, setCountryFilter } from '../../stores/settingSlice';
import { RootState } from '../../stores/store';
import styles from './CountryFilters.module.css';

const selector = createSelector(
  [
    (state: RootState) => state.setting.countryFilter,
    (state: RootState) => state.config.options.localCountryFilter,
  ],
  (countryFilter, localCountryFilter) => ({
    countryFilter,
    localCountryFilter,
  })
);

export default function CountryFilters() {
  const intl = 'Intl';
  const { countryFilter, localCountryFilter } = useSelector(selector);
  const dispatch = useDispatch();

  if (!localCountryFilter) return null;

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: countryFilter === CountryFilter.Local,
        })}
        onClick={() => dispatch(setCountryFilter(CountryFilter.Local))}
      >
        {localCountryFilter.label}
      </button>
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: countryFilter === CountryFilter.International,
        })}
        onClick={() => dispatch(setCountryFilter(CountryFilter.International))}
      >
        {intl}
      </button>
    </div>
  );
}
