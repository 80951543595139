import clsx from 'clsx';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import Button from '../../../../components/Button/Button';
import Icon from '../../../../components/Icon/Icon';
import { MeetingType } from '../../../../generated/graphql';
import useFormat from '../../../../hooks/useFormat';
import { useMeasure } from '../../../../hooks/useMeasure';
import { RunnerFormHistory } from '../../../../types/racing';
import styles from './Start.module.css';
import StartInfo from './StartInfo';
import TimesAndDistance from './TimesAndDistance';

type Props = {
  start: NonNullable<RunnerFormHistory>;
  startBefore: RunnerFormHistory;
  meetingType: MeetingType;
};

export default function Start({ start, startBefore, meetingType }: Props) {
  const [navExpanded, setNavExpanded] = useState(false);
  const format = useFormat();
  const startRef = useRef<HTMLDivElement>(null);
  const measure = useMeasure(startRef);

  const isWide = measure ? measure.width > 700 : null;
  const isMobile = measure ? measure.width < 320 : null;
  const isLabelShowing = isWide || navExpanded;
  const labelStyles = clsx(styles.label, {
    [styles.displayNone]: !isLabelShowing,
  });
  const headerItemStyles = clsx(styles.minWidth, {
    [styles.fullWidth]: navExpanded,
  });
  const headerItemStylesHideable = clsx(styles.minWidth, {
    [styles.fullWidth]: navExpanded,
    [styles.displayNone]: isMobile && !navExpanded,
  });

  return (
    <div ref={startRef}>
      <button
        type="button"
        onClick={() => setNavExpanded((prev) => !prev)}
        className={clsx(styles.shortFormPositionInfo, {
          [styles.openState]: navExpanded,
        })}
      >
        <div className={clsx(styles.header, { [styles.wrap]: navExpanded })}>
          <span
            className={headerItemStyles}
            title={`Position: ${format.ordinal(start.finishingPosition)} of ${
              start.starters
            }`}
          >
            <span className={labelStyles}>Position: </span>
            <span className={styles.startItem}>{`${format.ordinal(
              start.finishingPosition
            )} of ${start.starters}`}</span>
          </span>
          <span
            className={clsx(headerItemStyles, {
              [styles.displayNone]: isMobile && !navExpanded,
            })}
            title={`Lengths: ${start.margin}L`}
          >
            <span className={labelStyles}>Lengths: </span>
            <span
              className={clsx(styles.startItem, {
                [styles.centerTextAlign]: !isWide && !navExpanded,
              })}
            >{`${start.margin}L`}</span>
          </span>
          <span
            className={headerItemStyles}
            title={`Date: ${dayjs(start.date).format('dddd, MMMM D, YYYY')}`}
          >
            <span className={labelStyles}>Date: </span>
            <span className={styles.startItem}>
              {dayjs(start.date).format('DD/MM/YY')}
            </span>
          </span>
          <span className={headerItemStyles} title={`Track: ${start.track}`}>
            <span className={labelStyles}>Track: </span>
            <span className={styles.startItem}>{start.track}</span>
          </span>
          <span
            className={headerItemStylesHideable}
            title={`Distance: ${start.distance}m`}
          >
            <span className={labelStyles}>Dist: </span>
            <span className={styles.startItem}>{`${start.distance}m`}</span>
          </span>
          {!meetingType.includes(MeetingType.Greyhound) ? (
            <span
              className={headerItemStylesHideable}
              title={`Surface/Condition: ${start.trackCondition}`}
            >
              <span className={labelStyles}>Surface/Cond: </span>
              <span className={styles.startItem}>{start.trackCondition}</span>
            </span>
          ) : (
            <span
              className={headerItemStylesHideable}
              title={`Box: ${start.barrier}`}
            >
              <span className={labelStyles}>Box: </span>
              <span className={styles.startItem}>{start.barrier}</span>
            </span>
          )}
        </div>

        <span
          className={clsx(styles.chevronButton, {
            [styles.expanded]: navExpanded,
          })}
        >
          <Icon name="caretDown" />
        </span>
      </button>
      {navExpanded ? (
        <>
          <div className={styles.divider} />
          <TimesAndDistance />
          <div className={styles.divider} />
          <StartInfo
            start={start}
            startBefore={startBefore}
            meetingType={meetingType}
          />
          <div className={styles.divider} />
          <div className={styles.recentStartWatchReplay}>
            <Button
              theme="secondary"
              leftIcon="watch"
              className={styles.replayButton}
            >
              Watch Replay
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
}
