import React, { createContext, useMemo } from 'react';
import { useQuery } from 'urql';
import {
  SportTypesDocument,
  SportTypesQuery,
  SportTypesQueryVariables,
} from '../../generated/graphql';

function useSportFilters() {
  const [allSports] = useQuery<SportTypesQuery, SportTypesQueryVariables>({
    query: SportTypesDocument,
  });

  const filters =
    useMemo(
      () =>
        allSports.data?.sportTypes.map((d) => ({
          label: d?.description || 'Cannot load the filters',
          value: d?.id || -1,
        })),
      [allSports.data]
    ) || [];

  return { filters };
}

export const SportsFilterContext = createContext<{
  filters: { label: string; value: number }[];
}>({
  filters: [],
});

export const SportsFilterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { filters } = useSportFilters();
  return (
    <SportsFilterContext.Provider value={{ filters }}>
      {children}
    </SportsFilterContext.Provider>
  );
};
