import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { ReactNode, useState } from 'react';
import styles from './Badge.module.css';

type Props = {
  Component: ReactNode;
  children: ReactNode;
  containerClassName?: string;
};

export default function Badge({ Component, children }: Props) {
  const [open, setOpen] = useState(false);
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(4), flip(), shift()],
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <button
        type="button"
        className={styles.button}
        {...getReferenceProps({
          ref: reference,
          onClick: (e) => {
            e.preventDefault();
          },
        })}
      >
        {Component}
      </button>
      <FloatingPortal>
        {open ? (
          <div
            {...getFloatingProps({
              ref: floating,
              className: styles.tooltip,
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            })}
          >
            {children}
          </div>
        ) : null}
      </FloatingPortal>
    </>
  );
}
