import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import Icon from '../../../../components/Icon/Icon';
import {
  MeetingType,
  PlacingStats,
  RaceCardQuery,
  RunnerQuery,
  TrackCondition,
} from '../../../../generated/graphql';
import PercentageBar from './PercentageBar';
import styles from './ShortFormInfo.module.css';

type Props = {
  competitor: NonNullable<
    NonNullable<RunnerQuery['runner']>['competitorsConnection']['competitors']
  >[0];
  race: NonNullable<RaceCardQuery['race']>;
};

function getCondition(condition: string) {
  let keyCondition = '';
  if (condition.includes('Firm')) {
    keyCondition = 'firm';
  }
  if (condition.includes('Good')) {
    keyCondition = 'good';
  }
  if (condition.includes('Dead')) {
    keyCondition = 'dead';
  }
  if (condition.includes('Dirt')) {
    keyCondition = 'dirt';
  }
  if (condition.includes('Easy')) {
    keyCondition = 'easy';
  }
  if (condition.includes('Fair')) {
    keyCondition = 'fair';
  }
  if (condition.includes('Fast')) {
    keyCondition = 'fast';
  }
  if (condition.includes('Good')) {
    keyCondition = 'good';
  }
  if (condition.includes('Sand')) {
    keyCondition = 'sand';
  }
  if (condition.includes('Slow')) {
    keyCondition = 'slow';
  }
  if (condition.includes('Heavy')) {
    keyCondition = 'heavy';
  }
  if (condition.includes('Very_Heavy')) {
    keyCondition = 'very_heavy';
  }
  if (condition.includes('Soft')) {
    keyCondition = 'soft';
  }
  if (condition.includes('Synthetic')) {
    keyCondition = 'synthetic';
  }
  return keyCondition;
}

const defaultHistory = {
  numberOfRuns: 0,
  firsts: 0,
  seconds: 0,
  thirds: 0,
  totalPrizeMoneyWon: 0,
};

enum Filter {
  LIFE = 'life',
  THIS_YEAR = 'thisYear',
  LAST_YEAR = 'lastYear',
  TRACK = 'track',
  DISTANCE = 'distance',
}

enum Surface {
  SYNTHETIC = 'Synthetic',
  TURF = 'Turf',
}

export default function ShortFormInfo({ competitor, race }: Props) {
  const [selectedFilter, setSelectedFilter] = useState<Filter>(Filter.LIFE);
  const careerDetail = competitor?.runnerStatistics?.career?.detail;

  const conditions = careerDetail?.byCondition?.reduce<PlacingStats>(
    (aggregated, value) => {
      const condition = getCondition(value.key);
      const currentValue = aggregated[condition] || defaultHistory;

      return {
        ...aggregated,
        [condition]: {
          ...currentValue,
          numberOfRuns: +currentValue.numberOfRuns + value.numberOfRuns,
          firsts: +currentValue.firsts + value.firsts,
          seconds: +currentValue.seconds + value.seconds,
          thirds: +currentValue.thirds + value.thirds,
          totalPrizeMoneyWon:
            +currentValue.totalPrizeMoneyWon + value.totalPrizeMoneyWon,
        },
      };
    },
    {} as PlacingStats
  );

  const life = `${
    competitor?.runnerStatistics
      ? competitor?.runnerStatistics?.career?.all?.numberOfRuns
      : 0
  } ${
    competitor?.runnerStatistics
      ? competitor?.runnerStatistics?.career?.all?.firsts
      : 0
  }-${
    competitor?.runnerStatistics
      ? competitor?.runnerStatistics?.career?.all?.seconds
      : 0
  }-${
    competitor?.runnerStatistics
      ? competitor?.runnerStatistics?.career?.all?.thirds
      : 0
  }`;

  const byTrack =
    careerDetail?.byTrack?.filter?.(
      ({ key }) => key === race.meeting.track.name
    )[0] || defaultHistory;

  const byDistance =
    careerDetail?.byDistance?.filter?.(
      ({ key }) => key === String(race.distance)
    )[0] || defaultHistory;

  function getSurfaceByKey(key: string): Partial<PlacingStats> {
    return (
      careerDetail?.bySurface?.find((result) => result.key === key) ||
      defaultHistory
    );
  }

  const maxLength = 4;

  function getFilteredStarts(filter: Filter) {
    if (!competitor) return 0;
    switch (filter) {
      case Filter.LIFE: {
        return competitor?.runnerStatistics
          ? competitor?.runnerStatistics?.career?.all?.numberOfRuns || 0
          : 0;
      }
      case Filter.THIS_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) => dayjs().isSame(e?.date || dayjs(), 'year')).length;
      }
      case Filter.LAST_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) =>
            dayjs()
              .subtract(1, 'year')
              .isSame(e?.date || dayjs(), 'year')
          ).length;
      }
      case Filter.TRACK: {
        return byTrack?.numberOfRuns;
      }
      case Filter.DISTANCE: {
        return byDistance?.numberOfRuns;
      }
      default:
        return 0;
    }
  }

  function getFilteredWins(filter: string) {
    if (!competitor) return 0;
    switch (filter) {
      case Filter.LIFE: {
        return competitor?.runnerStatistics
          ? competitor?.runnerStatistics?.career?.all?.firsts || 0
          : 0;
      }
      case Filter.THIS_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) => dayjs().isSame(e?.date || dayjs(), 'year'))
          .filter((e) => e?.finishingPosition === 1).length;
      }
      case Filter.LAST_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) =>
            dayjs()
              .subtract(1, 'year')
              .isSame(e?.date || dayjs(), 'year')
          )
          .filter((e) => e?.finishingPosition === 1).length;
      }
      case Filter.TRACK: {
        return byTrack?.firsts;
      }
      case Filter.DISTANCE: {
        return byDistance?.firsts;
      }
      default:
        return 0;
    }
  }
  function getFilterdWinPercentage(filter: Filter) {
    if (!competitor) return 0;
    return (
      Math.floor((getFilteredWins(filter) / getFilteredStarts(filter)) * 100) ||
      0
    );
  }

  function getFilteredPlaces(filter: Filter) {
    if (!competitor) return 0;
    switch (filter) {
      case Filter.LIFE: {
        return competitor?.runnerStatistics
          ? competitor?.runnerStatistics?.career?.all?.seconds || 0
          : 0;
      }
      case Filter.THIS_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) => dayjs().isSame(e?.date || dayjs(), 'year'))
          .filter((e) => e?.finishingPosition === 2).length;
      }
      case Filter.LAST_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) =>
            dayjs()
              .subtract(1, 'year')
              .isSame(e?.date || dayjs(), 'year')
          )
          .filter((e) => e?.finishingPosition === 2).length;
      }
      case Filter.TRACK: {
        return byTrack.seconds;
      }
      case Filter.DISTANCE: {
        return byDistance.seconds;
      }
      default:
        return 0;
    }
  }

  function getFilterdPlacePercentage(filter: Filter) {
    if (!competitor) return 0;
    return (
      Math.floor(
        (getFilteredPlaces(filter) / getFilteredStarts(filter)) * 100
      ) || 0
    );
  }

  function getFilteredShows(filter: Filter) {
    if (!competitor) return 0;
    switch (filter) {
      case Filter.LIFE: {
        return competitor?.runnerStatistics
          ? competitor?.runnerStatistics?.career?.all?.thirds || 0
          : 0;
      }
      case Filter.THIS_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) => dayjs().isSame(e?.date || dayjs(), 'year'))
          .filter((e) => e?.finishingPosition === 3).length;
      }
      case Filter.LAST_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) =>
            dayjs()
              .subtract(1, 'year')
              .isSame(e?.date || dayjs(), 'year')
          )
          .filter((e) => e?.finishingPosition === 3).length;
      }
      case Filter.TRACK: {
        return byTrack?.thirds;
      }
      case Filter.DISTANCE: {
        return byDistance?.thirds;
      }
      default:
        return 0;
    }
  }
  function getFilterdShowPercentage(filter: Filter) {
    if (!competitor) return 0;
    return (
      Math.floor(
        (getFilteredShows(filter) / getFilteredStarts(filter)) * 100
      ) || 0
    );
  }

  function getHistoryByCondition(conditionType: 'dry' | 'wet' | 'all') {
    const dryConditions = [
      TrackCondition.Firm,
      TrackCondition.Firm1,
      TrackCondition.Firm2,
      TrackCondition.Good,
      TrackCondition.Good3,
      TrackCondition.Good4,
      TrackCondition.Dirt,
    ];
    const wetConditions = [
      TrackCondition.Soft,
      TrackCondition.Soft5,
      TrackCondition.Soft6,
      TrackCondition.Soft7,
      TrackCondition.Heavy,
      TrackCondition.Heavy8,
      TrackCondition.Heavy9,
      TrackCondition.Heavy10,
      TrackCondition.Slow,
      TrackCondition.VeryHeavy,
      TrackCondition.Dead,
    ];

    const historyByCondition = { ...defaultHistory };
    function mapHistory(types: string[]) {
      types.forEach((type) => {
        historyByCondition.numberOfRuns +=
          conditions?.[type]?.numberOfRuns || 0;
        historyByCondition.firsts += conditions?.[type]?.firsts || 0;
        historyByCondition.seconds += conditions?.[type]?.seconds || 0;
        historyByCondition.thirds += conditions?.[type]?.thirds || 0;
        historyByCondition.totalPrizeMoneyWon +=
          conditions?.[type]?.totalPrizeMoneyWon || 0;
      });
      return historyByCondition;
    }
    switch (conditionType) {
      case 'dry':
        return mapHistory(dryConditions);
      case 'wet':
        return mapHistory(wetConditions);
      case 'all':
        return mapHistory([...dryConditions, ...wetConditions]);
      default:
        return defaultHistory;
    }
  }

  function getMappedHistoryByCondition(conditionType: 'dry' | 'wet' | 'all') {
    return mapDetail(getHistoryByCondition(conditionType));
  }

  function mapDetail(stats: Partial<PlacingStats>) {
    return `${stats.numberOfRuns} ${stats.firsts}-${stats.seconds}-${stats.thirds}`;
  }
  function getAllHistoryByFilter(filter: Filter) {
    return `${getFilteredStarts(filter)} ${getFilteredWins(
      filter
    )}-${getFilteredPlaces(filter)}-${getFilteredShows(filter)}`;
  }

  function getFilteredPrizemoney(filter: Filter) {
    if (!competitor || !competitor.formHistory) return 0;
    switch (filter) {
      case Filter.LIFE: {
        return competitor?.formHistory.reduce(
          (acc, comp) => (comp?.prizeTotal || 0) + acc,
          0
        );
      }
      case Filter.THIS_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) => dayjs().isSame(e?.date || dayjs(), 'year'))
          .filter((e) => e?.finishingPosition === 3)
          .reduce((acc, comp) => (comp?.prizeTotal || 0) + acc, 0);
      }
      case Filter.LAST_YEAR: {
        return competitor.formHistory
          ?.filter((e) => e?.class !== 'BTrial' && e?.class !== 'Barrier Trial')
          .filter((e) =>
            dayjs()
              .subtract(1, 'year')
              .isSame(e?.date || dayjs(), 'year')
          )
          .filter((e) => e?.finishingPosition === 3)
          .reduce((acc, comp) => (comp?.prizeTotal || 0) + acc, 0);
      }
      case Filter.TRACK: {
        return byTrack?.totalPrizeMoneyWon;
      }
      case Filter.DISTANCE: {
        return byDistance?.totalPrizeMoneyWon;
      }
      default:
        return 0;
    }
  }

  return (
    <>
      <div className={styles.infoContainer}>
        <div className={styles.containerBox}>
          <div
            className={clsx(
              styles.containerBoxContent,
              styles.commentContainer
            )}
          >
            <span className={styles.heading}>
              Stats & Insights Powered by
              <Link to="/">
                <span className={styles.linkBold}> DYNAMIC</span>
                FORM
              </Link>
              <Icon name="info" size="small" />
            </span>
            <span>{competitor?.comment}</span>
          </div>
        </div>
        <div className={styles.containerBox}>
          <div className={styles.statsContent}>
            <div className={styles.selectionButtons}>
              <Button
                className={clsx(styles.button, {
                  [styles.selected]:
                    selectedFilter.length === 0 ||
                    selectedFilter.length === maxLength,
                })}
                onClick={() => setSelectedFilter(Filter.LIFE)}
              >
                Life
              </Button>
              <Button
                className={clsx(styles.button, {
                  [styles.selected]: selectedFilter.includes(Filter.THIS_YEAR),
                })}
                onClick={() => setSelectedFilter(Filter.THIS_YEAR)}
              >
                {dayjs().get('year')}
              </Button>
              <Button
                className={clsx(styles.button, {
                  [styles.selected]: selectedFilter.includes(Filter.LAST_YEAR),
                })}
                onClick={() => setSelectedFilter(Filter.LAST_YEAR)}
              >
                {dayjs().subtract(1, 'year').get('year')}
              </Button>
              <Button
                className={clsx(styles.button, {
                  [styles.selected]: selectedFilter.includes(Filter.TRACK),
                })}
                onClick={() => setSelectedFilter(Filter.TRACK)}
              >
                {race?.meeting?.track?.name?.slice(0, 4)}
              </Button>
              <Button
                className={clsx(styles.button, {
                  [styles.selected]: selectedFilter.includes(Filter.DISTANCE),
                })}
                onClick={() => setSelectedFilter(Filter.DISTANCE)}
              >
                Dist
              </Button>
            </div>
            <div className={styles.filteredStats}>
              <div className={styles.filteredStatsItem}>
                <span className={styles.featured}>
                  {getFilteredStarts(selectedFilter)}
                </span>
                <span>Starts</span>
              </div>
              <div className={styles.filteredStatsItem}>
                <PercentageBar
                  percentage={getFilterdWinPercentage(selectedFilter)}
                />
                <span>Win</span>
              </div>
              <div className={styles.filteredStatsItem}>
                <PercentageBar
                  percentage={getFilterdPlacePercentage(selectedFilter)}
                />
                <span>Place</span>
              </div>
              <div className={styles.filteredStatsItem}>
                <PercentageBar
                  percentage={getFilterdShowPercentage(selectedFilter)}
                />
                <span>Show</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerBox}>
          <div className={styles.containerBoxContent}>
            <div className={styles.runnerDetails}>
              <div>
                <div className={styles.runnerDetail}>
                  <span className={styles.label}>Age</span>
                  <span>{competitor?.age}</span>
                </div>
                <div className={styles.runnerDetail}>
                  <span className={styles.label}>Sex</span>
                  <span>{competitor?.sex}</span>
                </div>
                <div className={styles.runnerDetail}>
                  <span className={styles.label}>Color</span>
                  <span>{competitor?.colour}</span>
                </div>
                {!race?.meeting?.type.includes(MeetingType.Greyhound) && (
                  <div className={styles.runnerDetail}>
                    <span className={styles.label}>Meds</span>
                  </div>
                )}
              </div>
              <div>
                {!race?.meeting?.type.includes(MeetingType.Greyhound) && (
                  <div className={styles.runnerDetail}>
                    <span className={styles.label}>Equip</span>
                    <span>{competitor?.formHistory?.[0]?.gearChanges}</span>
                  </div>
                )}
                <div className={styles.runnerDetail}>
                  <span className={styles.label}>Dam</span>
                  <span>{competitor?.dam}</span>
                </div>
                <div className={styles.runnerDetail}>
                  <span className={styles.label}>Sire</span>
                  <span>{competitor?.sire}</span>
                </div>
                <div className={styles.runnerDetail}>
                  <span className={styles.label}>Owner</span>
                  <span className={styles.breakWord}>{competitor?.owners}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerBox}>
          <div className={styles.containerBoxContent}>
            <div className={styles.runnerDetails}>
              <div>
                <div className={styles.runnerDetailWin}>
                  <span className={styles.label}>Life</span>
                  <span>{life}</span>
                  <span>${getFilteredPrizemoney(Filter.LIFE)}</span>
                </div>
                <div className={styles.runnerDetailWin}>
                  <span className={styles.label}>{dayjs().get('year')}</span>
                  <span>{getAllHistoryByFilter(Filter.THIS_YEAR)}</span>
                  <span>${getFilteredPrizemoney(Filter.THIS_YEAR)}</span>
                </div>
                <div className={styles.runnerDetailWin}>
                  <span className={styles.label}>
                    {dayjs().subtract(1, 'year').get('year')}
                  </span>
                  <span>{getAllHistoryByFilter(Filter.LAST_YEAR)}</span>
                  <span>${getFilteredPrizemoney(Filter.LAST_YEAR)}</span>
                </div>
                <div className={styles.runnerDetailWin}>
                  <span className={styles.label}>
                    {race?.meeting?.track?.name?.slice(0, 4)}
                  </span>
                  <span>{getAllHistoryByFilter(Filter.TRACK)}</span>
                  <span>${getFilteredPrizemoney(Filter.TRACK)}</span>
                </div>
                <div className={styles.runnerDetailWin}>
                  <span className={styles.label}>Dist</span>
                  <span>{getAllHistoryByFilter(Filter.DISTANCE)}</span>
                  <span>${getFilteredPrizemoney(Filter.DISTANCE)}</span>
                </div>
              </div>
              {!race?.meeting?.type.includes(MeetingType.Greyhound) && (
                <div>
                  <div className={styles.runnerDetailWin}>
                    <span className={styles.label}>Dirt.Fast</span>
                    <span>{getMappedHistoryByCondition('dry')}</span>
                    <span>
                      ${getHistoryByCondition('dry').totalPrizeMoneyWon}
                    </span>
                  </div>
                  <div className={styles.runnerDetailWin}>
                    <span className={styles.label}>Wet</span>
                    <span>{getMappedHistoryByCondition('wet')}</span>
                    <span>
                      ${getHistoryByCondition('wet').totalPrizeMoneyWon}
                    </span>
                  </div>
                  <div className={styles.runnerDetailWin}>
                    <span className={styles.label}>Synth</span>
                    <span>{mapDetail(getSurfaceByKey(Surface.SYNTHETIC))}</span>
                    <span>
                      $
                      {getSurfaceByKey(Surface.SYNTHETIC)?.totalPrizeMoneyWon ||
                        0}
                    </span>
                  </div>
                  <div className={styles.runnerDetailWin}>
                    <span className={styles.label}>Turf</span>
                    <span>{mapDetail(getSurfaceByKey(Surface.TURF))}</span>
                    <span>
                      ${getSurfaceByKey(Surface.TURF)?.totalPrizeMoneyWon || 0}
                    </span>
                  </div>
                  <div className={styles.runnerDetailWin}>
                    <span className={styles.label}>All Whtr</span>
                    <span>{getMappedHistoryByCondition('all')}</span>
                    <span>
                      ${getHistoryByCondition('all').totalPrizeMoneyWon}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
