import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as renderers from './pages/renderers';
import { initClient as initCmsAxiosClient } from './services/cmsApi/client';
import { toggleBetslip as toggleBetslipFn } from './stores/betslipSlice';
import { ConfigState, setConfig } from './stores/configSlice';
import { store } from './stores/store';
import { updateCurrentTime } from './stores/timeSlice';
import './styles/globals.css';
import dodgeToken from './utils/dodgeToken';

async function init(config: ConfigState): Promise<void> {
  function updateTime() {
    store.dispatch(updateCurrentTime());
  }

  return new Promise((resolve, reject) => {
    if (!config) {
      reject(
        new Error(
          'Application is misconfigured. Did you run init with a your configuration?'
        )
      );
    }

    initCmsAxiosClient(config.cmsUrl);
    store.dispatch(setConfig(config));

    if (
      process.env.NODE_ENV === 'development' &&
      localStorage.getItem('dodge') === 'true'
    ) {
      dodgeToken();
    }

    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    dayjs.extend(timezone);
    dayjs.extend(utc);
    dayjs.extend(advanced);

    window.setInterval(updateTime, 1000);

    resolve();
  });
}

const renderComponents = () => {
  const rendererArray = Object.entries(renderers);
  rendererArray.forEach(([key, renderer]) => {
    renderer();
  });
};

const toggleBetslip = () => {
  store.dispatch(toggleBetslipFn());
};

window.bm = {
  ...(window.bm || {}),
  init,
  renderComponents,
  toggleBetslip,
};
