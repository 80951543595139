const combosDescriptors: { [key: string]: string } = {
  Double:
    'Double bets to place all available 2 leg multi bets from the selections in the betslip.',
  Treble:
    'Treble bets to place all available 3 leg multi bets from the selections in the betslip.',
  '4-fold':
    '4-fold bets to place all available 4 leg multi bets from the selections in the betslip.',
  '5-fold':
    '5-fold bets to place all available 5 leg multi bets from the selections in the betslip.',
  '6-fold':
    '6-fold bets to place all available 6 leg multi bets from the selections in the betslip.',
  '7-fold':
    '7-fold bets to place all available 7 leg multi bets from the selections in the betslip.',
  '8-fold':
    '8-fold bets to place all available 8 leg multi bets from the selections in the betslip.',
  '9-fold':
    '9-fold bets to place all available 9 leg multi bets from the selections in the betslip.',
};

export default combosDescriptors;
