import clsx from 'clsx';
import { HTMLAttributes } from 'react';
import styles from './Label.module.css';

export default function Label({
  className,
  ...rest
}: HTMLAttributes<HTMLSpanElement>) {
  return <span className={clsx(styles.label, className)} {...rest} />;
}
