import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores/store';
import styles from './Stats.module.css';

const Stats = () => {
  const iSportgeniusBaseUrl = useSelector(
    (state: RootState) => state.config.iSportgeniusBaseUrl
  );
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <iframe src={`${iSportgeniusBaseUrl}`} title="League Stats" />
      </div>
    </div>
  );
};

export default Stats;
