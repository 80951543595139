import React from 'react';
import styles from './Heading.module.css';

type HeadingProps = {};

const Heading = (props: HeadingProps) => (
  <>
    <h3 className={styles.title}>Live Bet</h3>
    <div className={styles.howTo}>
      <span>
        To place a live bet, call <strong>1800 000 000</strong>
      </span>
      <span> and quote your name and the fast code below.</span>
    </div>
  </>
);

export default Heading;
