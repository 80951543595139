/* eslint-disable react/no-array-index-key */
import { first, last } from 'lodash';
import React from 'react';
import SportOddsButton from '../../SportOddsButton/SportOddsButton';
import SportTeamsName from '../../SportTeamsName/SportTeamsName';
import styles from './Teams.module.css';

type TeamsProps = {
  teams: { name: string; outcome: string; price: number }[];
  onClick: () => void;
  sportType: string;
};

const Teams = ({ teams, onClick, sportType }: TeamsProps) => (
  <div
    className={styles.container}
    onClick={onClick}
    onKeyPress={(e) => {
      if (e.key === 'Enter') {
        onClick();
      }
    }}
    role="button"
    tabIndex={0}
  >
    <SportTeamsName
      homeTeam={{ name: first(teams)?.name || 'unknown' }}
      awayTeam={{ name: last(teams)?.name || 'unknown' }}
      sportType={sportType}
    />
    <div className={styles.odds}>
      {teams &&
        teams.map((team, i) => (
          <SportOddsButton
            key={i}
            jerseyIcon="chicago"
            label={team.name}
            odd={team.price}
            className={styles.oddsbutton}
            isSelected={false}
            onOddButtonClick={() => {}}
            reverse={i === teams.length - 1}
          />
        ))}
    </div>
  </div>
);

export default Teams;
