import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import styles from './Accordion.module.css';

type AccordionProps = {
  children: React.ReactNode;
  label: string;
  className?: string;
  onExpand?: (isExpanded: boolean) => void;
};

const Accordion = ({
  children,
  label,
  className,
  onExpand,
}: AccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (onExpand) {
      onExpand(expanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <div className={clsx(styles.container, className)}>
      <div
        role="button"
        tabIndex={0}
        className={clsx(styles.labelContainer, {
          [styles.labelContainerExpanded]: expanded,
        })}
        onClick={() => setExpanded((prev) => !prev)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setExpanded((prev) => !prev);
          }
        }}
      >
        <div className={styles.label}>{label}</div>
        <div className={styles.expand}>
          <Icon name={expanded ? 'caretUp' : 'caretDown'} />
        </div>
      </div>
      <div
        className={clsx(styles.content, { [styles.expandedContent]: expanded })}
      >
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    </div>
  );
};
export default Accordion;
