import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../components/Icon/Icon';
import ShadowButton from '../../../components/ShadowButton/ShadowButton';
import { updateSettings } from '../../../stores/settingSlice';
import { RootState } from '../../../stores/store';
import { PlatformType } from '../../../types/platformType';
import styles from './SelectPlatform.module.css';

export default function SelectPlatform() {
  const dispatch = useDispatch();
  const selectedPriceType = useSelector(
    (state: RootState) => state.setting.selectedPriceType
  );
  const [open, setOpen] = useState(false);
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(4), flip(), shift()],
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <ShadowButton {...getReferenceProps({ ref: reference })}>
        {selectedPriceType} <Icon name="chevron" size="extra-small" />
      </ShadowButton>
      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({
                ref: floating,
                className: styles.popup,
                style: {
                  position: strategy,
                  top: y ?? '',
                  left: x ?? '',
                },
              })}
            >
              <button
                type="button"
                onClick={() => {
                  dispatch(
                    updateSettings({ selectedPriceType: PlatformType.Fixed })
                  );
                  setOpen(false);
                }}
                className={styles.button}
              >
                {PlatformType.Fixed}
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(
                    updateSettings({ selectedPriceType: PlatformType.Tote })
                  );
                  setOpen(false);
                }}
                className={styles.button}
              >
                {PlatformType.Tote}
              </button>
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}
