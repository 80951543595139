import clsx from 'clsx';
import React from 'react';
import useFormat from '../../hooks/useFormat';
import Icon, { IconName } from '../Icon/Icon';
import styles from './SportOddsButton.module.css';

type SportOddsButtonProps = {
  jerseyIcon?: IconName; // TODO change this
  label?: string;
  odd?: number;
  className?: string;
  reverse?: boolean;
  isSelected?: boolean;
  onOddButtonClick?: () => void;
};

const SportOddsButton = ({
  jerseyIcon,
  label,
  odd,
  className,
  reverse,
  onOddButtonClick,
  isSelected = false,
}: SportOddsButtonProps) => {
  const format = useFormat();
  return (
    <button
      type="button"
      className={clsx(
        styles.container,
        className,
        reverse && styles.reverse,
        isSelected && styles.selected
      )}
      onClick={(e) => {
        e.stopPropagation();
        onOddButtonClick && onOddButtonClick();
      }}
    >
      {jerseyIcon && (
        <div className={styles.jersey}>
          <div>
            <Icon name={jerseyIcon} />
          </div>
        </div>
      )}
      <div className={styles.teamName}>
        <span className={styles.label}>{label}</span>
        <span className={styles.odds}>{format.odds(odd)}</span>
      </div>
    </button>
  );
};

export default SportOddsButton;
