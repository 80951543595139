import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import {
  SportCategoriesWithEventsQuery,
  SportCategoriesWithEventsQueryVariables,
} from '../../generated/graphql';
import { SportCategoriesWithEventsDocument } from './../../generated/graphql';
import { TopSportMarkets } from './../../utils/sport/transformers/SportEvents';

const useSportCategoriesEvents = (
  params: SportCategoriesWithEventsQueryVariables
) => {
  const [{ data, ...rest }] = useQuery<
    SportCategoriesWithEventsQuery,
    SportCategoriesWithEventsQueryVariables
  >({
    query: SportCategoriesWithEventsDocument,
    pause: isEmpty(params.categoryIds) && !params.sportTypeId,
    variables: params,
  });

  const events = useMemo(() => {
    if (data?.sportCategories && data.sportCategories.length) {
      const sportCategory = data?.sportCategories[0];
      if (sportCategory.competitions?.length) {
        const competition = sportCategory.competitions[0];
        const allEvents = TopSportMarkets((competition.events as any) || []);
        return allEvents;
      }
    }
    return [];
  }, [data?.sportCategories]);

  return {
    data,
    events,
    ...rest,
  };
};

export default useSportCategoriesEvents;
