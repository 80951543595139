import clsx from 'clsx';
import { MeetingType as MeetingTypeEnum } from '../../generated/graphql';
import Icon, { IconName } from '../Icon/Icon';
import styles from './MeetingType.module.css';

export default function MeetingType({
  meetingType,
  className = '',
}: {
  meetingType: MeetingTypeEnum;
  className?: string;
}) {
  return (
    <div className={clsx(styles.meetingType, styles[meetingType], className)}>
      <Icon name={meetingType as IconName} />
    </div>
  );
}
