import React from 'react';
import styles from './Carousel.module.css';

type CarouselProps = {
  children: React.ReactNode;
};

const Carousel = ({ children }: CarouselProps) => (
  <div className={styles.container}>
    <div className={styles.wrapper}>{children}</div>
  </div>
);

export const CarouselItem = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.item}>{children}</div>
);
Carousel.CarouselItem = CarouselItem;

export default Carousel;
