import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import orderBy from 'lodash/orderBy';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PairedFlucs from '../../../../../components/Flucs/PairedFlucs';
import Icon from '../../../../../components/Icon/Icon';
import Label from '../../../../../components/Label';
import OddsButton from '../../../../../components/OddsButton/OddsButton';
import { BetType } from '../../../../../constants/betTypes';
import {
  PriceType,
  RaceCardQuery,
  RaceStatus,
} from '../../../../../generated/graphql';
import useFormat from '../../../../../hooks/useFormat';
import { addOrRemoveBet } from '../../../../../stores/betslipSlice';
import { RootState } from '../../../../../stores/store';
import { BetslipType } from '../../../../../types/betslipType';
import { LocaleType } from '../../../../../types/localeType';
import { PlatformType } from '../../../../../types/platformType';
import { hasSingleBet } from '../../../../../utils/betslip/common';
import { SortByKey, SortOrder } from '../../../../../utils/race/types';
import RunnerDetails from '../../RunnerDetails/RunnerDetails';
import CompetitorFooter from '../CompetitorFooter';
import fieldStyles from '../Fields.module.css';
import ScratchedCompetitorDeductions from '../ScratchedCompetitorDeductions/ScratchedCompetitorDeductions';
import styles from './WPSField.module.css';

type Props = {
  race: RaceCardQuery['race'];
  onClickRunner?: (runnerId: string) => void;
  competitors?: NonNullable<RaceCardQuery['race']>['competitors'];
};

const selector = createSelector(
  [
    (state: RootState) => state.config.options.locale,
    (state: RootState) => state.setting,
    (state: RootState) => state.config.options.betslipType,
    (state: RootState) => state.betslip.betslip,
    (state: RootState) => state.setting.selectedPriceType,
  ],
  (locale, settings, betslipType, betslip, selectedPriceType) => ({
    locale,
    settings,
    betslipType,
    betslip,
    selectedPriceType,
  })
);

export default function WPSField({ race, competitors, onClickRunner }: Props) {
  const [sortBy, setSortBy] = useState(SortByKey.NAME);
  const [sortDesc, setSortDesc] = useState(false);
  const { locale, settings, selectedPriceType, betslipType, betslip } =
    useSelector(selector);
  const format = useFormat();
  const dispatch = useDispatch();

  const handleChangeSort = useCallback(
    (sortValue: SortByKey) => {
      if (sortValue === sortBy) {
        setSortDesc((prev) => !prev);
      } else {
        setSortBy(sortValue);
        setSortDesc(false);
      }
    },
    [sortBy]
  );

  const renderSortIcon = useCallback(
    (sortValue: string) => {
      if (sortValue !== sortBy) {
        return 'sort';
      }

      return sortDesc ? SortOrder.DESCENDING : SortOrder.ASCENDING;
    },
    [sortBy, sortDesc]
  );

  const getFlucsForCompetitor = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      settings.selectedPriceType === PlatformType.Fixed
        ? competitor.flucs?.filter((fluc) => fluc.price > 0)
        : competitor.toteFlucs?.filter((fluc) => fluc.price > 0),
    [settings.selectedPriceType]
  );

  const getOpenPrice = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      getFlucsForCompetitor(competitor)?.[0]?.price,
    [getFlucsForCompetitor]
  );

  const getWinPrice = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      competitor.prices?.find(
        (price) =>
          price.type ===
          (selectedPriceType === PlatformType.Fixed
            ? PriceType.WinFixedOdds
            : PriceType.Win)
      )?.price,
    [selectedPriceType]
  );

  const getPlacePrice = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      competitor.prices?.find(
        (price) =>
          price.type ===
          (selectedPriceType === PlatformType.Fixed
            ? PriceType.PlaceFixedOdds2
            : PriceType.Place)
      )?.price,
    [selectedPriceType]
  );

  const getShowPrice = useCallback(
    (competitor: NonNullable<RaceCardQuery['race']>['competitors'][0]) =>
      competitor.prices?.find(
        (price) =>
          price.type ===
          (selectedPriceType === PlatformType.Fixed
            ? PriceType.PlaceFixedOdds
            : PriceType.Place) // TODO: Show bet type for tote doesn't exist yet in bet director, but it will be TBD regardless
      )?.price,
    [selectedPriceType]
  );

  const sortedCompetitors = useMemo(() => {
    switch (sortBy) {
      case SortByKey.OP:
        return orderBy(
          competitors,
          ['scratched', getOpenPrice],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
      case SortByKey.WIN:
        return orderBy(
          competitors,
          ['scratched', getWinPrice],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
      case SortByKey.PLACE:
        return orderBy(
          competitors,
          ['scratched', getPlacePrice],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
      case SortByKey.SHOW:
        return orderBy(
          competitors,
          ['scratched', getShowPrice],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
      case SortByKey.NAME:
      default:
        return orderBy(
          competitors,
          ['scratched', 'tabNo'],
          ['asc', sortDesc ? 'desc' : 'asc']
        );
    }
  }, [
    competitors,
    sortBy,
    sortDesc,
    getOpenPrice,
    getWinPrice,
    getPlacePrice,
    getShowPrice,
  ]);

  const showBetTypeEnabled = useMemo(() => locale === LocaleType.US, [locale]);

  return (
    <>
      <div
        className={clsx(fieldStyles.grid, styles.grid, {
          [styles.toteGrid]: showBetTypeEnabled,
        })}
      >
        <Label
          className={clsx(fieldStyles.label, fieldStyles.sortable, {
            [fieldStyles.selected]: sortBy === SortByKey.NAME,
          })}
          onClick={() => handleChangeSort(SortByKey.NAME)}
        >
          RUNNER
          <Icon name={renderSortIcon(SortByKey.NAME)} size="small" />
        </Label>
        <Label
          className={clsx(
            fieldStyles.label,
            fieldStyles.center,
            fieldStyles.sortable,
            fieldStyles.hiddenOnMobile,
            {
              [fieldStyles.selected]: sortBy === SortByKey.OP,
            }
          )}
          onClick={() => handleChangeSort(SortByKey.OP)}
        >
          OPEN
          <Icon name={renderSortIcon(SortByKey.OP)} size="small" />
        </Label>
        <Label
          className={clsx(
            fieldStyles.label,
            fieldStyles.center,
            fieldStyles.hiddenOnMobile
          )}
        >
          TODAY
        </Label>
        <Label
          className={clsx(
            fieldStyles.label,
            fieldStyles.center,
            fieldStyles.sortable,
            {
              [fieldStyles.selected]: sortBy === SortByKey.WIN,
            }
          )}
          onClick={() => handleChangeSort(SortByKey.WIN)}
        >
          WIN
          <Icon name={renderSortIcon(SortByKey.WIN)} size="small" />
        </Label>
        <Label
          className={clsx(
            fieldStyles.label,
            fieldStyles.center,
            fieldStyles.sortable,
            fieldStyles.hiddenOnMobile,
            {
              [fieldStyles.selected]: sortBy === SortByKey.PLACE,
            }
          )}
          onClick={() => handleChangeSort(SortByKey.PLACE)}
        >
          PLACE
          <Icon name={renderSortIcon(SortByKey.PLACE)} size="small" />
        </Label>
        {showBetTypeEnabled ? (
          <Label
            className={clsx(
              fieldStyles.label,
              fieldStyles.center,
              fieldStyles.sortable,
              fieldStyles.hiddenOnMobile,
              {
                [fieldStyles.selected]: sortBy === SortByKey.SHOW,
              }
            )}
            onClick={() => handleChangeSort(SortByKey.SHOW)}
          >
            SHOW
            <Icon name={renderSortIcon(SortByKey.SHOW)} size="small" />
          </Label>
        ) : null}

        {sortedCompetitors.map((competitor, index) => {
          const winPrice = getWinPrice(competitor);
          const placePrice = getPlacePrice(competitor);
          const showPrice = getShowPrice(competitor);

          return (
            <Fragment key={`${competitor.tabNo}:${competitor.selectionID}`}>
              {race?.meeting ? (
                <RunnerDetails
                  meeting={race.meeting}
                  competitor={competitor}
                  onClick={() => onClickRunner?.(competitor.runner.id)}
                />
              ) : null}
              {!competitor.scratched ? (
                <>
                  <div
                    className={clsx(
                      fieldStyles.center,
                      fieldStyles.hiddenOnMobile
                    )}
                  >
                    {format.odds(getOpenPrice(competitor))}
                  </div>
                  <div
                    className={clsx(
                      fieldStyles.center,
                      fieldStyles.hiddenOnMobile
                    )}
                  >
                    <PairedFlucs
                      flucs={getFlucsForCompetitor(competitor)?.map(
                        (fluc) => fluc?.price
                      )}
                      scratched={competitor.scratched}
                    />
                  </div>
                  <div className={fieldStyles.center}>
                    <OddsButton
                      price={winPrice}
                      onClick={() => {
                        if (race) {
                          dispatch(
                            addOrRemoveBet({
                              betType:
                                selectedPriceType === PlatformType.Fixed
                                  ? BetType.WIN_FIXED_ODDS
                                  : BetType.WIN,
                              traditional: betslipType === BetslipType.American,
                              odds: winPrice,
                              race,
                              meeting: race?.meeting,
                              competitor,
                            })
                          );
                        }
                      }}
                      selected={
                        !!race &&
                        hasSingleBet(betslip, {
                          betType:
                            selectedPriceType === PlatformType.Fixed
                              ? BetType.WIN_FIXED_ODDS
                              : BetType.WIN,
                          race,
                          meeting: race?.meeting,
                          competitor,
                        })
                      }
                      disabled={
                        competitor.scratched || race?.status !== RaceStatus.Open
                      }
                    />
                  </div>
                  <div
                    className={clsx(
                      fieldStyles.center,
                      fieldStyles.hiddenOnMobile
                    )}
                  >
                    <OddsButton
                      price={placePrice}
                      onClick={() => {
                        if (race) {
                          dispatch(
                            addOrRemoveBet({
                              betType:
                                selectedPriceType === PlatformType.Fixed
                                  ? BetType.PLACE_FIXED_ODDS2
                                  : BetType.PLACE,
                              traditional: betslipType === BetslipType.American,
                              odds: placePrice,
                              race,
                              meeting: race?.meeting,
                              competitor,
                            })
                          );
                        }
                      }}
                      selected={
                        !!race &&
                        hasSingleBet(betslip, {
                          betType:
                            selectedPriceType === PlatformType.Fixed
                              ? BetType.PLACE_FIXED_ODDS2
                              : BetType.PLACE,
                          race,
                          meeting: race?.meeting,
                          competitor,
                        })
                      }
                      disabled={
                        competitor.scratched || race?.status !== RaceStatus.Open
                      }
                    />
                  </div>
                  {showBetTypeEnabled ? (
                    <div
                      className={clsx(
                        fieldStyles.center,
                        fieldStyles.hiddenOnMobile
                      )}
                    >
                      <OddsButton
                        price={showPrice}
                        onClick={() => {
                          if (race) {
                            dispatch(
                              addOrRemoveBet({
                                betType:
                                  selectedPriceType === PlatformType.Fixed
                                    ? BetType.PLACE_FIXED_ODDS
                                    : BetType.SHOW,
                                traditional:
                                  betslipType === BetslipType.American,
                                odds: showPrice,
                                race,
                                meeting: race?.meeting,
                                competitor,
                              })
                            );
                          }
                        }}
                        selected={
                          !!race &&
                          hasSingleBet(betslip, {
                            betType:
                              selectedPriceType === PlatformType.Fixed
                                ? BetType.PLACE_FIXED_ODDS
                                : BetType.SHOW,
                            race,
                            meeting: race?.meeting,
                            competitor,
                          })
                        }
                        disabled={
                          competitor.scratched ||
                          race?.status !== RaceStatus.Open
                        }
                      />
                    </div>
                  ) : null}
                </>
              ) : (
                <ScratchedCompetitorDeductions
                  competitor={competitor}
                  race={race}
                />
              )}
              <CompetitorFooter competitor={competitor} index={index} />
            </Fragment>
          );
        })}
      </div>
    </>
  );
}
