import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FixedOddsBadge from '../../../../components/Badge/FixedOddsBadge';
import CountryFlag, {
  FlagName,
} from '../../../../components/CountryFlag/CountryFlag';
import HorizontalOverflow from '../../../../components/HorizontalOverflow/HorizontalOverflow';
import Icon from '../../../../components/Icon/Icon';
import RaceStatus from '../../../../components/RaceStatus/RaceStatus';
import { RacesUpcomingQuery } from '../../../../generated/graphql';
import useFormat from '../../../../hooks/useFormat';
import { RootState } from '../../../../stores/store';
import { LocaleType } from '../../../../types/localeType';
import styles from './RaceUpcoming.module.css';

const selector = createSelector(
  [
    (state: RootState) => state.config.options.toteEnabled,
    (state: RootState) => state.config.options.locale,
  ],
  (toteEnabled, locale) => ({
    toteEnabled,
    locale,
  })
);

export default function RaceUpcoming({
  races = [],
}: {
  races?: RacesUpcomingQuery['races'];
}) {
  const format = useFormat();
  const { toteEnabled, locale } = useSelector(selector);
  const navigate = useNavigate();

  return (
    <HorizontalOverflow>
      <div className={styles.grid}>
        {races.length > 0 ? (
          races.map((race) => (
            <React.Fragment key={race.id}>
              <div
                className={styles.row}
                onClick={() =>
                  navigate(`/racing/meeting/${race.meeting.id}/race/${race.id}`)
                }
              >
                <div className={styles.raceDetailContainer}>
                  <Icon
                    name={race.meeting.type}
                    className={styles.icon}
                    size="medium"
                  />
                  <div>
                    <div className={styles.raceDetail}>
                      <span className={styles.raceName}>
                        {race.meeting.track.name}
                      </span>
                      <span className={styles.raceNumber}>R{race.number}</span>
                    </div>
                    <span className={styles.country}>
                      <CountryFlag
                        countryCode={race.meeting.track.country as FlagName}
                        size="small"
                      />
                      {race.meeting.track.country}
                    </span>
                  </div>
                </div>
                {toteEnabled && locale === LocaleType.US ? (
                  <div className={styles.racePool}>
                    <span className={styles.pool}>
                      {format.currency(race.prizeMoney)}
                    </span>
                  </div>
                ) : (
                  <div />
                )}
                <div className={styles.legend}>
                  {/* Dollarsign icon to highlight featured races. TODO */}
                  {/* <Icon name="dollarSign" size="small" className={styles.dollarIcon} /> */}
                  {race.fixedAvailable ? (
                    <span className={styles.tooltip}>
                      <FixedOddsBadge />
                    </span>
                  ) : null}
                  {race.meeting.hasVision ? (
                    <span className={styles.tooltip}>
                      <Icon name="watch" size="small" />
                    </span>
                  ) : null}
                </div>
                <div className={styles.countdown}>
                  <RaceStatus startTime={race.startTime} status={race.status} />
                </div>
              </div>
            </React.Fragment>
          ))
        ) : (
          <span className={styles.empty}>No upcoming races available.</span>
        )}
      </div>
    </HorizontalOverflow>
  );
}
